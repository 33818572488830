import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: `linear-gradient(180deg, #000F11 0%, #001E32 8%, #06031D 100%)`,
        borderRadius: '30px 30px 0px 0px',
        minHeight: '100vh',
        position: 'relative',
        zIndex: 10,
        marginTop: -60,
        marginLeft: -16,
        marginRight: -16,
        padding: theme.spacing(5, 0),
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: -100,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -100,
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(7, 0),
        },

    },
    heading: {
        color: theme.palette.warning.light,
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '2.25rem',
        fontWeight: 400,
        textShadow: `0px 10px 10px ${alpha(theme.palette.warning.main, 0.3)}`,
        marginBottom: 50,
    },
    nameWrapper: {
        marginLeft: 20,
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0,
            textAlign: 'center',
        },
    },
    conselhoItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 25,
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'column',
            marginBottom: 0,
        },
    },
    avatar: {
        border: '2px solid #fff',
        height: 100,
        width: 100,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 25,
            height: 90,
            width: 90,
        },
        [theme.breakpoints.up('md')]: {
            height: 116,
            width: 116,
        },
    },
    nome: {
        color: '#fff',
        fontFamily: "'Poppins', sans-serif",
        lineHeight: 1,
        fontSize: '1.15rem',
        fontWeight: 600,
        marginBottom: '.3em',
    },
    sobrenome: {
        color: '#fff',
        fontFamily: "'Quicksand', sans-serif",
        lineHeight: 1,
        fontSize: '1.5rem',
        fontWeight: 400,
    }
}));