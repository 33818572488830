import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: `linear-gradient(115deg, ${theme.palette.primary.light2} 20%, ${theme.palette.primary.light} 100%)`,
    borderRadius: 10,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    "& .MuiIconButton-root": {
      marginRight: theme.spacing(1),
    },
    "& a": {
      textDecoration: "none",
    },
    "& .MuiTypography-root": {
      lineHeight: 1.2,
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 900,
      color: theme.palette.primary.darker,
    },
  },
}));
