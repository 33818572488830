import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    CardNoticiaRecente: {
        backgroundColor: theme.palette.secondary.dark100,
        color: '#fff',
        borderRadius: 12,
    },
    CardMedia: {
        height: 130,
        margin: '15px 0px 15px 5px',
        borderRadius: 10,
        boxShadow: `5px 5px 20px ${theme.palette.secondary.dark100}`,
        '&.MuiSkeleton-root': {
            backgroundColor: 'rgba(255,255,255,0.4)'
        }
    },
    CardMidiaLarge: { 
        [theme.breakpoints.up('md')]: {
            height: 200,
            margin: '15px 0px 15px 12px',
        }
    },
    CardContent:{
        '& .MuiSkeleton-root': {
            backgroundColor: 'rgba(255,255,255,0.4)'
        }
    },
    titulo: {
        fontWeight: 'bolder',
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.primary.main,
        marginBottom: '0.3em',
        lineHeight: 1.2,
    },
    tituloLG:{
        [theme.breakpoints.up('md')]: {
            fontSize:'1.25rem'
        }
    },
    resumo: {
        fontWeight: 500,
        fontFamily: "'Quicksand', sans-serif",
        color: '#fff',
        fontSize: '.85rem',
        maxHeight: '3.8rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        marginBottom: '0.3em',
        lineHeight: 1.2,
    },
    data: {
        fontWeight: 'bolder',
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.secondary.light,
        fontSize: '.85rem'
    }
}));