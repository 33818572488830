export const cargoPessoa = [
    {
        id: 31,
        descricao: 'Olímpico'
    },

    {
        id: 16,
        descricao: 'Nacional'
    },

    {
        id: 30,
        descricao: 'Internacional'
    },
]


