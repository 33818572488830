import { Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { useStyles } from "../home/styles/Transparencia";
import { CreateMenusTransparencia } from "../../assets/data/transparencia";
import { useEffect, useState } from "react";
import React from "react";
import { Helmet } from "react-helmet";
import TransparenciaRecursosHumanos from "./TransparenciaRecursosHumanos";
import TransparenciaItem from "./TransparenciaItem";
import { governancaApi } from "../../services/api";
import _ from "lodash";

export default function PageTransparencia(params) {
  const { queryTransparencia } = params;
  const classes = useStyles();
  const [menusTransparencia, setMenusTransparencia] = useState([]);

  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [expanded, setExpanded] = useState(false);
  const [expandedAnexos, setExpandedAnexos] = useState(false);
  const [activeIdMenu, setActiveIdMenu] = useState("");

  const handleClickAnexos = (target, index) => {
    setExpandedAnexos({ [index]: !expandedAnexos[index] });
  };

  useEffect(() => {
    async function FetchMenus() {
      await governancaApi
        .get("menu-governanca", { params: { id_pai: 2, sort: "titulo" } })
        .then((response) => {
          if (response.data._meta.totalCount > 0) {
            const menus = CreateMenusTransparencia(response.data.items);
            setMenusTransparencia(menus);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    FetchMenus();
  }, []);

  useEffect(() => {
    if (queryTransparencia && menusTransparencia) {
      const menuObj = _.filter(
        menusTransparencia,
        (o) => o.slug === queryTransparencia
      );
      if (menuObj && menuObj[0]) {
        setActiveIdMenu(menuObj[0].id_menu);
        setExpanded({ [menuObj[0].id_menu]: true });
        setExpandedAnexos(false);
      }
    } else {
      setExpanded(false);
      setExpandedAnexos(false);
    }
  }, [queryTransparencia, menusTransparencia]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          Transparência - Saltos Brasil – Confederação Brasileira de Saltos
          Ornamentais
        </title>
        <meta
          name="description"
          content="Transparência - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais"
        />
      </Helmet>
      <div className={classes.blueBrackground}></div>
      <div className={classes.sectionTop}></div>
      <div className={classes.purpleGradient2}></div>
      <div className={classes.blueGradient}></div>

      <Container className={classes.contentContainer}>
        <Typography className={classes.title}>Transparência CBSO</Typography>
      </Container>
      <Grid
        container
        justifyContent="space-between"
        spacing={matchesMD ? 2 : 1}
        style={{ zIndex: 10 }}
      >
        {menusTransparencia.map((item, key) => {
          if (item.id_menu === 9) {
            return (
              <TransparenciaRecursosHumanos
                key={key}
                item={item}
                index={key}
                expanded={expanded}
                expandedAnexos={expandedAnexos}
                handleClickAnexos={handleClickAnexos}
              />
            );
          } else {
            return (
              <TransparenciaItem
                key={key}
                item={item}
                index={key}
                expanded={expanded}
                idMenu={item.id_menu}
                activeIdMenu={activeIdMenu}
                expandedAnexos={expandedAnexos}
                handleClickAnexos={handleClickAnexos}
              />
            );
          }
        })}
      </Grid>
    </div>
  );
}
