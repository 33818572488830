import { Box, Container, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

function RankingsApoioPage() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.cardWhite}>
          <div className={classes.cardContent}>
            <Typography variant="h1" align="center">
              RANKINGS
            </Typography>
            <Typography variant="subtitle1"></Typography>
            <Typography>Clubes com mais Sócios Torcedores</Typography>
            <Typography>Atletas com mais Fãs</Typography>
            <Typography>Maiores apoiadores</Typography>
          </div>
        </Paper>
      </Container>
    </Box>
  );
}

export default RankingsApoioPage;
