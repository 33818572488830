import React, { useState } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../styles/DuvidasFrequentes";
import {
  QuestionsAccordion,
  QuestionsAccordionDetails,
  QuestionsAccordionSummary,
} from "./components/DuvidasAccordion";

const perguntas = [
  {
    pergunta: "CBSO e Saltos Brasil são a mesma entidade?",
    keyword: "mesma entidade",
    resposta:
      "Sim. CBSO é abreviação de Confederação Brasileira de Saltos Ornamentais e Saltos Brasil é o nome fantasia da Confederação.",
  },
  {
    pergunta: "Qual o endereço da Saltos Brasil?",
    keyword: "endereço",
    resposta:
      "A sede administrativa é localizada no endereço SHCN CL 305, Bloco C, nº 34, 1º andar, Parte 1, Asa Norte, Brasília, DF. CEP: 70737-530 mas nosso atendimento é realizado nas dependências do  Centro Olímpico da Universidade de Brasília.",
  },
  {
    pergunta: "Como entro em contato com a Saltos Brasil?",
    keyword: "contato",
    resposta: `Para entrar em contato conosco, acesse: <a  style="color:#fff" href="mailto:saltosbrasil@cbso.org.br">saltosbrasil@cbso.org.br</a><br/>
      Telefone: (61) 99956-7181<br/>Horário de atendimento: 08h às 18h Segunda a Sexta, Sábado das 08h às 12h.`,
  },
  {
    pergunta: "Onde encontro o calendário de competições?",
    keyword: "calendário de competições",
    resposta:
      "Nos Saltos Ornamentais, as regras de classificação variam de competição para competição. Você pode encontra-las " +
      'clicando <a style="color:#fff" href="https://cbsaltos.bigmidia.com/competições"> AQUI</a>, no boletim de cada competição.',
  },
  {
    pergunta: "Onde encontro as regras para a classificação em competições?",
    keyword: "regras para a classificação",
    resposta:
      "As regras para a classificação em competições você encontra na página principal, clica em COMPETIÇÕES na barra de menu e em seguida você verá o documento Manual CBSO 2017-2020.",
  },
  {
    pergunta: "Como faço para me filiar a Saltos Brasil?",
    keyword: "filiar",
    resposta:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
  },
];

export default function DuvidasFrequentes() {
  const classes = useStyles();

  const [expanded, setExpanded] = useState("panel5");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const questionKeyWord = (question, keyword) => {
    let _question = question.split(keyword);
    return (
      <Typography>
        {_question[0]}
        <span className={classes.keyword}>{keyword}</span>
        {_question[1]}
      </Typography>
    );
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <Typography className={classes.title}>
              Dúvidas <br />
              Frequentes
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={9}>
            <div className={classes.accordionContainer}>
              {perguntas.map((item, key) => (
                <QuestionsAccordion
                  key={key}
                  square
                  expanded={expanded === `panel${key}`}
                  onChange={handleChange(`panel${key}`)}
                >
                  <QuestionsAccordionSummary aria-controls={`panel${key}d-content`} id={`panel${key}d-header`}>
                    {questionKeyWord(item.pergunta, item.keyword)}
                    <span className={classes.numDuvida}>0{key + 1}</span>
                  </QuestionsAccordionSummary>
                  <QuestionsAccordionDetails>
                    <Typography dangerouslySetInnerHTML={{ __html: item.resposta || '' }}/>
                  </QuestionsAccordionDetails>
                </QuestionsAccordion>
              ))}
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
