import { SvgIcon } from "@material-ui/core";

export function LogoDesenhoSalto() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" style={{ height: 130, width: 160 }}>
            <g id="ico---nav---home_2" data-name="ico---nav---home – 2" clipath="url(#clip-ico---nav---home_2)">
                <g id="Group_80" data-name="Group 80" transform="translate(0.999 4)">
                    <g id="Group_67" data-name="Group 67" transform="translate(-0.48 -0.252)">
                        <path id="Path_71" data-name="Path 71" d="M568.075,154.918l.566-.528h-1.557Z" transform="translate(-539.228 -145.392)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_72" data-name="Path 72" d="M568.075,154.918l.566-.528h-1.557Z" transform="translate(-539.228 -145.392)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_73" data-name="Path 73" d="M420.945,204.446v0Z" transform="translate(-405.465 -190.838)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_74" data-name="Path 74" d="M420.945,204.446v0Z" transform="translate(-405.465 -190.838)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_75" data-name="Path 75" d="M420.945,209.063v0Z" transform="translate(-405.465 -195.21)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_76" data-name="Path 76" d="M420.945,209.063v0Z" transform="translate(-405.465 -195.21)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_77" data-name="Path 77" d="M420.945,183.5v0Z" transform="translate(-405.465 -170.295)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_78" data-name="Path 78" d="M420.945,183.5v0Z" transform="translate(-405.465 -170.295)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_79" data-name="Path 79" d="M424.84,89.319l-.633-2.028-.745-2.388-2.517.313,3.887,4.1Z" transform="translate(-405.465 -81.789)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_80" data-name="Path 80" d="M424.84,89.319l-.633-2.028-.745-2.388-2.517.313,3.887,4.1Z" transform="translate(-405.465 -81.789)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_81" data-name="Path 81" d="M452.856,130.948l-.051-.016-.01-.01-1.3-.563,1.752,1.2,3.344,1Z" transform="translate(-433.428 -123.394)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_82" data-name="Path 82" d="M452.856,130.948l-.051-.016-.01-.01-1.3-.563,1.752,1.2,3.344,1Z" transform="translate(-433.428 -123.394)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_83" data-name="Path 83" d="M424.833,92.7l-3.887-4.1,2.587,3.536Z" transform="translate(-405.465 -85.174)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_84" data-name="Path 84" d="M424.833,92.7l-3.887-4.1,2.587,3.536Z" transform="translate(-405.465 -85.174)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_85" data-name="Path 85" d="M466.99,137.074l-.053-.023,0,.007Z" transform="translate(-447.561 -129.521)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_86" data-name="Path 86" d="M466.99,137.074l-.053-.023,0,.007Z" transform="translate(-447.561 -129.521)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_87" data-name="Path 87" d="M420.945,88.6l1.688,4.741.9-1.2Z" transform="translate(-405.465 -85.174)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_88" data-name="Path 88" d="M420.945,88.6l1.688,4.741.9-1.2Z" transform="translate(-405.465 -85.174)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_89" data-name="Path 89" d="M466.858,137.022l0-.007-.008,0Z" transform="translate(-447.48 -129.485)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_90" data-name="Path 90" d="M466.858,137.022l0-.007-.008,0Z" transform="translate(-447.48 -129.485)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_91" data-name="Path 91" d="M420.945,51.1v3.176l2.517-.313Z" transform="translate(-405.465 -50.846)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_92" data-name="Path 92" d="M420.945,51.1v3.176l2.517-.313Z" transform="translate(-405.465 -50.846)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_93" data-name="Path 93" d="M451.414,71.72l.113-3.828-.858,1.441Z" transform="translate(-432.671 -66.218)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_94" data-name="Path 94" d="M451.414,71.72l.113-3.828-.858,1.441Z" transform="translate(-432.671 -66.218)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_95" data-name="Path 95" d="M424.32,52.52,420.945,51.1l2.517,2.863Z" transform="translate(-405.464 -50.846)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_96" data-name="Path 96" d="M424.32,52.52,420.945,51.1l2.517,2.863Z" transform="translate(-405.464 -50.846)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_97" data-name="Path 97" d="M440.883,131.562h2.651l-1.752-1.2Z" transform="translate(-423.714 -123.394)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_98" data-name="Path 98" d="M440.883,131.562h2.651l-1.752-1.2Z" transform="translate(-423.714 -123.394)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_99" data-name="Path 99" d="M567.084,140.3h1.557l-.565-1.3Z" transform="translate(-539.228 -131.299)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_100" data-name="Path 100" d="M567.084,140.3h1.557l-.565-1.3Z" transform="translate(-539.228 -131.299)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_101" data-name="Path 101" d="M579.354,140.3l1.058-1.01-1.624-.293Z" transform="translate(-549.942 -131.299)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_102" data-name="Path 102" d="M579.354,140.3l1.058-1.01-1.624-.293Z" transform="translate(-549.942 -131.299)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_103" data-name="Path 103" d="M424.842,95.61l.442-2.267h-2.651L420.945,88.6v3.042l3.836,3.966Z" transform="translate(-405.464 -85.174)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_104" data-name="Path 104" d="M424.842,95.61l.442-2.267h-2.651L420.945,88.6v3.042l3.836,3.966Z" transform="translate(-405.464 -85.174)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_105" data-name="Path 105" d="M540.294,145.615l.415-.546-2.894-.266Z" transform="translate(-512.438 -136.615)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_106" data-name="Path 106" d="M540.294,145.615l.415-.546-2.894-.266Z" transform="translate(-512.438 -136.615)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_107" data-name="Path 107" d="M470.748,138.5l-3.735-1.18,3.735,1.619-3.786,1.264,4.848-.111-1.062-1.153Z" transform="translate(-447.585 -129.769)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_108" data-name="Path 108" d="M470.748,138.5l-3.735-1.18,3.735,1.619-3.786,1.264,4.848-.111-1.062-1.153Z" transform="translate(-447.585 -129.769)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_109" data-name="Path 109" d="M467.4,144.585l-.442,2.267,3.786-1.264Z" transform="translate(-447.584 -136.416)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_110" data-name="Path 110" d="M467.4,144.585l-.442,2.267,3.786-1.264Z" transform="translate(-447.584 -136.416)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_111" data-name="Path 111" d="M524.219,155.719l3.63-1.326-1.574.058Z" transform="translate(-499.993 -145.394)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_112" data-name="Path 112" d="M524.219,155.719l3.63-1.326-1.574.058Z" transform="translate(-499.993 -145.394)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_113" data-name="Path 113" d="M511.672,155.194l1.063,1.153,2.056-1.268Z" transform="translate(-488.508 -146.022)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_114" data-name="Path 114" d="M511.672,155.194l1.063,1.153,2.056-1.268Z" transform="translate(-488.508 -146.022)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_115" data-name="Path 115" d="M420.945,232.972l.946-1.414-.946-4.58Z" transform="translate(-405.465 -211.832)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_116" data-name="Path 116" d="M420.945,232.972l.946-1.414-.946-4.58Z" transform="translate(-405.465 -211.832)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_117" data-name="Path 117" d="M443.681,162.588l0,0h0Z" transform="translate(-426.271 -152.895)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_118" data-name="Path 118" d="M443.681,162.588l0,0h0Z" transform="translate(-426.271 -152.895)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_119" data-name="Path 119" d="M420.945,153.813v1.961l1.925-1.215Z" transform="translate(-405.465 -144.863)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_120" data-name="Path 120" d="M420.945,153.813v1.961l1.925-1.215Z" transform="translate(-405.465 -144.863)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_121" data-name="Path 121" d="M422.874,162.62h0l-1.925,1.215L422.4,165.5Z" transform="translate(-405.465 -152.923)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_122" data-name="Path 122" d="M422.874,162.62h0l-1.925,1.215L422.4,165.5Z" transform="translate(-405.465 -152.923)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_123" data-name="Path 123" d="M421.891,203.984l.511-7.131-1.457,2.551Z" transform="translate(-405.465 -184.258)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_124" data-name="Path 124" d="M421.891,203.984l.511-7.131-1.457,2.551Z" transform="translate(-405.465 -184.258)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_125" data-name="Path 125" d="M433.3,198.9l-.671-2.044-.511,7.131Z" transform="translate(-415.687 -184.258)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_126" data-name="Path 126" d="M433.3,198.9l-.671-2.044-.511,7.131Z" transform="translate(-415.687 -184.258)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_127" data-name="Path 127" d="M420.945,300.883l1.4-2.774-1.4-.338Z" transform="translate(-405.465 -276.631)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_128" data-name="Path 128" d="M420.945,300.883l1.4-2.774-1.4-.338Z" transform="translate(-405.465 -276.631)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_129" data-name="Path 129" d="M420.945,197.626l1.457-1.035-1.457.631Z" transform="translate(-405.465 -184.019)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_130" data-name="Path 130" d="M420.945,197.626l1.457-1.035-1.457.631Z" transform="translate(-405.465 -184.019)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_131" data-name="Path 131" d="M422.343,282.821l-.452-1.752-.946,1.414Z" transform="translate(-405.464 -261.343)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_132" data-name="Path 132" d="M422.343,282.821l-.452-1.752-.946,1.414Z" transform="translate(-405.464 -261.343)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_133" data-name="Path 133" d="M422.4,165.511l-1.457,1.28v1.293l1.457-2.551,1.233-2.6-.762-.3Z" transform="translate(-405.465 -152.939)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_134" data-name="Path 134" d="M422.4,165.511l-1.457,1.28v1.293l1.457-2.551,1.233-2.6-.762-.3Z" transform="translate(-405.465 -152.939)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_135" data-name="Path 135" d="M420.945,178.963v.3l1.457-.631-1.457-1.662v2Z" transform="translate(-405.465 -166.055)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_136" data-name="Path 136" d="M420.945,178.963v.3l1.457-.631-1.457-1.662v2Z" transform="translate(-405.465 -166.055)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_137" data-name="Path 137" d="M422.871,127.753l0,0v0l.762.3,1.146.444-3.836-3.966v2.48Z" transform="translate(-405.465 -118.057)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_138" data-name="Path 138" d="M422.871,127.753l0,0v0l.762.3,1.146.444-3.836-3.966v2.48Z" transform="translate(-405.465 -118.057)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_139" data-name="Path 139" d="M516.364,145.615l-2.478-.812-2.214.985Z" transform="translate(-488.508 -136.615)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_140" data-name="Path 140" d="M516.364,145.615l-2.478-.812-2.214.985Z" transform="translate(-488.508 -136.615)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_141" data-name="Path 141" d="M420.945,197.626v.245l1.457-1.28Z" transform="translate(-405.465 -184.019)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_142" data-name="Path 142" d="M420.945,197.626v.245l1.457-1.28Z" transform="translate(-405.465 -184.019)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_143" data-name="Path 143" d="M513.885,144.8l-2.214.547v.438Z" transform="translate(-488.508 -136.615)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_144" data-name="Path 144" d="M513.885,144.8l-2.214.547v.438Z" transform="translate(-488.508 -136.615)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_145" data-name="Path 145" d="M420.945,183.359v0Z" transform="translate(-405.465 -170.295)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_146" data-name="Path 146" d="M420.945,183.359v0Z" transform="translate(-405.465 -170.295)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_147" data-name="Path 147" d="M420.945,204.041v0Z" transform="translate(-405.465 -190.838)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_148" data-name="Path 148" d="M420.945,204.041v0Z" transform="translate(-405.465 -190.838)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_149" data-name="Path 149" d="M420.945,208.849v0Z" transform="translate(-405.465 -195.211)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_150" data-name="Path 150" d="M420.945,208.849v0Z" transform="translate(-405.465 -195.211)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_151" data-name="Path 151" d="M256.989,154.918l-.566-.528h1.557Z" transform="translate(-254.874 -145.392)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_152" data-name="Path 152" d="M256.989,154.918l-.566-.528h1.557Z" transform="translate(-254.874 -145.392)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_153" data-name="Path 153" d="M420.946,204.446v0Z" transform="translate(-405.465 -190.838)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_154" data-name="Path 154" d="M420.946,204.446v0Z" transform="translate(-405.465 -190.838)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_155" data-name="Path 155" d="M420.946,209.063v0Z" transform="translate(-405.465 -195.21)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_156" data-name="Path 156" d="M420.946,209.063v0Z" transform="translate(-405.465 -195.21)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_157" data-name="Path 157" d="M420.946,183.5v0Z" transform="translate(-405.465 -170.295)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_158" data-name="Path 158" d="M420.946,183.5v0Z" transform="translate(-405.465 -170.295)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_159" data-name="Path 159" d="M374.955,89.319l.633-2.028.745-2.388,2.517.313-3.887,4.1Z" transform="translate(-363.368 -81.789)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_160" data-name="Path 160" d="M374.955,89.319l.633-2.028.745-2.388,2.517.313-3.887,4.1Z" transform="translate(-363.368 -81.789)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_161" data-name="Path 161" d="M333.955,130.948l.051-.016.01-.01,1.3-.563-1.752,1.2-3.344,1Z" transform="translate(-322.421 -123.394)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_163" data-name="Path 163" d="M375.043,92.7l3.887-4.1-2.587,3.536Z" transform="translate(-363.45 -85.174)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_164" data-name="Path 164" d="M375.043,92.7l3.887-4.1-2.587,3.536Z" transform="translate(-363.45 -85.174)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_165" data-name="Path 165" d="M374.328,137.074l.053-.023,0,.007Z" transform="translate(-362.794 -129.521)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_166" data-name="Path 166" d="M374.328,137.074l.053-.023,0,.007Z" transform="translate(-362.794 -129.521)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_167" data-name="Path 167" d="M392.983,88.6l-1.688,4.741-.9-1.2Z" transform="translate(-377.502 -85.174)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_168" data-name="Path 168" d="M392.983,88.6l-1.688,4.741-.9-1.2Z" transform="translate(-377.502 -85.174)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_169" data-name="Path 169" d="M374.929,137.022l0-.007.008,0Z" transform="translate(-363.345 -129.485)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_170" data-name="Path 170" d="M374.929,137.022l0-.007.008,0Z" transform="translate(-363.345 -129.485)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_171" data-name="Path 171" d="M393.739,51.1v3.176l-2.517-.313Z" transform="translate(-378.258 -50.846)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_172" data-name="Path 172" d="M393.739,51.1v3.176l-2.517-.313Z" transform="translate(-378.258 -50.846)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_173" data-name="Path 173" d="M381.207,71.72l-.113-3.828.858,1.441Z" transform="translate(-368.988 -66.218)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_174" data-name="Path 174" d="M381.207,71.72l-.113-3.828.858,1.441Z" transform="translate(-368.988 -66.218)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_175" data-name="Path 175" d="M381.094,52.52l3.375-1.422-2.517,2.863Z" transform="translate(-368.988 -50.846)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_176" data-name="Path 176" d="M381.094,52.52l3.375-1.422-2.517,2.863Z" transform="translate(-368.988 -50.846)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_177" data-name="Path 177" d="M372.355,131.562H369.7l1.752-1.2Z" transform="translate(-358.563 -123.394)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_178" data-name="Path 178" d="M372.355,131.562H369.7l1.752-1.2Z" transform="translate(-358.563 -123.394)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_179" data-name="Path 179" d="M257.98,140.3h-1.557l.566-1.3Z" transform="translate(-254.874 -131.299)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_180" data-name="Path 180" d="M257.98,140.3h-1.557l.566-1.3Z" transform="translate(-254.874 -131.299)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_181" data-name="Path 181" d="M244.983,140.3l-1.058-1.01,1.624-.293Z" transform="translate(-243.433 -131.299)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_182" data-name="Path 182" d="M244.983,140.3l-1.058-1.01,1.624-.293Z" transform="translate(-243.433 -131.299)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_183" data-name="Path 183" d="M370.208,95.61l3.836-3.966V88.6l-1.688,4.741H369.7l.442,2.267Z" transform="translate(-358.563 -85.174)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_184" data-name="Path 184" d="M370.208,95.61l3.836-3.966V88.6l-1.688,4.741H369.7l.442,2.267Z" transform="translate(-358.563 -85.174)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_185" data-name="Path 185" d="M270.32,145.615l-.415-.546,2.894-.266Z" transform="translate(-267.214 -136.615)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_186" data-name="Path 186" d="M270.32,145.615l-.415-.546,2.894-.266Z" transform="translate(-267.214 -136.615)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_187" data-name="Path 187" d="M318.735,138.5l3.735-1.18-3.735,1.619,3.786,1.264-4.848-.111,1.062-1.153Z" transform="translate(-310.936 -129.769)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_188" data-name="Path 188" d="M318.735,138.5l3.735-1.18-3.735,1.619,3.786,1.264-4.848-.111,1.062-1.153Z" transform="translate(-310.936 -129.769)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_189" data-name="Path 189" d="M333.563,144.585l.442,2.267-3.786-1.264Z" transform="translate(-322.421 -136.416)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_190" data-name="Path 190" d="M333.563,144.585l.442,2.267-3.786-1.264Z" transform="translate(-322.421 -136.416)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_191" data-name="Path 191" d="M278.437,155.719l-3.63-1.326,1.574.058Z" transform="translate(-271.701 -145.394)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_192" data-name="Path 192" d="M278.437,155.719l-3.63-1.326,1.574.058Z" transform="translate(-271.701 -145.394)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_193" data-name="Path 193" d="M296.515,155.194l-1.062,1.153-2.056-1.268Z" transform="translate(-288.717 -146.022)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_194" data-name="Path 194" d="M296.515,155.194l-1.062,1.153-2.056-1.268Z" transform="translate(-288.717 -146.022)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_195" data-name="Path 195" d="M410.723,232.972l-.946-1.414.946-4.58Z" transform="translate(-395.243 -211.832)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_196" data-name="Path 196" d="M410.723,232.972l-.946-1.414.946-4.58Z" transform="translate(-395.243 -211.832)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_197" data-name="Path 197" d="M398.165,162.588l0,0h0Z" transform="translate(-384.613 -152.895)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_198" data-name="Path 198" d="M398.165,162.588l0,0h0Z" transform="translate(-384.613 -152.895)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_199" data-name="Path 199" d="M400.14,153.813v1.961l-1.925-1.215Z" transform="translate(-384.659 -144.863)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_200" data-name="Path 200" d="M400.14,153.813v1.961l-1.925-1.215Z" transform="translate(-384.659 -144.863)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_201" data-name="Path 201" d="M398.172,162.62h0l1.925,1.215-1.457,1.662Z" transform="translate(-384.62 -152.923)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_202" data-name="Path 202" d="M398.172,162.62h0l1.925,1.215-1.457,1.662Z" transform="translate(-384.62 -152.923)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_203" data-name="Path 203" d="M404.251,203.984l-.511-7.131L405.2,199.4Z" transform="translate(-389.716 -184.258)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_204" data-name="Path 204" d="M404.251,203.984l-.511-7.131L405.2,199.4Z" transform="translate(-389.716 -184.258)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_205" data-name="Path 205" d="M395.816,198.9l.671-2.044.511,7.131Z" transform="translate(-382.463 -184.258)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_206" data-name="Path 206" d="M395.816,198.9l.671-2.044.511,7.131Z" transform="translate(-382.463 -184.258)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_207" data-name="Path 207" d="M405.839,300.883l-1.4-2.774,1.4-.338Z" transform="translate(-390.359 -276.631)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_208" data-name="Path 208" d="M405.839,300.883l-1.4-2.774,1.4-.338Z" transform="translate(-390.359 -276.631)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_209" data-name="Path 209" d="M405.2,197.626l-1.457-1.035,1.457.631Z" transform="translate(-389.716 -184.019)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_210" data-name="Path 210" d="M405.2,197.626l-1.457-1.035,1.457.631Z" transform="translate(-389.716 -184.019)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_211" data-name="Path 211" d="M404.442,282.821l.452-1.752.946,1.414Z" transform="translate(-390.359 -261.343)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_212" data-name="Path 212" d="M404.442,282.821l.452-1.752.946,1.414Z" transform="translate(-390.359 -261.343)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_213" data-name="Path 213" d="M390.41,165.511l1.457,1.28v1.293l-1.457-2.551-1.233-2.6.762-.3Z" transform="translate(-376.386 -152.939)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_214" data-name="Path 214" d="M390.41,165.511l1.457,1.28v1.293l-1.457-2.551-1.233-2.6.762-.3Z" transform="translate(-376.386 -152.939)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_215" data-name="Path 215" d="M405.2,178.963v.3l-1.457-.631,1.457-1.662v2Z" transform="translate(-389.716 -166.055)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_216" data-name="Path 216" d="M405.2,178.963v.3l-1.457-.631,1.457-1.662v2Z" transform="translate(-389.716 -166.055)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_217" data-name="Path 217" d="M377.56,127.753l0,0v0l-.762.3-1.146.444,3.836-3.966v2.48Z" transform="translate(-364.004 -118.057)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_218" data-name="Path 218" d="M377.56,127.753l0,0v0l-.762.3-1.146.444,3.836-3.966v2.48Z" transform="translate(-364.004 -118.057)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_219" data-name="Path 219" d="M274.807,145.615l2.479-.812,2.214.985Z" transform="translate(-271.701 -136.615)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_220" data-name="Path 220" d="M274.807,145.615l2.479-.812,2.214.985Z" transform="translate(-271.701 -136.615)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_221" data-name="Path 221" d="M405.2,197.626v.245l-1.457-1.28Z" transform="translate(-389.716 -184.019)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_222" data-name="Path 222" d="M405.2,197.626v.245l-1.457-1.28Z" transform="translate(-389.716 -184.019)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_223" data-name="Path 223" d="M304.076,144.8l2.214.547v.438Z" transform="translate(-298.491 -136.615)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_224" data-name="Path 224" d="M304.076,144.8l2.214.547v.438Z" transform="translate(-298.491 -136.615)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_225" data-name="Path 225" d="M420.946,183.359v0Z" transform="translate(-405.465 -170.295)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_226" data-name="Path 226" d="M420.946,183.359v0Z" transform="translate(-405.465 -170.295)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_227" data-name="Path 227" d="M420.946,204.041v0Z" transform="translate(-405.465 -190.838)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_228" data-name="Path 228" d="M420.946,204.041v0Z" transform="translate(-405.465 -190.838)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_229" data-name="Path 229" d="M420.946,208.849v0Z" transform="translate(-405.465 -195.211)" fill="#001e32" stroke="#fff" strokeLinejoin="round" strokeWidth="0.1" />
                        <path id="Path_230" data-name="Path 230" d="M420.946,208.849v0Z" transform="translate(-405.465 -195.211)" fill="#001e32" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.1" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}