import { alpha, makeStyles, Menu, withStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  AppBar: {
    backgroundColor: theme.palette.warning.main,
    filter: "drop-shadow(0px 1px 10px rgba(0,0,0,0.24))",
  },
  logo: {
    height: 115,
  },
  Toolbar: {
    position: "relative",
    padding: 0,
    minHeight: 55,
  },
  ToolbarHeight: {
    minHeight: 100,
    [theme.breakpoints.up("sm")]: {
      minHeight: 56,
    },
    [theme.breakpoints.up("md")]: {
      minHeight: 100,
    },
    [theme.breakpoints.up("lg")]: {
      minHeight: 56,
    },
  },
  brandWrapper: {
    position: "absolute",
    backgroundColor: theme.palette.warning.main,
    borderBottomRightRadius: 70,
    top: 0,
    left: 10,
    width: "90%",
    transform: "translate(-79%, 0)",
    display: "flex",
    justifyContent: "flex-end",
    zIndex: 10,
    [theme.breakpoints.up("md")]: {
      left: 0,
      transform: "translate(-85%, 0)",
    },
  },
  btnLogo: {
    marginRight: "3.5vw",
    marginTop: 25,
    marginBottom: 10,
  },
  navbar: {
    marginLeft: "auto",
    display: "flex",
  },
  menuButton: {
    marginLeft: "auto",
    display: "flex",
  },
  NavLink: {
    fontWeight: 600,
    color: "#373737",
    fontFamily: "'Poppins', sans-serif",
  },
  searchButton: {
    color: "#373737",
    backgroundColor: "#fff",
    padding: 9,
    "&:hover": {
      color: alpha("#373737", 0.85),
      backgroundColor: alpha("#fff", 0.85),
    },
  },
  searchIcon: {
    fontSize: "0.9rem",
  },
}));

export const SearchMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={2}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));
