import { Grid, Typography } from "@material-ui/core";
import { FacebookSVG, InstagramSVG, SpotifySVG, YoutubeSVG } from "../../../assets/icons/RedesSociais";
import { FacebookBtn, InstagramBtn, SpotifyBtn, YoutubeBtn } from "../../../components/Buttons/Buttons";
import { useStyles } from "../styles/RedesSociais";

export default function RedesSociais() {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.section}>
                <Typography variant="body1" className={classes.textWhite}>Siga nossa equipe nas redes:</Typography>

                <Grid container className={classes.container}>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.btnWrapper}>
                            <FacebookBtn className={classes.svgBtn} href="https://www.facebook.com/saltosbrasil" target="_blank" rel="noreferrer">
                                <FacebookSVG />
                            </FacebookBtn>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.btnWrapper}>
                            <YoutubeBtn className={classes.svgBtn} href="https://www.youtube.com/channel/UCBdyat_VyaN2f-7tamSSmvg" target="_blank" rel="noreferrer">
                                <YoutubeSVG />
                            </YoutubeBtn>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.btnWrapper}>
                            <InstagramBtn className={classes.svgBtn} href="https://www.instagram.com/saltosbrasil/" target="_blank" rel="noreferrer">
                                <InstagramSVG />
                            </InstagramBtn>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.section}>
                <Typography variant="body1" className={classes.textWhite}>Mergulhe nas nossas Playlists!</Typography>

                <Grid container className={classes.container}>
                    <Grid item xs={12} sm={12} md={4}>
                        <div className={classes.btnWrapper}>
                            <SpotifyBtn className={classes.svgBtn}>
                                <SpotifySVG />
                            </SpotifyBtn>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}