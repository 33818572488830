import React from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Paper, Typography } from '@material-ui/core';
import { useStyles } from '../styles/Novidades';
// import img_notica from '../../../assets/images/img_notica.webp'
import { convertDateMonthString } from '../../../util/convertDate';
import { Skeleton } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { createSlug } from "../../../util/createSlug";
import CardsNoticas from './CardsNoticias';
import placeholder_img from '../../../assets/images/placeholder_img.webp'
export default function CardNovidades(props) {
    const classes = useStyles();
    const { noticiaPrincipal, noticias, loadingNoticias, errorNoticias } = props
    // const imgplaceholder = img_notica

    return (
        <div>
            <div className={classes.cardTop}></div>
            <Paper className={classes.card} elevation={0}>
                <div className={classes.cardHeaderLabel}>
                    <Typography variant="h2" className={classes.CardTitle}>Novidades</Typography>
                </div>
                <div className={classes.cardPrincipal}>
                    <Card className={classes.cardNoticiaPrincipal}>
                        {loadingNoticias || errorNoticias ?
                            <Skeleton animation="wave" variant="rect" className={classes.cardNoticiaMedia} />
                            :
                            <CardActionArea component={Link} to={noticiaPrincipal.id ? `/noticias/leitura/${noticiaPrincipal.id}/${createSlug(noticiaPrincipal.titulo)}` : ''}>
                                <CardMedia
                                    className={classes.cardNoticiaMedia}
                                    onError={e => { e.target.src = `${placeholder_img}` }}
                                    component="img"
                                    image={noticiaPrincipal.urlFotoCapa}
                                    title={noticiaPrincipal.titulo}
                                />
                            </CardActionArea>
                        }
                    </Card>
                    <Card className={classes.cardPrincipalContent}>
                        {loadingNoticias || errorNoticias ?
                            <CardContent>
                                <Skeleton animation="wave" height={35} style={{ marginBottom: 16 }} />
                                <Skeleton animation="wave" height={20} width="100%" />
                                <Skeleton animation="wave" height={20} width="85%" />
                                <Skeleton animation="wave" height={20} width="90%" style={{ marginBottom: 16 }} />
                                <Skeleton animation="wave" height={20} width="60%" />
                            </CardContent>
                            :
                            <CardActionArea component={Link} to={noticiaPrincipal.id ? `/noticias/leitura/${noticiaPrincipal.id}/${createSlug(noticiaPrincipal.titulo)}` : ''}>
                                <CardContent>
                                    <Typography className={classes.tituloNoticia} gutterBottom variant="h5" component="h2">
                                        {noticiaPrincipal.titulo}
                                    </Typography>
                                    <Typography className={classes.resumoNoticia} variant="body2" color="textSecondary" component="p">
                                        {noticiaPrincipal.resumo}
                                    </Typography>
                                    <Typography className={classes.dataNoticia} variant="caption" component="p">
                                        {convertDateMonthString(noticiaPrincipal.created_at * 1000)}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        }
                    </Card>
                </div>
                <CardsNoticas noticias={noticias} loadingNoticias={loadingNoticias} />
            </Paper>
            <div className={classes.cardBottom}></div>
        </div>
    )
}