import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    zIndex: 2,
    borderRadius: 12,
    overflow: "hidden",
    background: alpha(theme.palette.primary.white, 0.9),
    boxShadow: "none",
    backdropFilter: "blur(30px)",
    [theme.breakpoints.up("md")]: {
      borderRadius: 40,
    },
    "& .MuiTypography-subtitle1": {
      fontSize: "1rem",
      fontFamily: "'Quicksand', sans-serif",
    },
  },
  cardRoot: {
    padding: theme.spacing(5, 2),
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bold",
    color: theme.palette.primary.darker,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4, 0, 4),
      fontSize: "1.15rem",
    },
  },
  cardWhite: {
    borderRadius: 30,
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bold",
    "& .MuiCardContent-root": {
      padding: theme.spacing(5, 4),
    },
    "& .MuiListItemIcon-root": {
      minWidth: 40,
      "& svg": {
        fontSize: 15,
      },
    },
  },
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4),
    },
  },
  containerTop: {
    paddingBottom: 0,
  },
  cardTop: {
    height: 46,
    borderRadius: 12,
    boxShadow: "0px 1px 10px #010b1a91",
    marginBottom: -38,
    width: "95%",
    margin: "auto",
    background: `linear-gradient(96deg, ${theme.palette.warning.pale} 0%, ${theme.palette.secondary.light} 100%)`,
    [theme.breakpoints.up("md")]: {
      marginBottom: -32,
      borderRadius: "35px 35px 0px 0px",
    },
  },
  cardBottom: {
    height: 58,
    borderRadius: "4px 4px 50px 12px",
    boxShadow: "0px 3px 6px #010B1A",
    marginTop: -40,
    width: "90%",
    background: `linear-gradient(106deg, ${theme.palette.warning.pale} 0%, ${theme.palette.secondary.light} 100%)`,
    [theme.breakpoints.up("md")]: {
      width: "85%",
      height: 68,
      borderRadius: "0px 0px 80px 40px",
    },
  },
  cardContent: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bold",
    color: theme.palette.primary.darker,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4, 0, 4),
      fontSize: "1.15rem",
    },
  },
  heading: {
    fontSize: "1.5rem",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bold",
    color: theme.palette.secondary.dark,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      fontSize: "2.25rem",
      padding: theme.spacing(0, 4),
    },
  },
  List: {
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4),
    },
  },
  ListItem: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bold",
    color: theme.palette.primary.darker,
    maxWidth: 600,
    padding: theme.spacing(1, 0),
    alignItems: "baseline",
    [theme.breakpoints.up("md")]: {
      fontSize: "1.15rem",
      padding: theme.spacing(2, 1),
    },
  },
  listIcon: {
    minWidth: 35,
    height: 13,
    backgroundColor: theme.palette.secondary.dark,
    borderRadius: "3px 3px 20px 3px",
    marginRight: 20,
  },
}));
