import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: `${alpha(theme.palette.primary.dark100, 0.7)}`,
        boxShadow: `0px 3px 6px ${alpha(theme.palette.primary.dark100, 0.1)} `,
        borderRadius: 20,
        position: 'relative',
        backdropFilter: 'blur(30px)',
        color: '#fff',
        paddingBottom: 20,
        width: '90%',
        margin: 'auto',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            width: '75%'
        },
        [theme.breakpoints.up('md')]: {
            width: '80%'
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 50,
            width: '100%'
        },
        '& .sectionTop': {
            height: 20,
            backgroundColor: '#fff',
            maxWidth: 130,
            borderRadius: '10px 3px 20px 10px',
        },
        '& .MuiContainer-root': {
            position: 'relative',
            paddingBottom: 30,
            [theme.breakpoints.up('lg')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        '& .section-1 .sectionTop': {
            position: 'absolute',
            marginTop: -8,
            left: -30,
            backgroundColor: theme.palette.warning.light,
            width: 130,
            [theme.breakpoints.up('lg')]: {
                left: -60,
            },
        },
        '& .section-2 .sectionTop': {
            position: 'absolute',
            marginTop: -8,
            left: -30,
            backgroundColor: theme.palette.info.light,
            width: 80,
            [theme.breakpoints.up('lg')]: {
                left: -60,
            },
        },
        '& .section-3 .sectionTop': {
            position: 'absolute',
            marginTop: -8,
            left: -30,
            width: 60,
            [theme.breakpoints.up('lg')]: {
                left: -60,
            },
        },
        '& .section-1 .MuiTypography-root': {
            color: theme.palette.warning.light
        },
        '& .section-2 .MuiTypography-root': {
            color: theme.palette.info.pale
        },
        '& .section-3 .MuiTypography-root': {
            color: '#fff'
        }
    },
    section: {
        paddingTop: 20,
        '& .MuiButtonBase-root': {
            borderRadius: 12,
            padding: theme.spacing(1, 2.5),
            fontSize: '0.9rem',
            fontWeight: 'bolder',
            fontFamily: "'Quicksand', sans-serif",
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(1.3, 3.5),
                fontSize: '1rem',
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(2, 4.5),
                fontSize: '1.12rem',
            }
        },
        '& .btnWrapper': {
            marginTop: 20,
            marginBottom: 20,
            textAlign: 'center',
            [theme.breakpoints.up('lg')]: {
                marginTop: 40,
                marginBottom: 30,
            },
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 50,
        },
    },
    sectionText: {
        fontSize: '1.1rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 300,

        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.5rem',
        },
    },

    btn1: {
        background: `linear-gradient(45deg, ${theme.palette.warning.light} 10%, ${theme.palette.warning.dark} 100%)`,
        border: `2px solid ${theme.palette.warning.light}`,
    },
    btn2: {
        background: `linear-gradient(108deg, ${theme.palette.warning.pale} 0%, ${theme.palette.info.light} 52%,  ${theme.palette.primary.main} 100%)`,
        border: `2px solid ${theme.palette.info.darker}`,
    },
    btn3: {
        backgroundColor: 'transparent',
        border: '2px solid #fff',
        color: '#fff',
        '&:hover': {
            backgroundColor: 'transparent',
        }
    },
}));