import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        [theme.breakpoints.up('sm')]: {
            marginTop: 20,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 40,
        }
    },

    container: {

    },
    card: {
        border: `2px solid ${theme.palette.primary.pale}`,
        backgroundColor: theme.palette.secondary.dark100,
        height: 72,
        margin: '15px',
        borderRadius: 12,
        '& svg': {
            fontSize: '2.2rem'
        },
        '& .MuiCardActionArea-root': {
            padding: 20,
            textAlign: 'left',
            color: '#fff',
            height: '100%'
        },
        [theme.breakpoints.up('sm')]: {
            width: '80%',
            margin: '15px auto',
            '& .MuiGrid-grid-xs-2': {
                textAlign: 'center',
            },
        },
        [theme.breakpoints.up('md')]: {
            '& .MuiCardActionArea-root': {
                textAlign: 'center',
                padding: '20px 0',
            }, 
            margin: '0 auto',
            height: 156,
            width: 143,
            '& svg': {
                fontSize: '3.5rem'
            },
        },
        [theme.breakpoints.up('lg')]: {
            height: 165,
            width: 165,
            '& svg': {
                fontSize: '5rem'
            },
        },

    },
    CardContent: {
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'column'
        }
    },
    textLink: {
        color: '#fff',
        fontSize: '1.2rem',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        [theme.breakpoints.up('md')]: {
            marginTop: '.4em',
        }
    }
}));