import _ from "lodash";

export const calculaSalto = (salto, isSincronizado) => {
  let fatorSincronizado = 1;
  let NotaFinal = 0;

  if (isSincronizado) {
    if (salto.notasSaltosSinc) {
      fatorSincronizado = 0.6;
      if (salto.notasSaltosSinc[0] && salto.notasSaltosSinc[1]) {
        const salto1 = CalculaSaltoComPenalidade({ notas: salto.notasSaltosSinc[0].notasSaltos, penalidade: salto.nota_penalidade });
        const salto2 = CalculaSaltoComPenalidade({ notas: salto.notasSaltosSinc[1].notasSaltos, penalidade: salto.nota_penalidade });
        NotaFinal = (salto1 + salto2)*fatorSincronizado ;
       
      }
    } else {
      NotaFinal = 0;
    }
  } else {
    if (salto.notasSaltos) {
      NotaFinal = CalculaSaltoComPenalidade({
        notas: salto.notasSaltos,
        penalidade: salto.nota_penalidade,
      });
    } else {
      NotaFinal = 0;
    }
  }

  return NotaFinal;
};

function CalculaSaltoComPenalidade({ notas, penalidade }) { 
  const notasValidas = _.filter(notas, (o) => !o.stroke);
  const convertNotas = notasValidas.map((item) =>
    parseFloat(item.nota.replace(",", "."))
  ); 
  const _penalidade = penalidade || 0

  // soma se penalidade menos resultado for maior que zero
  const total = convertNotas.reduce(
    (prevValue, currentValue) =>
      prevValue +
      (currentValue - _penalidade > 0 ? currentValue - _penalidade : 0),
    0
  );

  return total;
}

 