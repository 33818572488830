import { alpha, makeStyles, Tab, Tabs, withStyles } from "@material-ui/core";

export const AntTabs = withStyles((theme) => ({
  root: {
    overflow: "unset",
    minHeight: "unset",
    borderBottom: "3px solid #e8e8e8",
    marginTop: 10,
    marginBottom: 0,
    "&.centered": {
      "& .MuiTabs-flexContainer": { justifyContent: "center" },
    },
    "& .MuiTabs-scrollButtons": {
      display: "inline-flex",
      color: "#F5F8FB",
      opacity: 1,
      "& svg": {
        fontSize: "2rem",
      },
    },
    "& .MuiTabs-scrollButtons.Mui-disabled": {
      opacity: 0,
    },
    "& .MuiTabs-scroller": {
      "& .MuiTabs-flexContainer": {
        alignItems: "center",
      },
    },
    "& .MuiButtonBase-root": {
      margin: 0,
    },
  },
  indicator: {
    display: "none",
  },
}))(Tabs);

export const AntTab = withStyles((theme) => ({
  root: {
    overflow: "unset",
    textTransform: "none",
    minWidth: "unset",
    padding: theme.spacing(0.8, 2.5),
    minHeight: "unset",
    color: "#fff",
    opacity: 1,
    borderRadius: 12,
    border: "none",
    transition: "background 250ms ease-in-out",
    maxWidth: "fit-content",
    maxHeight: "unset",
    "& .MuiTab-wrapper": {
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 900,
      [theme.breakpoints.up("md")]: {
        fontSize: "1.1rem",
      },
    },
    "&$selected": {
      background: `linear-gradient(106deg, #FFFFFF 0%, #F5F8FB 52%, #D4EEFF 100%)`,
      color: theme.palette.primary.darker,
      fontWeight: theme.typography.fontWeightMedium,
      boxShadow: "2px 2px 6px #16113840",
      border: "none",
    },
    "&:focus": {
      color: theme.palette.primary.darker,
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 1),
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(0, 2),
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.dark80,
    position: "relative",
    zIndex: 10,
    borderRadius: "0px 0px 30px 30px",
    paddingBottom: 100,
    marginBottom: 40,
    paddingTop: 50,
  },
  nomeAtleta: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 400,
    fontSize: "2.2rem",
    color: theme.palette.warning.light,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.6rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem",
    },
  },
  sobrenomeAtleta: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 800,
    fontSize: "1.25rem",
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.7rem",
    },
  },
  infoAtleta: {
    marginTop: 20,
    marginBottom: 20,
  },
  descricaoAtleta: {
    display: "flex",
    flexDirection: "row",
    alignItems: "base-line",
    marginBottom: 6,
    "& .titulo": {
      fontSize: ".9rem",
      fontWeight: 600,
      fontFamily: "'Quicksand', sans-serif",
      color: theme.palette.info.main,
      marginRight: ".3rem",
    },
    "& .dado": {
      fontSize: ".9rem",
      fontWeight: 500,
      fontFamily: "'Quicksand', sans-serif",
      color: theme.palette.info.pale,
    },
    "& .dado-categoria": {
      fontSize: ".9rem",
      fontWeight: 600,
      fontFamily: "'Quicksand', sans-serif",
      color: theme.palette.warning.main,
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiTypography-root": {
        fontSize: "1rem",
      },
    },
  },
  fotoAtleta: {
    height: 160,
    width: 160,
    [theme.breakpoints.up("sm")]: {
      height: 200,
      width: 200,
    },
    [theme.breakpoints.up("md")]: {
      height: 250,
      width: 250,
    },
    [theme.breakpoints.up("lg")]: {
      height: 310,
      width: 310,
    },
  },
  wrapperFotoAtleta: {
    display: "flex",
    justifyContent: "center",
    height: "100%",
    alignItems: "flex-start",
  },
  wrapperFotoAtletaEspecial: {
    alignItems: "end",
  },
  redesSociais: {
    marginLeft: -10,
    marginRight: -16,
    transition: "250ms all ease-in-out",
    "& .MuiButtonBase-root": {
      padding: 9,
      color: "#fff",
      position: "relative",
      "&:hover": {
        backgroundSize: 0,
        backgroundColor: "none",
        "& svg": {
          position: "relative",
          zIndex: 10,
        },
      },
      "&:after": {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        content: "''",
        display: "block",
        zIndex: 0,
        position: "absolute",
        transform: "scale(0)",
        transition: "0.3s cubic-bezier(0.01, 0.9, 0.58, 1)",
        borderRadius: 40,
        backgroundColor: "rgba(255,255,255,0.2)",
      },
      "&:hover:after": {
        transform: "scale(1)",
      },
      "&.Mui-disabled": {
        opacity: 0.7,
      },
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 0,
      marginRight: 0,
      "& .MuiButtonBase-root": {
        padding: 12,
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& svg": {
        fontSize: "2rem",
      },
    },
  },
  twitter: {
    "&.MuiButtonBase-root:hover": {
      color: "#1DA1F2",
    },
  },
  facebook: {
    "& svg .inner": {
      fill: "#001e32",
    },
    "& svg .bg-facebook": {
      fill: "#fff",
    },
    "&.MuiButtonBase-root:hover": {
      "& svg .inner": {
        fill: "#fff",
      },
      "& svg .bg-facebook": {
        fill: "url(#jsc_s_5)",
      },
    },
  },
  instagram: {
    "&.MuiButtonBase-root:hover": {
      "& .st0": {
        fill: "url(#SVGID_1_)",
      },
      "& .st1": {
        fill: "url(#SVGID_2_)",
      },
      "& .st2": {
        fill: "#654C9F",
      },
    },
  },
  tiktok: {
    "&.MuiButtonBase-root:hover": {
      "& svg": {
        filter:
          "drop-shadow(-2px -1px 0px #00f2ea) drop-shadow(2px 1px 0px #ff0050)",
      },
    },
  },
  cardResultados: {
    marginTop: -8,
    position: "relative",
  },
  cardTop: {
    height: 40,
    borderRadius: 16,
    maxWidth: "96%",
    margin: "auto",
    background: `linear-gradient(98deg, ${theme.palette.info.light} 0%, ${theme.palette.secondary.light} 100%)`,
    marginBottom: -33,
    [theme.breakpoints.up("lg")]: {
      marginBottom: -25,
    },
  },
  cardRoot: {
    position: "relative",
    minHeight: 600,
    borderRadius: 20,
    backdropFilter: "blur(30px)",
    boxShadow: "none",
    backgroundColor: alpha(theme.palette.primary.white, 0.9),
    padding: theme.spacing(3, 0.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3, 4),
    },
  },
  cardContainer: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 2.5),
    },
  },
  cardBottom: {
    height: 50,
    borderRadius: 16,
    background: `linear-gradient(98deg, ${theme.palette.info.light} 0%, ${theme.palette.primary.light} 100%)`,
    marginTop: -40,
    width: "70%",
    [theme.breakpoints.up("lg")]: {
      marginTop: -35,
    },
  },

  rankingAtleta: {
    fontWeight: 600,
    fontFamily: "'Quicksand', sans-serif",
    color: theme.palette.info.dark,
    fontSize: "1.2rem",
    "& span": {
      fontWeight: 900,
      fontSize: "2.5rem",
    },
    "& b": {
      fontSize: "1.35rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.4rem",
      "& span": {
        fontSize: "2.7rem",
      },
      "& b": {
        fontSize: "1.55rem",
      },
    },
  },
  GridPtsAtleta: {
    marginTop: "auto",
    marginBottom: "0.4rem",
  },
  ptsAtleta: {
    fontWeight: 600,
    fontFamily: "'Quicksand', sans-serif",
    color: theme.palette.error.main,
    textAlign: "right",
    "& span": {
      fontSize: "1.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.5rem",
      "& span": {
        fontSize: "2rem",
      },
    },
  },
  tabLine: {
    height: 4,
    background: `linear-gradient(91deg, ${theme.palette.info.light} 0%,  ${theme.palette.secondary.light} 100%)`,
  },
  subtitle1: {
    color: theme.palette.info.main,
    fontWeight: 400,
    fontFamily: "'Quicksand', sans-serif",
    fontSize: "1.25rem",
    lineHeight: 1.2,
    marginTop: 40,
    textAlign: "center",
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  btnSpotify: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
  },
  svgBtn: {
    width: "90%",
    margin: "auto",
    height: 120,
    marginBottom: 20,
    "& svg": {
      fontSize: "14rem",
      color: theme.palette.warning.white,
    },
    [theme.breakpoints.up("lg")]: {
      width: "unset",
      marginBottom: 0,
    },
  },
  TabList: {
    overflow: "unset",
    minHeight: "unset",
    borderBottom: "3px solid #e8e8e8",
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
    "& .MuiTabs-scroller": {
      overflow: "unset !important",
    },
    marginTop: 10,
    marginBottom: -8,
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  Tab: {
    overflow: "unset",
    textTransform: "none",
    minWidth: 72,
    padding: theme.spacing(0.8, 2.5),
    minHeight: "unset",
    color: theme.palette.primary.dark100,
    opacity: 1,
    borderRadius: 12,
    border: "none",
    transition: "background 250ms ease-in-out",
    "& .MuiTab-wrapper": {
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 900,
      [theme.breakpoints.up("md")]: {
        fontSize: "1.1rem",
      },
    },
    "&.Mui-selected": {
      background: `linear-gradient(106deg, #FFFFFF 0%, #F5F8FB 52%, #D4EEFF 100%)`,
      color: theme.palette.primary.darker,
      fontWeight: theme.typography.fontWeightMedium,
      boxShadow: "2px 2px 6px #16113840",
      border: "none",
    },
    "&:focus": {
      color: theme.palette.primary.darker,
    },
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 1),
    },
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(0, 2),
    },
  },
  TabPanelTable: {
    padding: 0,
  },
  loadingDark: {
    marginBottom: 20,
    "& .MuiSkeleton-root": {
      background: "#ffffff29",
    },
  },
  sekeletonAvatarSM: {
    backgroundColor: "#ffffff29",
    width: 40,
    height: 40,
  },
  sekeleton: {
    backgroundColor: "#ffffff29",
  },
  sekeletonAvatar: {
    backgroundColor: "#ffffff29",
    height: 160,
    width: 160,
    [theme.breakpoints.up("sm")]: {
      height: 200,
      width: 200,
    },
    [theme.breakpoints.up("md")]: {
      height: 250,
      width: 250,
    },
    [theme.breakpoints.up("lg")]: {
      height: 310,
      width: 310,
    },
  },
}));
