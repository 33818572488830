import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            lg2: 1600,
            xl: 1920,
        },
    },
    palette: {
        primary: {
            dark100: '#00101a',
            dark80: '#001E32',
            darker: '#004b7d',
            dark: '#0071bc',
            main: '#008be7',
            light: '#3eb2ff',
            pale: '#d4eeff',
            white: '#f5f8fb',
            contrastText: '#000',
        },
        secondary: {
            dark100: '#06031D',
            dark80: '#161138',
            darker: '#21166b',
            dark: '#382a92',
            main: '#4938bf',
            light: '#8677f1',
            light2: '#C2B1F2',
            pale: '#F3F2F6',
            contrastText: '#fff',
        },
        info: {
            dark100: '#000f11',
            darker: '#004a53',
            dark: '#006f7d',
            main: '#1eb1c4',
            light: '#32e8ff',
            pale: '#ccf9ff',
            contrastText: '#fff',
        },
        warning: {
            darker: '#eecf00',
            dark: '#ffde00',
            main: '#f3e061',
            light: '#ffed77',
            pale: '#fff8cc',
            white: '#FFFDEE',
            contrastText: '#000',
        },
        error: {
            dark: '#b13a21',
            main: '#fe5330',
            light: '#eb806b',
            contrastText: '#fff',
        },

        gold: {
            light: '#caaf4a',
            main: '#e8ce50',
            dark: '#846c14',
            contrastText: '#000',

        },
        silver: {
            light: '#F3F2F6',
            main: '#C9CDD0',
            dark: '#BDC2C5',
            contrastText: '#000',

        },
        bronze: {
            light: '#9c7a3c',
            main: '#CB9D8B',
            dark: '#846c14',
            contrastText: '#000',

        }

    },
});

export default theme;