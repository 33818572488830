import {
  Avatar,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";
import TagEscopo from "../../../components/Tags/TagEscopo";
import { formatDatePeriod } from "../../../util/formatDatePeriod";
import { useStyles } from "../style/CardEvento";
import { convertDate } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import { Link } from "react-router-dom";

export default function CardEvento(props) {
  const classes = useStyles();
  const { tipoCard, loadingEventos, evento } = props;

  const matchesSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <div className={classes.root}>
      <Card
        className={clsx(classes.Card, {
          [classes.CardSecondary]: tipoCard === 1,
        })}
      >
        <CardActionArea
          component={Link}
          to={
            evento
              ? `/${tipoCard === 0 ? "resultado" : "competicao"}/${
                  evento.id
                }/${createSlug(evento.descricao)}`
              : ""
          }
        >
          <div className={classes.CardActionArea}>
            {loadingEventos ? (
              <Skeleton variant="rect" className={classes.CardMedia} />
            ) : (
              <CardMedia image="" className={classes.CardMedia} />
            )}
            <div className={classes.EventInfo}>
              <Typography variant="caption" className="dataEvento">
                {loadingEventos ? (
                  <Skeleton variant="text" width={180} height={16} />
                ) : (
                  formatDatePeriod(evento.data_inicio, evento.data_fim, true)
                )}
              </Typography>
              <Typography variant="subtitle1" className={classes.tituloEvento}>
                {loadingEventos ? (
                  <Skeleton variant="text" width={"100%"} height={25} />
                ) : (
                  evento.descricao
                )}
              </Typography>
              {tipoCard === 1 ? (
                <React.Fragment>
                  {!matchesSm ? (
                    <Typography className={classes.dataLimite}>
                      {loadingEventos
                        ? ""
                        : `INSCREVA-SE ATÉ ${convertDate(
                            evento.data_limit_inscricao
                          )}`}
                    </Typography>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              ) : (
                ""
              )}
              <div className={classes.localEvento}>
                <Typography variant="caption">
                  <Avatar />
                  {loadingEventos ? (
                    <Skeleton variant="text" width={150} height={16} />
                  ) : (
                    "--"
                  )}
                </Typography>
                {matchesSm ? (
                  <Typography variant="caption">
                    <RoomIcon />
                    {loadingEventos ? (
                      <Skeleton variant="text" width={150} height={16} />
                    ) : (
                      evento.local
                    )}
                  </Typography>
                ) : (
                  <React.Fragment>
                    <TagEscopo
                      escopo={loadingEventos ? "" : evento.escopo.toLowerCase()}
                      label={loadingEventos ? "" : evento.escopo.slice(0, 3)}
                      size="small"
                    />
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className={classes.cardTag}>
              <TagEscopo
                escopo={loadingEventos ? "" : evento.escopo.toLowerCase()}
                label={
                  matchesSm
                    ? `${loadingEventos ? "" : evento.escopo}`
                    : `${loadingEventos ? "" : evento.escopo.slice(0, 3)}.`
                }
                size="small"
              />
              {tipoCard === 1 ? (
                <Typography className={classes.dataLimite}>
                  {loadingEventos
                    ? ""
                    : `INSCREVA-SE ATÉ ${convertDate(
                        evento.data_limit_inscricao
                      )}`}
                </Typography>
              ) : (
                ""
              )}
            </div>
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
}
