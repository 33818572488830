export const categoriasAtletas = [
    {
        id: 1,
        sigla: "Aberto"
    },
    {
        id: 2,
        sigla: "A"
    },
    {
        id: 3,
        sigla: "B"
    },
    {
        id: 4,
        sigla: "C"
    },
    {
        id: 5,
        sigla: "D"
    },
]


