import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: '80px 0',
        paddingBottom: 60,
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: 50,
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 150,
        }
    },
    Container: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    },
    paper: {

        background: `linear-gradient(180deg, #EEC600 0%, ${theme.palette.warning.light} 2%, ${theme.palette.warning.light} 100%)`,
        borderRadius: 40,
        minHeight: '200vh',
        padding: theme.spacing(6, 3),
        [theme.breakpoints.up('sm')]: {
            position: 'relative',
            padding: theme.spacing(7, 4.5),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(8, 6),
        },
    },
    heading: {
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '2rem',
        fontWeight: 500,
        color: theme.palette.warning.dark80,
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.25rem',
            paddingLeft: '4rem',
            marginBottom: '1.5rem'
        },
    },
    herosWrapper: {
        [theme.breakpoints.up('lg')]: {
            maxWidth: '90%',
            margin: 'auto',
        }
    },
    wrapperShadow: {
        filter: `drop-shadow(0px 0px 16px ${theme.palette.warning.dark})`,
    },
    PaperNossosAtletas: {
        backgroundColor: theme.palette.warning.light,
        position: 'relative',
        zIndex: 10,
        marginTop: -160,
        background: `linear-gradient(180deg, #FFED77 0%, #F3E061 100%)`,
        borderRadius: 20,
        boxShadow: 'none',
        padding: theme.spacing(0.5, 2),
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(4.5, 10),
        }
    },
    contentTitle: {
        fontSize: '1.5rem',
        color: theme.palette.primary.dark100,
        fontWeight: 'bold',
        marginTop: '1.2rem',
        marginBottom: '1rem',
    },
    avatarWrapper: {
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    AvatarBorder: {
        height: 56,
        width: 56,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        background: `linear-gradient(134deg, #32E8FF 0%, #4938BF 100%, #000000 100%)`,
        [theme.breakpoints.up('sm')]: {
            height: 104,
            width: 104,
        },
        [theme.breakpoints.up('lg')]: {
            height: 120,
            width: 120,
        },
    },
    Avatar: {
        height: 50,
        width: 50,
        border: '2px solid #fff',
        [theme.breakpoints.up('sm')]: {
            height: 94,
            width: 94,
        },
        [theme.breakpoints.up('lg')]: {
            height: 108,
            width: 108,
        },
    },
    nomeAtleta: {
        // textAlign: 'center',
        fontFamily: "'Poppins', sans-serif",
        textDecoration: 'none',
        margin: '0.5rem 0',
        fontSize: '0.85rem',
        fontWeight: 600,
        color: theme.palette.primary.dark100,
        textAlign: 'center',
        textTransform: 'uppercase',
        minHeight: '5rem',
        '&:hover':{
            color: theme.palette.primary.darker,
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
            margin: '1rem 0',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.2rem',
            minHeight: '3rem'
        },
    },
    linkWrapper: {
        margin: '1.25rem 0',
        textAlign: 'center',
    },
    section: {
        marginBottom: '2rem',
    },
    textSection: {
        margin: '40px 0',
        [theme.breakpoints.up('lg')]: {
            maxWidth: '90%',
            margin: '50px auto',
        }
    },
    sectionTitle: {
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: theme.palette.secondary.darker,
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.25rem',
        }
    },

    titleDevider: {
        height: 2,
        backgroundColor: theme.palette.secondary.darker,
        margin: '20px 0'
    },
    sectionContent: {
        color: theme.palette.secondary.dark80,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        lineHeight: 1.4,
        marginBottom: '0.5rem',
        padding: theme.spacing(0, 2)
    },

    entendaSection: {
        minHeight: 500,
        marginLeft: -12,
        marginRight: -12,
        [theme.breakpoints.up('sm')]: {
            minHeight: 620,
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 800,
            marginLeft: 0,
            marginRight: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: -100,
            marginBottom: -85,
            minHeight: 0,
        }
    },
    ListItem: {
        fontSize: '1rem',
        fontWeight: 600,
        fontFamily: "'Quicksand', sans-serif",
        lineHeight: 1.4,
        color: theme.palette.secondary.darker,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.25rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.5rem',
        }
    },
    listItemIcon: {
        height: 13,
        minWidth: 25,
        borderRadius: ' 3px 3px 20px 3px',
        marginRight: 15,
        background: theme.palette.secondary.darker,
        [theme.breakpoints.up('sm')]: {
            marginRight: 25,
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 40,
        }
    },
    contrastColor: {
        color: theme.palette.warning.light,
    },
    contrastBackground: {
        background: theme.palette.warning.light,
    },
}));