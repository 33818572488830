import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from "@material-ui/core";
import { Pagination, Skeleton } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { portalApi } from "../../../services/api";
import { convertDateMonthString } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import { useStyles } from "../styles/MateriasTab";
import placeholder_img from "../../../assets/images/placeholder_img.webp";
import clsx from "clsx";

export default function CompeticoesTab() {
    const classes = useStyles();
    const [noticias, setNoticias] = useState({
        payload: [],
        loading: true,
        error: false,
    });

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        setNoticias({
            payload: [],
            loading: true,
            error: false
        })
        async function getNoticias() {
            await portalApi.get('noticia', {
                params: {
                    flag_del: 0,
                    flag_publicado: 1,
                    id_estabelecimento: 1,
                    expand: 'evento',
                    forcejoin: 'eventos',
                    notNullField: 'eventos.id_evento',
                    sort: '-created_at',
                    lessField: 'created_at',
                    lessValue: (new Date()).getTime().toString().substr(0, 10),
                    pageSize: 12,
                    page: page,
                },
            }).then((response) => {
                setTotalPages(response.data._meta.pageCount)
                if (response.data._meta.totalCount === 0) {
                    setNoticias({
                        payload: [],
                        loading: false,
                        error: true
                    })
                } else {
                    setNoticias({
                        payload: response.data.items,
                        loading: false,
                        error: false
                    })

                }
            }).catch((err) => {
                setNoticias({
                    payload: [],
                    loading: false,
                    error: true
                })
                console.log(err)
            });
        }
        getNoticias()
    }, [page])

    const sectionTop = useRef(null);
    const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: sectionTop.current.offsetTop - 0 });

    const handleChange = (event, value) => {
        setPage(value);
        executeScroll()
    };



    return (
        <div ref={sectionTop}>
            <div className={clsx(classes.cardTop, classes.cardTopSecondary)}></div>
            <div className={clsx(classes.root, classes.rootSecondary)}>
                <Container className={classes.content}>
                    <Typography className={clsx(classes.heading, classes.headingSecondary)} variant="h2">Competições</Typography>
                    <div className={classes.noticiasWrapper}>
                        <Grid container>
                            {(noticias.loading || noticias.error ? Array.from(new Array(8)) : noticias.payload).map((item, key) => (
                                <Grid keu={key} item xs={12} sm={12} md={12} lg={3}>
                                    <Card className={classes.CardNoticiaRecente}>
                                        <CardActionArea component={Link} to={item ? `/noticias/leitura/${item.id}/${createSlug(item.titulo)}` : ''}>
                                            <div className={classes.cardSpacing}>
                                                <Grid container>
                                                    <Grid item xs={4} sm={4} md={3} lg={12}>
                                                        {noticias.loading || noticias.error ?
                                                            <Skeleton animation="wave" variant="rect" className={classes.CardMedia} />
                                                            :
                                                            <CardMedia className={classes.CardMedia}
                                                                onError={e => { e.target.src = `${placeholder_img}` }}
                                                                component="img"
                                                                src={item.urlFotoCapa}
                                                                title={item.titulo}
                                                            />
                                                        }
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={9} lg={12}>
                                                        <CardContent className={classes.CardContent}>
                                                            <Typography variant="subtitle2" className={classes.titulo}>
                                                                {item ? item.titulo : <Skeleton animation="wave" variant="text" width="80%" />}
                                                            </Typography>
                                                            <Typography variant="body2" className={classes.resumo}>
                                                                {item ? item.resumo : <Skeleton animation="wave" variant="text" width="100%" height={60} />}
                                                            </Typography>
                                                            <Typography variant="caption" className={classes.data}>
                                                                {item ? convertDateMonthString(item.created_at * 1000) : <Skeleton animation="wave" variant="text" width="60%" />}
                                                            </Typography>
                                                        </CardContent>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </div>
                    {totalPages > 1 ?
                        <div className={clsx(classes.paginationContainer, classes.paginationContainerSecondary)}>
                            <Pagination
                                page={page}
                                count={totalPages}
                                onChange={handleChange}
                            />
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                    }
                </Container>
            </div>
            <div className={clsx(classes.cardBottom, classes.cardBottomSecondary)}></div>
        </div>
    )
}