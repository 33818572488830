import { makeStyles } from "@material-ui/core";
import posterHome from '../../../assets/images/poster_home.webp';
import water_seamless_transp from '../../../assets/images/water_seamless_transp.webp';

export const useStyles = makeStyles((theme) => ({
    rootGradient: {
        background: 'linear-gradient(160deg,#004b7d 0%,#0078c8 27%,#008be7 64%, #47abe9 84%,#fffdee 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed',
        paddingTop: 30,
    },
    root: {
        position: 'relative',
        backgroundImage: `url(${posterHome})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 1300,
        marginTop: -50,
        [theme.breakpoints.up('md')]: {
            backgroundSize: 1445,
        },
        [theme.breakpoints.up('lg')]: {
            backgroundSize: 1600,
            backgroundPosition: 'top left',
        },
        [theme.breakpoints.up('lg2')]: {
            backgroundPosition: 'top center',
        }
    },
    aguaBG: {
        backgroundImage: `url(${water_seamless_transp})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 600,
        backgroundPosition: 'center',
        height: '60%',
        width: '100%',
        position: 'absolute',
        top: 667,
        opacity: 0.3,
        left: 0,
        [theme.breakpoints.up('md')]: {
            top: 600,
        },
        [theme.breakpoints.up('lg')]: {
            top: 667,
        },
    },
    bgGradient1: {
        background: 'linear-gradient(180deg, #ffffff00 0%, #008be729  100%)',
        position: 'absolute',
        top: 667,
        opacity: 0.6,
        left: 0,
        height: '200vh',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            top: 600,
        },
        [theme.breakpoints.up('lg')]: {
            top: 667,
        },
    },


    regua: {
        position: 'absolute',
        right: 10,
        top: 55,
        '& svg': {
            zIndex: 0,
            height: '100%',
            fontSize: '1.74rem',
        },
        [theme.breakpoints.up('md')]: {
            top: 50,
            '& svg': {
                height: 1140,
                fontSize: '1.98rem',
            },
        },
        [theme.breakpoints.up('lg')]: {
            right: 25,
            '& svg': {
                height: 1295,
                fontSize: '2.2rem',
            },
        },
    },
    container: {
        position: 'relative',
        '& section': {
            margin: '40px 0',
            [theme.breakpoints.up('sm')]: {
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            [theme.breakpoints.up('md')]: {
                margin: '100px 0',
            },
            [theme.breakpoints.up('lg')]: {
                margin: '80px 0',
            }
        }
    },

    cardContainer: {
        [theme.breakpoints.up('sm')]: {
            marginLeft: 'auto',
            marginRight: 'auto',
            maxWidth: '80%',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '95%',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 'unset',
        }
    },
    containerEntenda: {
        marginLeft: 'auto',
        marginRight: 0,
        paddingRight: 0,
        [theme.breakpoints.up('lg')]: {
            maxWidth: '60%',
        }
    },
    sectionEntenda: {
        margin: '60px 0',
        paddingBottom: 30,
        [theme.breakpoints.up('sm')]: {
            margin: '70px 0',
            paddingLeft: '20%',
        },
        [theme.breakpoints.up('md')]: {
            margin: '90px 0',
            paddingLeft: '25%',
        },
        [theme.breakpoints.up('lg')]: {
            margin: '130px 0',
            paddingLeft: 0,
        }
    },
    divider: {
        backgroundColor: theme.palette.warning.light,
        height: 4,
        [theme.breakpoints.up('md')]: {
            height: 6
        }
    }
}));