import { Avatar, Typography, Grid } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { MedalSVG } from "../../../assets/icons/LogosTemplate";
import { createSlug } from "../../../util/createSlug";
import { nomeProva } from "../../competicao-item/functions/nomeProva";
import { useStyles } from "../styles/TableResultados";

export default function TableResultados(props) {
    const classes = useStyles();
    const { eventoAtleta, eventos } = props;

    const renderMedal = (colocacao) => {
        if (colocacao === 1 || colocacao === 2 || colocacao === 3) {
            return (
                <div className={`tipo-${colocacao}`} >
                    <MedalSVG />
                </div>
            )
        }
    }

    const eventoInfo = (eventoID, tipoDado) => {
        if (!eventos.loading) {
            let evento = _.filter(eventos.eventos, function (o) { return o.id === eventoID })

            if (evento[0] && tipoDado === 'descricao') {
                return evento[0].descricao
            }

            if (evento[0] && tipoDado === 'arte') {
                return evento[0].arte_evento
            }

            return eventoID
        } else {
            return ''
        }
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.header}>
                <Grid item xs={7}>
                    Competição
                </Grid>
                <Grid item style={{ marginLeft: 'auto' }}>
                    Pts.
                </Grid>
                <Grid item>
                    Pos.
                </Grid>
            </Grid>
            {eventoAtleta.loading ?
                ''
                :
                <React.Fragment>
                    {eventoAtleta.eventoAtletas.map((item, key) => (
                        <div key={key} className={classes.resultadosEventoItem}>
                            <div className={classes.infoEvento}>
                                <Avatar src={eventoInfo(item[0].id_evento, 'arte')} variant="rounded" />
                                <Link to={`/resultado/${item[0].id_evento}/${createSlug(eventoInfo(item[0].id_evento, 'descricao'))}`}>
                                    <Typography variant="subtitle1">
                                        {eventoInfo(item[0].id_evento, 'descricao')}
                                    </Typography>
                                </Link>
                            </div>
                            <Grid container>
                                {item.map((item, key) => (
                                    <Grid item xs={12} key={key}>
                                        <div className={classes.competicao}>
                                            <Typography className={classes.nomeProva}>
                                                {nomeProva(item.eventoProva)}
                                            </Typography>
                                            <div className={classes.medal}>
                                                {renderMedal(item.colocacao_f)}
                                            </div>
                                            <div className={classes.pts}>
                                                {item.notaTotalProva ? item.notaTotalProva : '-'}
                                            </div>
                                            <div className={classes.pos}>
                                                {item.colocacao_f ? `${item.colocacao_f}º` : '-'}
                                            </div>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    ))}
                </React.Fragment>
            }
        </div>
    )
}