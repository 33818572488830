import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    List: {
        '& li': {
            alignItems: 'baseline',
        },
        '& p': {
            color: theme.palette.primary.dark,
            fontWeight: 400,
            fontFamily: "'Quicksand', sans-serif",
        },
        '& li:before': {
            content: "''",
            height: 15,
            minWidth: 30,
            backgroundColor: theme.palette.primary.dark,
            borderRadius: '0px 3px 20px 0px',
            marginRight: 10,
        }
    },
    tableWrapper: {
        width: 'auto',
        [theme.breakpoints.up('xs')]: {
            width: 200,
        },
        [theme.breakpoints.up('sm')]: {
            width: 240,
        },
        [theme.breakpoints.up('md')]: {
            width:'auto'
        }
    },
    TabelaGridContainer: {
        marginBottom: 30,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
        }
    },
    tabelaGrid: {
        marginTop: 30,
        maxWidth: 400,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: {
            maxWidth: '100%',
        }
    },
    Table: {
        '& td, & th': {
            border: 'none',
            padding: '0 5px',
            textAlign: 'center',
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 700,
        },
        '& td': {
            color: theme.palette.primary.darker,
        },
        '& th': {
            textAlign: 'center',
            color: theme.palette.primary.dark,
        },
        [theme.breakpoints.up('md')]: {
            minWidth: 350,
        }
    },
    Divider: {
        backgroundColor: `${theme.palette.primary.dark} !important`,
        marginBottom: 5,
        '&.MuiDivider-flexItem': {
            marginLeft: 10,
            marginRight: 10,
            [theme.breakpoints.up('md')]: {
                marginLeft: 15,
                marginRight: 15,
            }
        },
    },
    DividerHorizontal: {
        height: 2,
        backgroundColor: `${theme.palette.primary.dark} !important`,
        width: '100%',
    },
    notasTableWrapper: {
        display: 'flex',
    },
    strikeTD: {
        opacity: 0.5,
        textDecoration: 'line-through',
    },
    notaParcial: {
        fontSize: '.9rem',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    numGD: {
        textAlign: 'center',
        fontSize: '.9rem',
        color: theme.palette.primary.darker, 
    },
    notaFinal: {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',
        fontWeight: 'bold',
        maxWidth: 80,
    }
}));