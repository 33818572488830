import { Avatar, Collapse, List, ListItem, Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect } from "react";
import { useStyles, Accordion, AccordionSummary } from '../styles/AccordionPodium';

export default function AccordionPodium(props) {
    const { eventoProva } = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [coloados, setColocados] = React.useState([])
    const handleChange = () => {
        setExpanded(!expanded);
    };


    const nomeProva = (prova) => {
        let fase = '';
        if (prova.tipo_fase === 'F') {
            fase = 'Final'
        }

        return `${fase} ${prova.sexo === 'F' ? 'Feminino' : 'Masculino'} ${prova.altura.descricao} ${prova.altura.descricao === prova.aparelho.descricao ? '' : prova.aparelho.descricao} ${prova.classe.descricao}`
    }

    useEffect(() => {
        let classificacao = _.orderBy(eventoProva.classificacao, ['colocacao'], ['asc'])
        
        setColocados(classificacao)
        // console.log(eventoProva)
    }, [eventoProva])

    return (
        <div className={classes.root}>
            <Accordion expanded={expanded} onChange={handleChange} className={classes.Accordion}>
                <AccordionSummary>
                    <Typography className={classes.heading}>
                        {nomeProva(eventoProva)}
                    </Typography>
                </AccordionSummary>
            </Accordion>
            <Collapse in={expanded} className={classes.Collapse}>
                <List className={classes.ListPodium}>
                    {coloados.map((item, key) => {
                        if (item.colocacao !== null && item.colocacao < 4) {
                            return (
                                <ListItem key={key} className={`pos-${item.colocacao}`}>
                                    <Avatar />
                                    <div>
                                        <Typography variant="body2" className={classes.nome}>
                                            {item.nome_completo.split(' ')[0]}
                                        </Typography>
                                        <Typography variant="body2" className={classes.sobrenome}>
                                            {item.nome_completo.split(' ').slice(1).join(' ')}
                                        </Typography>
                                    </div>
                                    <div className={classes.pontuacao}>
                                        {item.nota_final}
                                    </div>
                                </ListItem>
                            )
                        } else {
                            return (
                                <div key={key}></div>
                            )
                        }
                    }
                    )}
                </List>
            </Collapse>
        </div>
    )
}