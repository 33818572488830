export const testURLstr = (url) => {
    if (url) {
        let urlStr = url.toLowerCase()

        if (urlStr.includes('https://') || urlStr.includes('www')) {
            return urlStr
        } else {

            return ''
        }
    } else {
        return ''
    }
}