export const saltoAovivo = [
    {
        online: 0,
        saltando: {
            prova: {
                tipo_fase: 'F',
                sexo: 'F',
                altura: { id: 6, sigla: "5M / 7,5M / 10M", descricao: "5M / 7,5M / 10M"},
                aparelho: { id: 2, descricao: "PLATAFORMA", sigla: "PLAT", tipo_aparelho: "I" },
                classe: { id: 2, descricao: "Grupo A", sigla: "A" },
            },
            atleta: {
                id: 312,
                id_atleta_par: 456,
                foto: '',
                nome_evento: "KAYLLANE REIS"
            },
            saltoAtual: {
                numSalto: 4,
                num_gd: 3.2,
                ptsParcial: 60.3,
                metaPts: 75.5,
                rankingParcial: 5,
                tipoSalto: {
                    codigo: 103,
                    nome_pt: "1½ Mortal para Frente",
                }
            },
        },
        podioParcial: [
            {
                id: 312,
                id_atleta_par: 456,
                colocacao: 1,
                foto: '',
                nome_evento: "KAYLLANE REIS",
                notaFinal: 255.3,
            },
            {
                id: 312,
                id_atleta_par: 456,
                colocacao: 2,
                foto: '',
                nome_evento: "KAYLLANE REIS",
                notaFinal: 255.3,
            },
            {
                id: 312,
                id_atleta_par: 456,
                colocacao: 3,
                foto: '',
                nome_evento: "KAYLLANE REIS",
                notaFinal: 255.3,
            },
        ],
        evento: {
            id: 123,
            urlLive: '',
            arte_evento: '',
            descricao: "Olimpíadas 2024",
            texto: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        }
    },
]