import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    tituloNoticiaCards: {
        color: theme.palette.info.dark,
        fontSize: '0.9rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bolder',
        marginBottom: 5,
        maxHeight: '3rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        lineHeight: 1,
        [theme.breakpoints.up('sm')]: {
            WebkitLineClamp: '1',
            fontSize: '1rem',
            maxHeight: '1.5rem',
            lineHeight: 1.2,
        },
        [theme.breakpoints.up('lg')]: {
            WebkitLineClamp: '2',
            maxHeight: '3.5rem',
            fontSize: '1.15rem',
        }
    },
    resumoNoticiaCards: {
        color: theme.palette.secondary.dark80,
        fontSize: '0.8rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        marginBottom: 5,
        maxHeight: '3.5rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical',
        lineHeight: 1.1,
        [theme.breakpoints.up('sm')]: {
            WebkitLineClamp: '3',
            fontSize: '0.9rem',
            lineHeight: 1.2,
        },
        [theme.breakpoints.up('lg')]: {
            maxHeight: '7rem',
            WebkitLineClamp: '5',
            fontSize: '1rem',
        }
    },
    dataNoticia: {
        color: theme.palette.info.main,
        fontSize: '0.8rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 700,
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1rem',
        }
    },
    containerNoticias: {
        padding: theme.spacing(0, 1.2, 1.5, 1.2),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        }
    },
    noticiasWrapper: {
        borderRadius: 6,
        boxShadow: 'none',
        minHeight: 100,
        padding: theme.spacing(2,0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2.5, 2.5),
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 20,
            maxWidth: '85%',
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
        }
    },
    cardNoticias: {
        width: '100%',
        boxShadow: 'none',
        [theme.breakpoints.up('lg')]: {
            height: '100%',
        },
        '& .MuiCardActionArea-root': {
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: 10,
            [theme.breakpoints.up('lg')]: {
                padding: 0,
                paddingTop: 10,
                display: 'block',
                alignItems: 'center',
                flexDirection: 'column',
            }
        }
    },
    cardNoticiasContent: {
        padding: theme.spacing(0, 2),
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: 'unset',
            padding: theme.spacing(2),
        }
    },
    cardNoticiasMedia: {
        height: '100px !important',
        minWidth: 110,
        boxShadow: `5px 5px 20px ${alpha(theme.palette.info.dark, 0.2)}`,
        borderRadius: 10,
        [theme.breakpoints.up('sm')]: {
            minWidth: 124,
            width: 180,
        },
        [theme.breakpoints.up('lg')]: {
            margin: 'auto',
            height: '166px !important',
            width: 'calc(100% - 32px) !important',
        }
    },
    link: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
        marginTop: 15,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        }
    }
}));