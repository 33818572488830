export const categoriasPessoas = [
    {
        id: 1,
        descricao: "FINA"
    },
    {
        id: 2,
        descricao: "UANA"
    },
    {
        id: 3,
        descricao: "CONSANAT"
    },
    {
        id: 4,
        descricao: "NACIONAL"
    },
    {
        id: 5,
        descricao: "ESTADUAL"
    },
]


