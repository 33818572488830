import { Avatar, Card, CardContent, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { EmailOutlineSVG, FacebookOutlineSVG, InstagramOutlineSVG, LinkedInOutlineSVG, TwitterOutlineSVG } from "../../../assets/icons/RedesSociais";
import { useStyles } from "../style/ConselhoAdministrativo";

export default function ConselhoAdministrativo() {
    const classes = useStyles();

    const diretoria = [
        {
            nome: 'Ricardo',
            sobrenome: 'Moreira',
            cargo: 'Presidente',
            biografia: 'Chefe de equipe dos Jogos Olímpicos de 2016 e 2020, técnico dos atletas Hugo Parisi e César Castro e idealizador do ' +
              'Centro de Excelência da UnB.',
            redesSociais: {
                linkedIn: "",
                twitter: "",
                instagram: "",
                facebook: "",
                email: ""
            }
        },
        {
            nome: 'Verônica',
            sobrenome: 'Lopes',
            cargo: 'Vice Presidente',
            biografia: 'Auditora interna de certificação ISO 9001 Diretora de Saltos Ornamentais e membro do conselho deliberativo do Esporte Clube Pinheiros.',
            redesSociais: {
                linkedIn: "",
                twitter: "",
                instagram: "",
                facebook: "",
                email: ""
            }
        },

        {
            nome: 'Aurélio',
            sobrenome: 'Araújo',
            cargo: 'Membro Independente do Conselho e Administração',
            biografia: 'Consultor de políticas públicas para crianças e adolescentes, tendo ocupado o cargo de Secretário de Estado do DF na área.',
            redesSociais: {
                linkedIn: "",
                twitter: "",
                instagram: "",
                facebook: "",
                email: ""
            }
        },
        {
            nome: 'Tony',
            sobrenome: 'Pellicer',
            cargo: 'Membro Independente do Conselho e Administração',
            biografia: 'CFO de multinacionais com extensa experiência no Brasil e na América Latina.',
            redesSociais: {
                linkedIn: "",
                twitter: "",
                instagram: "",
                facebook: "",
                email: ""
            }
        },
        {
            nome: 'Tammy',
            sobrenome: 'Galera',
            cargo: 'Representante dos Atletas',
            biografia: 'Atleta olímpica e especialista em gestão esportiva.',
            redesSociais: {
                linkedIn: "",
                twitter: "",
                instagram: "",
                facebook: "",
                email: ""
            }
        },
    ]

    return (
        <div className={classes.root}>
            <Typography variant="h1" className={classes.heading}>Conselho <br />Administrativo</Typography>
            <Grid container justifyContent="center" spacing={2}>
                {diretoria.map((item, key) => (
                    <Grid key={key} item xs={12} sm={9} md={6}>
                        <Card className={classes.cardDiretoria}>
                            <CardContent className={classes.cardContent}>
                                <Grid container alignItems="center">
                                    <Grid item xs={3}>
                                        <Avatar className={classes.avatarDiretor} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <div className={classes.nameWrapper}>
                                            <Typography className="nome">
                                                {item.nome}
                                            </Typography>
                                            <Typography className="sobrenome">
                                                {item.sobrenome}
                                            </Typography>
                                            <Typography className="cargo">
                                                {item.cargo}
                                            </Typography>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Divider className={classes.Divider} />
                                <Typography className={classes.biografia}>
                                    {item.biografia}
                                </Typography>
                                <div className={classes.redesSociais}>
                                    <IconButton className={classes.btnSocial} size="small">
                                        <LinkedInOutlineSVG />
                                    </IconButton>
                                    <IconButton className={classes.btnSocial} size="small">
                                        <TwitterOutlineSVG />
                                    </IconButton>
                                    <IconButton className={classes.btnSocial} size="small">
                                        <InstagramOutlineSVG />
                                    </IconButton>
                                    <IconButton className={classes.btnSocial} size="small">
                                        <FacebookOutlineSVG />
                                    </IconButton>
                                    <IconButton className={classes.btnSocial} size="small">
                                        <EmailOutlineSVG />
                                    </IconButton>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}
