import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core';

export const QuestionsAccordion = withStyles((theme) => ({
    root: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion);

export const QuestionsAccordionSummary = withStyles((theme) => ({
    root: {
        borderBottom: '5px solid #000F1140',
        marginBottom: -1,
        minHeight: 56,
        display: 'flex',
        alignItems: 'center',
        '&$expanded': {
            minHeight: 56,
            borderBottom: '5px solid transparent',
        },
        '& .MuiTypography-root': {
            fontWeight: 500,
            letterSpacing: '0.05em',
            color: '#fffdee',
            fontFamily: "'Quicksand', sans-serif",  
            textTransform: 'uppercase',
            fontSize:'0.9rem',
            [theme.breakpoints.up('md')]: {
                fontSize:'1.2rem'
            }
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

export const QuestionsAccordionDetails = withStyles((theme) => ({
    root: {
        backgroundColor: '#000F1140',
        borderRadius: 20,
        padding: theme.spacing(2.5),
        color: '#fff',
    },
}))(MuiAccordionDetails);