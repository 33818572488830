import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  galeriaItem: {
    backgroundColor: "#fff",
    height: 180,
    transition: "all ease-in-out 200ms",
    cursor: "pointer",
    overflow: "hidden",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "top",
      transition: "all 250ms ease-in-out",
    },
    "&:hover": {
      "& img": {
        opacity: 0.85,
        transform: "scale(1.1)",
        overflow: "hidden",
      },
    },
  },
  creditosGaleria: {
    marginTop: 12,
    "& .MuiTypography-root": {
      fontSize: 13,
      letterSpacing: ".05em",
      textTransform: "uppercase",
      fontFamily: "'Poppins', sans-serif",
    },
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    "& .MuiPaginationItem-root": {
      fontSize: "1rem",
      fontWeight: "bolder",
      fontFamily: "'Quicksand', sans-serif",
      color: theme.palette.info.main,
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: alpha(theme.palette.info.light, 0.1),
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: alpha(theme.palette.info.light, 0.3),
      color: theme.palette.info.main,
    },
  },
}));
