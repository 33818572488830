import { Card, CardContent, CardMedia, Container, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Slide, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom'
import { siglasEstados } from "../../../assets/data/estadosBR";
import { gestaoApi } from "../../../services/api";
import { useStyles } from "../style/Arenas";
import CloseIcon from '@material-ui/icons/Close';
import { formataCEP, formataTelefone } from "../../../util/formatString";
import { Alert, Skeleton } from "@material-ui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export default function Arenas() {
    const classes = useStyles();

    const [lugar, setLugar] = useState({
        loading: true,
        error: false,
        arenas: []
    });
    const [ufArenas, setUFArenas] = React.useState('');
    const [selectedUF, setSelectedUf] = React.useState('');

    useEffect(() => {
        setLugar({
            loading: true,
            error: false,
            arenas: []
        })

        let params = {
            flag_del: 0,
            expand: 'municipio',
            pageSize: 6,
        }
        if (selectedUF) {
            params = {
                ...params,
                'municipio.uf': selectedUF,
                pageSize: 12,
            }
        }

        async function getArenas() {
            await gestaoApi.get('lugar', {
                params: params,
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setLugar({
                        loading: false,
                        error: true,
                        arenas: []
                    })
                } else {
                    setLugar({
                        loading: false,
                        error: false,
                        arenas: response.data.items
                    })
                }
            }).catch((error) => {
                console.error(error)
                setLugar({
                    loading: false,
                    error: true,
                    arenas: []
                })
            })
        }
        getArenas()
    }, [selectedUF])

    const handleChangeUF = (event, newValue) => {
        setUFArenas(newValue);
        setSelectedUf(siglasEstados[newValue].descricao)
    };

    const fullScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));

    const [open, setOpen] = React.useState(false);
    const [lugarAtual, setLugarAtual] = useState({});

    const handleClickOpen = (lugar) => {
        setOpen(true);
        setLugarAtual(lugar)
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={classes.root}>
            <div className={classes.arenaBG}></div>
            <div className={classes.arenasWrapper}>
                <Container>
                    <Typography variant="h6" className={classes.heading}>Arenas</Typography>
                    <Typography variant="subtitle1" className={classes.subHeading}>Selecione os Estados</Typography>

                    <Tabs value={ufArenas} onChange={handleChangeUF} className={classes.TabList}>
                        {siglasEstados.map((item, key) => (
                            <Tab key={key} className={classes.Tab} label={item.descricao} index={key} />
                        ))}
                    </Tabs>

                    <Grid container spacing={3}>
                        {(lugar.loading ? Array.from(new Array(2)) : lugar.arenas).map((item, key) => (
                            <Grid item xs={12} sm={12} md={6} key={key}>
                                <div className={classes.lugarWrapper}>
                                    <div className={classes.cardBackdrop}></div>
                                    <Card className={classes.cardLugar} elevation={0}>
                                        {lugar.loading ?
                                            <Skeleton variant="rect" className={classes.CardMedia} />
                                            :
                                            <CardMedia className={classes.CardMedia} image={`https://saltosbrasil.bigmidia.com/_uploads/lugar/${item.foto}`} alt={item.descricao} />
                                        }

                                        <CardContent className={classes.CardContent}>

                                            <Typography className={classes.tituloLugar}>
                                                {lugar.loading ?
                                                    <React.Fragment>
                                                        <Skeleton variant="text" width="100%" />
                                                        <Skeleton variant="text" width="50%" />
                                                    </React.Fragment>
                                                    : item.descricao}
                                            </Typography>
                                            <Grid container className={classes.linksLugar}>
                                                {/* <Grid item>
                                                    <RouterLink to={'/noticias'}>Destaques</RouterLink>
                                                </Grid>
                                                <Grid item>
                                                    <RouterLink to={'/competicoes'}>Eventos</RouterLink>
                                                </Grid> */}
                                                <Grid item>
                                                    {lugar.loading ? '' : <Link onClick={() => handleClickOpen(item)}>Contato</Link>}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </div>
                            </Grid>
                        ))}
                        {lugar.error ?
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    Nenhuma arena encontrada.
                                </Alert>
                            </Grid>
                            :
                            ''
                        }
                    </Grid>
                </Container>
            </div>

            <Dialog
                open={open}
                fullScreen={fullScreen}
                onClose={handleClose}
                TransitionComponent={Transition}
                keepMounted
            >
                <DialogTitle>
                    {lugarAtual ? lugarAtual.descricao : ''}
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                {lugarAtual ?
                    <DialogContent dividers>
                        {lugarAtual.biografia ?
                            <React.Fragment>
                                <Typography variant="h6">Biografia:</Typography>
                                {lugarAtual.biografia}
                            </React.Fragment>
                            :
                            ''
                        }

                        <Typography variant="h6">Contato:</Typography>
                        <Typography variant="body1" gutterBottom>
                            {lugarAtual.telefone_fixo ? `Tel: ${formataTelefone(lugarAtual.telefone_fixo)}` : ''}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {lugarAtual.telefone_celular ? `Celular: ${formataTelefone(lugarAtual.telefone_celular)}` : ''}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {lugarAtual.email ? ` Email: ${lugarAtual.email}` : ''}
                        </Typography>
                        <Typography variant="h6">Endereço:</Typography>
                        <Typography variant="body1" gutterBottom>
                            {lugarAtual.tipo_logradouro}  {lugarAtual.logradouro}, {lugarAtual.numero} - {lugarAtual.bairro},
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            {lugarAtual.municipio ? `${lugarAtual.municipio.descricao} - ${lugarAtual.municipio.uf}` : ''}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            CEP: {lugarAtual.cep ? formataCEP(lugarAtual.cep) : ''}
                        </Typography>

                    </DialogContent>
                    :
                    ''
                }
            </Dialog>
        </div>
    )
}
