import { alpha, makeStyles, withStyles } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles((theme) => ({
    root: {
        backgroundColor: '#fff',
        borderRadius: '12px !important',
        boxShadow: 'none',
        [theme.breakpoints.up('md')]: {
            borderRadius: '20px !important',
        },
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            '& .MuiBox-root': {
                width: '100%',
            }
        },
    },
    expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            '& .MuiIconButton-root': {
                transform: 'rotate(45deg)',
            }
        },
        '& .MuiIconButton-root': {
            padding: 4,
            backgroundColor: '#E5FCFF',
            color: theme.palette.info.main,
            marginRight: 0,

            '& svg': {
                fontSize: '1.6rem',
                [theme.breakpoints.up('md')]: {
                    fontSize: '2rem',
                },
            }
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(.5, 1),
    },
}))(MuiAccordionDetails);

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(16),
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        flexShrink: 0,
    },
    titleBorder: {
        width: 20,
        height: 4,
        borderRadius: 3,
        backgroundColor: theme.palette.info.main,
        transition: 'all 200ms ease-in-out'
    },
    ListPodium: {
        paddingLeft: 0,
        marginLeft: '-1rem',
        marginRight: '-1rem',
        '& li': {
            paddingLeft: 0,
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            '& .MuiAvatar-root': {
                marginRight: 10,
                height: 48,
                width: 48,
                color: '#fff',
                backgroundColor: '#fff',
                border: `1px solid ${theme.palette.info.main}`,
                [theme.breakpoints.up('lg')]: {
                    marginLeft: 20,
                }
            },
            '& .MuiTypography-body2': {
                fontFamily: "'Roboto', sans-serif",
                fontWeight: 500,
            }
        },
        '& li:before': {
            content: '""',
            backgroundColor: 'red',
            width: 12,
            height: 36,
            marginRight: 10,
            borderRadius: '0px 10px 10px 0px',
        },
        '& li:nth-of-type(even)': {
            backgroundColor: '#fff'
        },

        '& li:nth-child(1):before': {
            backgroundColor: '#FFDE00',
        },
        '& li:nth-child(2):before': {
            backgroundColor: '#BDC2C5',
        },
        '& li:nth-child(3):before': {
            backgroundColor: '#CB9D8B',
        }
    },
    pontuação: {
        marginLeft: 'auto',
        backgroundColor: alpha('#0071BC', 0.05),
        height: 66,
        marginTop: -8,
        marginBottom: -8,
        width: 90,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: "'Roboto', sans-serif",
        fontWeight: 500,
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            width: 120,
            fontSize: '1.25rem'
        },
        [theme.breakpoints.up('lg')]: {
            width: 150,
            fontSize: '1.5rem'
        },
    },
    nome: {
        fontSize: '0.9rem',
        color: '#001E32A6',
    },
    sobrenome: {
        fontSize: '1.15rem',
        color: '#00101A',
    },
    ListCronograma: {
        paddingTop: 0,
        '& li': {
            paddingTop: 0,
        }
    }
}));