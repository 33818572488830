import { SvgIcon } from "@material-ui/core";

export function ShareSVG() {
    return (
        <SvgIcon width="35" height="35" viewBox="0 0 18 18">
            <g >
                <path d="M17.765,5.545l-4.5,4.25A.751.751,0,0,1,12,9.25V7c-4.518.03-6.424,1.1-5.149,5.355a.5.5,0,0,1-.781.542A6.015,6.015,0,0,1,3.75,8.417C3.75,3.919,7.425,3.026,12,3V.75A.751.751,0,0,1,13.265.205l4.5,4.25A.75.75,0,0,1,17.765,5.545ZM12,11.848V14H2V4H3.591a.375.375,0,0,0,.27-.115A6.092,6.092,0,0,1,5.456,2.707.375.375,0,0,0,5.282,2H1.5A1.5,1.5,0,0,0,0,3.5v11A1.5,1.5,0,0,0,1.5,16h11A1.5,1.5,0,0,0,14,14.5V11.725a.375.375,0,0,0-.5-.353,2.245,2.245,0,0,1-1.068.106A.376.376,0,0,0,12,11.848Z" fill="currentcolor" />
            </g>
        </SvgIcon>
    )
}

export function DownloadSVG() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 20 20">
            <g id="ico---download-line" clipPath="url(#clip-ico---download-line)">
                <g id="Group_1241" data-name="Group 1241" transform="translate(-111 -111)">
                    <g id="Icon_feather-download" data-name="Icon feather-download" transform="translate(116 115)">
                        <path id="Path_3539" data-name="Path 3539" d="M14.5,22.5v2.222a1.111,1.111,0,0,1-1.111,1.111H5.611A1.111,1.111,0,0,1,4.5,24.722V22.5" transform="translate(-4.5 -15.833)" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        <path id="Path_3540" data-name="Path 3540" d="M10.5,15l2.778,2.778L16.056,15" transform="translate(-8.278 -11.111)" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                        <path id="Path_3541" data-name="Path 3541" d="M18,11.167V4.5" transform="translate(-13 -4.5)" fill="none" stroke="currentcolor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export function MedalSVG() {
    return (
        <SvgIcon width="512" height="512" viewBox="0 0 512 512">
            <path fill="currentColor" d="M223.75 130.75L154.62 15.54A31.997 31.997 0 0 0 127.18 0H16.03C3.08 0-4.5 14.57 2.92 25.18l111.27 158.96c29.72-27.77 67.52-46.83 109.56-53.39zM495.97 0H384.82c-11.24 0-21.66 5.9-27.44 15.54l-69.13 115.21c42.04 6.56 79.84 25.62 109.56 53.38L509.08 25.18C516.5 14.57 508.92 0 495.97 0zM256 160c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm92.52 157.26l-37.93 36.96 8.97 52.22c1.6 9.36-8.26 16.51-16.65 12.09L256 393.88l-46.9 24.65c-8.4 4.45-18.25-2.74-16.65-12.09l8.97-52.22-37.93-36.96c-6.82-6.64-3.05-18.23 6.35-19.59l52.43-7.64 23.43-47.52c2.11-4.28 6.19-6.39 10.28-6.39 4.11 0 8.22 2.14 10.33 6.39l23.43 47.52 52.43 7.64c9.4 1.36 13.17 12.95 6.35 19.59z"></path>
        </SvgIcon>
    )
}

export function ArrowSVG() {
    return (
        <SvgIcon width="64" height="64" viewBox="0 0 64 64">
            <defs>
                <filter id="Polygon_16" x="-5" y="-7" width="81" height="78" filterUnits="userSpaceOnUse">
                    {/* <feOffset dx="3" dy="3" input="SourceAlpha" /> */}
                    <feGaussianBlur stdDeviation="10" result="blur" />
                    {/* <feFlood flood-color="#32e8ff" /> */}
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                </filter>
                <clipPath id="clip-ico-or-element---seta">
                    <rect width="64" height="64" />
                </clipPath>
            </defs>
            <g id="ico-or-element---seta" clipPath="url(#clip-ico-or-element---seta)">
                <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Polygon_16)">
                    <g id="Polygon_16-2" data-name="Polygon 16" transform="translate(22 20)" fill="#32e8ff">
                        <path d="M8.772,2.962a2,2,0,0,1,3.455,0l7.018,12.031A2,2,0,0,1,17.518,18H3.482a2,2,0,0,1-1.728-3.008Z" stroke="none" />
                        <path d="M 10.49999904632568 2.969268798828125 C 10.32729911804199 2.969268798828125 9.897429466247559 3.017608642578125 9.636219024658203 3.465398788452148 L 2.61829948425293 15.49612808227539 C 2.355049133300781 15.9473991394043 2.528249740600586 16.34713935852051 2.61492919921875 16.4980583190918 C 2.701608657836914 16.64897918701172 2.959629058837891 16.99999809265137 3.482078552246094 16.99999809265137 L 17.51792907714844 16.99999809265137 C 18.04036903381348 16.99999809265137 18.29838943481445 16.64897918701172 18.38506889343262 16.4980583190918 C 18.47174835205078 16.34713935852051 18.64494895935059 15.9473991394043 18.38169860839844 15.49612808227539 L 11.36377906799316 3.465398788452148 C 11.10256862640381 3.017608642578125 10.67269897460938 2.969268798828125 10.49999904632568 2.969268798828125 M 10.49999904632568 1.969270706176758 C 11.17083930969238 1.969270706176758 11.84167861938477 2.300024032592773 12.22755908966064 2.961528778076172 L 19.24547958374023 14.99225807189941 C 20.02324867248535 16.32556915283203 19.06150817871094 17.99999809265137 17.51792907714844 17.99999809265137 L 3.482078552246094 17.99999809265137 C 1.93848991394043 17.99999809265137 0.9767494201660156 16.32556915283203 1.754518508911133 14.99225807189941 L 8.772439002990723 2.961528778076172 C 9.158319473266602 2.300024032592773 9.829158782958984 1.969270706176758 10.49999904632568 1.969270706176758 Z" stroke="none" fill="rgba(30,177,196,0.5)" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export function MinusSVG() {
    return (
        <SvgIcon width="448" height="512" viewBox="0 0 448 512">
            <path fill="currentColor" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z" />
        </SvgIcon>
    )
}

export function ChevronUPSVG() {
    return (
        <SvgIcon width="448" height="512" viewBox="0 0 448 512">
            <path fill="currentColor" d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z" />
        </SvgIcon>
    )
}

export function ChevronDOWNSVG() {
    return (
        <SvgIcon width="448" height="512" viewBox="0 0 448 512">
            <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z" />
        </SvgIcon>
    )
}


export function ShieldSVG() {
    return (
        <SvgIcon width="512" height="512" viewBox="0 0 512 512">
            <path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z"/>
        </SvgIcon>
    )
}