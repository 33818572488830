export const menu = [
    { titulo: 'INÍCIO', url: '/' },
    { titulo: 'NOTÍCIAS', url: '/noticias' },
    {
        titulo: 'EVENTOS',
         url: '/competicoes' 
    },
    {
        titulo: 'EQUIPE',
        url: '/equipe'
        // filho: [
        //     {
        //         titulo: 'ATLETAS',
        //         url: '/equipe'
        //     },
        //     {
        //         titulo: 'CONVOCAÇÕES',
        //         url: ''
        //     }
        // ]
    },
    {
        titulo: 'SOBRE A CBSO',
        url: '/sobre-a-cbso',
        //  filho: [
        //     {
        //         titulo: 'O ESPORTE',
        //         url: '/sobre-a-cbso'
        //     },
        // ],
    },
    { titulo: 'APOIO', url: '/apoio' },
    {
        titulo: 'TRANSPARÊNCIA',
         url: '/transparencia' 
    },
    {
        titulo: 'CONTATO',
         url: '/contato' 
    },
];