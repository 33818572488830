import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {

    },
    heading: {
        color: theme.palette.info.light,
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '2.25rem',
        marginBottom: '1rem',
    },
    headingInfo: {
        color: theme.palette.primary.light,
    },
    actionWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        order: -1,
        margin: '20px 0',
        [theme.breakpoints.up('md')]: {
            order: 1,
            margin: 0,
        }
    },
    contentContainer: {
        marginBottom: '2rem',
    },
    content: {
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.info.pale,
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1rem',
            marginBottom: 30,
        }
    },
    avatarWrapper: {
        display: 'flex',
        justifyContent: 'center',
        '& svg': {
            fontSize: '2.5rem',
        }
        // '& .MuiAvatar-root': {
        //     color: '#bdbdbd',
        //     backgroundColor: '#bdbdbd'
        // }
    },
    Avatar: {
        border: '2px solid white',
        height: 68,
        width: 68,
    },
    nomeClube: {
        fontFamily: "'Quicksand', sans-serif",
        margin: '10px 0',
        color: '#fff',
        fontSize: '.85rem',
        textAlign: 'center',
        minHeight: '3rem'
    }
}));