import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: `linear-gradient(115deg, ${theme.palette.secondary.light2} 20%, ${theme.palette.secondary.light} 100%)`,
        borderRadius: 30,
        '& .MuiCardContent-root': {
            padding: theme.spacing(2.5)
        },
        [theme.breakpoints.up('sm')]: {
            marginTop: 20
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 40
        }
    },
    rootListItem: {
        marginTop: 10,
        [theme.breakpoints.up('sm')]: {
            marginTop: 15
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 25
        }
    },
    docTitle: {
        color: theme.palette.secondary.dark80,
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '1.35rem',
        lineHeight: 1,
        fontWeight: 'bold',
        marginBottom: '.5em',
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.6rem',
        }
    },
    downdload: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end'
        }
    },
    downloadBtn: {
        [theme.breakpoints.up('lg')]: {
            order: 1,
        },
        '& svg': {
            fontSize: '1.6rem',
        },
    },
    textBaixar: {
        display: 'inline-block',
        marginLeft: 5,
        fontSize: '.95rem',
        fontWeight: 500,
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.secondary.pale,

        [theme.breakpoints.up('lg')]: {
            opacity: 0.7,
            fontSize: '1.2rem',
            order: 0,
            marginRight: 10,
        }
    },
    data: {
        textAlign: 'right',
        fontSize: '1.2rem',
        fontWeight: 'bolder',
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.secondary.darker,

    },
    naoEncontrado: {
        fontSize: '1.2rem',
        color: 'white'
    }
}));