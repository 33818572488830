import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { useHistory } from 'react-router-dom';

import { useStyles } from './styles';
import { IconButton, InputBase } from '@material-ui/core';
import { SearchSVG } from '../../assets/icons/NavIcons';
import { ArrowForward } from '@material-ui/icons';
import clsx from 'clsx';

export default function SearchInput(props) {
    const classes = useStyles();
    const { collapsed, setAnchorSearchEl, closeDialog } = props
    const [searchQuery, setSearchQuery] = useState('')

    const [error, setError] = useState(false)
    let history = useHistory();

    const handleSearchKeyDown = (e) => {
        if (e.key === 'Enter') {
            executeSearch()
            e.preventDefault()
            if (setAnchorSearchEl) {
                setAnchorSearchEl(null)
            }
        }
    }

    const executeScroll = () => window.scrollTo({ behavior: 'smooth', top: 0 });

    const executeSearch = () => {
        if (searchQuery !== "") {
            setError(false)
            history.push(`/pesquisa?q=${searchQuery}`);
        } else {
            setError(true)
        }
        setSearchQuery('')

        executeScroll();
        if (closeDialog) {
            closeDialog()
        }
    }

    if (collapsed) {
        return (
            <div className={classes.container}>
                <Grid container spacing={1} alignItems="flex-end">
                    <Grid item>
                        <TextField
                            className={classes.textField}
                            placeholder="Pesquisar…"
                            value={searchQuery}
                            error={error}
                            onChange={e => setSearchQuery(e.target.value)}
                            onKeyDown={(e) => handleSearchKeyDown(e)}
                            autoFocus="true"
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            className={classes.btnSearch}
                            color="primary"
                            variant="contained"
                            aria-label="pesquisa"
                            size="small"
                            disableElevation
                            onClick={executeSearch}
                        >
                            <SearchSVG />
                        </Button>
                    </Grid>
                </Grid>
            </div>
        )
    } else {
        return (
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                    <SearchSVG />
                </div>
                <InputBase
                    placeholder="Buscar"
                    value={searchQuery}
                    error={error}
                    onChange={e => setSearchQuery(e.target.value)}
                    onKeyDown={(e) => handleSearchKeyDown(e)}
                    classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
                <div className={clsx(classes.btnSearchArrow, { [classes.btnSearchArrowShow]: searchQuery.length > 0 })}>
                    <IconButton size="small" onClick={executeSearch}>
                        <ArrowForward />
                    </IconButton>
                </div>
            </div>
        )
    }
}
