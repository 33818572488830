import { Avatar, Card, CardActionArea, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../util/createSlug";
import { useStyles } from "./styles/CardAtleta";

function CardAtleta({ atleta }) {
  const classes = useStyles();
  return (
    <Card className={classes.Root}>
      <CardActionArea
        className={classes.CardActionRoot}
        component={Link}
        to={`/atleta/${atleta.id}/${createSlug(atleta.nome_completo)}`}
      >
        <Avatar
          className={classes.Avatar}
          variant="circle"
          src={`https://saltosbrasil.bigmidia.com/_uploads/fotoAtleta/${atleta.foto}`}
        />
        <Typography className={classes.Nome}>{atleta.nome_completo}</Typography>
      </CardActionArea>
    </Card>
  );
}

export default CardAtleta;
