import { Card, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { DownloadSVG } from "../../../../assets/icons/LogosTemplate";
import { useStyles } from "./styles/CardDoc";

function CardDoc({ anexo }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <IconButton
        color="primary"
        size="small"
        target="_blank"
        href={anexo && `https://saltosbrasil.bigmidia.com/_uploads/orgaoAnexo/${anexo.nome_arquivo}`}
        className={classes.downloadBtn}
      >
        <DownloadSVG />
      </IconButton>
      <a
        href={`https://saltosbrasil.bigmidia.com/_uploads/orgaoAnexo/${anexo.nome_arquivo}`}
        target="_blank"
        rel="noreferrer"
      >
        <Typography>{anexo.titulo}</Typography>
      </a>
    </Card>
  );
}

export default CardDoc;
