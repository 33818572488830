import { Link } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';


const styledBy = (property, mapping) => (props) => mapping[props[property]];

export default function TemplateLink(props) {
    const theme = useTheme();

    const styles = {
        root: {
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 'bold',
            fontSize: '1rem',
            textDecoration: 'underline',
            color: styledBy('color', {
                white: '#fff',
                black: theme.palette.info.dark100,
                primary: theme.palette.primary.main,
                primaryLight: theme.palette.primary.light,
                info: theme.palette.info.main,
                infoLight: theme.palette.info.light,
                warning: theme.palette.warning.main,
                warningLight: theme.palette.warning.light,
                error: theme.palette.error.main,
                errorLight: theme.palette.error.light,
            }),
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.25rem',
            },
            '&:hover': {
                opacity: 0.8,
                cursor: 'pointer'
            }
        }
    }

    const DefaultLink = withStyles(styles)(({ classes, color, ...other }) => (
        <Link className={classes.root} {...other} />
    ));

    return (
        <DefaultLink {...props}>
            {props.children}
        </DefaultLink>
    )
}