import { alpha, makeStyles } from "@material-ui/core";
import water_seamless_transp from '../../../assets/images/water_seamless_transp.webp';

export const useStyles = makeStyles((theme) => ({
    root: {
        background: `linear-gradient(180deg, ${theme.palette.primary.darker} 0%, ${theme.palette.primary.main} 100%)`,
        position: 'relative',
        marginBottom: 60,
        '& section': {
            margin: '60px 0',
            [theme.breakpoints.up('md')]: {
                margin: '80px 0',
            },
        }
    },
    bgWater: {
        [theme.breakpoints.up('md')]: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${water_seamless_transp})`,
            backgroundRepeat: 'repeat',
            backgroundSize: 450,
            backgroundPosition: 'center',
            opacity: 0.3,
        },
    },
    bgContent: {
        [theme.breakpoints.up('md')]: {
            paddingTop: 100,
            marginTop: -60,
            background: `linear-gradient(180deg, transparent 0%,  ${theme.palette.primary.darker} 5%, ${theme.palette.primary.main} 100%)`,
            minHeight: '300vh',
        }
    },
    content: {
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        flexDirection: 'column',

    },
    imgEntenda: {
        marginLeft: 'auto',
        marginTop: 80,
        width: 800,
        objectFit: 'cover',
        height: 600,
        objectPosition: 'left',
        maxWidth: '100%',
        marginBottom: 40,
    },
    imgEntendaSM: {
        marginTop: 40,
        marginBottom: 30,
        marginLeft: 20,
        maxWidth: '100%'
    },
    heading: {
        fontSize: '2.5rem',
        fontFamily: "'Quicksand', sans-serif",
        lineHeight: 1.2,
        color: theme.palette.primary.pale,
        [theme.breakpoints.up('md')]: {
            fontSize: '3.5rem',
        }
    },
    headingInfo: {
        fontSize: '1.5rem',
        fontFamily: "'Quicksand', sans-serif",
        lineHeight: 1.2,
        color: theme.palette.info.light,
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        },

    },
    text: {
        margin: '10px 0',
        fontSize: '1rem',
        color: '#fff',
        fontFamily: "'Quicksand', sans-serif",
        [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
        }
    },
    listRegras: {
        color: '#fff',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 20,
        },
        '& li': {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
            },
        },
        '& li:before': {
            content: "''",
            height: '0.9rem',
            width: '1.5rem',
            minWidth: '1.5rem',
            background: '#fff',
            borderRadius: '3px 3px 20px 3px',
            marginRight: 10,
        },
        '& a': {
            color: '#fff',
            textDecoration: 'none',
            '&:hover': {
                opacity: 0.7
            }
        },
    },
    card: {
        background: theme.palette.primary.dark,
        boxShadow: `0px 10px 40px ${theme.palette.primary.dark}`,
        border: '2px solid #fff',
        borderRadius: 4,
        minHeight: '10vh',
        overflow: 'unset',
        position: 'relative',
        zIndex: 2,
        '& .MuiDivider-root': {
            backgroundColor: '#fff'
        }
    },
    cardRoundTop: {
        [theme.breakpoints.up('sm')]: {
            padding: '0 10px',
            borderRadius: '40px 40px 4px 4px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 50px',
        }
    },
    cardRoundBottom: {
        backgroundColor: '#fff',
        [theme.breakpoints.up('sm')]: {
            padding: '0 10px',
            borderRadius: '4px 4px 40px 40px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '0 30px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '0 50px',
        }
    },
    cardTitle: {
        background: '#fff',
        boxShadow: `0px 10px 20px ${alpha(theme.palette.primary.dark80, 0.5)}`,
        borderRadius: 20,
        padding: theme.spacing(2),
        color: theme.palette.primary.darker,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        marginTop: '-1.5rem',
        marginLeft: -2,
        marginRight: -2,
        fontSize: '1.1rem',
        [theme.breakpoints.up('md')]: {
            maxWidth: '30%',
            paddingLeft: '5%',
            fontSize: '1.2rem',
        }
    },
    cardTitleFull: {
        marginLeft: 16,
        marginRight: 16,
        [theme.breakpoints.up('sm')]: {
            marginLeft: -2,
            marginRight: -2,
        }
    },
    cardTitleRoundCard: {
        marginLeft: 16,
        marginRight: 16,
        maxWidth: 'fit-content',

        [theme.breakpoints.up('sm')]: {
            marginLeft: -2,
            marginRight: -2,
            paddingLeft: 30,
            paddingRight: 30,
        }
    },
    cardBottom: {
        background: '#FFFFFF',
        borderRadius: '12px 4px 36px 12px',
        height: 24,
        width: '40%',
        marginTop: -14,
        position: 'relative',
        zIndex: 1,
        marginLeft: 10,
    },
    containerFullSM: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    }
}))