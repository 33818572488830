import { Button, alpha, IconButton, withStyles } from "@material-ui/core";

export const GradientPrimaryIconBtn = withStyles((theme) => ({
    root: {
        background: `linear-gradient(150deg, ${theme.palette.secondary.main} 30%, ${theme.palette.info.light} 100%)`,
        color: '#fff',
        boxShadow: `0px 3px 20px ${theme.palette.info.main}`,

    }
}))(IconButton);

export const SecondaryIconBtn = withStyles((theme) => ({
    root: {
        borderColor: theme.palette.secondary.darker,
        background: theme.palette.secondary.dark80,
        color: '#fff',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    },
}))(IconButton);

export const OutlineWarningBtn = withStyles((theme) => ({
    root: {
        background: 'transparent',
        border: `2px solid ${theme.palette.warning.light}`,
        color: theme.palette.warning.light,
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.05rem',
        fontWeight: 500,
        lineHeight: 1.2,
        padding: theme.spacing(2.5, 5),
        boxShadow: 'none',
        borderRadius: 12,
        transition: 'all 250ms ease-in-out',
        '&:hover': {
            background: `${alpha(theme.palette.warning.light, 0.1)}`,
        },
    },
}))(Button);

export const OutlineWhiteBtn = withStyles((theme) => ({
    root: {
        background: 'transparent',
        border: `2px solid #fff`,
        color: '#fff',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.05rem',
        fontWeight: 500,
        lineHeight: 1.2,
        padding: theme.spacing(2.5, 5),
        boxShadow: 'none',
        borderRadius: 12,
        transition: 'all 250ms ease-in-out',
        '&:hover': {
            background: `${alpha(theme.palette.warning.light, 0.1)}`,
        },
    },
}))(Button);

export const GradientWarningBtn = withStyles((theme) => ({
    root: {
        background: `linear-gradient(45deg, ${theme.palette.warning.light} 10%, ${theme.palette.warning.dark} 100%)`,
        border: `2px solid ${theme.palette.warning.light}`,
        color: theme.palette.primary.dark100,
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.05rem',
        fontWeight: 700,
        lineHeight: 1.2,
        padding: theme.spacing(2.5, 5),
        boxShadow: 'none',
        borderRadius: 12,
        transition: 'all 250ms ease-in-out',
    },
}))(Button);

export const GradientPrimaryBtn = withStyles((theme) => ({
    root: {
        background: `linear-gradient(109deg, ${theme.palette.primary.pale} 0%, ${theme.palette.primary.light} 52%, ${theme.palette.primary.main} 100%)`,
        border: `2px solid ${theme.palette.primary.light}`,
        color: theme.palette.primary.dark100,
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.05rem',
        fontWeight: 700,
        lineHeight: 1.2,
        padding: theme.spacing(2.5, 5),
        boxShadow: 'none',
        borderRadius: 12,
        transition: 'all 250ms ease-in-out',
    },
}))(Button);

export const GradientSecondaryBtn = withStyles((theme) => ({
    root: {
        background: `linear-gradient(109deg, ${theme.palette.primary.light} 0%, ${theme.palette.secondary.main} 100%)`,
        border: `2px solid ${theme.palette.primary.main}`,
        color: '#fff',
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.05rem',
        fontWeight: 700,
        lineHeight: 1.2,
        padding: theme.spacing(2.5, 5),
        boxShadow: 'none',
        borderRadius: 12,
        transition: 'all 250ms ease-in-out',
    },
}))(Button);

export const GradientPrimaryWarningBtn = withStyles((theme) => ({
    root: {
        background: `linear-gradient(110deg, #FFF8CC 0%, #32E8FF 52%, #008BE7 100%)`,
        border: `2px solid ${theme.palette.info.darker}`,
        color: theme.palette.primary.dark100,
        fontFamily: "'Poppins', sans-serif",
        fontSize: '1.05rem',
        fontWeight: 700,
        lineHeight: 1.2,
        padding: theme.spacing(2.5, 5),
        boxShadow: 'none',
        borderRadius: 12,
        transition: 'all 250ms ease-in-out',
    },
}))(Button);

export const FacebookBtn = withStyles((theme) => ({
    root: {
        height: 80,
        padding: theme.spacing(2, 6),
        background: `linear-gradient(107deg, #1877F2 0%, ${theme.palette.primary.light} 100%)`,
        boxShadow: `0px 3px 6px #000F11`,
        borderRadius: 30,
        color: '#fff',
        transition: 'all 250ms ease-in-out',
        '&:hover': {
            background: `linear-gradient(107deg, #1877F2 0%, ${theme.palette.primary.light} 100%)`,
        },
    },
}))(Button);


export const YoutubeBtn = withStyles((theme) => ({
    root: {
        height: 80,
        padding: theme.spacing(2, 6),
        background: `linear-gradient(107deg, #FF0000 0%, #FE5330 100%)`,
        boxShadow: `0px 3px 6px #000F11`,
        borderRadius: 30,
        color: '#fff',
        transition: 'all 250ms ease-in-out',
        '&:hover': {
            background: `linear-gradient(107deg, #FF0000 0%, #FE5330 100%)`,
        },
    },
}))(Button);

export const InstagramBtn = withStyles((theme) => ({
    root: {
        height: 80,
        padding: theme.spacing(2, 6),
        background: `linear-gradient(61deg, rgba(155,61,149,1) 0%, rgba(199,52,99,1) 40%, rgba(242,43,49,1) 71%, rgba(251,147,75,1) 100%)`,
        boxShadow: `0px 3px 6px #000F11`,
        borderRadius: 30,
        color: '#fff',
        transition: 'all 250ms ease-in-out',
        '&:hover': {
            background: `linear-gradient(61deg, rgba(155,61,149,1) 0%, rgba(199,52,99,1) 40%, rgba(242,43,49,1) 71%, rgba(251,147,75,1) 100%)`,
        },
    },
}))(Button);

export const SpotifyBtn = withStyles((theme) => ({
    root: {
        height: 80,
        padding: theme.spacing(2, 6),
        background: `linear-gradient(110deg, #1EB1C4 0%,  #5DDF8B 100%)`,
        boxShadow: `0px 3px 6px #000F11`,
        borderRadius: 30,
        color: '#fff',
        transition: 'all 250ms ease-in-out',
        '&:hover': {
            background: `linear-gradient(110deg, #1EB1C4 0%,  #5DDF8B 100%)`,
        },
    },
}))(Button);

