import { Container, TableContainer, Table, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useStyles } from "../styles/tabelaCodigoSaltos";

export default function TabelaCodigoSaltos() {
    const classes = useStyles();

    const tabelaCod = [
        {
            thead: [true, true, true, true, true, true],
            descricao: 'CÓDIGO',
            classecss: 'codigo'
        },
        {
            thead: [false, false, true, true, true, false],
            descricao: 'SALTO PADRÃO',
            classecss: 'salto-padrao'
        },
        {
            thead: [false, true, true, false, true, true],
            descricao: 'SALTO COM PARAFUSO',
            classecss: 'salto-parafuso'
        },
        {
            thead: [true, false, true, false, true, false],
            descricao: 'SAIDA COM PARADA DE MÃO',
            classecss: 'salto-parada-mao'
        },
        {
            thead: [true, true, true, false, true, true],
            descricao: 'SAIDA PARADA DE MÃO COM PARAFUSO',
            classecss: 'salto-parada-mao-parafuso'
        },
    ]

    return (
        <Container maxWidth="md" className={classes.tableContaienr}>
            <TableContainer>
                <Table className={classes.tableCodigos}>
                    <TableHead>
                        {tabelaCod.map((item, key) => (
                            <React.Fragment key={key}>
                                <TableRow>
                                    <TableCell> </TableCell>
                                    <TableCell>
                                        {item.thead[0] ? '6' : ''}
                                    </TableCell>
                                    <TableCell>
                                        {item.thead[1] ? '5' : ''}
                                    </TableCell>
                                    <TableCell>
                                        {item.thead[2] ? <div>#<span>s</span></div> : ''}
                                    </TableCell>
                                    <TableCell>
                                        {item.thead[3] ? <div>#<span>v</span></div> : ''}
                                    </TableCell>
                                    <TableCell>
                                        {item.thead[4] ? <div>#<span>g</span></div> : ''}
                                    </TableCell>
                                    <TableCell>
                                        {item.thead[5] ? <div>#<span>p</span></div> : ''}
                                    </TableCell>
                                    <TableCell>
                                        X
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                                <TableRow className={classes.TableBody}>
                                    <TableCell colspan={9}>
                                        <div className={clsx(classes.rowCaption, item.classecss)}>
                                            <Typography variant="body2">
                                                {item.descricao}
                                            </Typography>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableHead>
                </Table>
            </TableContainer>
        </Container>
    )
}