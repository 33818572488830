import { Box, Grid, Typography } from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { governancaApi } from "../../../services/api";
import { useStyles } from "../styles/Pessoas";
import CardDoc from "./components/CardDoc";

function DocOrgaosSection({ searchTerm }) {
  const classes = useStyles();
  const sectionTop = useRef(null);
  const [page, setPage] = useState(1);
  const [anexos, setAnexos] = useState({
    error: false,
    loading: true,
    payload: [],
    meta: {},
  });

  useEffect(() => {
    setAnexos({
      error: false,
      loading: true,
      payload: [],
      meta: {},
    });

    // governanca/api/orgao?id_menu=4&flag_del=0&sort=-data&expand=orgaoAnexos&pageSize=100

    async function getAnexos() {
      await governancaApi
        .get("orgao-anexo", {
          params: {
            sort: "titulo",
            arrayLike: `titulo:${searchTerm}`,
            page,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setAnexos({
              error: true,
              loading: false,
              payload: [],
              meta: {},
            });
          } else {
            setAnexos({
              error: false,
              loading: false,
              payload: response.data.items,
              meta: {
                pageCount: response.data._meta.pageCount,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setAnexos({
            error: true,
            loading: false,
            payload: [],
            meta: {},
          });
        });
    }
    getAnexos();
  }, [searchTerm, page]);

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 200,
    });

  const handleChange = (event, value) => {
    setPage(value);
    if (value === 1) {
      executeScroll();
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <div className={classes.cardHeaderLabel}>
        <Typography variant="h2" className={classes.CardTitle}>
          Documentos
        </Typography>
      </div>
      <Grid container spacing={2}>
        {anexos.payload.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <CardDoc anexo={item} />
          </Grid>
        ))}
      </Grid>
      {anexos.error && (
        <div className={classes.errorContainer}>
          <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
        </div>
      )}
      {anexos.meta && anexos.meta.pageCount > 1 && (
        <div className={classes.paginationContainer}>
          <Pagination page={page} count={anexos.meta.pageCount} onChange={handleChange} />
        </div>
      )}
    </Box>
  );
}

export default DocOrgaosSection;
