import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    sectionTitle: {
        fontSize: theme.typography.pxToRem(24),
        color: theme.palette.primary.pale,
        fontFamily: "'Quicksand', sans-serif",
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(30),
            marginBottom: 35,
        }
    },
    root: {
        '& .detalhe': {
            color: theme.palette.warning.dark,
            marginLeft: 3,
            marginRight: 3,
            fontSize:'1.2rem',
            verticalAlign:'baseline'
        }
    },
    Table: {
        '& thead th': {
            padding: 8,
            fontSize: '1.1rem',
            color: '#fff',
            borderBottom: 'none',
            fontFamily: "'Quicksand', sans-serif",
            '& span': {
                fontSize: '.8rem',
                verticalAlign: 'super',
            },
            width: 50,
            textAlign: 'center'
        },
        '& thead th:first-child th ': {
            borderBottom: '2px solid #fff'
        },
        '& thead th:nth-child(3) th ': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        '& thead th:nth-child(5) th ': {
            borderBottom: `2px solid ${theme.palette.primary.darker}`,
        },
        '& thead th:nth-child(7) th ': {
            borderBottom: `2px solid ${theme.palette.primary.dark80}`,
        },
        '& thead th:nth-child(9) th ': {
            borderBottom: `2px solid ${theme.palette.primary.dark100}`,
        },

        '& tr th:nth-child(2)': {
            color: theme.palette.primary.dark80
        },
        '& tr th:nth-child(2), & tr th:nth-child(6)': {
            color: theme.palette.info.light
        },
        '& tr th:nth-child(4)': {
            color: theme.palette.warning.main
        },
        '& thead th:nth-child(9) th:nth-child(3)': {
            color: theme.palette.primary.darker
        },
        '& .emptyTd': {
            backgroundColor: theme.palette.primary.darker
        },
    },
    TableBody: {
        '& td': {
            textAlign: 'left',
            borderBottom: 'none',
            padding: 0,
        },
        '&.salto-padrao': {
            '& td': {
                borderTop: `2px solid ${theme.palette.primary.main}`,
            }
        },
        '&.salto-parafuso': {
            '& td': {
                borderTop: `2px solid ${theme.palette.primary.darker}`,
            }
        },
        '&.salto-parada-mao': {
            '& td': {
                borderTop: `2px solid ${theme.palette.primary.dark80}`,
            }
        },
        '&.salto-parada-mao-parafuso': {
            '& td': {
                borderTop: `2px solid ${theme.palette.primary.dark100}`,
            }
        },
    },

    rowCaption: {
        width: 'fit-content',
        height: 25,
        borderRadius: ' 0px 0px 30px 2px',
        paddingLeft: 15,
        paddingRight: 20,
        display: 'flex',
        alignItems: 'center',
        '& p': {
            color: '#fff',
            fontSize: '.7rem',
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 600,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 20,
            paddingRight: 30,
            '& p': {
                fontSize: '.9rem',
            },
        },
        '&.salto-padrao': {
            backgroundColor: theme.palette.primary.main,
        },
        '&.salto-parafuso': {
            backgroundColor: theme.palette.primary.darker,
        },
        '&.salto-parada-mao': {
            backgroundColor: theme.palette.primary.dark80,
        },
        '&.salto-parada-mao-parafuso': {
            backgroundColor: theme.palette.primary.dark100,
        },
    },

    saltoExemplo: {
        padding: theme.spacing(2, 0),
        display: 'flex',
        color: theme.palette.primary.pale,
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 3),
            flexDirection: 'column'
        }
    },
    textExemplo: {
        color: theme.palette.primary.pale,
        fontSize: '1rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 500,
        paddingRight: 10,
        [theme.breakpoints.up('md')]: {
            paddingRight: 10,
        }
    },
    codSalto: {
        color: '#fff',
        fontSize: '1.5rem',
        fontWeight: 600,
        fontFamily: "'Quicksand', sans-serif",
        marginRight: 10,
        lineHeight: 1,
        marginBottom: 8,
        [theme.breakpoints.up('md')]: {
            marginRight: 0,
            fontSize: '1.8rem',
        }
    },
    saltoDescricao: {
        color: theme.palette.primary.pale,
        fontSize: '0.8rem',
        fontWeight: 300,
        fontFamily: "'Quicksand', sans-serif",
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        }
    }
}))