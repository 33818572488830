import { IconButton, Link, Paper, Typography } from "@material-ui/core";
import { Instagram } from "@material-ui/icons";
import { Twitter } from "@material-ui/icons";
import { YouTube } from "@material-ui/icons";
import { useStyles } from "../styles/Instagram";

export default function SocialSection() {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Typography variant="subtitle1" className={classes.heading}>
        Siga a Saltos Brasil nas redes sociais
      </Typography>
      <div className={classes.linkWrappers}>
        <IconButton className={classes.link} color="primary" component={Link} href="https://twitter.com/saltos_brasil/" target="_blank" rel="noreferrer">
          <Twitter />
        </IconButton>
        <IconButton className={classes.link} color="primary" component={Link} href="https://www.youtube.com/channel/UCBdyat_VyaN2f-7tamSSmvg" target="_blank" rel="noreferrer">
          <YouTube />
        </IconButton>
        <IconButton className={classes.link} color="primary" component={Link} href="https://www.instagram.com/saltosbrasil/" target="_blank" rel="noreferrer">
          <Instagram />
        </IconButton>
      </div>
    </Paper>
  )
}
