import { Typography } from "@material-ui/core";
import { GradientPrimaryBtn, GradientPrimaryWarningBtn, GradientWarningBtn, OutlineWarningBtn, OutlineWhiteBtn } from "../../../components/Buttons/Buttons";
import { useStyles } from "../style/ConselhoTopicos";

export default function ConselhoTopicos() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.section}>
                <div className="section-1">
                    <div className="sectionTop"></div>
                    <Typography className="sectionText">
                        Oferecemos a estrutura e o conhecimento necessário para que nossos atletas, técnicos e juízes possam focar no que importa, com missão de difundir o Salto Ornamental como esporte nacional.
                    </Typography>
                    <div className={classes.sectionAction}>
                        <OutlineWarningBtn>
                            TORNE-SE UM ÁRBITRO
                        </OutlineWarningBtn>
                        <GradientWarningBtn>
                            VER ÍNDICE DE FEDERADOS
                        </GradientWarningBtn>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <div className="section-2">
                    <div className="sectionTop"></div>
                    <Typography className="sectionText">
                      A Saltos Brasil promove o suporte na capacitação de professores e  treinadores da modalidade. Venha ser um dos guias na preparação dos nossos heróis!
                    </Typography>
                    <div className={classes.sectionAction}>
                        <GradientPrimaryBtn>
                            SEJA UM TREINADOR
                        </GradientPrimaryBtn>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <div className="section-3">
                    <div className="sectionTop"></div>
                    <Typography className="sectionText">
                      Somos responsáveis pela organização dos eventos nacionais de Saltos Ornamentais e levamos a seleção brasileira para as mais importantes competições mundiais.
                    </Typography>
                    <div className={classes.sectionAction}>
                        <GradientPrimaryWarningBtn>
                            VER COMPETIÇÕES
                        </GradientPrimaryWarningBtn>
                    </div>
                </div>
            </div>
            <div className={classes.section}>
                <div className="section-4">
                    <div className="sectionTop"></div>
                    <Typography className="sectionText">
                      Com ética e paixão, a Saltos Brasil é administrada com objetivo de ser a confederação mais moderna e transparente do Brasil.
                    </Typography>
                    <div className={classes.sectionAction}>
                        <OutlineWhiteBtn>
                            VER TRANSPARÊNCIA
                        </OutlineWhiteBtn>
                    </div>
                </div>
            </div>
        </div>
    )
}
