import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  // Redirect
} from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core";
import { Helmet } from "react-helmet";
import theme from "./services/theme";

import NavDialog from "./components/navigation/sections/NavDialog";
//pages
import Home from "./pages/home";
import ScrollToTop from "./util/ScrollToTop";
import Header from "./components/header";
import BottomAppBar from "./components/navigation";
import Footer from "./components/footer";
import PageNoticias from "./pages/noticias";
import NoticiaLeitura from "./pages/noticia-leitura";
import PageCompeticoes from "./pages/competicoes";
import PageSobre from "./pages/sobre";
import PageApoie from "./pages/apoie";
import PageEquipe from "./pages/equipe";
import PagePesquisa from "./pages/pesquisa";
import PageAtleta from "./pages/atleta";
import PageTransparencia from "./pages/transparencia";
import PageCompeticaoItem from "./pages/competicao-item";
import EntendaPage from "./pages/entenda";
import PageNotFound from "./pages/page-not-found";
import PageTencico from "./pages/tenico";
import PageTransparenciaPessoa from "./pages/transparencia-pessoa";
import ContatoPage from "./pages/contato";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import SocioTorcedorPage from "./pages/apoieSaltosOrnamentais/SocioTorcedor";
import SejaPadrinhoPage from "./pages/apoieSaltosOrnamentais/SejaPadrinho";
import SejaDoadorPage from "./pages/apoieSaltosOrnamentais/SejaDoador";
import PatrocinePage from "./pages/apoieSaltosOrnamentais/Patrocine";
import ClubeDevantagensPage from "./pages/apoieSaltosOrnamentais/ClubeDeVantagens";
import TransparenciaApoioPage from "./pages/apoieSaltosOrnamentais/Transparencia";
import RankingsApoioPage from "./pages/apoieSaltosOrnamentais/Ranking";

function App() {
  const [openMenu, setOpenMenu] = useState(false);
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>
            Saltos Brasil – Confederação Brasileira de Saltos Ornamentais
          </title>
          <meta
            name="description"
            content="Saltos Brasil – Confederação Brasileira de Saltos Ornamentais"
          />
          <meta
            property="og:description"
            content="Saltos Brasil – Confederação Brasileira de Saltos Ornamentais"
          />
          <meta property="og:image" content="/logo192.png" />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <Router>
          <ScrollToTop />

          <Header openMenu={openMenu} setOpenMenu={setOpenMenu} />

          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/noticias" exact>
              <PageNoticias />
            </Route>
            <Route path="/noticias/leitura/:id/:slug">
              <NoticiaLeitura />
            </Route>

            <Route path="/competicoes" exact>
              <PageCompeticoes />
            </Route>
            <Route path="/competicao/:id/:slug">
              <PageCompeticaoItem resultado={false} />
            </Route>
            <Route path="/resultado/:id/:slug">
              <PageCompeticaoItem resultado={true} />
            </Route>

            <Route path="/sobre-a-cbso" exact>
              <PageSobre />
            </Route>

            <Route path="/apoio">
              <PageApoie />
            </Route>

            <Route path="/equipe">
              <EquipeParams />
            </Route>

            <Route path="/atleta/:id">
              <PageAtleta />
            </Route>

            <Route path="/tecnico/:id">
              <PageTencico />
            </Route>

            <Route path="/transparencia" exact>
              <TransparenciaParams />
            </Route>
            <Route path="/transparencia/pessoa/:id/:slug">
              <PageTransparenciaPessoa />
            </Route>

            <Route path="/pesquisa">
              <PesquisaParams />
            </Route>

            <Route path="/entenda">
              <EntendaPage />
            </Route>

            <Route path="/contato">
              <ContatoPage />
            </Route>

            {/* apoio */}
            {/* <Route path="/apoie-os-saltos-ornamentais" exact>
              <ApoieSaltosOrnamentais />
            </Route> */}
            <Route path="/apoie-os-saltos-ornamentais/socio-torcedor" exact>
              <SocioTorcedorPage />
            </Route>
            <Route path="/apoie-os-saltos-ornamentais/padrinho" exact>
              <SejaPadrinhoPage />
            </Route>
            <Route path="/apoie-os-saltos-ornamentais/doador" exact>
              <SejaDoadorPage />
            </Route>
            <Route path="/apoie-os-saltos-ornamentais/patrocine" exact>
              <PatrocinePage />
            </Route>
            <Route path="/apoie-os-saltos-ornamentais/beneficios" exact>
              <ClubeDevantagensPage />
            </Route>
            <Route path="/apoie-os-saltos-ornamentais/transparencia" exact>
              <TransparenciaApoioPage />
            </Route>
            <Route path="/apoie-os-saltos-ornamentais/ranking" exact>
              <RankingsApoioPage />
            </Route>

            <Route>
              <PageNotFound />
            </Route>
          </Switch>
          <Footer />
          <BottomAppBar openMenu={openMenu} setOpenMenu={setOpenMenu} />
          <NavDialog openMenu={openMenu} setOpenMenu={setOpenMenu} />
        </Router>
      </MuiThemeProvider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  );
}

export default App;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function PesquisaParams() {
  let query = useQuery();
  return <PagePesquisa searchTerm={query.get("q")} />;
}

function EquipeParams() {
  let query = useQuery();
  return <PageEquipe searchTerm={query.get("q")} />;
}

function TransparenciaParams() {
  let query = useQuery();
  return <PageTransparencia queryTransparencia={query.get("q")} />;
}
