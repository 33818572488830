import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { Link } from "react-router-dom";
import { ShareSVG } from "../../../assets/icons/LogosTemplate";
import { GradientPrimaryIconBtn } from "../../../components/Buttons/Buttons";
import { convertDateMonthString } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import { useStyles } from "../styles/CardNoticaPrincipal";
import placeholder_img from '../../../assets/images/placeholder_img.webp'

export default function CardNoticiaPrincipal(props) {
    const classes = useStyles();
    const { noticiaPrincipal, loadingNoticias } = props

    return (
        <div>
            <Card className={classes.CardNoticiaPrincipal}>
                <CardActionArea component={Link} to={noticiaPrincipal.id ? `/noticias/leitura/${noticiaPrincipal.id}/${createSlug(noticiaPrincipal.titulo)}` : ''}>
                    {loadingNoticias ?
                        <Skeleton animation="wave" variant="rect" className={classes.CardMedia} />
                        :
                        <CardMedia
                            className={classes.CardMedia}
                            onError={e => { e.target.src = `${placeholder_img}` }}
                            component="img"
                            src={noticiaPrincipal.urlFotoCapa}
                            title={noticiaPrincipal.titulo}
                        />
                    }
                    <CardContent className={classes.CardContent}>
                        <GradientPrimaryIconBtn
                            // onClick={(e) => { alert('click share'); e.stopPropagation() }}
                            color="primary"
                            className={classes.shareBtn}>
                            <ShareSVG />
                        </GradientPrimaryIconBtn>
                        {loadingNoticias ?
                            <React.Fragment>
                                <Skeleton animation="wave" variant="text" height={40} width="60%" />
                                <Skeleton animation="wave" variant="text" height={80} width="90%" />
                                <Skeleton animation="wave" variant="text" height={20} width="40%" />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Typography className={classes.CardNoticiaTitle}>
                                    {noticiaPrincipal.titulo}
                                </Typography>
                                <Typography className={classes.CardNoticiaResumo}>
                                    {noticiaPrincipal.resumo}
                                </Typography>
                                <Typography className={classes.CardNoticiaData}>
                                    {convertDateMonthString(noticiaPrincipal.created_at * 1000)}
                                </Typography>
                            </React.Fragment>
                        }
                    </CardContent>
                </CardActionArea>
            </Card>
            <div className={classes.cardBottom}></div>
        </div>
    )
}