import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    tableContaienr: {
        marginTop: 100,
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            marginTop: 140,
            marginBottom: 40,
        },
    },
    tableCodigos: {
        '& thead th': {
            padding: 8,
            fontSize: '1.1rem',
            color: '#fff',
            borderBottom: 'none',
            fontFamily: "'Quicksand', sans-serif",
            '& span': {
                fontSize: '.8rem',
                verticalAlign: 'super',
            },
            [theme.breakpoints.up('sm')]: {
                padding: 16,
                fontSize: '2rem',
                '& span': {
                    fontSize: '1.2rem',
                },
            },
        },
        '& thead tr:first-child th ': {
            borderBottom: '2px solid #fff'
        },
        '& thead tr:nth-child(3) th ': {
            borderBottom: `2px solid ${theme.palette.primary.main}`,
        },
        '& thead tr:nth-child(5) th ': {
            borderBottom: `2px solid ${theme.palette.primary.darker}`,
        },
        '& thead tr:nth-child(7) th ': {
            borderBottom: `2px solid ${theme.palette.primary.dark80}`,
        },
        '& thead tr:nth-child(9) th ': {
            borderBottom: `2px solid ${theme.palette.primary.dark100}`,
        },

        '& tr th:nth-child(2)': {
            color: theme.palette.primary.dark80
        },
        '& tr th:nth-child(3), & tr th:nth-child(7)': {
            color: theme.palette.info.light
        },
        '& tr th:nth-child(5)': {
            color: theme.palette.warning.main
        },
        '& thead tr:nth-child(9) th:nth-child(3)': {
            color: theme.palette.primary.darker
        },
    },
    TableBody: {
        '& th': {
            borderColor: 'transparent',
            color: theme.palette.primary.dark80,
            padding: '0px !important',
        },
    },
    rowCaption: {
        width: 'fit-content',
        height: 25,
        borderRadius: ' 0px 0px 30px 2px',
        paddingLeft: '8%',
        paddingRight: 30,
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '.7rem',
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 600,
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: 80,
            paddingRight: 80,
            '& p': {
                fontSize: '.9rem',
            },
        },
        '&.codigo': {
            color: theme.palette.primary.dark80,
            backgroundColor: '#fff',
        },
        '&.salto-padrao': {
            backgroundColor: theme.palette.primary.main,
        },
        '&.salto-parafuso': {
            backgroundColor: theme.palette.primary.darker,
        },
        '&.salto-parada-mao': {
            backgroundColor: theme.palette.primary.dark80,
        },
        '&.salto-parada-mao-parafuso': {
            backgroundColor: theme.palette.primary.dark100,
        }
    },


}))