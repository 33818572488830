import React from 'react';
import { Avatar, Grid, Link, Table, TableBody, TableRow, TableCell, Typography, TableContainer, Chip, CardMedia, Button, useMediaQuery } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { useStyles } from '../styles/LiveEventos';
import { nomeProva } from '../../competicao-item/functions/nomeProva';
import { saltoAovivo } from '../../../assets/data/saltoAoVivo';

export default function LiveEvento(props) {
    const classes = useStyles();
    const { pageCompeticao } = props;
    const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));
 
    const saltoLoading = false;

    return (
        <React.Fragment>
            {saltoLoading ?
                <React.Fragment></React.Fragment>
                :
                <React.Fragment>
                    {saltoAovivo.map((item, key) => (
                        <div className={classes.card} key={key}>
                            <Paper className={pageCompeticao ? classes.cardTopCompeticao : classes.cardTop} elevation={0}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={pageCompeticao ? 4 : 12} className={pageCompeticao ? classes.cardFlex : ''}>
                                        {pageCompeticao ?
                                            <div className={classes.cardVideo}>
                                                <div className={classes.CardMedia}>
                                                    <CardMedia className={classes.liveMedia} image=""
                                                        title="" />
                                                    <div className={classes.liveTagWrapper}>
                                                        <Chip label="AGORA" size="small" className={classes.btnLive} />
                                                    </div>
                                                </div>
                                                <Typography className={classes.cardHeadingTitle}>{item.evento.descricao}</Typography>
                                                <Typography className={classes.cardHeadingLabel}>
                                                    {item.evento.texto}
                                                </Typography>
                                                <div className={classes.btnWrapper}>
                                                    <Button className={classes.liveBtn}>
                                                        ACOMPANHAR EVENTO AO VIVO
                                                    </Button>
                                                </div>
                                            </div>
                                            :
                                            <div className={classes.cardHeading}>
                                                <div className={classes.imgContainer}>
                                                    <div className={classes.imgEventoBorder}>
                                                        <Avatar className={classes.imgEvento} />
                                                    </div>
                                                    <Chip label="AGORA" size="small" className={classes.btnLive} />
                                                </div>
                                                <div>
                                                    <Typography className={classes.cardHeadingTitle}>{item.evento.descricao}</Typography>
                                                    <Typography className={classes.cardHeadingLabel}>
                                                        {item.evento.texto}
                                                    </Typography>
                                                </div>
                                            </div>
                                        }
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={pageCompeticao ? 8 : 12}>
                                        <Paper className={classes.cardContent} elevation={0}>
                                            <Grid container className={classes.gridContainer}>
                                                <Grid item xs={12} sm={12} md={6} lg={pageCompeticao ? 12 : 6}>
                                                    <div className={classes.cardBody}>
                                                        Saltando agora:
                                                        <Typography className={classes.cardTitle}>
                                                            {nomeProva(item.saltando.prova)}
                                                            {/* SEMI-FINAIS: Feminino Plataforma Sincronizada */}
                                                        </Typography>
                                                        <div className={classes.infoAtleta}>
                                                            <Avatar className={classes.fotoAtleta} />
                                                            <div className={classes.nomeWrapper}>
                                                                <Typography className={classes.sobrenomeAtleta}>
                                                                    {item.saltando.atleta.nome_evento}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                        <TableContainer>
                                                            <Table className={classes.tabelaSalto} size="small">
                                                                {matchesLG && pageCompeticao ?
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell >Salto:</TableCell>
                                                                            <TableCell className="pts">{item.saltando.saltoAtual.numSalto}</TableCell>
                                                                            <TableCell >Pts Parciais:</TableCell>
                                                                            <TableCell className="pts">{item.saltando.saltoAtual.ptsParcial}</TableCell>
                                                                            <TableCell >Código:</TableCell>
                                                                            <TableCell className="pts">{item.saltando.saltoAtual.tipoSalto.codigo}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell >Rank Parcial:</TableCell>
                                                                            <TableCell className="pts">{item.saltando.saltoAtual.rankingParcial}º</TableCell>
                                                                            <TableCell >Dificuldade:</TableCell>
                                                                            <TableCell className="pts">{item.saltando.saltoAtual.num_gd}</TableCell>
                                                                            <TableCell >Meta de Pts:</TableCell>
                                                                            <TableCell className="ptsFinal">{item.saltando.saltoAtual.metaPts}</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                    :
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell >Salto:</TableCell>
                                                                            <TableCell className="pts">{item.saltando.saltoAtual.numSalto}</TableCell>
                                                                            <TableCell >Pts Parciais:</TableCell>
                                                                            <TableCell className="pts">{item.saltando.saltoAtual.ptsParcial}</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell >Código:</TableCell>
                                                                            <TableCell className="pts">405B</TableCell>
                                                                            <TableCell >Rank Parcial:</TableCell>
                                                                            <TableCell className="pts">5º</TableCell>
                                                                        </TableRow>
                                                                        <TableRow>
                                                                            <TableCell >Dificuldade:</TableCell>
                                                                            <TableCell className="pts">3.2</TableCell>
                                                                            <TableCell >Meta de Pts:</TableCell>
                                                                            <TableCell className="ptsFinal">75.5</TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                }
                                                            </Table>
                                                        </TableContainer>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={pageCompeticao ? 12 : 6}>
                                                    <Paper className={classes.cardContentBottom} elevation={0}>
                                                        <div className={classes.cardBody}>
                                                            <Typography className={classes.cardSubtitle}>
                                                                Pódio parcial:
                                                            </Typography>
                                                            <TableContainer>
                                                                <Table className={classes.tabelaPodio}>
                                                                    <TableBody>
                                                                        {item.podioParcial.map((item, key) => (
                                                                            <TableRow key={key}>
                                                                                <TableCell>{item.colocacao}</TableCell>
                                                                                <TableCell>
                                                                                    <div className={classes.atletaPodio}>
                                                                                        <Avatar className={classes.fotoAtletaPodio} />
                                                                                        <div>
                                                                                            <Typography className="nomeAtleta">
                                                                                                {item.nome_evento.split(' ')[0]}
                                                                                            </Typography>
                                                                                            <Typography className="sobrenomeAtleta">
                                                                                                {item.nome_evento.split(' ').slice(1).join(' ')}
                                                                                            </Typography>
                                                                                        </div>
                                                                                    </div>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <Chip size="small" className={classes.ptsPodio} label={item.notaFinal} />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </div>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Paper>

                                    </Grid>
                                </Grid>
                            </Paper>
                            {matchesLG && pageCompeticao ? ''
                                :
                                <Paper className={classes.cardBottom} elevation={0}>
                                    <Link className={classes.LinkEvento}>
                                        Acompanhar evento ao vivo
                                    </Link>
                                </Paper>
                            }
                        </div>
                    ))}
                </React.Fragment>
            }
        </React.Fragment>
    )
}