import { useStyles } from "../styles/ProximaCompeticao";
import entendaImg from '../../../assets/images/entenda.webp'
import { Link } from "@material-ui/core";

export default function EntendaSection() {
    const classes = useStyles();
    return (
        <div className={classes.EntendaWrapper}>
            <div className={classes.EntendaSection}>
                <Link href="/entenda">
                    <img src={entendaImg} alt="entenda" />
                </Link>
            </div>
        </div>
    )
}