import { Typography, useMediaQuery } from "@material-ui/core";
import { useStyles } from '../styles/Hero';


export default function HeroSection() {
    const classes = useStyles();
    const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));
    return (
        <div className={classes.root}> 
            <Typography variant="h1" className={classes.Heading}>Salta {matchesLG ? <br /> : ''} & Ginga</Typography>
        </div>
    )
}