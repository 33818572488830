import { useEffect, useState } from "react";
import { CBSaltospi } from "../../../services/api";
import _ from "lodash";

export const useEventoProva = ({ idEvento }) => {
  const [classesProvas, setClassesProvas] = useState([]);
  const [eventoProva, setEventoProva] = useState({
    error: false,
    loading: true,
    payload: [],
  });

  useEffect(() => {
    setEventoProva({ loading: true, error: false, payload: [] });
    async function getEventoProva() {
      await CBSaltospi.get("evento-prova", {
        params: {
          id_evento: idEvento,
          expand: "classificacao,aparelho,classe,altura",
          sort: "id_classe",
          pageSize: 100,
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEventoProva({
              error: true,
              loading: true,
              payload: [],
            });
          } else {
            let orderProvas = _.orderBy(
              response.data.items,
              ["altura.id", "aparelho.id", "classe.id"],
              ["asc", "asc", "asc"]
            );

            setEventoProva({
              error: false,
              loading: false,
              payload: orderProvas,
            });

            let classes = response.data.items.map((item) => ({
              id: item.id_classe,
              classe: item.classe,
              aparelho: item.aparelho.tipo_aparelho,
            }));

            setClassesProvas(_.uniqBy(classes, "id"));
          }
        })
        .catch((err) => {
          setEventoProva({
            error: true,
            loading: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getEventoProva();
  }, [idEvento]);

  return { eventoProva, classesProvas };
};
