import { Box, Container, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

function TransparenciaApoioPage() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.cardWhite}>
          <div className={classes.cardContent}>
            <Typography variant="h1" align="center">
              TRANSPARÊNCIA
            </Typography>
            Saiba quanto está sendo arrecadado e como está sendo investido o seu
            dinheiro
            <br />
            <br />
            30% do valor líquido será investido diretamente no atleta escolhido,
            para custear despesas de passagem aérea para participar de
            competições, hospedagem, alimentação, ajuda de custos para cobrir
            despesas de treinamento do atleta especificado, entre outras
            despesas.
            <br />
            <br />
            30% do valor líquido será investido no clube, podendo ser utilizada
            para custear despesas de competições, pagamento de treinador, compra
            de equipamento, entre outros.
            <br />
            <br />
            40% do valor líquido será investido na Saltos Brasil, podendo ser
            utilizado para pagar despesas com a realização de competições,
            participação em competições, cursos de capacitação, contratação de
            profissionais, compra de equipamentos, centros de treinamento,
            despesas administrativas entre outras.
            <br />
            <br />
            Do total do valor doado, será retirado 10% + R$ 0,60 para cobrir
            despesas operacionais com a plataforma de doação, taxas do cartão de
            crédito e despesas com o clube de benefícios. Todo o restante será
            aplicado nos saltos ornamentais, conforme detalhamento acima.
            (colocar esse texto em letra menor, de forma mais discreta)
            <br />
            <br />
          </div>
        </Paper>
      </Container>
    </Box>
  );
}

export default TransparenciaApoioPage;
