import { CardContent, Divider, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useStyles } from "../styles/gruposSaltos";
import entenda1 from "../../../assets/images/entenda/grupo_saltos/entenda_1_frente.jpg";
import entenda2 from "../../../assets/images/entenda/grupo_saltos/entenda_2_costas.jpg";
import entenda3 from "../../../assets/images/entenda/grupo_saltos/entenda_3_pontape.jpg";
import entenda4 from "../../../assets/images/entenda/grupo_saltos/entenda_4_revirado.jpg";
import entenda5 from "../../../assets/images/entenda/grupo_saltos/entenda_5_parafuso.jpg";
import entenda6 from "../../../assets/images/entenda/grupo_saltos/entenda_6_parada_mao.jpg";

export default function GrupoSaltos() {
    
    const classes = useStyles();
    
    return (
        <React.Fragment>
            <CardContent className={classes.cardContent}>
                <Typography>
                    Introdução ao conceito dos diferentes tipos de saltos ornamentais e suas diferenças fundamentais - tipos de saída, movimentos de saída, etc...
                </Typography>
            </CardContent>
            <Divider />
            <div className={classes.gridSaltos}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <div className={clsx(classes.saltoWrapper, classes.saltoEsquerda)}>
                            <div className={classes.imgWrapper}>
                                <img src={entenda1} alt="entenda1" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.saltoInfo}>
                                <Typography variant="subtitle1">1. FRENTE</Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.saltoDescricao}>
                                    Salto de <b>Frente</b> <br /> Giro para <b>Frente</b>
                                </Typography>
                                <Typography variant="body2">
                                    ex. 101a
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.saltoWrapper}>
                            <div className={classes.imgWrapper}>
                                <img src={entenda2} alt="entenda2" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.saltoInfo}>
                                <Typography variant="subtitle1">2. COSTAS</Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.saltoDescricao}>
                                    Salto de <b>Costas</b> <br /> Giro para <b>Costas</b>
                                </Typography>
                                <Typography variant="body2">
                                    ex. 203c
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={clsx(classes.saltoWrapper, classes.saltoEsquerda)}>
                            <div className={classes.imgWrapper}>
                                <img src={entenda3} alt="entenda3" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.saltoInfo}>
                                <Typography variant="subtitle1">
                                    3. PONTAPÉ À LUA
                                </Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.saltoDescricao}>
                                    Salto de <b>Frente</b> <br /> Giro para <b>Costas</b>
                                </Typography>
                                <Typography variant="body2">
                                    ex. 301b
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.saltoWrapper}>
                            <div className={classes.imgWrapper}>
                                <img src={entenda4} alt="entenda4" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.saltoInfo}>
                                <Typography variant="subtitle1">4. REVIRADO</Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.saltoDescricao}>
                                    Salto de <b>Costas</b> <br /> Giro para <b>Frente</b>
                                </Typography>
                                <Typography variant="body2">
                                    ex. 402c
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={clsx(classes.saltoWrapper, classes.saltoEsquerda)}>
                            <div className={classes.imgWrapper}>
                                <img src={entenda5} alt="entenda5" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.saltoInfo}>
                                <Typography variant="subtitle1">5x PARAFUSO</Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.saltoDescricao}>
                                    Salto com <b>Giro Lateral</b>
                                </Typography>
                                <small>x - número dos saltos acima - 1 à 4</small>
                                <Typography variant="body2">
                                    ex. 5254b
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.saltoWrapper}>
                            <div className={classes.imgWrapper}>
                                <img src={entenda6} alt="entenda6" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.saltoInfo}>
                                <Typography variant="subtitle1">6x PARADA DE MÃO</Typography>
                                <Divider />
                                <Typography variant="body1" className={classes.saltoDescricao}>
                                    Salto iniciados em <b>Equilíbrio</b>
                                </Typography>
                                <Typography variant="body2">
                                    ex. 612c
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    )
}