import React from 'react';
import { Input, Label } from './styles/index';
import { FormControl } from '@material-ui/core';

export default function TextInput(props) {

    return (
        <FormControl fullWidth={props.fullWidth} variant="outlined">
            <Label>{props.label}</Label>
            <Input
                variant="outlined"
                {...props}
            />
        </FormControl>
    )
}