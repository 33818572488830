import { makeStyles } from "@material-ui/core";
import sobre_mobile from "../../../assets/images/sobre_mobile1000.webp";
import sobre_tablet from "../../../assets/images/sobre_tablet1000.webp";
import sobre_desk from "../../../assets/images/sobre_desk2400.webp";

export const useStyles = makeStyles((theme) => ({
  root: {
    background: `url(${sobre_mobile})`,
    backgroundColor: theme.palette.secondary.dark100,
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    marginBottom: -100,
    height: 600,
    [theme.breakpoints.up("sm")]: {
      height: 900,
      background: `url(${sobre_tablet})`,
      backgroundColor: theme.palette.secondary.dark100,
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    },
    [theme.breakpoints.up("md")]: {
      height: 1000,
      background: `url(${sobre_desk})`,
      backgroundColor: theme.palette.secondary.dark100,
      backgroundPosition: "top center",
      backgroundRepeat: "no-repeat",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: -400,
      backgroundSize: "1600px",
    },
    [theme.breakpoints.up("lg2")]: {
      backgroundSize: "contain",
    },
  },
  headingWrapper: {
    height: 500,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    paddingBottom: 120,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 140,
      height: 620,
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: 0,
      marginBottom: 0,
      height: 900,
      justifyContent: "center",
    },
    [theme.breakpoints.up("lg")]: {
      height: 600,
    },
  },
  subHeading: {
    fontFamily: "'Quicksand', sans-serif",
    color: theme.palette.warning.light,
    textShadow: `-20px 21px 7px #004B7D63`,
    fontSize: "3rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "4.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "5.2rem",
    },
  },
  heading: {
    fontFamily: "'Quicksand', sans-serif",
    color: theme.palette.primary.pale,
    fontWeight: 600,
    textShadow: `-20px 21px 7px #004B7D63`,
    fontSize: "4.5rem",
    paddingBottom: 40,
    [theme.breakpoints.up("sm")]: {
      fontSize: "5.5rem",
      paddingBottom: 0,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "6.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "7.5rem",
    },
  },
  sectionConselho: {
    position: "relative",
    zIndex: 5,
    background: `linear-gradient(180deg, #FFFFFF 0%, #D4EEFF 100%)`,
    borderRadius: 40,
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 100,
    [theme.breakpoints.up("sm")]: {
      paddingBottom: 150,
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: 150,
    },
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 150,
    },
  },
  conselhoContainer: {
    position: "relative",
  },
  darkSection: {
    position: "relative",
    zIndex: 10,
    marginTop: -100,
    paddingBottom: 40,
    minHeight: "200vh",
    background: theme.palette.secondary.dark100,
    [theme.breakpoints.up("sm")]: {
      paddingBottom: 70,
      borderRadius: "40px 40px 80px 12px",
    },
  },
}));
