import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { gestaoApi } from "../../services/api";
import PerfilPessoa from "../atleta/sections/PerfilPessoa";


export default function PageTencico() {

    const [atleta, setAtleta] = useState({
        loading: true,
        error: false,
        payload: [],
    })

    const { id } = useParams();

    useEffect(() => {
        setAtleta({
            loading: true,
            error: false,
            payload: []
        })

        async function getAtleta() {
            await gestaoApi.get('pessoa', {
                params: {
                    id: id,
                    expand: 'departamento,cargo,estabelecimento',
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setAtleta({
                        loading: false,
                        error: true,
                        payload: []
                    })
                } else {

                    let nome = response.data.items[0].nome_completo.split(' ');
                    let sobrenome = response.data.items[0].nome_completo.split(' ').slice(1).join(' ')

                    setAtleta({
                        loading: false,
                        error: false,
                        payload: { ...response.data.items[0], firstName: nome[0], lastName: sobrenome }
                    })
                }
            });
        }
        getAtleta();

    }, [id])


    return (
        <PerfilPessoa pessoa={atleta} isAtleta={false} />
    )
}