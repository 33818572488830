import { Container, Link, Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { useStyles } from "../styles/NossoHerois";
// import Anna_900 from '../../../assets/images/atletas/Anna_900.webp'
import Giovanna_900 from '../../../assets/images/atletas/Giovanna_900.webp'
import Ingrid_900 from '../../../assets/images/atletas/Ingrid_900.webp'
import Issac_900 from '../../../assets/images/atletas/Issac_900.webp'
import Luana_900 from '../../../assets/images/atletas/Luana_900.webp'
import Tammy_900 from '../../../assets/images/atletas/Tammy_900.webp'

export default function NossosHerois(props) {
    const classes = useStyles();
    const { onlyCardAtletas } = props;

    if (onlyCardAtletas) {
        return (
            <Paper className={classes.cardRoot}>
                <div className={classes.greenGradient1}></div>
                <div className={classes.greenGradient2}></div>
                <div className={classes.cardAtletas} >
                    <img src={Giovanna_900} alt="" />
                    <img src={Tammy_900} alt="" />
                    <img src={Ingrid_900} alt="" />
                    <img src={Luana_900} alt="" />
                    <img src={Issac_900} alt="" />
                </div >
            </Paper>
        )
    }

    return (
        <div className={classes.bgWrapper}>
            <Paper className={classes.root}>
                <Container maxWidth="md">
                    <div className={classes.greenGradient1}></div>
                    <div className={classes.greenGradient2}></div>
                    <div>
                        <Typography className={classes.title} variant="h2">Nossos Heróis</Typography>
                    </div>
                    <div className={classes.cardAtletas}>
                        <img src={Giovanna_900} alt="" />
                        <img src={Tammy_900} alt="" />
                        <img src={Ingrid_900} alt="" />
                        <img src={Luana_900} alt="" />
                        <img src={Issac_900} alt="" />
                    </div>
                    <div className={classes.cardAtletasBottom}>
                        <Link className={classes.link} href={'equipe'}>
                            CONHEÇA NOSSA EQUIPE
                        </Link>
                    </div>
                </Container>

            </Paper>
        </div >
    )
}