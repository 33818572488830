import { List, ListItem, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from '../styles/AccordionEtapa';
import AddIcon from '@material-ui/icons/Add';
import { convertDateTime } from "../../../util/convertDate";
import _ from "lodash";

export default function AccordionEtapa(props) {
    const classes = useStyles();
    const { etapa } = props
    const [expanded, setExpanded] = React.useState(false);
    const [etapaF, setEtapaF] = useState([])

    const handleChange = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (etapa.eventoProvasF) { 
            // console.log(_.orderBy(etapa.eventoProvasF, ['id_classe','id_aparelho'],['asc','asc']))
            setEtapaF(_.orderBy(etapa.eventoProvasF, ['id_classe','id_aparelho'],['asc','asc']))
        }
    }, [etapa])
    return (
        <div className={classes.root}>
            <Accordion expanded={expanded} onChange={handleChange} className={classes.Accordion}>
                <AccordionSummary
                    expandIcon={<AddIcon />}>
                    <Typography className={classes.heading}>
                        {convertDateTime(etapa.data_etapa)} - {props.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {etapa ?
                        <List dense className={classes.ListCronograma}>
                            {etapaF ?
                                etapaF.map((item, key) => (
                                    <ListItem key={key}>
                                        <Typography>
                                            <b>Final </b> {` ${item.sexo === 'M' ? "Masculino" : "Feminina"} ${item.altura ? item.altura.descricao : ''} ${item.aparelho ? item.aparelho.descricao : ''} - ${item.classe ? item.classe.descricao : ''}`}
                                        </Typography>
                                    </ListItem>
                                ))
                                : ''}
                        </List>
                        :
                        ''
                    }
                </AccordionDetails>
            </Accordion>
        </div>
    )
}