import { createSlug } from "../../util/createSlug";

export const TransparenciaIcones = {
  1: { icon: "ArquivosSVG" },
  3: { icon: "BalancetesSVG" },
  4: { icon: "ConveniosSVG" },
  5: { icon: "RelatoriosSVG" },
  6: { icon: "ConselhoSVG" },
  7: { icon: "LicitacoesSVG" },
  8: { icon: "TrabalheSVG" },
  9: { icon: "RecursosSVG" },
  11: { icon: "RelatoriosSVG" },
};

export function CreateMenusTransparencia(menus) {
  const formatMenu = menus.map((item) => ({
    title: item.titulo,
    slug: item.url || createSlug(item.titulo),
    icon: TransparenciaIcones[item.id]
      ? TransparenciaIcones[item.id].icon
      : "RelatoriosSVG",
    id_menu: item.id,
  }));
  return formatMenu;
}
