import axios from 'axios';

const apiURL = (category) => `https://restsaltos.bigmidia.com/${category}/api/`;
// const apiURL = (category) => `https://restcbtkd.bigmidia.com/${category}/api/`;


const apiConnection = (category) => axios.create({
  baseURL: apiURL(category),
});

// api gestao
export const gestaoApi = apiConnection('gestao');

//api cbtkd
export const CBSaltospi = apiConnection('cbsaltos');

// api portal
export const portalApi = apiConnection('portal');

// api cbca
export const governancaApi = apiConnection('governanca');