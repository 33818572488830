import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

function SejaPadrinhoPage() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Typography variant="h1" align="center">
          SEJA UM PADRINHO
        </Typography>
        <Typography variant="subtitle1"></Typography>
        <Typography>
          Ceda a sua imagem e contribua para a campanha de Sócios Torcedores da
          Saltos Brasil. Preencha o cadastro abaixo e publique um banner com a
          campanha da Saltos Brasil em suas redes sociais e ajude a fazermos a
          diferença no esporte brasileiro.
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Card>
            <CardContent>
              <Typography variant="h6">CASTRO DE PADRINHO</Typography>
              <Grid container spacing={2}>
                <Grid item sx={12} md={12}>
                  <TextField label="Nome" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={3}>
                  <TextField label="CPF" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={3}>
                  <TextField label="Sexo" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={12}>
                  <TextField label="Endereço" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={4}>
                  <TextField label="Telefone" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={4}>
                  <TextField label="Email" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={4}>
                  <TextField label="Instagram" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={4}>
                  <TextField label="Facebook" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={4}>
                  <TextField label="TikTok" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={4}>
                  <TextField label="Twiter" color="secondary" fullWidth />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default SejaPadrinhoPage;
