import React from "react";
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../styles/NoticiasRecentes";
import { convertDateMonthString } from "../../../util/convertDate";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import placeholder_img from '../../../assets/images/placeholder_img.webp'
import clsx from "clsx";
export default function NoticiasRecentes(props) {
    const classes = useStyles();
    const { noticias, loadingNoticias, fullSection, noticiasSection } = props;

    return (
        <Grid container spacing={fullSection ? 3 : 1}>
            {(loadingNoticias ? Array.from(new Array(fullSection ? 4 : 3)) : noticias).map((item, key) => (
                <Grid item xs={12} sm={12} md={12} lg={fullSection && noticias.length > 1 ? 6 : 12} key={key}>
                    <Card className={classes.CardNoticiaRecente}>
                        <CardActionArea component={Link} disabled={!item} to={item ? `/noticias/leitura/${item.id}/${createSlug(item.titulo)}` : ''}>
                            <Grid container>
                                <Grid item xs={5} sm={4} md={3} lg={4}>
                                    {loadingNoticias ?
                                        <Skeleton variant="rect" className={clsx(classes.CardMedia, { [classes.CardMidiaLarge]: noticiasSection })} />
                                        :
                                        <CardMedia className={clsx(classes.CardMedia, { [classes.CardMidiaLarge]: noticiasSection })}
                                            component="img"
                                            onError={e => { e.target.src = `${placeholder_img}` }}
                                            src={item.urlFotoCapa}
                                            title={item.titulo}
                                        />
                                    }
                                </Grid>
                                <Grid item xs={7} sm={8} md={9} lg={8}>
                                    <CardContent className={classes.CardContent} >
                                        {loadingNoticias ?
                                            <div style={{ marginTop: 10 }} >
                                                <Skeleton variant="text" height={26} width="70%" />
                                                <Skeleton variant="text" height={46} width="90%" />
                                                <Skeleton variant="text" height={16} width="40%" />
                                            </div>
                                            :
                                            <React.Fragment>
                                                <Typography className={classes.titulo}>{item.titulo}</Typography>
                                                <Typography className={classes.resumo}>{item.resumo}</Typography>
                                                <Typography className={classes.data}>
                                                    {convertDateMonthString(item.created_at * 1000)}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    </CardContent>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    )
}