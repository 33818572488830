import { Container, Divider, Table, TableBody, TableContainer, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import React from "react";
import { useStyles } from "../styles/TabelaAtletas";
import TabelaArbitros from "./TabelaArbitros";
import TabelaAtletas from "./TabelaAtletas";
import TabelaTecnicos from "./TabelaTecnicos";

export default function TabelaContainer(props) {
    const classes = useStyles();
    const {
        tipo,
        genero,
        atletas,
        tecnicos,
        arbitros,
        searchTerm,
        handleChangePage,
        totalPages,
        page,
    } = props

    const tipoPessoa = ['Atletas', 'Técnicos', 'Árbitros']
    const [selected, setSelected] = React.useState([]);

    return (
        <React.Fragment>
            <div className={classes.root}>
                <Container maxWidth="xl">
                    <div className={classes.TableHeading}>
                        <Typography variant="h3" className={classes.titulo}>
                            {searchTerm ? `Nome: ${searchTerm} ` : `${tipoPessoa[tipo]} ${genero ? `- ${genero}` : ''}`}
                        </Typography>
                    </div>
                </Container>
                <Divider className={classes.Divider} />

                <TableContainer>
                    <Table className={classes.Table} >
                        <TableBody>
                            {tipo === 0 && !atletas.loading ?
                                <TabelaAtletas
                                    atletas={atletas} selected={selected} setSelected={setSelected} page={page} />
                                :
                                ''
                            }
                            {tipo === 1 && !tecnicos.loading ?
                                <TabelaTecnicos tecnicos={tecnicos} selected={selected} setSelected={setSelected} page={page} />
                                :
                                ''
                            }
                            {tipo === 2 && !arbitros.loading ?
                                <TabelaArbitros tecnicos={arbitros} selected={selected} setSelected={setSelected} page={page} />
                                :
                                ''
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                {totalPages > 1 ?
                    <div className={classes.paginationContainer}>
                        <Pagination count={totalPages} page={page} onChange={handleChangePage} />
                    </div>
                    :
                    <></>
                }
            </div>
            <div className={classes.cardBottom}></div>
        </React.Fragment>
    )
}