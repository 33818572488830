import { makeStyles } from "@material-ui/core";
// import apoie_1600 from '../../../assets/images/apoie_1600.png';
// import apoie_2400 from '../../../assets/images/apoie_2400.png';

export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        zIndex: 2,
        background: `transparent linear-gradient(180deg, #231D47 0%, #0E0B1E 90%,  ${theme.palette.secondary.dark100} 100%)`,
        borderRadius: '0px 0px 30px 30px'
    },
    rootStright: {
        borderRadius: 0,
        background: 'transparent',
    },
    marcasWrapper: {
        backgroundColor: theme.palette.secondary.dark100,
        width: '100%',
    },
    topSection: {
        color: '#fff',
        padding: '25px 0',
        textAlign: 'center',
        '& img': {
            maxWidth: '100%',
            padding: '0 5px',
            [theme.breakpoints.up('md')]: {
                padding: '0 10px',
                height: '100%',
                // maxHeight: 40,
                // maxWidth: 200,
                maxHeight: 50,
                maxWidth: 450,
            },
        }
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        [theme.breakpoints.up('md')]: {
            padding: '0 15px',
        },
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-start',
            marginTop: 50,
        }
    },
    wrapperAbsolute: {
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
        }
    },
    sectionTitle: {
        display: 'none',
        fontFamily: "'Quicksand', sans-serif",
        color: '#fff',
        fontSize: '2rem',
        letterSpacing: '1.92px',
        textShadow: '-20px 21px 12px #004B7D63',
        [theme.breakpoints.up('lg')]: {
            display: 'block',
            fontSize: '3rem',
            marginTop: 170,
            marginBottom: 20,
        },
    },
    footerTitle: {
        marginTop: 30,
        color: '#fff',
        fontSize: '1rem',
        textAlign: 'center',
        marginBottom: '.6rem',
        fontFamily: "'Quicksand', sans-serif",
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 30,
        }
    },
    sectionInfo: {
        display: 'none',
        fontFamily: "'Quicksand', sans-serif",
        color: '#fff',
        marginBottom: 70,
        [theme.breakpoints.up('lg')]: {
            display: 'block',
        }
    },
    contatoWrapper: {
        // overflow: 'hidden',
        display: 'flex',
        flexDirection: 'row',
        color: '#fff',
        '& svg': {
            marginRight: 10,
            [theme.breakpoints.up('md')]: {
                margin: '0 10px 0px 0px',
            },
            [theme.breakpoints.up('lg')]: {
                margin: '0 8px 0 18px',
            }
        }
    },
    btnWrapper: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    btnApoiePF: {
        display: 'none',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        fontSize: '1.1rem',
        lineHeight: 1,
        color: theme.palette.warning.light,
        border: `2px solid ${theme.palette.warning.light}`,
        padding: theme.spacing(1.6, 2.5),
        borderRadius: 12,
        marginBottom: 30,
        marginTop: 20,
        [theme.breakpoints.up('sm')]: {
            marginTop: 50,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 60,
            padding: theme.spacing(1.8, 3.5),
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.25rem',
            padding: theme.spacing(2.2, 4),
        }
    },
    btnMargin0: {
        marginTop: 0,
    },
    linkApoieEquipe: {
        display: 'block',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        fontSize: '1.25rem',
        lineHeight: 1,
        color: theme.palette.info.light
    },
    contentWrapper: {
        paddingBottom: 50,
        //     backgroundImage: `url(${apoie_1600})`,
        //     backgroundSize: 'calc(100vh - 95px)',
        //     backgroundPosition: 'top right',
        //     backgroundRepeat: 'no-repeat',
        //     [theme.breakpoints.up('md')]: {
        //         backgroundImage: `url(${apoie_2400})`,
        //     }
    },
    imgApoie: {
        '& img': {
            maxWidth: '100%',
        }
    },
    textContainer: {
        order: 1,
        [theme.breakpoints.up('lg')]: {
            order: 0,
        }
    },
    imgContainer: {
        order: 0,
        '& img': {
            display: 'flex',
            marginLeft: 'auto',
            maxHeight: '100vh'
        },
        [theme.breakpoints.up('sm')]: {
            '& img': {
                maxHeight: 600
            },
        },
        [theme.breakpoints.up('md')]: {
            '& img': {
                maxHeight: 700
            },
        },
        [theme.breakpoints.up('lg')]: {
            order: 1,
            '& img': {
                maxHeight: 800
            },
        }
    },
    title: {
        fontSize: '2.25rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        textAlign: 'center',
        color: "#fff",
        marginBottom: 10,
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.8rem',
        },
    },
    titleWarning: {
        color: theme.palette.warning.light
    },
    titleInfo: {
        color: theme.palette.info.light
    },
    marcas: {
        marginTop: 15,
        marginBottom: 20,
        maxWidth: '100%',
        overflow: 'hidden',
        textAlign: 'center',
        '& img': {
            maxWidth: '100%',
            maxHeight: 100,
        },
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            '& img': {
                padding: 0,
            },
        }
    },
    logoPatrocinadores: {
        '& img': {
            maxHeight: 65,
        },
    },
    imgWrapper: {
        marginBottom: 10,
        padding: '0 10px',
        [theme.breakpoints.up('md')]: {
            margin: '0 10px',
        }
    },
    contatoItem: {
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '0.3rem',
        padding: '0 10px',
        '& .MuiTypography-body1': {
            fontFamily: "'Quicksand', sans-serif",
        },
        '& a': {
            color: '#fff',
            '&:hover': {
                opacity: 0.75
            }
        }
    }
}));