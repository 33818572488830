import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Paper, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Link } from 'react-router-dom';
import { convertDateMonthString } from "../../../util/convertDate";
import { createSlug } from "../../../util/createSlug";
import { useStyles } from '../styles/CardsNoticias';
import placeholder_img from '../../../assets/images/placeholder_img.webp'

export default function CardsNoticas(props) {
    const classes = useStyles();
    const { noticias, loadingNoticias, noContainer } = props

    const cards = (noticias, loadingNoticias) => {
        return (
            <Grid container>
                {(loadingNoticias ? Array.from(new Array(4)) : noticias).map((item, key) => (
                    <Grid key={key} item xs={12} sm={12} md={12} lg={3}>
                        <Card className={classes.cardNoticias}>
                            <CardActionArea component={Link} disabled={!item} to={item ? `/noticias/leitura/${item.id}/${createSlug(item.titulo)}` : ''}>
                                {item ?
                                    <CardMedia
                                        className={classes.cardNoticiasMedia}
                                        onError={e => { e.target.src = `${placeholder_img}` }}
                                        component="img"
                                        src={item.urlFotoCapa}
                                        title={item.titulo}
                                    />
                                    :
                                    <Skeleton animation="wave" variant="rect" className={classes.cardNoticiasMedia} />
                                }
                                <CardContent className={classes.cardNoticiasContent}>
                                    <Typography className={classes.tituloNoticiaCards} variant="body2" color="textSecondary" component="p">
                                        {item ? item.titulo : <Skeleton animation="wave" variant="text" height={24} width="90%" />}
                                    </Typography>
                                    <Typography className={classes.resumoNoticiaCards} variant="body2" color="textSecondary" component="p">
                                        {item ? item.resumo : <Skeleton animation="wave" variant="text" height={46} />}
                                    </Typography>
                                    <Typography className={classes.dataNoticia} variant="caption" component="p">
                                        {item ? convertDateMonthString(item.created_at * 1000) : <Skeleton animation="wave" variant="text" height={20} width="70%" />}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        )
    }

    if (noContainer) {
        return cards(noticias, loadingNoticias)
    }
    return (
        <Container className={classes.containerNoticias}>
            <Paper className={classes.noticiasWrapper}>
                {cards(noticias, loadingNoticias)}
                <Link to="/noticias" className={classes.link}>TODAS AS NOVIDADES</Link>
            </Paper>
        </Container >
    )
}