import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: '80px 0',
        overflow: 'hidden',
        paddingBottom: 70,
    },
    section: {
        '& .sectionText': {
            fontSize: '1.5rem',
            color: '#fff',
            fontFamily: "'Quicksand', sans-serif"
        },
        '& .section-1 .sectionText': {
            color: theme.palette.warning.light
        },
        '& .section-2 .sectionText': {
            color: theme.palette.primary.pale
        },
        '& .section-3 .sectionText': {
            color: theme.palette.info.pale
        },

        '& .sectionTop': {
            height: 16,
            marginBottom: 20,
            backgroundColor: '#fff',
            maxWidth: 130,
            borderRadius: '3px 3px 20px 3px',
        },
        '& .section-1 .sectionTop': {
            backgroundColor: theme.palette.warning.light,
            width: 60,
        },
        '& .section-2 .sectionTop': {
            backgroundColor: theme.palette.primary.light,
            width: 50,
        },
        '& .section-3 .sectionTop': {
            backgroundColor: theme.palette.info.light,
            width: 40,
        },
        '& .section-4 .sectionTop': { 
            width: 32,
        },

        marginBottom: 40,
    },
    sectionAction: {
        marginTop: 30,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left',
        },
        '& .MuiButtonBase-root': {
            marginBottom: 30,
            [theme.breakpoints.up('sm')]: {
                marginBottom: 0,
                marginRight: 20,
            },
            [theme.breakpoints.up('md')]: {
                minWidth: 280,
                marginRight: 50,
            },
        },

    }
}));