import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContent: {
        color: '#fff',
        marginTop: 15,
        '& .MuiTypography-body1': {
            fontFamily: "'Quicksand', sans-serif",
        }
    },
    sectionTitle: {
        color: theme.palette.info.light,
        textTransform: 'uppercase',
        fontWeight: 600,
        marginBottom: 8,
        [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem'
        }
    },
    calculadoraWrapper: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 20,
        marginBottom: 20,
        '& .calcItem': {
            margin: '0 3px',
        },
        '& .calcHeader': {
            marginBottom: 10,
            textAlign: 'center',
            fontSize: '1.7rem',
            '& span': {
                fontSize: '1rem',
                verticalAlign: 'super',
            },
        },
        '& .calcSelector': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 40,
            height: 110,
            borderRadius: 10,
            border: '2px solid #fff',
            overflow: 'hidden',
            '& span': {
                fontWeight: 'bold',
                fontSize:'1.25rem'
            },
            '& button': {
                minWidth: '100%',
                padding: 0,
                '& svg': {
                    fontSize: '2rem',
                    color: theme.palette.info.light
                },
                '&.disabled svg': {
                    color: theme.palette.primary.darker
                }
            },

        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-start',
            '& .calcItem': {
                margin: '0 5px',
            },
            '& .calcSelector': {
                width: 50,
            }
        },
        '& .calcItem:nth-child(1) .calcHeader': {
            color: theme.palette.primary.dark80
        },
        '& .calcItem:nth-child(2) .calcHeader, & .calcItem:nth-child(6) .calcHeader': {
            color: theme.palette.info.light
        },
        '& .calcItem:nth-child(4) .calcHeader': {
            color: theme.palette.warning.main
        },

    },
    textBoldLG: {
        color: '#fff',
        fontSize: '1.3rem',
        fontWeight: 600,
        fontFamily: "'Quicksand', sans-serif",
        marginRight: 10,
        lineHeight: 1,
        marginBottom: 8,
        [theme.breakpoints.up('md')]: {
            marginRight: 0,
            fontSize: '1.5rem',
        }
    },
    descSalto: {
        lineHeight: 1.2,
        color: theme.palette.primary.pale,
        fontSize: '1rem',
        fontWeight: 400,
        fontFamily: "'Quicksand', sans-serif",
        marginBottom: 20,
    }
}));