
export default function SaltosBrasil(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={props.width ? props.width : '113.386px'} height={props.height ? props.height : '49.802px'} viewBox="0 0 113.386 49.802">
            <g>
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#00707E" d="M90.049,6.423c0.008,3.322-2.632,6.234-6.277,6.234
			c-3.433,0-6.198-2.805-6.205-6.232c-0.007-3.442,2.819-6.265,6.267-6.259C87.254,0.172,90.048,2.985,90.049,6.423 M87.676,6.455
			c0.062-2.147-1.661-3.95-3.823-4.003c-2.031-0.051-3.839,1.686-3.902,3.745c-0.07,2.33,1.549,4.117,3.808,4.178
			C85.753,10.427,87.642,8.83,87.676,6.455"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#00707E" d="M61.384,12.476c-0.909,0-1.776,0.004-2.641-0.006
			c-0.063,0-0.149-0.09-0.186-0.158c-0.305-0.568-0.605-1.139-0.896-1.713c-0.061-0.119-0.131-0.162-0.266-0.16
			c-1.887,0.004-3.773,0.004-5.66-0.002c-0.159,0-0.233,0.062-0.301,0.193c-0.293,0.562-0.592,1.123-0.897,1.682
			c-0.038,0.068-0.123,0.156-0.187,0.158c-0.866,0.01-1.731,0.006-2.641,0.006C50.013,8.304,52.297,4.165,54.593,0
			C56.859,4.163,59.11,8.296,61.384,12.476 M54.593,4.745C53.966,5.922,53.359,7.06,52.74,8.22h3.671
			C55.804,7.058,55.211,5.926,54.593,4.745"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#00707E" d="M47.099,1.477c-0.465,0.594-0.926,1.186-1.35,1.727
			c-0.44-0.219-0.836-0.463-1.265-0.613c-0.292-0.104-0.631-0.117-0.946-0.102c-0.459,0.025-0.719,0.332-0.848,0.754
			c-0.124,0.408-0.012,0.771,0.311,1.025c0.401,0.316,0.836,0.586,1.264,0.867c0.621,0.406,1.286,0.756,1.865,1.214
			c1.625,1.283,1.739,3.809,0.264,5.223c-0.657,0.629-1.466,0.945-2.358,1.047c-1.264,0.145-2.427-0.168-3.49-0.85
			c-0.273-0.176-0.51-0.41-0.777-0.627c0.46-0.613,0.891-1.186,1.33-1.771c0.248,0.164,0.473,0.328,0.713,0.469
			c0.634,0.371,1.312,0.572,2.056,0.512c0.522-0.043,0.876-0.293,1.021-0.727c0.156-0.467,0.029-0.996-0.379-1.293
			c-0.52-0.379-1.06-0.732-1.614-1.057c-0.629-0.367-1.267-0.717-1.771-1.256c-0.932-0.999-1.177-2.171-0.784-3.45
			c0.385-1.254,1.301-2.021,2.554-2.284c1.507-0.316,2.907-0.025,4.077,1.055C47.013,1.379,47.05,1.424,47.099,1.477"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#00707E" d="M91.169,11.115c0.441-0.588,0.872-1.162,1.286-1.715
			c0.45,0.248,0.875,0.521,1.331,0.721c0.454,0.197,0.944,0.283,1.452,0.232c0.484-0.047,0.865-0.238,1.027-0.719
			c0.162-0.484,0.056-0.93-0.332-1.262c-0.312-0.268-0.653-0.508-1.002-0.725c-0.49-0.307-1.008-0.568-1.5-0.871
			c-0.666-0.412-1.22-0.946-1.556-1.663c-0.708-1.506-0.296-3.656,1.66-4.59c1.5-0.715,3.896-0.288,4.942,0.957
			c-0.457,0.584-0.918,1.174-1.358,1.736c-0.392-0.209-0.75-0.453-1.146-0.594c-0.324-0.113-0.693-0.145-1.04-0.135
			c-0.471,0.012-0.744,0.336-0.865,0.764c-0.12,0.422,0.011,0.795,0.354,1.062c0.332,0.258,0.686,0.488,1.039,0.715
			c0.646,0.414,1.334,0.77,1.941,1.235C98.58,7.167,99.01,8.4,98.715,9.849c-0.277,1.359-1.161,2.207-2.466,2.582
			c-1.843,0.529-3.491,0.109-4.927-1.152C91.272,11.236,91.231,11.183,91.169,11.115"/>
                    <polygon fillRule="evenodd" clipRule="evenodd" fill="#00707E" points="74.444,12.47 72.058,12.47 72.058,2.555 69.234,2.555 
			69.234,0.375 77.259,0.375 77.259,2.542 74.444,2.542 		"/>
                    <path fillRule="evenodd" clipRule="evenodd" fill="#00707E" d="M70.104,10.281v2.182h-7.409V0.379h2.361v0.305
			c0,3.096,0.003,6.192-0.006,9.286c-0.002,0.254,0.066,0.318,0.316,0.316c1.473-0.012,2.947-0.006,4.42-0.006H70.104z"/>
                </g>
                <g>
                    <path fill="#161138" d="M111.969,2.668c0,0-0.911-1.32-1.858-1.32c-1.752,0-2.903,1.508-3.258,1.926
			c-0.689,0.844-1.236,1.693-1.747,2.551c-0.506,0.86-0.965,1.724-1.393,2.593c-0.857,1.738-1.613,3.482-2.334,5.215
			c-0.717,1.732-1.384,3.457-1.997,5.168c-0.615,1.711-1.171,3.41-1.665,5.102c-0.119,0.426-0.237,0.85-0.354,1.271
			c-0.058,0.215-0.114,0.432-0.17,0.645c-0.052,0.209-0.106,0.402-0.156,0.629c-0.203,0.881-0.373,1.725-0.52,2.582
			c-0.091,0.562-0.169,1.123-0.235,1.682c-0.47,0.441-0.937,0.875-1.396,1.301c-1.173,1.078-2.321,2.107-3.449,3.045
			c-0.564,0.469-1.131,0.904-1.689,1.309c-0.556,0.402-1.111,0.768-1.648,1.061c-0.267,0.143-1.003,0.486-1.125,0.521
			c-1.489,0.441-1.424-0.861-1.367-1.516c0.143-1.611,0.661-3.201,1.233-4.699c0.761-1.986,1.707-4.926,2.789-6.76
			c0.45-0.762-2.697-2.129-3.271-1.156c-1.724,2.92-4.972,10.551-4.062,13.824c0.971,3.498,4.442,3.996,6.237,3.418
			c1.794-0.576,3.953-2.098,5.234-3.1c0.819-0.637,1.609-1.33,2.382-2.061c0.021,0.248,0.047,0.494,0.08,0.742
			c0.12,0.846,0.295,1.699,0.636,2.574c0.167,0.438,0.396,0.883,0.701,1.322c0.307,0.436,0.713,0.873,1.24,1.209
			c0.522,0.338,1.155,0.543,1.734,0.582c0.583,0.043,1.1-0.043,1.548-0.17c0.905-0.258,1.561-0.672,2.153-1.08
			c0.584-0.418,1.085-0.85,1.538-1.287c0.906-0.873,1.636-1.744,2.278-2.564c0.636-0.824,1.186-1.596,1.651-2.311
			c0.946-1.418,1.604-2.576,2.045-3.367c0.437-0.797,0.654-1.227,0.654-1.227c0.041-0.08,0.027-0.184-0.042-0.248
			c-0.084-0.08-0.217-0.078-0.297,0.006c0,0-0.332,0.348-0.955,1c-0.617,0.652-1.525,1.605-2.698,2.775
			c-0.591,0.58-1.248,1.213-1.979,1.869c-0.729,0.656-1.534,1.338-2.415,1.959c-0.442,0.307-0.898,0.604-1.364,0.842
			c-0.116,0.059-0.233,0.119-0.348,0.166c-0.116,0.055-0.231,0.098-0.343,0.139c-0.225,0.084-0.442,0.141-0.626,0.16
			c-0.186,0.025-0.335,0.014-0.421-0.018c-0.086-0.033-0.11-0.064-0.153-0.115c-0.08-0.088-0.272-0.395-0.4-0.879
			c-0.133-0.479-0.212-1.078-0.239-1.715c-0.027-0.639-0.016-1.318,0.036-2.021c0.08-1.062,0.227-2.172,0.433-3.307
			c1.138-1.482,6.563-8.73,9.173-13.115C112.382,13.433,115.129,7.048,111.969,2.668z M110.686,8.146
			c-0.018,0.885-0.159,1.787-0.361,2.68c-0.414,1.789-1.111,3.529-1.934,5.188c-1.652,3.322-3.885,6.283-6.277,8.891
			c-0.074-0.045,0.888-3.109,1.386-4.729c1.043-3.369,2.186-6.854,3.575-10.236c0.35-0.844,0.701-1.686,1.082-2.5
			c0.186-0.41,0.381-0.811,0.584-1.201c0.199-0.39,1.097-1.903,1.19-1.903c0.093,0,0.331,0.83,0.436,1.232
			c0.055,0.184,0.107,0.476,0.154,0.663c0.031,0.205,0.083,0.406,0.1,0.619l0.037,0.318l0.018,0.322
			C110.693,7.705,110.685,7.927,110.686,8.146"/>
                    <path fill="#161138" d="M82.608,22.724c0.004-0.158-0.012-0.34-0.073-0.531c-0.062-0.193-0.179-0.391-0.343-0.561
			c-0.331-0.348-0.808-0.541-1.292-0.645c-0.49-0.107-1.01-0.131-1.551-0.109c-1.083,0.043-2.257,0.268-3.49,0.635
			c-0.62,0.18-1.25,0.402-1.893,0.658c-0.646,0.254-1.3,0.549-1.96,0.887c-0.661,0.336-1.331,0.711-1.993,1.152
			c-0.665,0.439-1.338,0.922-1.984,1.525c-0.324,0.303-0.646,0.629-0.958,1.01c-0.31,0.383-0.62,0.812-0.872,1.365
			c-0.247,0.547-0.467,1.258-0.388,2.117c0.022,0.213,0.06,0.43,0.122,0.641c0.028,0.105,0.067,0.209,0.104,0.311l0.063,0.152
			l0.034,0.074L66.18,31.5c0.041,0.107,0.288,0.549,0.438,0.754c0.707,1.002,1.634,1.668,2.506,2.152
			c0.883,0.486,1.76,0.793,2.601,1.064c0.84,0.271,1.66,0.469,2.433,0.664c0.768,0.195,2.607,0.564,2.607,0.805
			c0,0.242-0.828,0.715-1.371,1.014c-0.546,0.316-1.14,0.59-1.735,0.83c-0.593,0.25-1.191,0.465-1.779,0.648
			c-0.587,0.191-1.164,0.354-1.72,0.48c-0.556,0.137-1.092,0.242-1.595,0.307c-0.5,0.072-0.975,0.098-1.366,0.072
			c-0.826-0.049-0.727-0.504-0.577-0.791c0.24-0.459,0.578-0.932,0.896-1.32c0.248-0.297,0.015-0.285-0.156-0.191
			c-0.172,0.092-0.384,0.211-0.627,0.367c-0.246,0.156-0.519,0.35-0.807,0.633c-0.143,0.143-0.291,0.311-0.42,0.539
			c-0.123,0.225-0.252,0.545-0.186,0.957c0.034,0.201,0.121,0.402,0.239,0.564c0.117,0.164,0.254,0.293,0.393,0.398
			c0.279,0.211,0.564,0.342,0.849,0.451c1.138,0.4,2.342,0.48,3.651,0.473c0.652-0.018,1.327-0.064,2.025-0.146
			c0.694-0.092,1.411-0.217,2.148-0.387c0.734-0.182,1.487-0.406,2.266-0.717c0.775-0.33,1.574-0.705,2.415-1.414
			c0.414-0.367,0.859-0.836,1.184-1.602c0.075-0.193,0.119-0.32,0.169-0.504c0.048-0.17,0.084-0.363,0.111-0.562
			c0.049-0.404,0.029-0.885-0.109-1.352c-0.134-0.467-0.379-0.893-0.645-1.213c-0.269-0.324-0.551-0.564-0.816-0.754
			c-0.533-0.377-1.017-0.6-1.471-0.789c-0.906-0.373-1.729-0.584-2.523-0.803c-0.792-0.213-1.545-0.414-2.257-0.621
			c-1.408-0.414-2.679-0.971-3.115-1.594c-0.772-1.104,3.198-3.895,3.73-4.281c0.535-0.383,1.07-0.742,1.598-1.078
			c0.53-0.33,1.054-0.633,1.563-0.912c1.021-0.549,1.993-0.986,2.864-1.26c0.435-0.133,0.845-0.225,1.208-0.256
			c0.364-0.027,0.675,0.006,0.896,0.115c0.221,0.107,0.371,0.311,0.448,0.543c0.077,0.229,0.109,0.654,0.063,0.936
			c-0.01,0.059,0.087,0.082,0.162-0.061C82.483,23.435,82.597,23.042,82.608,22.724"/>
                    <path fill="#161138" d="M50.864,24.337c0.516,0.012,0.966,0.137,1.268,0.24c0.154,0.051,0.272,0.098,0.351,0.131
			c0.081,0.031,0.123,0.047,0.123,0.047c0.05,0.02,0.107,0.018,0.158-0.008c0.095-0.049,0.132-0.166,0.083-0.26
			c0,0-0.086-0.168-0.305-0.461c-0.223-0.283-0.587-0.707-1.242-1.072c-0.641-0.373-1.626-0.621-2.701-0.486
			c-1.075,0.121-2.181,0.568-3.204,1.207c-0.853,0.529-1.654,1.199-2.412,1.953c-0.045-0.566-0.121-1.111-0.234-1.623
			c-0.211-0.93-0.533-1.779-1.083-2.422c-0.271-0.316-0.624-0.568-0.969-0.666c-0.345-0.104-0.64-0.074-0.841-0.016
			c-0.211,0.062-0.358,0.135-0.421,0.184c-0.075,0.051-0.114,0.078-0.114,0.078c-0.043,0.029-0.075,0.076-0.083,0.131
			c-0.016,0.105,0.057,0.205,0.164,0.221c0,0,0.046,0.008,0.136,0.02c0.101,0.006,0.205,0.037,0.321,0.092
			c0.126,0.051,0.244,0.145,0.322,0.264c0.077,0.121,0.127,0.27,0.166,0.48c0.076,0.42,0.065,1.064-0.008,1.83
			c-0.073,0.766-0.205,1.658-0.362,2.641c-0.047,0.295-0.096,0.598-0.146,0.906c-0.222-0.141-0.486-0.244-0.774-0.309
			c-0.658-0.15-1.445-0.148-2.345-0.08c-0.546,0.045-1.138,0.129-1.764,0.242c-0.11-0.352-0.245-0.697-0.407-1.035
			c-0.606-1.309-1.59-2.443-2.731-3.289c-1.139-0.859-2.415-1.475-3.715-1.93c-0.668-0.229-1.344-0.416-2.026-0.57
			c1.888-1.287,3.686-2.672,5.361-4.215c0.923-0.861,1.822-1.762,2.64-2.771c0.814-1.012,1.594-2.1,2.171-3.445
			c0.284-0.676,0.521-1.418,0.62-2.26c0.099-0.834,0.037-1.785-0.29-2.673c-0.32-0.889-0.873-1.65-1.466-2.213
			c-0.597-0.57-1.229-0.979-1.845-1.301c-1.238-0.643-2.437-0.965-3.585-1.219c-0.576-0.121-1.142-0.219-1.698-0.297
			c-0.542-0.079-1.147-0.139-1.636-0.18C23.944,0,21.771,0.364,19.791,0.879c-3.954,1.084-7.169,2.826-9.85,4.557
			c-0.67,0.436-1.308,0.87-1.909,1.31c-0.599,0.443-1.165,0.883-1.698,1.32C5.268,8.937,4.327,9.783,3.53,10.607
			C1.924,12.238,0.85,13.728,0.338,14.9c-0.253,0.586-0.357,1.096-0.335,1.455c0.003,0.166,0.046,0.355,0.071,0.412
			c0.027,0.072,0.041,0.111,0.041,0.111c0.487,0,0.419-0.941,0.92-1.6c0.677-0.891,1.863-2.119,3.599-3.414
			c0.872-0.645,1.863-1.326,2.976-2.012C8.167,9.511,8.754,9.167,9.372,8.83c0.615-0.344,1.256-0.689,1.929-1.025
			c1.342-0.676,2.808-1.309,4.364-1.896c1.561-0.572,3.224-1.082,4.959-1.408c0.866-0.162,1.749-0.285,2.632-0.326
			c0.88-0.049,1.773-0.021,2.613,0.1c0.532,0.072,0.953,0.148,1.442,0.234c0.474,0.088,0.941,0.189,1.399,0.309
			c0.91,0.234,1.79,0.549,2.482,0.936c0.345,0.191,0.633,0.405,0.829,0.606c0.201,0.205,0.3,0.381,0.354,0.551
			c0.054,0.17,0.083,0.371,0.048,0.66c-0.034,0.287-0.136,0.643-0.299,1.021c-0.331,0.764-0.868,1.609-1.513,2.42
			c-0.648,0.812-1.382,1.627-2.179,2.41c-1.594,1.568-3.393,3.066-5.294,4.461c-0.952,0.695-1.935,1.367-2.935,1.986
			c-0.272,0.17-0.545,0.332-0.818,0.49c-0.491,0.055-0.99,0.133-1.534,0.27c-0.097,0.023-0.198,0.053-0.304,0.086
			c-0.1,0.029-0.213,0.068-0.327,0.111c-0.105,0.035-0.247,0.104-0.368,0.16c-0.152,0.082-0.283,0.146-0.485,0.301
			c-0.05,0.041-0.081,0.059-0.145,0.117c-0.062,0.059-0.122,0.119-0.179,0.18c-0.093,0.088-0.276,0.344-0.38,0.578
			c-0.042,0.064-0.165,0.447-0.169,0.691c-0.021,0.289,0.042,0.59,0.029,0.512c0.05,0.172-0.097-0.305,0.114,0.402
			c0.111,0.266,0.299,0.531,0.491,0.699c0.222,0.199,0.404,0.295,0.575,0.369c0.211,0.086,0.28,0.094,0.429,0.129
			c0.139,0.027,0.193,0.027,0.295,0.035c0.103,0.008,0.149,0.006,0.219,0.004c0.145-0.002,0.219-0.014,0.325-0.025
			c0.08-0.012,0.169-0.025,0.236-0.041c0.546-0.119,0.873-0.252,1.227-0.389c0.34-0.137,0.655-0.279,0.963-0.426
			c0.349-0.166,0.686-0.338,1.018-0.514c0.802-0.072,1.623-0.098,2.439-0.07c1.174,0.029,2.35,0.156,3.482,0.416
			c1.131,0.254,2.225,0.641,3.191,1.207c0.965,0.568,1.786,1.33,2.354,2.275c0.103,0.176,0.194,0.357,0.28,0.543
			c-2.113,0.51-4.56,1.295-7.258,2.387c-2.92,1.189-6.141,2.713-9.492,4.752c-1.669,1.033-3.378,2.18-5.046,3.602
			c-0.837,0.711-1.658,1.494-2.434,2.436c-0.389,0.471-0.753,0.992-1.085,1.594c-0.324,0.604-0.632,1.295-0.747,2.158
			c-0.057,0.43-0.058,0.9,0.029,1.379c0.085,0.477,0.261,0.951,0.505,1.361c0.06,0.104,0.127,0.197,0.193,0.295
			c0.068,0.098,0.16,0.211,0.244,0.312c0.025,0.031,0.041,0.049,0.05,0.057l0.036,0.039l0.071,0.074c-0.001,0,0.104,0.113,0.095,0.1
			l0.073,0.068l0.147,0.133c0.4,0.338,0.826,0.559,1.228,0.713c0.811,0.297,1.539,0.363,2.225,0.363
			c3.872,0,11.307-1.617,17.405-6.479c3.917-3.123,7.159-8.721,5.885-14.18c0,0,1.145-0.395,1.647-0.537
			c0.798-0.229,1.491-0.344,2.017-0.34c0.263-0.002,0.475,0.039,0.627,0.1c0.057,0.025,0.102,0.055,0.143,0.084
			c-0.091,0.514-1.127,3.984-1.396,4.746c-0.48,1.359-0.918,2.764-1.146,4.254c-0.06,0.375-0.11,0.752-0.136,1.158
			c-0.015,0.205-0.025,0.41-0.008,0.67c0.014,0.137,0.015,0.275,0.112,0.523c0.03,0.068,0.065,0.137,0.131,0.223
			c0.025,0.035,0.073,0.084,0.119,0.123c0.034,0.031,0.118,0.092,0.208,0.131c0.033,0.016,0.113,0.041,0.108,0.037l0.017,0.004
			l0.104,0.02l0.077,0.004c0.097,0,0.224-0.016,0.288-0.041c0.068-0.025,0.134-0.055,0.197-0.09
			c0.054-0.033,0.083-0.059,0.124-0.092c0.086-0.066,0.121-0.115,0.171-0.172c0.088-0.107,0.15-0.205,0.203-0.299
			c0.112-0.188,0.191-0.361,0.268-0.537c0.154-0.344,0.278-0.682,0.408-1.014c0.31-0.857,0.62-1.693,1.005-2.488
			c2.049-4.232,6.165-8.742,6.798-9.389c0.633-0.65,1.251-1.146,1.833-1.453C49.783,24.457,50.342,24.335,50.864,24.337
			 M33.727,32.443c-0.081,0.594-0.198,1.186-0.349,1.773c-0.285,1.129-0.849,2.178-1.558,3.141
			c-0.703,0.969-1.556,1.832-2.461,2.627c-0.457,0.393-0.928,0.768-1.411,1.125c-0.486,0.355-0.986,0.689-1.492,1.012
			c-2.031,1.271-4.199,2.273-6.386,3.025c-2.18,0.742-4.414,1.285-6.475,1.398c-0.509,0.027-1.007,0.02-1.453-0.027
			c-0.443-0.045-0.847-0.148-1.079-0.27c-0.114-0.059-0.181-0.117-0.199-0.146c-0.001-0.004-0.002-0.006-0.004-0.01
			c0.013,0.016,0.032,0.035,0.019,0.02l-0.065-0.08l-0.033-0.041c-0.015-0.018-0.02-0.025-0.016-0.023
			c0.001-0.01,0.005-0.004-0.002-0.021c-0.007-0.021-0.022-0.039-0.027-0.057c-0.051-0.141-0.062-0.219-0.02-0.447
			c0.046-0.219,0.173-0.543,0.37-0.877c0.194-0.338,0.46-0.684,0.742-1.033c0.571-0.697,1.267-1.363,1.975-2
			c0.715-0.639,1.47-1.232,2.229-1.805s1.526-1.115,2.293-1.631c3.068-2.07,6.061-3.775,8.751-5.197
			c2.478-1.299,4.709-2.355,6.625-3.143c0.05,0.301,0.084,0.607,0.101,0.916C33.826,31.257,33.794,31.849,33.727,32.443"/>
                    <path fill="#161138" d="M65.658,23.58c-0.011-0.111-0.082-0.512-0.458-0.697c-0.377-0.188-0.898-0.037-1.109,0.211
			c-0.067,0.08-0.769,1.139-1.248,1.975c-0.168-0.311-0.388-0.645-0.691-0.994c-0.367-0.422-0.896-0.857-1.632-1.129
			c-0.367-0.129-0.78-0.213-1.208-0.223c-0.106-0.002-0.212,0.002-0.321,0.004l-0.041,0.002l-0.021,0.002
			c-0.119,0.021-0.041,0.008-0.072,0.012l-0.059,0.006l-0.06,0.004c-0.023,0.002-0.074,0.014-0.11,0.021
			c-0.074,0.018-0.169,0.039-0.228,0.057l-0.175,0.053c-0.111,0.037-0.221,0.076-0.323,0.117c-0.808,0.338-1.462,0.771-2.118,1.264
			c-0.653,0.49-1.279,1.047-1.901,1.652c-1.236,1.225-2.433,2.662-3.503,4.402c-0.527,0.877-1.019,1.828-1.384,2.961
			c-0.183,0.564-0.322,1.184-0.386,1.889c-0.027,0.355-0.031,0.738,0.02,1.15c0.05,0.41,0.156,0.861,0.361,1.316
			c0.202,0.453,0.512,0.902,0.897,1.256c0.189,0.176,0.396,0.334,0.603,0.459c0.216,0.139,0.403,0.227,0.645,0.332
			c0.524,0.215,1.07,0.287,1.557,0.266c0.487-0.02,0.914-0.121,1.288-0.248c0.749-0.26,1.322-0.615,1.843-0.982
			c0.519-0.369,0.977-0.76,1.403-1.168c0.798-0.764,1.489-1.588,2.116-2.443c-0.138,0.799-0.241,1.564-0.304,2.287
			c-0.123,1.369-0.105,2.584,0,3.582c0.104,0.998,0.295,1.773,0.473,2.283c0.177,0.512,0.314,0.764,0.314,0.766l0.002,0.002
			c0.031,0.057,0.089,0.098,0.158,0.104c0.111,0.01,0.21-0.072,0.22-0.184c0,0,0.294-1.977,0.482-2.875
			c0.518-2.469,2.094-6.406,3.098-9.586C64.79,28.302,65.741,24.408,65.658,23.58z M54.986,34.945
			c-0.38,0.285-0.759,0.549-1.126,0.756c-0.362,0.205-0.722,0.355-0.973,0.4c-0.125,0.027-0.413-0.135,0.07-1.551
			c0.247-0.725,0.589-1.502,0.965-2.246c0.76-1.498,1.669-2.896,2.559-4.123c0.444-0.615,0.896-1.182,1.335-1.689
			c0.438-0.498,1.452-1.555,2.22-1.555c0.375,0,0.764,0.131,1.113,0.322c0.451,0.254,1.195,0.682,1.116,0.859
			c-0.956,2.15-2.977,5.076-4.979,6.887C56.518,33.701,55.745,34.365,54.986,34.945"/>
                    <path fill="#161138" d="M9.721,30.83c0.368-0.715,0.727-1.484,1.048-2.305c0.32-0.82,0.584-1.699,0.852-2.602
			c0.265-0.9,0.549-1.812,0.88-2.709c0.328-0.896,0.662-1.791,0.997-2.672c0.34-0.877,0.656-1.752,0.973-2.596
			c0.636-1.688,1.23-3.262,1.739-4.611c1.017-2.709,1.568-4.174,1.694-4.516c0.125-0.344-0.189-0.453-0.369-0.195
			c-0.179,0.258-0.28,0.396-0.752,1.102c-0.239,0.35-0.523,0.777-0.844,1.271c-0.321,0.496-0.689,1.051-1.076,1.664
			c-0.385,0.615-0.802,1.281-1.244,1.986s-0.886,1.461-1.353,2.24c-0.462,0.781-0.946,1.586-1.405,2.422
			c-0.465,0.832-0.933,1.682-1.396,2.535c-0.458,0.857-0.95,1.699-1.432,2.537c-0.48,0.836-0.934,1.672-1.287,2.521
			c-0.352,0.85-0.621,1.705-0.82,2.539c-0.208,0.832-0.356,1.639-0.447,2.4c-0.187,1.52-0.139,2.871,0.098,3.838
			c0.119,0.482,0.283,0.867,0.446,1.107c0.042,0.059,0.08,0.111,0.113,0.154c0.037,0.045,0.123,0.129,0.183,0.182
			c0.341,0.307,0.361-0.205,0.361-0.205s0.004-0.029,0.01-0.084c0.003-0.025,0.008-0.055,0.013-0.094
			c0.009-0.037,0.02-0.082,0.032-0.131c0.009-0.045,0.035-0.105,0.053-0.162c0.015-0.057,0.04-0.123,0.069-0.193
			c0.025-0.068,0.057-0.143,0.097-0.225c0.038-0.078,0.077-0.162,0.12-0.252c0.345-0.713,0.932-1.715,1.599-2.945
			C9.006,32.22,9.363,31.55,9.721,30.83"/>
                    <path fill="#161138" d="M90.178,21.658c1.333,0,2.415-1.082,2.415-2.416c0-1.332-1.081-2.414-2.415-2.414
			c-1.334,0-2.416,1.082-2.416,2.414C87.763,20.576,88.844,21.658,90.178,21.658"/>
                </g>
            </g>
        </svg>
    )
}
