import { Avatar, Card, CardActionArea, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../../../util/createSlug";
import { useStyles } from "./styles/CardAtleta";

function CardPessoa({ pessoa }) {
  const classes = useStyles();
  return (
    <Card className={classes.Root}>
      <CardActionArea
        className={classes.CardActionRoot}
        component={Link}
        to={`/transparencia/pessoa/${pessoa.id}/${createSlug(pessoa.nome_completo)}`}
      >
        <Avatar
          className={classes.Avatar}
          variant="circle"
          src={`https://saltosbrasil.bigmidia.com/_uploads/fotopessoa/${pessoa.foto}`}
        />
        <Typography className={classes.Nome}>{pessoa.nome_completo}</Typography>
      </CardActionArea>
    </Card>
  );
}

export default CardPessoa;
