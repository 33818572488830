import { useEffect, useState } from "react";
import { CBSaltospi } from "../../../services/api";

export const useEtapas = ({ idEvento }) => {
  const [etapas, setEtapas] = useState({
    loading: true,
    error: false,
    payload: {},
  });

  useEffect(() => {
    setEtapas({ loading: true, error: false, payload: {} });
    async function getEtapa() {
      await CBSaltospi.get("etapa", {
        params: {
          id_evento: idEvento,
          expand:
            "eventoProvas,eventoProvasE,eventoProvasF,eventoProvasS,eventoProvasF.altura,aparelho,classe",
          sort: "data_etapa",
        },
      })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEtapas({ loading: false, error: true, payload: [] });
          } else {
            // console.log(response.data.items);
            setEtapas({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setEtapas({ loading: false, error: true, payload: [] });
          console.log(err);
        });
    }
    getEtapa();
  }, [idEvento]);

  return { etapas };
};
