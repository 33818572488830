import { Box, Grid, Typography } from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { gestaoApi } from "../../../services/api";
import { useStyles } from "../styles/Pessoas";
import CardPessoa from "./components/CardPessoa";

function TecnicosSection({ searchTerm }) {
  const classes = useStyles();
  const sectionTop = useRef(null);
  const [page, setPage] = useState(1);
  const [arbitros, setArbitros] = useState({
    error: false,
    loading: true,
    payload: [],
    meta: {},
  });

  useEffect(() => {
    setArbitros({
      error: false,
      loading: true,
      payload: [],
      meta: {},
    });

    async function getTecnicos() {
      await gestaoApi
        .get("pessoa", {
          params: {
            flag_del: 0,
            // id_departamento: 1,
            expand: "departamento,estabelecimento",
            arrayLike: `nome_completo:${searchTerm}`,
            sort: "nome_completo",
            page,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setArbitros({
              error: true,
              loading: false,
              payload: [],
              meta: {},
            });
          } else {
            setArbitros({
              error: false,
              loading: false,
              payload: response.data.items,
              meta: {
                pageCount: response.data._meta.pageCount,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setArbitros({
            error: true,
            loading: false,
            payload: [],
            meta: {},
          });
        });
    }
    getTecnicos();
  }, [searchTerm, page]);

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 200,
    });

  const handleChange = (event, value) => {
    setPage(value);
    if (value === 1) {
      executeScroll();
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <div className={classes.cardHeaderLabel}>
        <Typography variant="h2" className={classes.CardTitle}>
          Recursos Humanos
        </Typography>
      </div>
      <Grid container spacing={2}>
        {arbitros.payload.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <CardPessoa pessoa={item} />
          </Grid>
        ))}
      </Grid>
      {arbitros.error && (
        <div className={classes.errorContainer}>
          <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
        </div>
      )}
      {arbitros.meta && arbitros.meta.pageCount > 1 && (
        <div className={classes.paginationContainer}>
          <Pagination page={page} count={arbitros.meta.pageCount} onChange={handleChange} />
        </div>
      )}
    </Box>
  );
}

export default TecnicosSection;
