import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { formatDatePeriod } from "../../../util/formatDatePeriod";
import { useStyles } from "../styles/CardEvento";
import { Skeleton } from "@material-ui/lab";
import TagEscopo from "../../../components/Tags/TagEscopo";
import RoomIcon from "@material-ui/icons/Room";

export default function CardEventoResultado(props) {
  const { evento, loading } = props;
  const classes = useStyles();
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <div className={classes.cardEvento}>
      <div className={classes.cardBackdrop}>
        {loading
          ? ""
          : formatDatePeriod(evento.data_inicio, evento.data_fim, true)}
      </div>
      <Card className={classes.Card} elevation={0}>
        <CardContent className={classes.CardContent}>
          <CardMedia className={classes.CardMedia} src={evento.arte_evento} />
          <div className={classes.cardInfo}>
            <Typography variant="h6" className={classes.cardTitle}>
              {loading ? (
                <Skeleton variant="text" width={"100%"} height={32} />
              ) : (
                evento.descricao
              )}
            </Typography>
            <Typography className={classes.cardText}>
              {loading ? (
                <Skeleton variant="text" width={"100%"} height={16} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: evento.texto || '' }} />
              )}
            </Typography>

            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="caption">
                  <Avatar />
                  {loading ? (
                    <Skeleton variant="text" width={150} height={16} />
                  ) : (
                    "--"
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  <RoomIcon />
                  {loading ? (
                    <Skeleton variant="text" width={150} height={16} />
                  ) : (
                    evento.local
                  )}
                </Typography>
              </Grid>

              {matchesSm ? (
                ""
              ) : (
                <Grid item>
                  <div className={classes.eventoLocal}>
                    <TagEscopo
                      escopo={loading ? "" : evento.escopo.toLowerCase()}
                      label={loading ? "" : evento.escopo.slice(0, 3)}
                      size="small"
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </div>
          {matchesSm ? (
            <div className={classes.tagEvento}>
              <TagEscopo
                escopo={loading ? "" : evento.escopo.toLowerCase()}
                label={loading ? "" : evento.escopo}
                size="small"
              />
            </div>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </div>
  );
}
