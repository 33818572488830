import { Avatar, Container, Grid, Typography } from "@material-ui/core";
import { GradientSecondaryBtn } from "../../../components/Buttons/Buttons";
import { useStyles } from "../style/ClubesAssociados";
import clsx from 'clsx';
import { ShieldSVG } from "../../../assets/icons/LogosTemplate";
import React from "react";

export default function ClubesAssociados(props) {
    const classes = useStyles();
    const { estabelecimento, loading, encontreClubeHidden } = props;

    return (
        <Container>
            <Typography className={classes.heading}>
                Clubes Associados
            </Typography>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={12} md={5} lg={5}>
                    <Typography className={classes.content}>
                        Para entrar para a Seleção Brasileira de saltos ornamentais é preciso atingir, dentro da categoria do atleta, a pontuação necessária da seletiva.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={2} className={classes.contentContainer}>
                {(loading ? Array.from(new Array(12)) : estabelecimento).map((item, key) => (
                    <Grid key={key} item xs={4} sm={3} md={2}>
                        <Grid container>
                            <Grid item xs={12} className={classes.avatarWrapper}>
                                {!item ?
                                    <Avatar className={classes.Avatar}>
                                        <ShieldSVG />
                                    </Avatar>
                                    :
                                    <React.Fragment>
                                        {item.url_logo ?
                                            <Avatar className={classes.Avatar} src={`https://saltosbrasil.bigmidia.com/_uploads/estabelecimento/logo/${item.url_logo}`} />
                                            :
                                            <Avatar className={classes.Avatar}>
                                                <ShieldSVG />
                                            </Avatar>
                                        }
                                    </React.Fragment>

                                }

                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={classes.nomeClube}>
                                    {!item ? '' : item.descricao}
                                    {!item ? '' : ` / ${item.uf}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
            {encontreClubeHidden ?
                ''
                :
                <Grid container className={classes.contentContainer}>
                    <Grid item xs={12} sm={12} md={6} lg={7}>
                        <Typography className={clsx(classes.heading, { [classes.headingInfo]: true })}>
                            Encontre um clube!
                        </Typography>
                        <Typography className={classes.content}>
                            Pelo Brasil há mais de 16 centros de treinamento prontos para receber novos interessados no Salto Ornamental.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={5} className={classes.actionWrapper}>
                        <GradientSecondaryBtn>INSCREVER MEU CLUBE</GradientSecondaryBtn>
                    </Grid>
                </Grid>
            }

        </Container>
    )
}