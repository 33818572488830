import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";
import { convertDate } from "../../../util/convertDate";
import { useStyles } from "../styles/CardBoletim";
import CardItemBoletim from "./components/CardItemBoletim";

export default function CardBoletim(props) {
  const classes = useStyles();
  const { boletins, loadingBoletins, errorBoletins, boletinsSection, totalPages, page, handleChange } = props;

  if (boletinsSection) {
    return (
      <React.Fragment>
        {boletins.length > 0 ?
          boletins.map((item, key) => {
            if (item.descricao){ 
            return(       
            <Card key={key} className={clsx(classes.root, { [classes.rootListItem]: boletinsSection })}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={9}>
                    <Typography variant="h6" className={classes.docTitle}>
                      {loadingBoletins || errorBoletins ? "" : item.descricao}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Grid container alignItems="center">
                      <Grid item xs={6} sm={6} md={6} lg={12}>
                        <CardItemBoletim boletim={item} loading={loadingBoletins} error={errorBoletins} />
                      </Grid>
                      <Grid item xs={6} sm={6} md={6} lg={12}>
                        <Typography className={classes.data}>
                          {loadingBoletins || errorBoletins ? "" : convertDate(item.created_at * 1000)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          )} else {
            return null}}
            ):<p className={classes.naoEncontrado} >Boletim não encontrado</p>}

        {totalPages > 1 ? (
          <div className={classes.paginationContainer}>
            <Pagination page={page} count={totalPages} onChange={handleChange} />
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </React.Fragment>
    );
  }

  return (
    <Card className={clsx(classes.root, { [classes.rootListItem]: boletinsSection })}>
      <CardContent>
        <CardItemBoletim boletim={boletins[0]} />
      </CardContent>
    </Card>
  );
}
