import { useMediaQuery } from '@material-ui/core';
import React from 'react'
import { saltoAovivo } from '../../assets/data/saltoAoVivo';
import HeaderComponent from './sections/Header';
import HeaderLiveNotify from './sections/LiveNotify'

export default function Header(props) {
    const { openMenu, setOpenMenu } = props;
    const matchesSm = useMediaQuery(theme => theme.breakpoints.up('sm'));

    if (matchesSm) {
        return (
            <HeaderComponent openMenu={openMenu} setOpenMenu={setOpenMenu} />
        )
    }
    if (saltoAovivo[0].online === 1) {
        return (
            <HeaderLiveNotify />
        )
    }
    return (
        <React.Fragment></React.Fragment>
    )
}