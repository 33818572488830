import { Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { saltoAovivo } from "../../assets/data/saltoAoVivo";
import { gestaoApi } from "../../services/api";
import LiveEvento from "../home/sections/LiveEvento";
import TransparenciaSection from "../home/sections/Transparencia";
import ProximaCompeticao from "../noticias/sections/ProximaCompeticao";
import Arenas from "./sections/Arenas";
import CardListEventos from "./sections/CardListEventos";
import { EventoTab, EventoTabs, useStyles } from "./style";

export default function PageCompeticoes() {
    const classes = useStyles();

    const [selectTipo, setSelectTipo] = useState(0);

    const handleChangeTipo = (event, newValue) => {
        setSelectTipo(newValue);
        setPage(1)
    };

    const matchesMD = useMediaQuery(theme => theme.breakpoints.up('md'));

    const [eventos, setEventos] = useState([]);
    const [loadingEventos, setLoadingEventos] = useState(true);
    const [errorEventos, setErrorEventos] = useState(false);
    const [selectedTemporada, setSelectedTemporada] = useState()
    const [selectedEscopo, setSelectedEscopo] = useState('')
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0)

    useEffect(() => {
        let data1 = ''
        let data2 = ''
        if (selectedTemporada) {
            data1 = (selectedTemporada).toString().concat('-01-01')
            data2 = (selectedTemporada).toString().concat('-12-31')
        }

        setLoadingEventos(true)
        setErrorEventos(false)

        let params = {
            flag_del: 0,
            id_estabelecimento: 1,
            escopo: selectedEscopo,
            lessField: 'data_inicio',
            lessValue: ((new Date()).toISOString()),
            betweenField: 'data_inicio',
            betweenValue1: data1,
            betweenValue2: data2,
            sort: '-data_inicio',
            page: page,
        }

        if (selectTipo === 1) {
            params = {
                flag_del: 0,
                id_estabelecimento: 1,
                escopo: selectedEscopo,
                moreField: 'data_inicio',
                moreValue: ((new Date()).toISOString()),
                sort: 'data_inicio',
                page: page,
            }
        }

        async function getEventos() {
            await gestaoApi.get('evento', {
                params: params,
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorEventos(true)
                } else {
                    setTotalPages(response.data._meta.pageCount)

                    let mapEvents = response.data.items.map((item, key) => {
                        return ({
                            ...item,
                            month: new Date(response.data.items[key].data_inicio).getMonth(),
                            month_year: `${new Date(response.data.items[key].data_inicio).getMonth()}-${new Date(response.data.items[key].data_inicio).getFullYear()}`
                        })
                    })

                    var grouped = _.groupBy(mapEvents, 'month_year')
                    var order = []
                    if (selectTipo === 1) {
                        order = _.orderBy(grouped, ['[0]data_inicio'], ['asc']);
                    } else {
                        order = _.orderBy(grouped, ['[0]data_inicio'], ['desc']);
                    }

                    setEventos(_.toArray(order))


                    setLoadingEventos(false)
                    setErrorEventos(false)
                }
            }).catch((err) => {
                setErrorEventos(true)
                console.log(err)
            });
        }
        getEventos()

    }, [selectTipo, selectedEscopo, selectedTemporada, page])


    const [todosEventos, setTodosEventos] = useState([]);
    const [loadingTodosEventos, setLoadingTodosEventos] = useState(true);
    const [errorTodosEventos, setErrorTodosEventos] = useState(false);

    useEffect(() => {
        setLoadingTodosEventos(true)
        setErrorTodosEventos(false)
        async function getEventos() {
            await gestaoApi.get('evento', {
                params: {
                    flag_del: 0,
                    id_estabelecimento: 1,
                    moreField: 'data_inicio',
                    moreValue: ((new Date()).toISOString()),
                    sort: '-data_inicio',
                    pageSize: 12
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorTodosEventos(true)
                } else {
                    setTodosEventos(response.data.items)
                    setErrorTodosEventos(false)
                }
                setLoadingTodosEventos(false)
            }).catch((err) => {
                setErrorTodosEventos(true)
                setLoadingTodosEventos(false)
                console.log(err)
            });
        }
        getEventos()
    }, [])


    const handleChangePage = (event, value) => {
        setPage(value);
    };

    return (
        <React.Fragment>
            <div className={classes.root}>

                {matchesMD && saltoAovivo[0].online === 1 ?
                    <Container className={classes.liveContainer}>
                        <LiveEvento pageCompeticao={true} />
                    </Container> : ''
                }

                {matchesMD && saltoAovivo[0].online === 0 ?
                    <Container className={classes.liveContainer}>
                        <ProximaCompeticao onlyCompeticao={true} bgTransparent={true} eventos={todosEventos} loadingEventos={loadingTodosEventos} errorEventos={errorTodosEventos} />
                    </Container>
                    : ''}

                <div className={clsx(classes.containerEventos, { [classes.containerEventosSecondary]: selectTipo === 1 })}>

                    <Container className={classes.cardContainer}>
                        {!matchesMD && saltoAovivo[0].online === 1 ?
                            <Container className={classes.liveContainer}>
                                <LiveEvento pageCompeticao={true} />
                            </Container> : ''
                        }

                        {!matchesMD && saltoAovivo[0].online === 0 ?
                            <Container className={classes.liveContainer}>
                                <ProximaCompeticao onlyCompeticao={true} bgTransparent={true} eventos={todosEventos} loadingEventos={loadingTodosEventos} errorEventos={errorTodosEventos} />
                            </Container>
                            : ''}

                        <Grid container>
                            <Grid item xs={12} sm={12} md={5} lg={4}>
                                <Typography variant="h6" className={classes.PageTitle}>Eventos</Typography>
                                <EventoTabs value={selectTipo} onChange={handleChangeTipo}
                                    className={clsx({ [classes.secondaryTab]: selectTipo === 1 })}
                                    centered={!matchesMD} >
                                    <EventoTab label="Resultados" />
                                    <EventoTab label="Próximos Eventos" />
                                </EventoTabs>
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={8}>
                                <CardListEventos
                                    eventos={eventos}
                                    loadingEventos={loadingEventos}
                                    errorEventos={errorEventos}
                                    tipoCard={selectTipo}
                                    selectedTemporada={selectedTemporada}
                                    setSelectedTemporada={setSelectedTemporada}
                                    selectedEscopo={selectedEscopo}
                                    setSelectedEscopo={setSelectedEscopo}
                                    handleChangePage={handleChangePage}
                                    totalPages={totalPages}
                                    page={page}
                                    setPage={setPage}
                                />
                                {/* {page > totalPages ?
                                    <Button onClick={addPage}>carregar mais</Button>
                                    :
                                    ''
                                } */}

                            </Grid>
                        </Grid>
                    </Container>
                </div>
                <Arenas />
            </div>
            <TransparenciaSection />
        </React.Fragment>
    )
}