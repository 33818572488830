import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    saltoDetails: {
        minHeight: 50,
        backgroundColor: '#000F11',
        padding: theme.spacing(2),
        color: '#fff',
        [theme.breakpoints.up('sm')]: {
            borderRadius: 10,
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 20,
            margin: '0 60px',
            display: 'flex',
            justifyContent: 'center'
        }
    },
    tituloDetalhamento: {
        display: 'inline-block',
        color: theme.palette.warning.light,
        '& span': {
            color: theme.palette.info.light,
        }
    },
    divider: {
        backgroundColor: theme.palette.warning.light,
        marginBottom: 10,
        '&.MuiDivider-flexItem': {
            marginLeft: 10,
            marginRight: 10,
            [theme.breakpoints.up('md')]: {
                marginLeft: 20,
                marginRight: 20,
            }
        }
    },
    tabelaPontuacaoJ: {
        width: 'unset',
        '& thread': {
            color: theme.palette.info.pale,
        },
        '& td, & th': {
            padding: '4px !important'
        },
        '& th': {
            color: theme.palette.primary.pale,
            [theme.breakpoints.up('dm')]: {
                minWidth: 40,
            }
        },
    },
    pontosTotalTabela: {
        fontSize: '1rem !important',
        fontWeight: 'bold',
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem !important'
        }
    },
    dificuldade: {
        textAlign: 'center'
    },
    pontosTotal: {
        fontSize: '1.5rem !important',
        color: theme.palette.warning.pale,
        [theme.breakpoints.up('md')]: {
            fontSize: ' 2.25rem !important',
        }
    },
    tabelaGrid: {
        flexWrap: 'nowrap',
        [theme.breakpoints.up('md')]: {
            justifyContent: 'space-around',
        }
    },
    textInfo: {
        display: 'inline-block',
        color: theme.palette.info.light,
        '& span': {
            marginLeft: 20,
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: 35,
        }
    },
    saltoCancelado: {
        textDecoration: 'line-through',
        color: `${theme.palette.error.light} !important`,
        opacity: 0.8,
        '& p': {
            textDecoration: 'line-through',
        },

    },
    saltoTD: {
        '& .MuiTypography-body2': {
            display: 'inline !important'
        },
        '& span': {
            fontSize: '.6rem',
            verticalAlign: 'super',
            marginRight: 1.5,
        },
    }, 
}))