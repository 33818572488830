import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardTop: {
        height: 10,
        margin: '0px 4%',
        borderRadius: '10px 10px 0 0',
        background: `linear-gradient(to right, ${theme.palette.info.light}, ${theme.palette.primary.light})`,
        [theme.breakpoints.up('sm')]: {
            borderRadius: '15px 15px 0 0',
            height: 15,
            margin: '0px 6%',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '15%'
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '4%'
        },
    },
    card: {
        position: 'relative',
        backgroundColor: alpha(theme.palette.primary.white, 0.7),
        color: theme.palette.info.dark,
        minHeight: 200,
        backdropFilter: 'blur(30px)',
        webkitBackdropFilter: 'blur(30px)',
        borderRadius: 12,
        boxShadow: '0px 3px 6px #010B1A00',
        [theme.breakpoints.up('sm')]: {
            borderRadius: 20,
        }
    },
    cardHeaderLabel: {
        padding: '20px 4%',
        [theme.breakpoints.up('sm')]: {
            padding: '20px 6%',
        }
    },
    CardTitle: {
        fontSize: '1.5rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 400
    },
    cardBottom: {
        marginTop: '-25px',
        height: 40,
        width: '80%',
        borderRadius: '4px 4px 60px 20px',
        background: `linear-gradient(to right, ${theme.palette.info.light}, ${theme.palette.primary.light})`,
    },
    cardPrincipal: {
        padding: '20px 6%',
        paddingBottom: 60,
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            paddingBottom: 80,
        },
    },
    cardNoticiaPrincipal: {
        maxWidth: '70%',
        borderRadius: 10,
        backgroundColor: 'transparent',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '60%',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '5%',
            maxWidth: 440,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: '5%',
            maxWidth: 530,
        },
    },
    cardNoticiaMedia: {
        height: '250px !important',
        [theme.breakpoints.up('sm')]: {
            height: '260px !important',
        },
        [theme.breakpoints.up('md')]: {
            height: '280px !important',
        },
        [theme.breakpoints.up('lg')]: {
            height: '340px !important',
        }
    },
    cardPrincipalContent: {
        position: 'absolute',
        right: 0,
        bottom: '10%',
        minWidth: 160,
        maxWidth: 180,
        borderRadius: 10,
        marginRight: '3%',
        boxShadow: '-6px 6px 30px #4938BF40',
        '& .MuiCardContent-root': {
            padding: theme.spacing(1.5),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(2, 1.5),
            },
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(2, 2),
            },
            [theme.breakpoints.up('lg')]: {
                padding: theme.spacing(3, 4.5),
            }
        },
        [theme.breakpoints.up('sm')]: {
            minWidth: 250,
            maxWidth: 470,
            right: 'unset',
            left: '50%',
            bottom: '8%',
            marginRight: '5%',
            // minHeight: '50%',
        },
        [theme.breakpoints.up('md')]: {
            bottom: '10%',
            left: '45%',
            marginRight: '16%',
            // minHeight: '60%',
            minWidth: 400,
        },
        [theme.breakpoints.up('lg')]: {
            left: '47%',
        }
    },
    tituloNoticia: {
        color: theme.palette.info.darker,
        fontSize: '1.1rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 700,
        marginBottom: 13,
        lineHeight: 1.1,
        [theme.breakpoints.up('sm')]: {
            fontWeight: 500,
            fontSize: '1.4rem',
            lineHeight: 1.2,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem', 
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2rem',
        }
    },
    resumoNoticia: {
        color: theme.palette.secondary.dark80,
        fontSize: '0.8rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 700,
        marginBottom: 5,
        maxHeight: '5.5rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '5',
        WebkitBoxOrient: 'vertical',
        lineHeight: 1.1,
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
            WebkitLineClamp: '4',
        },
        [theme.breakpoints.up('md')]: {
            lineHeight: 1.2,
            WebkitLineClamp: '3',
            fontSize: '1rem',
        }
    },
    dataNoticia: {
        color: theme.palette.info.main,
        fontSize: '0.8rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 700,
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1rem',
        }
    },


}));