import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        [theme.breakpoints.up('sm')]: {
            marginTop: 20,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 40,
        }
    },
    cardEvento: {
        marginRight: '10px',
        borderRadius: '6px 6px 6px 0',
        zIndex: 1,
        position: 'relative',
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.white,
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
            marginRight: '15px',
        },
        [theme.breakpoints.up('md')]: {
            marginRight: '30px',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: '50px',
        },
        '& .MuiButtonBase-root': {
            padding: 4,
        }
    },
    GridItem: {
        marginRight: 0,
        backgroundColor: '#fff',
        filter: 'drop-shadow(0px 0px 5px #24060025)',
        '& .MuiButtonBase-root': {
            padding: 10,
        }
    },
    cardMargin: {
        marginBottom: 15,
    },
    cardBottom: {
        marginRight: '30px',
        width: '60%',
        background: `linear-gradient(120deg, #FFF8CC 0%, #8677F1 100%)`,
        height: 20,
        marginTop: -10,
        borderRadius: '0px 0px 10px 6px',
        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
        [theme.breakpoints.up('md')]: {
            width: '40%',
        },
    },
    cardMedia: {
        height: 120,
        borderRadius: 8,
    },
    cardContent: {
        padding: '5px 0px',
        minHeight: 100,
        display: 'flex',
        flexDirection: 'column',
    },
    eventoDescricao: {
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.secondary.dark80,
        fontWeight: 'bolder',
        lineHeight: 1,
        maxHeight: '3rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        marginBottom: 'auto',
        fontSize: '1rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '0.9rem',
            maxHeight: '4.5rem',
            WebkitLineClamp: '5',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
            maxHeight: '4rem',
            WebkitLineClamp: '4',
        },
    },
    eventoData: {
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.info.main,
        fontWeight: 'bolder',
        lineHeight: 1,
        fontSize: '0.85rem',
        marginBottom: '.3em'
    }
}));