import { Grid, Hidden, Table, TableBody, TableCell, TableHead, TableRow, Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useStyles } from "../styles/exemploSaltos";

export default function ExemplosSaltos() {
    const classes = useStyles();
    const matchesSM = useMediaQuery(theme => theme.breakpoints.up('sm'));

    const saltosExemplo = [
        {
            codigos: [false, false, true, true, true, false],
            tipoSalto: 'SALTO PADRÃO',
            codSalto: '203B',
            descricao: 'Salto Costas Sem vôo 1,5 Giros Carpado',
            classecss: 'salto-padrao',
            detalhe: '',
        },
        {
            codigos: [false, true, true, false, true, true],
            tipoSalto: 'SALTO COM PARAFUSO',
            codSalto: '5371C',
            descricao: 'Salto Parafuso Salto Pontapé à Lua 3,5 Giros 0,5 Parafuso Grupado',
            classecss: 'salto-parafuso',
            detalhe: '',
        },
        {
            codigos: [true, false, true, false, true, false],
            tipoSalto: 'SAÍDA COM PARADA DE MÃO',
            codSalto: '616C',
            descricao: 'Salto Parada de Mão Giro para Frente 3 Giros Grupado',
            classecss: 'salto-parada-mao',
            detalhe: '',
        },
        {
            codigos: [true, false, true, false, true, true],
            tipoSalto: 'SAÍDA PARADA DE MÃO COM PARAFUSO',
            codSalto: '6241B',
            descricao: 'Salto Parada de Mão Salto Parafuso Giro para Trás 2 Giros 0,5 Parafuso Carpado',
            classecss: 'salto-parada-mao-parafuso',
            detalhe: 'para saltos em parada de mão e com parafuso, o número 5 não aparece'

        },
    ]

    return (
        <div className={classes.root}>
            <Typography variant="subtitle1" className={classes.sectionTitle}>
                Exemplos:
            </Typography>
            <Grid container spacing={matchesSM ? 3 : 1}>
                {saltosExemplo.map((item, key) => (
                    <React.Fragment key={key}>
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Table className={classes.Table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={item.codigos[0] ? "" : "emptyTd"}>{item.codigos[0] ? '6' : ''}</TableCell>
                                                <TableCell className={item.codigos[1] && !item.detalhe ? "" : "emptyTd"}>
                                                    {item.codigos[1] || item.detalhe ? '5' : ''}<span className="detalhe">{item.detalhe ? "*" : ''}</span>
                                                </TableCell>
                                                <TableCell className={item.codigos[2] ? "" : "emptyTd"}>
                                                    {item.codigos[2] ?
                                                        <div>#<span>s</span></div> : ''}
                                                </TableCell>
                                                <TableCell className={item.codigos[3] ? "" : "emptyTd"}>
                                                    {item.codigos[3] ?
                                                        <div>#<span>v</span></div> : ''}
                                                </TableCell>
                                                <TableCell className={item.codigos[4] ? "" : "emptyTd"}>
                                                    {item.codigos[4] ?
                                                        <div>#<span>g</span></div> : ''}
                                                </TableCell>
                                                <TableCell className={item.codigos[5] ? "" : "emptyTd"}>
                                                    {item.codigos[5] ?
                                                        <div>#<span>p</span></div> : ''}
                                                </TableCell>
                                                <TableCell>X</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={clsx(classes.TableBody, item.classecss)}>
                                            <TableRow>
                                                <TableCell colspan={7}>
                                                    <div className={clsx(classes.rowCaption, item.classecss)}>
                                                        <Typography variant="body2">
                                                            {item.tipoSalto}
                                                        </Typography>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                {item.detalhe ?
                                    <Grid item xs={12}>
                                        <span className="detalhe">*</span>{item.detalhe}
                                    </Grid>
                                    :
                                    <React.Fragment></React.Fragment>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <div className={classes.saltoExemplo}>
                                <Hidden smUp>
                                    <Typography className={classes.textExemplo}>
                                        EXEMPLO
                                    </Typography>
                                </Hidden>
                                <Typography className={classes.codSalto}>
                                    {item.codSalto}
                                </Typography>
                                <Typography className={classes.saltoDescricao}>
                                    {item.descricao}
                                </Typography>
                            </div>
                        </Grid> 
                    </React.Fragment>
                ))}
            </Grid>
        </div>
    )
}