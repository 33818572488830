import React from "react";
import { Box, Container, Grid, makeStyles, useMediaQuery } from "@material-ui/core";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// import clinorBranco from '../../../assets/images/clinor_branco.png';
// import { ArrowBack, ArrowForward } from "@material-ui/icons";
// import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import SearchInput from "../../searchInput";
import { LoginBtnSM } from "../components/LoginBtn";

const useStyles = makeStyles((theme) => ({
  carouselContainer: {
    flexGrow: 1,
    backgroundColor: "black",
    color: "#fff",

    height: 45,
  },
  slider: {
    margin: "5px 0",

    "& img": {
      maxHeight: 28,
    },
  },
  slideItem: {
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    paddingLeft: 5,
  },
  searchContainer: {
    marginLeft: 20,
    display: "flex",
    flexDirection: "row",

    color: "black",
  },
}));

export default function HeaderCarousel() {
  const classes = useStyles();

  //   function SampleNextArrow(props) {
  //     const { className, onClick } = props;
  //     return (
  //       <ChevronRightIcon
  //         onClick={onClick}
  //         className={className}
  //         style={{ color: "#fff", height: 35, width: 35, paddingLeft: 5 }}
  //       />
  //     );
  //   }

  //   function SamplePrevArrow(props) {
  //     const { className, onClick } = props;
  //     return (
  //       <ChevronLeftIcon
  //         onClick={onClick}
  //         className={className}
  //         style={{ color: "#fff", height: 35, width: 35, paddingRight: 5 }}
  //       />
  //     );
  //   }

  //   const settings = {
  //     dots: false,
  //     infinite: true,
  //     autoplay: true,
  //     autoplaySpeed: 10000,
  //     speed: 800,
  //     slidesToShow: 1,
  //     slidesToScroll: 1,
  //     initialSlide: 0,
  //     lazyLoad: true,
  //     nextArrow: <SampleNextArrow />,
  //     prevArrow: <SamplePrevArrow />,
  //     responsive: [
  //       {
  //         breakpoint: 1280,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 3,
  //         },
  //       },
  //       {
  //         breakpoint: 960,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 2,
  //         },
  //       },
  //       {
  //         breakpoint: 600,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //         },
  //       },
  //     ],
  //   };

  const matchesLG = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  if (!matchesLG) {
    return (
      <div className={classes.carouselContainer}>
        <Container>
          <Grid container direction="row" alignItems="center" justifyContent={matchesLG ? "center" : "flex-end"}>
            <Grid item xs={4} lg={6}>
              <Box sx={{ mt: 0.5 }} className={classes.searchContainer}>
                <SearchInput />
                <LoginBtnSM
                  href="https://saltosbrasil.bigmidia.com/site/login"
                  target="_blank"
                  rel="noreferrer"
                  color="primary"
                  style={{ marginLeft: 15 }}
                >
                  login
                </LoginBtnSM>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
  return "";
}
