import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Tab,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton, TabContext, TabList, TabPanel } from "@material-ui/lab";
import clsx from "clsx";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  FacebookOriginalSVG,
  InstagramOriginalSVG,
  SpotifySVG,
  TiktokRoundSVG,
  TwitterRoundSVG,
} from "../../../assets/icons/RedesSociais";
import { SpotifyBtn } from "../../../components/Buttons/Buttons";
import TransparenciaSection from "../../home/sections/Transparencia";
import { useStyles } from "../styles/PerfilPessoa";
import TableResultados from "./TableResultados";
import { testURLstr } from "../../../util/testUrl";
import { convertDateYearString } from "../../../util/convertDate";

export default function PerfilPessoa(props) {
  const classes = useStyles();

  const { pessoa, eventos, eventoAtleta, isAtleta } = props;

  const [tabIndex, setTabIndex] = useState(isAtleta ? "2" : "1");

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const matchesSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <React.Fragment>
      <Helmet>
        {isAtleta ? (
          <title>
            {pessoa.loading || pessoa.error
              ? ""
              : pessoa.payload.atleta.nome_completo}{" "}
            - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais
          </title>
        ) : (
          <title>
            {pessoa.loading || pessoa.error ? "" : pessoa.payload.nome_completo}{" "}
            - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais
          </title>
        )}
      </Helmet>
      <div className={classes.root}>
        <Container>
          <Grid container>
            <Grid item xs={6} sm={7}>
              {!pessoa.payload.foto_apresentacao ? (
                ""
              ) : (
                <React.Fragment>
                  {pessoa.loading ? (
                    <Skeleton
                      variant="circle"
                      className={classes.sekeletonAvatarSM}
                    />
                  ) : (
                    <React.Fragment>
                      {isAtleta ? (
                        <Avatar
                          className={classes.fotoAtletaSM}
                          variant="circular"
                          src={`https://saltosbrasil.bigmidia.com/_uploads/fotoAtleta/${pessoa.payload.atleta.foto}`}
                          alt={
                            pessoa.payload.atleta.foto
                              ? pessoa.payload.atleta.nome_completo
                              : ""
                          }
                        />
                      ) : (
                        <Avatar
                          className={classes.fotoAtletaSM}
                          variant="circular"
                          src={`https://saltosbrasil.bigmidia.com/_uploads/fotoPessoa/${pessoa.payload.foto}`}
                          alt={
                            pessoa.payload.foto
                              ? pessoa.payload.nome_completo
                              : ""
                          }
                        />
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}

              <Typography variant="h1" className={classes.nomeAtleta}>
                {!pessoa.payload.firstName ? (
                  <Skeleton
                    variant="text"
                    width="90%"
                    className={classes.sekeleton}
                  />
                ) : (
                  pessoa.payload.firstName
                )}
              </Typography>
              <Typography variant="h1" className={classes.sobrenomeAtleta}>
                {!pessoa.payload.lastName ? (
                  <Skeleton
                    variant="text"
                    width="100%"
                    className={classes.sekeleton}
                  />
                ) : (
                  pessoa.payload.lastName
                )}
              </Typography>

              {pessoa.loading ? (
                <div className={classes.loadingDark}>
                  <Skeleton variant="text" width="60%" />
                  <Skeleton variant="text" width="70%" />
                  <Skeleton variant="text" width="65%" />
                  <Skeleton variant="text" width="75%" />
                </div>
              ) : (
                <div className={classes.infoAtleta}>
                  {isAtleta ? (
                    <React.Fragment>
                      <div className={classes.descricaoAtleta}>
                        <Typography className="titulo">Catégoria:</Typography>
                        <Typography className="dado-categoria">
                          {pessoa.payload.classe[0] ? (
                            <React.Fragment>
                              {matchesSm
                                ? pessoa.payload.classe[0].descricao
                                : pessoa.payload.classe[0].sigla}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </div>
                      <div className={classes.descricaoAtleta}>
                        <Typography className="titulo">Técnico:</Typography>
                        <Typography className="dado">
                          {pessoa.payload.tecnico
                            ? pessoa.payload.tecnico.nome_completo
                            : ""}
                        </Typography>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className={classes.descricaoAtleta}>
                        <Typography className="titulo">
                          Departamento:
                        </Typography>
                        <Typography className="dado-categoria">
                          {pessoa.payload.departamento
                            ? pessoa.payload.departamento.descricao
                            : ""}
                        </Typography>
                      </div>
                      <div className={classes.descricaoAtleta}>
                        <Typography className="titulo">Cargo:</Typography>
                        <Typography className="dado">
                          {pessoa.payload.cargo
                            ? pessoa.payload.cargo.descricao
                            : ""}
                        </Typography>
                      </div>
                    </React.Fragment>
                  )}
                  {isAtleta ? (
                    <div className={classes.descricaoAtleta}>
                      <Typography className="titulo">Clube:</Typography>
                      <Typography className="dado">
                        {pessoa.payload.atleta.estabelecimento
                          ? pessoa.payload.atleta.estabelecimento.descricao
                          : ""}
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={classes.descricaoAtleta}>
                    <Typography className="titulo">UF:</Typography>
                    <Typography className="dado">
                      {pessoa.payload.estabelecimento
                        ? pessoa.payload.estabelecimento.uf
                        : ""}
                      {isAtleta ? (
                        <React.Fragment>
                          {pessoa.payload.atleta.estabelecimento
                            ? pessoa.payload.atleta.estabelecimento.uf
                            : ""}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {pessoa.payload.pessoa
                            ? pessoa.payload.pessoa.estabelecimento.uf
                            : ""}
                        </React.Fragment>
                      )}
                    </Typography>
                  </div>
                  <div className={classes.descricaoAtleta}>
                    <Typography className="titulo">Idade:</Typography>
                    <Typography className="dado">
                      {pessoa.payload.data_nascimento
                        ? convertDateYearString(pessoa.payload.data_nascimento)
                        : ""}
                      {isAtleta ? (
                        <React.Fragment>
                          {pessoa.payload.atleta.data_nascimento
                            ? convertDateYearString(
                                pessoa.payload.atleta.data_nascimento
                              )
                            : ""}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </Typography>
                  </div>
                  <div className={classes.redesSociais}>
                    <IconButton
                      disabled={
                        testURLstr(pessoa.payload.perfil_instagram) === ""
                          ? true
                          : false
                      }
                      className={clsx(
                        classes.linkRedesSociais,
                        classes.instagram
                      )}
                      href={testURLstr(pessoa.payload.perfil_instagram)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <InstagramOriginalSVG />
                    </IconButton>
                    <IconButton
                      disabled={
                        testURLstr(pessoa.payload.perfil_facebook) === ""
                          ? true
                          : false
                      }
                      className={clsx(
                        classes.linkRedesSociais,
                        classes.facebook
                      )}
                      href={testURLstr(pessoa.payload.perfil_facebook)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FacebookOriginalSVG />
                    </IconButton>
                    <IconButton
                      disabled={
                        testURLstr(pessoa.payload.perfil_twitter) === ""
                          ? true
                          : false
                      }
                      className={clsx(classes.linkRedesSociais, classes.tiktok)}
                    >
                      <TiktokRoundSVG />
                    </IconButton>
                    <IconButton
                      disabled={
                        testURLstr(pessoa.payload.perfil_twitter) === ""
                          ? true
                          : false
                      }
                      className={clsx(
                        classes.linkRedesSociais,
                        classes.twitter
                      )}
                      href={testURLstr(pessoa.payload.perfil_twitter)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <TwitterRoundSVG />
                    </IconButton>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={6} sm={5}>
              {pessoa.loading ? (
                <Skeleton
                  variant="circle"
                  className={classes.sekeletonAvatar}
                />
              ) : (
                <div
                  className={clsx(classes.wrapperFotoAtleta, {
                    [classes.wrapperFotoAtletaEspecial]: Boolean(
                      pessoa.payload.foto_apresentacao
                    ),
                  })}
                >
                  {pessoa.payload.foto_apresentacao ? (
                    <img
                      src={pessoa.payload.foto_apresentacao}
                      alt=""
                      style={{ maxWidth: "100%", maxHeight: "450px" }}
                    />
                  ) : (
                    <React.Fragment>
                      {isAtleta ? (
                        <Avatar
                          className={classes.fotoAtleta}
                          variant="circular"
                          src={`https://saltosbrasil.bigmidia.com/_uploads/fotoAtleta/${pessoa.payload.atleta.foto}`}
                          alt={
                            pessoa.payload.atleta.foto
                              ? pessoa.payload.atleta.nome_completo
                              : ""
                          }
                        />
                      ) : (
                        <Avatar
                          className={classes.fotoAtleta}
                          variant="circular"
                          src={`https://saltosbrasil.bigmidia.com/_uploads/fotoPessoa/${pessoa.payload.foto}`}
                          alt={
                            pessoa.payload.foto
                              ? pessoa.payload.nome_completo
                              : ""
                          }
                        />
                      )}
                    </React.Fragment>
                  )}
                </div>
              )}
            </Grid>
          </Grid>
          <div className={classes.cardResultados}>
            <div className={classes.cardTop}></div>
            <Paper className={classes.cardRoot}>
              {/* <Grid container className={classes.cardContainer}>
                <Grid item xs={9}>
                  <Typography className={classes.rankingAtleta}>
                    <span>6º</span> no Ranking <b>Saltos Brasil</b>
                  </Typography>
                </Grid>
                <Grid item xs={3} className={classes.GridPtsAtleta}>
                  <Typography className={classes.ptsAtleta}>
                    <span>985</span>pts
                  </Typography>
                </Grid>
              </Grid> */}
              <TabContext value={tabIndex}>
                <TabList onChange={handleChangeTab} className={classes.TabList}>
                  <Tab
                    className={classes.Tab}
                    label="Bio"
                    value="1"
                    disableRipple
                  />
                  {isAtleta ? (
                    <Tab
                      className={classes.Tab}
                      label="Resultados"
                      value="2"
                      disableRipple
                    />
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                  {/*<Tab className={classes.Tab} label="Estatísticas" value="3" disableRipple />*/}
                </TabList>
                <Box className={classes.tabLine} />
                <TabPanel className={classes.TabPanel} value="1">
                  {pessoa.payload.biografia}
                </TabPanel>
                <TabPanel
                  className={clsx(classes.TabPanel, classes.TabPanelTable)}
                  value="2"
                >
                  {isAtleta ? (
                    <React.Fragment>
                      {!eventoAtleta.loading ? (
                        <TableResultados
                          eventoAtleta={eventoAtleta}
                          eventos={eventos}
                        />
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </TabPanel>
                {/*<TabPanel className={classes.TabPanel} value="3"></TabPanel>*/}
              </TabContext>
            </Paper>
            <div className={classes.cardBottom}></div>
          </div>
          {pessoa.loading ? (
            ""
          ) : (
            <React.Fragment>
              <Typography variant="subtitle1" className={classes.subtitle1}>
                {pessoa.payload.atleta ? (
                  <React.Fragment>
                    Mergulhe na Playlist de Treino d
                    {pessoa.payload.atleta.sexo ? (
                      <React.Fragment>
                        {pessoa.payload.atleta.sexo === "M" ? "o" : "a"}{" "}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    {pessoa.payload.atleta.nome_completo
                      ? pessoa.payload.atleta.nome_completo
                      : ""}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Mergulhe na Playlist de Treino d
                    {pessoa.payload.sexo ? (
                      <React.Fragment>
                        {pessoa.payload.sexo === "M" ? "o" : "a"}{" "}
                      </React.Fragment>
                    ) : (
                      ""
                    )}
                    {pessoa.payload.nome_completo
                      ? pessoa.payload.nome_completo
                      : ""}
                  </React.Fragment>
                )}
              </Typography>
              <div className={classes.btnSpotify}>
                <SpotifyBtn className={classes.svgBtn}>
                  <SpotifySVG />
                </SpotifyBtn>
              </div>
            </React.Fragment>
          )}
        </Container>
      </div>
      <TransparenciaSection />
    </React.Fragment>
  );
}
