import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        marginTop: '-80px',
        background: theme.palette.primary.darker,
        // paddingTop: '120px', 

    },
    arenasWrapper: {
        // background: 'linear-gradient(180deg,  transparent 0%, #004B7D 20%, #001E32 100%)',
        background: 'linear-gradient(180deg, transparent 0%, #004B7D 22%, #004b7d 60%, #001E32 100%)',
        paddingTop: 100,
        paddingBottom: 200,
        marginTop: -260,
        position: 'relative',
        zIndex: 10,
        borderRadius: '0px 0px 30px 30px',
    },
    arenaBG: {
        background: 'url(https://saltosbrasil.bigmidia.com/_uploads/lugar/1-relatorioEvento-1630940848.jpg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        minHeight: 700,
        width: '100%',
        backgroundPosition: 'top center',
        [theme.breakpoints.up('md')]: {
            backgroundSize: '100%',
        }
    },
    heading: {
        // marginTop: 450,
        color: theme.palette.warning.pale,
        fontWeight: 400,
        fontFamily: "'Roboto', sans-serif",
        fontSize: theme.typography.pxToRem(67),
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.pxToRem(80),
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: theme.typography.pxToRem(90),
        }
    },
    subHeading: {
        color: theme.palette.info.light,
        fontWeight: 600,
        fontFamily: "'Quicksand', sans-serif",
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.25rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.5rem',
        }
    },
    lugarWrapper: {
        position: 'relative',
        marginBottom: 10,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 20,
        }
    },
    cardBackdrop: {
        position: 'absolute',
        top: 6,
        left: 0,
        width: '60%',
        height: '100%',
        background: 'linear-gradient(143deg, #32E8FF 0%, #3EB2FF 100%)',
        boxShadow: '0px 3px 6px #06031D80',
        borderRadius: '4px 4px 20px 4px',
        [theme.breakpoints.up('lg')]: {
            width: '45%',
        },
    },
    cardLugar: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 4,
        marginLeft: 3,
        height: 90,

        [theme.breakpoints.up('sm')]: {
            height: 120,
            marginLeft: 6,

        },
        [theme.breakpoints.up('lg')]: {
            height: 150,

        }
    },
    CardMedia: {
        width: 110,
        [theme.breakpoints.up('sm')]: {
            width: 135,
        },
        [theme.breakpoints.up('lg')]: {
            width: 170,
        },
        '&.MuiSkeleton-root': {
            height: '100%',
        },

    },
    tituloLugar: {
        color: theme.palette.secondary.dark80,
        fontWeight: 800,
        fontFamily: "'Quicksand', sans-serif",
        lineHeight: 1.2,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.25rem',
            fontWeight: 600,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.5rem',
        }
    },
    CardContent: {
        minWidth: '50%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            padding: 10,
            paddingBottom: '10px !important',
        }
    },
    linksLugar: {
        marginTop: 'auto',
        '& a': {
            color: theme.palette.secondary.main,
            fontWeight: 'bold',
            fontFamily: "'Quicksand', sans-serif",
            marginRight: 20,
            cursor: 'pointer'
        }
    },
    //tabs
    TabList: {
        marginTop: 30,
        marginBottom: 40,
        '& .MuiTabs-indicator': {
            background: 'transparent'
        },
        '& .MuiTabs-flexContainer': {
            flexWrap: 'wrap'
        },
    },
    Tab: {
        padding: theme.spacing(1, 1.5),
        minWidth: 'auto',
        minHeight: 'auto',
        borderRadius: 12,
        textShadow: `0px 2px 6px ${alpha(theme.palette.secondary.dark, 0.8)}`,
        '&.Mui-selected': {
            background: `linear-gradient(124deg, ${theme.palette.info.light} 0%, ${theme.palette.secondary.light} 100%)`,
            color: theme.palette.warning.white
        },
        backgroundColor: theme.palette.secondary.dark100,
        color: theme.palette.secondary.light,
        opacity: 1,
        marginBottom: 10,
        marginRight: 10,
    },
    TabPanel: {
        paddingLeft: 0,
        paddingRight: 0
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    }
}))