import { Avatar, Checkbox, TableCell, TableRow, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import React from "react";
// import { Link } from "react-router-dom";
import { convertDateYearString } from "../../../util/convertDate";
// import { createSlug } from "../../../util/createSlug";
import { useStyles } from "../styles/TabelaAtletas";


export default function TabelaArbitros(props) {
    const { tecnicos, selected, setSelected, page } = props;
    const classes = useStyles();

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const errorMessage = () => {
        return (
            <div>
                <Alert severity="error">
                    Nenhum Arbitro encontrado para os filtros selecionados.
                </Alert>
            </div>
        )
    }

    return (
        <React.Fragment>
            {tecnicos.error ?
                <TableRow>
                    <TableCell colSpan={5}>
                        {errorMessage()}
                    </TableCell>
                </TableRow>
                :
                tecnicos.payload.map((row, index) => {
                    const isItemSelected = isSelected(row.pessoa.nome_completo);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    let nomeCompleto = row.pessoa.nome_completo ? row.pessoa.nome_completo.split(' ') : '';
                    let sobrenome = row.pessoa.nome_completo ? row.pessoa.nome_completo.split(' ').slice(1).join(' ') : ''
                    return (
                        <TableRow
                            hover
                            className={clsx(classes.TableRow, { [classes.TableRowSelected]: isItemSelected })}
                            onClick={(event) => handleClick(event, row.pessoa.nome_completo)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.pessoa.nome_completo}
                            selected={isItemSelected}
                        >
                            <TableCell padding="checkbox" className={classes.CheckboxCell}>
                                <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ 'aria-labelledby': labelId }}
                                />
                            </TableCell>
                            <TableCell style={isItemSelected ? { display: 'none' } : { borderRadius: '20px 0px 0px 20px' }}>
                                {page === 1 ? index + 1 : ((20 * (page - 1)) + index + 1)}
                            </TableCell>
                            <TableCell colSpan={isItemSelected ? 2 : 1} className={isItemSelected ? classes.CellAvatarSelected : ''}>
                                <div className={classes.AvatarWapper}>
                                    <div className={clsx(classes.AvatarBorder, { [classes.AvatarBorderSelected]: isItemSelected })}>
                                        <Typography className={isItemSelected ? classes.posicaoAtleta : classes.posicaoAtletaHidden}>
                                            {index + 1}
                                        </Typography>
                                        <Avatar
                                            src={`https://saltosbrasil.bigmidia.com/_uploads/fotoPessoa/${row.pessoa.foto}`}
                                            className={clsx(classes.Avatar, { [classes.AvatarSelected]: isItemSelected })} />
                                    </div>
                                    <div>
                                        <Typography variant="body1" className={clsx(classes.Nome, { [classes.NomeSelected]: isItemSelected })}>
                                            {nomeCompleto[0]}
                                        </Typography>
                                        <Typography variant="body1" className={clsx(classes.Sobrenome, { [classes.SobrenomeSelected]: isItemSelected })}>
                                            {sobrenome}
                                        </Typography>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell>
                                {isItemSelected ?
                                    <Typography className={classes.titleInfoAtleta}>
                                        Categoria: <span className={classes.infoAtleta}>
                                            {row.categoria ? row.categoria.descricao : ''}
                                        </span>
                                    </Typography>
                                    :
                                    row.categoria ? row.categoria.descricao : ''}
                            </TableCell>
                            <TableCell>
                                {isItemSelected ?
                                    <React.Fragment>
                                        <Typography className={classes.titleInfoAtleta}>
                                            Idade: <span className={classes.infoAtleta}>
                                                {convertDateYearString(row.pessoa.data_nascimento)}
                                            </span>
                                        </Typography>
                                        <Typography className={classes.titleInfoAtleta}>
                                            UF: <span className={classes.infoAtleta}>
                                                {row.pessoa.estabelecimento ? row.pessoa.estabelecimento.uf : ''}
                                            </span>
                                        </Typography>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        {row.pessoa.estabelecimento ? row.pessoa.estabelecimento.uf : ''}
                                    </React.Fragment>
                                }
                            </TableCell>
                            <TableCell className={classes.tableCellClassificacao}></TableCell>
                        </TableRow>
                    )
                })
            }
        </React.Fragment>
    )

}

