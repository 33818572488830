import { alpha, makeStyles, withStyles } from "@material-ui/core";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.white,
        borderRadius: '20px !important',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
            '& .MuiBox-root': {
                width: '100%',
            }
        },
    },
    expanded: {},
}))(MuiAccordion);

export const AccordionSummary = withStyles((theme) => ({
    root: {
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            '& .MuiIconButton-root': {
                transform: 'rotate(360deg)',
            },
            '& svg': {
                filter: `drop-shadow(2px 3px 5px ${alpha(theme.palette.info.light, 0.8)})`
            }
        },
        '& .MuiIconButton-root': {
            padding: 0,
            color: theme.palette.info.main,
            transform: 'rotate(180deg)',
        },
        '& svg': {
            fontSize: '2.5em',
        }
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    AccordionCronograma: {
        backgroundColor: '#E5FCFF'
    },
    heading: {
        fontSize: theme.typography.pxToRem(18),
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 700,
        flexShrink: 0,
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(24),
        }
    },
    titleBorder: {
        width: 20,
        height: 4,
        borderRadius: 3,
        backgroundColor: theme.palette.info.main,
        transition: 'all 200ms ease-in-out'
    },
    cardBottom: {
        marginTop: -29,
        maxWidth: 200,
        height: 36,
        background: 'linear-gradient(112deg, #1EB1C4 0%, #32E8FF 100%)',
        borderRadius: '0px 0px 40px 20px',
    }
}));
// 'eventoProvas' 'eventoProvasE' 'eventoProvasF' 'eventoProvasS'