import React from 'react';
import Typography from '@material-ui/core/Typography';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from '../styles/AccordionCard';
import { Box } from '@material-ui/core';
import { ArrowSVG } from '../../../assets/icons/LogosTemplate';
import clsx from 'clsx';

export default function AccordionCard(props) {
    const classes = useStyles();
    const { resultado } = props
    const [expanded, setExpanded] = React.useState(true);

    const handleChange = () => {
        setExpanded(!expanded);
    };

    return (
        <div className={classes.root}>
            <Accordion expanded={resultado ? !expanded : expanded} onChange={handleChange} className={clsx(classes.Accordion, { [classes.AccordionCronograma]: !resultado })}>
                <AccordionSummary
                    expandIcon={<ArrowSVG />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography className={classes.heading}>
                        {props.title}
                        <Box className={classes.titleBorder} />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {props.children}
                </AccordionDetails>
            </Accordion>
            <div className={classes.cardBottom}></div>
        </div>
    );
}
