import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

function SocioTorcedorPage() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Typography variant="h1" align="center">
          SÓCIO TORCEDOR SALTOS BRASIL
        </Typography>
        <Typography variant="subtitle1"></Typography>
        <Typography>
          Se você é fã de esporte e sempre quis apoiar, mas não sabia como,
          agora você tem a oportunidade de incentivar os saltos ornamentais, o
          seu clube e seu atleta predileto com uma pequena quantia todo mês que
          não irá impactar no seu orçamento e te dará milhares de benefícios. A
          partir de R$ 10 reais você contribui com o esporte e ainda passa a
          integrar o Clube de Vantagens Saltos Brasil.
          <br />
          O valor arrecadado será investido integralmente nos saltos ornamentais
          e você ainda pode optar por destinar parte do recurso ao clube e ao
          atleta preferido.
          <br />
        </Typography>
        <Box sx={{ mt: 5 }}>
          <Card>
            <CardContent>
              <Typography variant="h6">CASTRO DE SÓCIO TORCEDOR</Typography>
              <Grid container spacing={2}>
                <Grid item sx={12} md={12}>
                  <TextField label="Nome" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={3}>
                  <TextField label="CPF" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={3}>
                  <TextField label="Sexo" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={12}>
                  <TextField label="Endereço" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={4}>
                  <TextField label="Telefone" color="secondary" fullWidth />
                </Grid>
                <Grid item sx={12} md={4}>
                  <TextField
                    label="Email"
                    type="email"
                    color="secondary"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Box>
  );
}

export default SocioTorcedorPage;
