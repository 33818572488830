import { Container, Typography } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import TransparenciaSection from "../home/sections/Transparencia";
import DocumentosSection from "./sections/Documentos";
import PesquisaEventos from "./sections/Eventos";
import PesquisaNoticias from "./sections/Noticias";
import PessoasSection from "./sections/Pessoas";
import { useStyles } from "./styles";

export default function PagePesquisa(params) {
  const classes = useStyles();
  const { searchTerm } = params;

  return (
    <React.Fragment>
      <Helmet>
        <title>Pesquisa - {searchTerm} - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais</title>
        <meta name="description" content="Pesquisa - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais" />
      </Helmet>
      <div className={classes.root}>
        <Container>
          <Typography variant="h1" className={classes.Heading}>
            Pesquisa - {searchTerm}
          </Typography>
          <PesquisaNoticias searchTerm={searchTerm} />
          <PesquisaEventos searchTerm={searchTerm} />
          <PessoasSection searchTerm={searchTerm} />
          <DocumentosSection searchTerm={searchTerm} />
        </Container>
      </div>
      <TransparenciaSection />
    </React.Fragment>
  );
}
