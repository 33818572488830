import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import logoSaltos from '../../../assets/images/logoSaltos.webp'
import { Container, Grid, Typography, Slide, List, ListItem, Dialog, ListItemText, Collapse, Divider, IconButton, useMediaQuery } from '@material-ui/core';
import SearchInput from '../../searchInput';
import { Link } from "react-router-dom";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { useStyles } from '../styles/NavDialog';
import { LoginBtn } from '../../header/components/LoginBtn';
import { menu } from '../../../assets/data/menu';

const TransitionUp = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TransitionDown = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function NavDialog(props) {
  const classes = useStyles();
  const { openMenu, setOpenMenu } = props;

  const [openMenuItem, setOpenMenuItem] = useState(false);
  const matchesSm = useMediaQuery(theme => theme.breakpoints.up('sm'));

  const handleClickMenuItem = (target, index) => {
    setOpenMenuItem({ [index]: !openMenuItem[index] })
  };

  const handleClose = () => {
    setOpenMenu(false);
  };

  return (
    <div>
      <Dialog className={classes.root} fullScreen open={openMenu} onClose={handleClose} TransitionComponent={matchesSm ? TransitionDown : TransitionUp}>
        <Container className={classes.container}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={4}>
              <img className={classes.logo} src={logoSaltos} alt="" />
            </Grid>
            <Grid item xs={8}>
              <Grid container>
                {matchesSm ?
                  <Grid item xs={12}>
                    <div className={classes.closeWrapper}>
                      <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Grid>
                  :
                  <Grid item xs={12}></Grid>
                }
                <Grid item xs={12}>
                  <SearchInput closeDialog={handleClose} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <List dense className={classes.menuList}>
            {menu.map((item, key) => (
              <React.Fragment key={key}>


                {item.filho ?
                  <React.Fragment>
                    <ListItem className={clsx(classes.btnNav, { [classes.btnNavActive]: openMenuItem[key] })} button onClick={e => handleClickMenuItem(e.currentTarget, key)}>
                      <ListItemText>
                        <Typography variant="body1" className={classes.menuLink}>{item.titulo}</Typography>
                      </ListItemText>
                      {item.filho ? openMenuItem[key] ? <ExpandLess /> : <ExpandMore /> : <React.Fragment></React.Fragment>}
                    </ListItem>
                    <Collapse className={classes.Collapse} in={openMenuItem[key]} timeout="auto" unmountOnExit>
                      <Divider className={classes.divider} />
                      <List dense={true} className={classes.nestedList}>
                        {item.filho.map((filho, key) => {
                          return (
                            <ListItem button component={Link} to={filho.url} className={classes.nestedListItem} key={key}>
                              <ListItemText>
                                <Typography variant="body1" className={classes.menuLink}>{filho.titulo}</Typography>
                              </ListItemText>
                            </ListItem>
                          )
                        })}
                      </List>
                      <Divider />
                    </Collapse>
                  </React.Fragment>
                  :
                  <ListItem className={classes.btnNav} button component={Link} to={item.url} onClick={handleClose}>
                    <ListItemText>
                      <Typography variant="body1" className={classes.menuLink}>{item.titulo}</Typography>
                    </ListItemText>
                  </ListItem>
                }
              </React.Fragment>
            ))}
          </List>
          <div className={classes.btnWrapper}>
            <LoginBtn href="https://saltosbrasil.bigmidia.com/site/login" target="_blank" rel="noreferrer" color="primary">login</LoginBtn>
          </div>
        </Container>
      </Dialog>
    </div>
  );
}
