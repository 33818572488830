import { Box, Container, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

function SejaDoadorPage() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.cardWhite}>
          <div className={classes.cardContent}>
            <Typography variant="h1" align="center">
              SEJA UM DOADOR
            </Typography>
            <Typography variant="subtitle1"></Typography>
            <Typography>
              Faça uma doação de qualquer valor sempre que quiser e contribua
              para o fortalecimento de um dos esportes olímpicos que mais cresce
              no Brasil. O valor será integralmente aplicado no desenvolvimento
              dos saltos ornamentais do Brasil e poderá ser integralmente
              deduzido do valor a ser pago do imposto de renda seu ou da sua
              empresa.
            </Typography>
            <Typography>
              Doação Simples
              <br />
              Faça a doação de qualquer valor para a Saltos Brasil e contribua
              para o desenvolvimento dos saltos ornamentais brasileiros. O valor
              pode ser doado através de cartão de crédito, transferência
              bancária ou PIX. Clique aqui e faça a sua doação (abrir formulário
              para doação).
            </Typography>
            <Typography>
              Doação com Incentivo Fiscal
              <br />A Lei de Incentivo ao Esporte nº 11.438 de 29 de dezembro de
              2006 permite que empresas e pessoas físicas façam doações a
              projetos previamente aprovados pelo Ministério do Esporte e tenham
              o valor deduzido do imposto de renda devido. Pessoas físicas podem
              doar até 7% do total de imposto devido na Declaração de Ajuste
              Anual e terem o valor abatido integralmente.
            </Typography>
            <Typography>
              Pessoas jurídicas podem doar até 4% do valor do imposto de renda
              devido e terem o valor integralmente deduzido. Apenas empresas que
              recolhem o tributo com base no lucro real podem usufruir desse
              benefício. O percentual do imposto que pode ser doado pelas
              empresas depende do tipo de projeto que será incentivado. Para os
              projetos voltados ao esporte de alto rendimento ou de
              participação, o percentual máximo de dedução é de 2%. Para os
              projetos de esporte educacional que beneficiam comunidades em
              locais de vulnerabilidade social, a empresa pode doar até 4% do
              imposto devido.
              <br />
              E é importante ressaltar que as doações para projetos aprovados
              pela Lei de Incentivo ao Esporte não competem com as doações a que
              se referem os incisos I, II e III do Artigo 12 da Lei nº 9.250, de
              26 de dezembro de 1995.
              <br />
              Entre em contato conosco para saber mais informações sobre a
              doação com benefícios fiscais ou clique aqui para conhecer os
              projetos da Saltos Brasil aprovados pelo Ministério do Esporte e
              habilitados para receberem a sua doação
            </Typography>
          </div>
        </Paper>
      </Container>
    </Box>
  );
}

export default SejaDoadorPage;
