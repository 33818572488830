import { Box, Container, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

function PatrocinePage() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.cardWhite}>
          <div className={classes.cardContent}>
            <Typography variant="h1" align="center">
              PATROCINE OS SALTOS ORNAMENTAIS
            </Typography>
            <Typography variant="subtitle1"></Typography>
            <Typography>
              O patrocínio esportivo é uma forma de parceria com a Saltos Brasil
              que pode ser utilizada de diferentes formas pela empresa
              patrocinadora. Geralmente os patrocínios esportivos estão
              alinhados com o Planejamento Estratégico e as estratégias de
              marketing ou responsabilidade social da empresa e, entre os
              motivos para patrocinar os saltos ornamentais, destacamos alguns
              abaixo:
            </Typography>
            <br />
            <Typography>
              - É um dos esportes olímpicos que mais cresce no país, tendo
              aumentado o número de praticantes em mais de 300% nos últimos
              anos;
              <br />
              - As saltadoras e saltadores brasileiros vêm alcançando resultados
              expressivos e inéditos nos últimos anos;
              <br />
              - Indicadores estatísticos mostram que os saltadores brasileiros
              têm grandes chances de alcançar uma medalha inédita nos próximos
              Jogos Olímpicos;
              <br />
              - É um dos esportes olímpicos com maior audiência em todo o mundo
              (saiba mais);
              <br />
              - Atletas brasileiros com expressiva participação e milhares de
              seguidores nas redes sociais;
              <br />
              - Competições nacionais e internacionais com transmissão ao vivo
              em streaming e TV;
              <br />
              - Esporte com ampla participação feminina, onde há tantas mulheres
              praticantes quanto homens;
              <br />
              - Esporte com ampla participação de atletas que apoiam a família e
              o movimento LGBTQI+;
              <br />
              - O crescimento e o desenvolvimento do esporte é fruto de um
              planejamento e trabalho estruturado da Confederação;
              <br />
              - A Saltos Brasil é uma das confederações olímpicas com modelo de
              gestão mais moderno e maior transparência, tendo suas contas
              auditadas anualmente por auditores externos independentes;
              <br />
              - A Saltos Brasil desenvolve projetos sociais e de massificação
              nas cinco regiões do país que possibilitam que mais de mil
              crianças carentes tenham acesso gratuito à prática dos saltos
              ornamentais, promovendo a democratização do esporte;
              <br />- Excelência, Precisão, Perfeição, Qualidade, Confiança,
              Coragem, Ousadia, Superação, Foco, Resiliência, Determinação, são
              algumas palavras que descrevem os atributos indispensáveis de um
              bom atleta de saltos ornamentais.
            </Typography>
            <br />
            <Typography>
              Contrapartida
              <br />
              <br />
              Existem diferentes formas de contrapartida que podem ser
              oferecidas às empresas que pretendem patrocinar os saltos
              ornamentais. Destacamos alguns exemplos abaixo, mas outras
              contrapartidas personalizadas podem ser oferecidas de acordo com o
              perfil da empesa: <br />
              <br />- Cessão dos direitos de imagem dos principais atletas e da
              entidade; <br />
              - Participação dos principais atletas em campanhas publicitárias
              do patrocinador; <br />
              - Divulgação no website e nas redes sociais da entidade e dos
              principais atletas; <br />
              - Divulgação da logomarca do patrocinador nos eventos e
              competições nacionais da Saltos Brasil; <br />
              - Colocar a logomarca do patrocinador no uniforme oficial da
              Seleção Brasileira de Saltos Ornamentais <br />
              - Colocar stand da empresa nas competições nacionais; <br />
              - Distribuição de brindes e produtos da empresa patrocinadora nos
              eventos nacionais para atletas e para o público; <br />
              - Menção do patrocinador nas transmissões ao vivo das competições
              nacionais em streaming e na TV; <br />
              - Realização de palestras motivacionais para os funcionários da
              empresa patrocinadora; <br />
              - Distribuição de ingresso dos eventos nacionais de saltos
              ornamentais para funcionários ou clientes da empresa; <br />
              - Divulgação da logomarca do patrocinador nos principais Centros
              de Treinamento de Saltos Ornamentais do país; <br />
              <br />
              <br />
              Se você está interessado em saber mais sobre um possível
              patrocínios aos saltos ornamentais brasileiros, entre em contato
              conosco para agendarmos uma conversa. <br />
            </Typography>
          </div>
        </Paper>
      </Container>
    </Box>
  );
}

export default PatrocinePage;
