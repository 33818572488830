import { SvgIcon } from "@material-ui/core";

export function ReguaSVG() {
    return (
        <SvgIcon width="80.33" height="2741" viewBox="0 0 80.33 2741" style={{ height: '100%' }}>
            <path fill="#d4eeff" d="M77.83,150.24H64v-2.7l8.13-9.42a3,3,0,0,0,.9-1.8c0-.72-.48-1.59-2.07-1.59s-2.25,1.26-2.49,2.67l-4.32-.66c.63-4,3.18-6.48,7.17-6.48,3.48,0,6.51,2.46,6.51,6.06a7.46,7.46,0,0,1-2,4.92l-4.35,4.68h6.39Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M80.8,130.56H95.68l-7.86,19.68h-5.4l6.15-15.33H80.8Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,129.57h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M75.49,356.78V376.9H70.6V363.53l-3.45,1.56V361Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M87.07,356.93c5.73,0,7.35,4.47,7.35,10.17s-1.62,10.07-7.35,10.07-7.47-4.44-7.47-10.07S81.28,356.93,87.07,356.93Zm0,15.63c1.89,0,2.55-2.46,2.55-5.46s-.66-5.52-2.55-5.52-2.67,2.49-2.67,5.52S85.12,372.56,87.07,372.56Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,356.24h4.23l2.52,20.66H117.1l-1.26-11.12-4.17,8.72h-3.78l-4.2-8.72L102.4,376.9H97.24l2.55-20.66H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M86.68,820.31a6.54,6.54,0,0,0-4.86,2.1l-2.07-1.17,1.56-10.68H93.25v4.17H85.09l-.42,2.67a6.11,6.11,0,0,1,3.63-1.08,6.38,6.38,0,0,1,6.39,6.75c0,4.23-3.24,7.47-8.4,7.47a9.29,9.29,0,0,1-7.14-3.75l3.72-2.7A4.68,4.68,0,0,0,86.29,826c2.16,0,3.66-1.59,3.66-2.94C90,821.33,88.57,820.31,86.68,820.31Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,809.57h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M55.63,583.89H70.51l-7.86,19.68h-5.4l6.15-15.33H55.63Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M73.6,598.32a2.77,2.77,0,0,1,2.88,2.73,2.8,2.8,0,0,1-2.88,2.79,2.77,2.77,0,0,1-2.82-2.79A2.74,2.74,0,0,1,73.6,598.32Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M86.68,593.64a6.54,6.54,0,0,0-4.86,2.1l-2.07-1.17,1.56-10.68H93.25v4.17H85.09l-.42,2.67a6.11,6.11,0,0,1,3.63-1.08,6.38,6.38,0,0,1,6.39,6.75c0,4.23-3.24,7.47-8.4,7.47a9.29,9.29,0,0,1-7.14-3.75l3.72-2.7a4.68,4.68,0,0,0,3.42,1.92c2.16,0,3.66-1.59,3.66-2.94C90,594.66,88.57,593.64,86.68,593.64Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,582.9h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M84.88,1048.56v-3.68h2a2,2,0,0,0,2.07-2.19,2,2,0,0,0-2-1.77,3.29,3.29,0,0,0-2.67,1.23l-2.82-2.31a7,7,0,0,1,6.3-2.91c3.51.18,6.06,2.7,6,5.55a4.48,4.48,0,0,1-2.58,3.81,5,5,0,0,1,3.45,4.73c0,4-3.63,6.13-7.59,6.13a6.8,6.8,0,0,1-6.36-3.63l3.06-2.71A3.63,3.63,0,0,0,87,1053c2,0,2.85-1.12,2.85-2.25s-.87-2.17-2.94-2.17Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,1036.24h4.23l2.52,20.66H117.1l-1.26-11.12-4.17,8.72h-3.78l-4.2-8.72-1.29,11.12H97.24l2.55-20.66H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M93.61,1263.44v20.13H88.72v-13.38l-3.45,1.56v-4.11Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,1262.9h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#000f11" d="M87.07,1490.26c5.73,0,7.35,4.47,7.35,10.17s-1.62,10.08-7.35,10.08-7.47-4.44-7.47-10.08S81.28,1490.26,87.07,1490.26Zm0,15.63c1.89,0,2.55-2.46,2.55-5.46s-.66-5.52-2.55-5.52-2.67,2.49-2.67,5.52S85.12,1505.89,87.07,1505.89Z" transform="translate(-42.67 -129.57)" />
            <path fill="#000f11" d="M115.51,1489.57h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <rect fill="#ccf9ff" x="58.33" y="37.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="58.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="80.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="101.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="143.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="122.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="164.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="185.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="206.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="258.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="279.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="301.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="322.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="364.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="343.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="385.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="406.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="427.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="489.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="510.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="532.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="553.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="595.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="574.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="616.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="637.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="658.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="717.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="738.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="760.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="781.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="823.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="802.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="844.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="865.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="886.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="945.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="966.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="988.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1009.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1051.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="1030.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1072.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1093.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1114.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1169.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1190.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1212.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1233.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1275.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="1254.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1296.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1317.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1338.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1397.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1418.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1440.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1461.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1503.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="1482.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1524.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1545.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1566.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1618.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1639.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1661.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1682.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1724.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="1703.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1745.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1766.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1787.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1849.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1870.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1892.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1913.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1955.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="1934.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1976.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="1997.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2018.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2077.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2098.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2120.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2141.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2183.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="2162.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2204.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2225.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2246.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2305.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2326.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2348.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2369.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2411.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="2390.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2432.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2453.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2474.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2529.93" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2550.79" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2572.07" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2593.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2635.08" width="22" height="3" />
            <rect fill="#32e8ff" x="41.83" y="2614.64" width="38.5" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2656.36" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2677.65" width="22" height="3" />
            <rect fill="#ccf9ff" x="58.33" y="2698.93" width="22" height="3" />
            <path fill="#d4eeff" d="M71.8,1731.66v-4.05H82.45v4.05Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M93.61,1716.78v20.13H88.72v-13.38l-3.45,1.56V1721Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,1716.24h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.72h-3.78l-4.2-8.72-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M65.56,2185v-4H76.21v4Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M86.68,2180.31a6.54,6.54,0,0,0-4.86,2.1l-2.07-1.17,1.56-10.68H93.25v4.17H85.09l-.42,2.67a6.11,6.11,0,0,1,3.63-1.08,6.38,6.38,0,0,1,6.39,6.75c0,4.23-3.24,7.47-8.4,7.47a9.29,9.29,0,0,1-7.14-3.75l3.72-2.7a4.68,4.68,0,0,0,3.42,1.92c2.16,0,3.66-1.59,3.66-2.94C90,2181.33,88.57,2180.31,86.68,2180.31Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,2169.57h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M67.21,1958.32v-4H77.86v4Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M84.88,1955.23v-3.69h2a2,2,0,0,0,2.07-2.19,2,2,0,0,0-2-1.77,3.29,3.29,0,0,0-2.67,1.23l-2.82-2.31a7,7,0,0,1,6.3-2.91c3.51.18,6.06,2.7,6,5.55a4.48,4.48,0,0,1-2.58,3.81,5,5,0,0,1,3.45,4.74c0,4-3.63,6.12-7.59,6.12a6.78,6.78,0,0,1-6.36-3.63l3.06-2.7a3.64,3.64,0,0,0,3.3,2.16c2,0,2.85-1.11,2.85-2.25s-.87-2.16-2.94-2.16Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,1942.9h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M42.67,2411.66v-4H53.32v4Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M55.63,2397.23H70.51l-7.86,19.68h-5.4l6.15-15.33H55.63Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M73.6,2411.66a2.77,2.77,0,0,1,2.88,2.72,2.8,2.8,0,0,1-2.88,2.79,2.77,2.77,0,0,1-2.82-2.79A2.73,2.73,0,0,1,73.6,2411.66Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M86.68,2407a6.54,6.54,0,0,0-4.86,2.1l-2.07-1.17,1.56-10.68H93.25v4.17H85.09l-.42,2.67A6.11,6.11,0,0,1,88.3,2403a6.38,6.38,0,0,1,6.39,6.75c0,4.22-3.24,7.47-8.4,7.47a9.29,9.29,0,0,1-7.14-3.75l3.72-2.71a4.64,4.64,0,0,0,3.42,1.92c2.16,0,3.66-1.59,3.66-2.93C90,2408,88.57,2407,86.68,2407Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,2396.24h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.72h-3.78l-4.2-8.72-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M53.68,2638.32v-4.05H64.33v4.05Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M75.49,2623.44v20.13H70.6v-13.38l-3.45,1.56v-4.11Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M87.07,2623.59c5.73,0,7.35,4.47,7.35,10.17s-1.62,10.08-7.35,10.08-7.47-4.44-7.47-10.08S81.28,2623.59,87.07,2623.59Zm0,15.63c1.89,0,2.55-2.46,2.55-5.46s-.66-5.52-2.55-5.52-2.67,2.49-2.67,5.52S85.12,2639.22,87.07,2639.22Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,2622.9h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M50.17,2865.32v-4.05H60.82v4.05Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M77.83,2870.57H64v-2.7l8.13-9.42a3,3,0,0,0,.9-1.8c0-.72-.48-1.59-2.07-1.59s-2.25,1.26-2.49,2.67l-4.32-.66c.63-4,3.18-6.48,7.17-6.48,3.48,0,6.51,2.46,6.51,6.06a7.46,7.46,0,0,1-2,4.92l-4.35,4.68h6.39Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M80.8,2850.89H95.68l-7.86,19.68h-5.4l6.15-15.33H80.8Z" transform="translate(-42.67 -129.57)" />
            <path fill="#d4eeff" d="M115.51,2849.9h4.23l2.52,20.67H117.1l-1.26-11.13-4.17,8.73h-3.78l-4.2-8.73-1.29,11.13H97.24l2.55-20.67H104l5.82,12.57Z" transform="translate(-42.67 -129.57)" />

        </SvgIcon>
    )
}