import {Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "../styles/gruposSaltos";

import entendaA from "../../../assets/images/entenda/posicao_corpo/entenda_a_estendido.jpg";
import entendaB from "../../../assets/images/entenda/posicao_corpo/entenda_b_carpado.jpg";
import entendaC from "../../../assets/images/entenda/posicao_corpo/entenda_c_grupado.jpg";
import entendaD from "../../../assets/images/entenda/posicao_corpo/entenda_d_livre.jpg";
 

export default function PosicaoCorpo() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.gridSaltos}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <div className={clsx(classes.saltoWrapper, classes.saltoEsquerda)}>
                            <div className={classes.imgWrapper}>
                                <img src={entendaA} alt="entenda1" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.posicaoInfo}>
                                <Typography variant="subtitle1">A. ESTENDIDO</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.saltoWrapper}>
                            <div className={classes.imgWrapper}>
                                <img src={entendaB} alt="entenda1" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.posicaoInfo}>
                                <Typography variant="subtitle1">B. CARPADO</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={clsx(classes.saltoWrapper, classes.saltoEsquerda)}>
                            <div className={classes.imgWrapper}>
                                <img src={entendaC} alt="entenda1" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.posicaoInfo}>
                                <Typography variant="subtitle1">C. GRUPADO</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.saltoWrapper}>
                            <div className={classes.imgWrapper}>
                                <img src={entendaD} alt="entenda1" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={classes.posicaoInfo}>
                                <Typography variant="subtitle1">D. LIVRE</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}