import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: '120 !important',
    height: '100%',
  },
  container: {
    height: '100%',
    paddingTop: 30,
    backgroundColor: theme.palette.warning.main,
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  logo: {
    height: 120,
  },
  closeWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 44
  },
  btnNav: {
    '& .MuiListItemText-root': {
      flex: 'unset'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '2.2rem'
    }
  },
  btnNavActive: {
    backgroundColor: `${theme.palette.warning.light} !important`,
  },
  menuLink: {
    fontWeight: 600,
    fontFamily: "'Poppins', sans-serif",
    fontSize: '1.2rem'
  },
  menuList: {
    margin: '20px 0',
    '& .MuiListItem-dense': {
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  nestedList: {
    backgroundColor: theme.palette.warning.light,
  },
  divider: {
    backgroundColor: theme.palette.warning.darker,
    margin: '0 10px',
    height: 2
  },
  Collapse: {
    backgroundColor: theme.palette.warning.light,
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30,
    paddingBottom: 100,
  }
}));