import { Button, withStyles } from "@material-ui/core";

export const LoginBtn = withStyles((theme) => ({
    root: {
        background: `linear-gradient(103deg, ${theme.palette.info.pale} 0%, ${theme.palette.info.light} 20%, ${theme.palette.primary.light} 70%, ${theme.palette.secondary.light} 100%)`,
        borderRadius: 12,
        padding: theme.spacing(1.1, 7.5),
        overflow: 'hidden',
        boxShadow: '6px 6px 12px rgba(0,0,0,0.2)'
    },
    label: {
        color: 'black',
        fontSize: '1.2rem',
        lineHeight: 1,
        textTransform: 'none',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
    },
}))(Button);

export const LoginBtnSM = withStyles((theme) => ({
    root: {
        background: `linear-gradient(103deg, ${theme.palette.info.pale} 0%, ${theme.palette.info.light} 20%, ${theme.palette.primary.light} 70%, ${theme.palette.secondary.light} 100%)`,
        borderRadius: 12,
        padding: theme.spacing(1, 1.5),
        overflow: 'hidden',
        boxShadow: '6px 6px 12px rgba(0,0,0,0.2)'
    },
    label: {
        color: 'black',
        fontSize: '1rem',
        lineHeight: 1,
        textTransform: 'none',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
    },
}))(Button);