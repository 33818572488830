import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  containerMD: {
    [theme.breakpoints.up("md")]: {
      padding: 0,
    },
  },
  title: {
    fontSize: "1.95rem",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 400,
    marginBottom: "0.5em",
    color: theme.palette.info.light,
    [theme.breakpoints.up("md")]: {
      fontSize: "2.25rem",
    },
  },
  titleCaption: {
    color: "#fff",
    marginBottom: "1rem",
  },
  tituloFiltro: {
    color: theme.palette.warning.white,
    fontSize: "1.1rem",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 600,
    marginTop: "2rem",
  },
  cardTabelaWrapper: {
    marginTop: 30,
    marginBottom: 30,
    [theme.breakpoints.up("md")]: {
      marginTop: 50,
    },
  },
  cardTabela: {
    padding: theme.spacing(4, 0),
    background: theme.palette.primary.dark80,
    boxShadow: "0px 20px 20px #00101A",
    borderRadius: 40,
    minHeight: "50vh",
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4.5, 0),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(5, 0),
    },
  },
  table: {
    "& th": {
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 600,
      color: theme.palette.warning.white,
      fontSize: ".9rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "1rem",
      },
    },
    "& td": {
      color: "#fff",
    },
    "& .MuiTableCell-root": {
      borderColor: "#001E32",
    },
    "& .MuiTableCell-sizeSmall": {
      padding: "4px",
      [theme.breakpoints.up("sm")]: {
        padding: "4px 6px",
      },
    },
    "& .MuiTypography-body1": {
      fontSize: ".8rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: ".9rem",
      },
      [theme.breakpoints.up("lg")]: {
        fontSize: "1rem",
      },
    },
    "& tr td:first-child, & tr th:first-child": {
      paddingLeft: 10,
      [theme.breakpoints.up("sm")]: {
        paddingLeft: 25,
      },
      [theme.breakpoints.up("lg")]: {
        paddingLeft: 50,
      },
    },
    "& tr td:last-child, & tr th:last-child": {
      paddingRight: 10,
      [theme.breakpoints.up("sm")]: {
        paddingRight: 25,
      },
      [theme.breakpoints.up("lg")]: {
        paddingRight: 50,
      },
    },
    "& .letra": {
      fontSize: ".7rem",
      verticalAlign: "super",
    },
    "& .decimal": {
      fontSize: ".7rem",
    },
  },
  tableTitle: {
    fontSize: "1rem",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 500,
    color: theme.palette.info.light,
    marginBottom: ".5em",
    paddingLeft: 10,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 25,
      fontSize: "1.1rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.25rem",
    },
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 50,
    },
  },
  tableBody: {
    background: "linear-gradient(180deg, #010B1A 0%, #001E32 100%)",
    "& .posicao": {
      color: theme.palette.info.dark,
      fontFamily: "'Roboto', sans-serif",
      fontWeight: 600,
      fontSize: "1rem",
      textAlign: "center"
    },
  },
  cellAtleta: {
    display: "flex",
    alignItems: "center",

    "& .MuiAvatar-root": {
      border: `1px solid ${theme.palette.info.dark}`,
      height: 18,
      width: 18,
      marginRight: 5,
      [theme.breakpoints.up("sm")]: {
        marginRight: 10,
      },
      [theme.breakpoints.up("md")]: {
        marginRight: 16,
        height: 48,
        width: 48,
      },
    },
    "& a": {
      color: "#fff",
      textDecoration: "none",
    },
  },
  cellAtletaDupla: {
    "& .posicao": {
      marginRight: 0,
    },
    "& .atletaWrapper": {
      display: "flex",
      alignItems: "center",
    },
    "& .MuiAvatar-root": {
      marginRight: 5,
      marginLeft: 5,
      [theme.breakpoints.up("sm")]: {
        marginRight: 10,
        marginLeft: 10,
      },
      [theme.breakpoints.up("md")]: {
        marginRight: 16,
        marginLeft: 16,
      },
    },
  },
  chipPontuacao: {
    fontFamily: "'Roboto', sans-serif",
    color: "#000",
    fontWeight: 600,
    borderRadius: 17,
    backgroundColor: theme.palette.info.light,
    padding: theme.spacing(0.5, 2.5),
    "&:hover": {
      backgroundColor: theme.palette.info.pale,
    },
    "&.Mui-disabled": {
      color: "rgb(0 0 0 / 80%)",
    },
  },
  chipPontuacaoPrimeiro: {
    backgroundColor: theme.palette.warning.dark,
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  chipPontuacaoExtra: {
    backgroundColor: theme.palette.info.dark,
  },
  btnPontos: {
    color: "#fff",
    padding: 0,
    fontSize: ".8rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: ".9rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1rem",
    },
    "&.Mui-disabled": {
      color: "rgb(255 255 255 / 80%);",
    },
  },
  btnPontosActive: {
    padding: "10px 0",
    backgroundColor: "rgba(0,0,0,0.6)",
    color: theme.palette.warning.light,
    zIndex: 10,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.4)",
    },
  },
  btnNotaWrapper: {
    position: "relative",
    "& svg": {
      fontSize: "4rem",
      color: theme.palette.warning.light,
      position: "absolute",
      marginLeft: "auto",
      marginRight: "auto",
      left: 0,
      right: 0,
      textAlign: "center",
      bottom: "-88%",
      [theme.breakpoints.up("md")]: {
        bottom: "-78%",
      },
    },
  },
  saltoDetailsTD: {
    padding: "0px !important",
  },
  saltoDetails: {
    minHeight: 50,
    backgroundColor: "#000F11",
    padding: theme.spacing(2),
    color: "#fff",
    // borderRadius: 4,
    [theme.breakpoints.up("sm")]: {
      borderRadius: 10,
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: 20,
      margin: "0 60px",
      display: "flex",
      justifyContent: "center",
    },
  },
  SNthead: {
    borderRight: " 2px solid #79797952 !important",
  },
  SNtheadLast: {
    borderRight: "none !important",
  },
  nomeCompletoAtleta: {
    minWidth: "unset",
  },
  filtroProvas: {
    "& .MuiTab-root": {
      textTransform: "capitalize",
    },
    "& .MuiTabs-centered": {
      alignItems: "center",
    },
  },
  clubeAtleta: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiAvatar-root": {
      marginLeft: 10,
      height: 16,
      width: 16,
      [theme.breakpoints.up("sm")]: {
        height: 24,
        width: 24,
      },
    },
  },
}));
