import { Container, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TransparenciaSection from "../home/sections/Transparencia";
import { useStyles } from "./styles";
import CardEventoResultado from "./sections/CardEventoResultado";
import CardEventoCompeticao from "./sections/CardEventoCompeticao";
import { governancaApi, portalApi } from "../../services/api";
import AccordionCard from "./sections/AccordionCard";
import AccordionPodium from "./sections/AccordionPodium";
import AccordionEtapa from "./sections/AccordionEtapa";
import EventoInfo from "./sections/EventoInfo";
import SectionTitle from "../../components/SectionTitle";
import CardBoletim from "../noticias/sections/CardBoletim";
import NoticiasRecentes from "../noticias/sections/NoticiasRecentes";
import AcompanheTreinos from "../noticias/sections/AcompanheTreinos";
import TabelaAtleta from "./sections/TabelaAtleta";
import _ from "lodash";
import { Helmet } from "react-helmet";
import InstagramSection from "../noticias/sections/Instagram";
import Galeria from "./sections/Galeria";
import { useEvento } from "./hooks/useEvento";
import { useEtapas } from "./hooks/useEtapas";
import { useEventoProva } from "./hooks/useEventoProva";

export default function PageCompeticaoItem(props) {
  const classes = useStyles();
  const { resultado } = props;
  const { id } = useParams();
  const { evento } = useEvento({ idEvento: id });
  const { etapas } = useEtapas({ idEvento: id });
  const { eventoProva, classesProvas } = useEventoProva({ idEvento: id });

  const [boletins, setBoletins] = useState({
    boletins: [],
    loading: true,
    error: false,
  });
  const [pageBoletins, setPageBoletins] = useState(1);
  const [totalPageBoletins, setTotalPageBoletins] = useState(1);

  useEffect(() => {
    setBoletins({
      boletins: [],
      loading: true,
      error: false,
    });
    async function getEventos() {
      await governancaApi
        .get("documento-evento", {
          params: {
            flag_del: 0,
            id_estabelecimento: 1,
            id_evento: id,
            expand: "documento,arquivoDocumento",
            pageSize: 8,
            page: pageBoletins,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setBoletins({
              boletins: [],
              loading: false,
              error: true,
            });
          } else {
            let docs = response.data.items.map(
              (item) => item.documento.arquivoDocumento
            );
            let docsArr = _.flatten(docs);

            setBoletins({
              boletins: docsArr,
              loading: false,
              error: false,
            });
            setTotalPageBoletins(response.data._meta.pageCount);
          }
        })
        .catch((err) => {
          setBoletins({
            boletins: [],
            loading: false,
            error: true,
          });
          console.error(err);
        });
    }
    getEventos();
  }, [pageBoletins, id]);

  const handleChangePageBoletim = (event, value) => {
    setPageBoletins(value);
  };

  const [noticias, setNoticias] = useState({
    error: false,
    loading: true,
    payload: [],
  });

  useEffect(() => {
    setNoticias({
      error: false,
      loading: true,
      payload: [],
    });
    async function getEventos() {
      await portalApi
        .get("noticia", {
          params: {
            flag_del: 0,
            id_estabelecimento: 1,
            "eventos.id_evento": id,
            sort: "-created_at",
            pageSize: 6,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setNoticias({
              error: true,
              loading: true,
              payload: [],
            });
          } else {
            setNoticias({
              error: false,
              loading: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setNoticias({
            error: true,
            loading: true,
            payload: [],
          });
          console.error(err);
        });
    }
    getEventos();
  }, [id]);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          {evento.payload && evento.payload.descricao
            ? `${evento.payload.descricao} - `
            : "Evento - "}
          Saltos Brasil – Confederação Brasileira de Saltos Ornamentais
        </title>
        <meta
          name="description"
          content={`${
            evento.payload && evento.payload.descricao
              ? `${evento.payload.descricao} - `
              : "Evento - "
          }Saltos Brasil – Confederação Brasileira de Saltos Ornamentais`}
        />
      </Helmet>

      <div className={classes.root}>
        <Container maxWidth="md">
          {resultado ? (
            <CardEventoResultado
              loading={evento.loading}
              evento={evento.payload}
              errorEvento={evento.error}
            />
          ) : (
            <CardEventoCompeticao
              loading={evento.loading}
              evento={evento.payload}
              errorEvento={evento.error}
            />
          )}
        </Container>
        <div className={classes.content}>
          <div className={classes.blurContainer}>
            <Container maxWidth="md">
              <div className={classes.firstCard}>
                <AccordionCard
                  resultado={resultado}
                  title={resultado ? "Resultado de Provas" : "Cronograma"}
                >
                  <Grid container spacing={2}>
                    {resultado && !eventoProva.loading
                      ? eventoProva.payload.map((item, key) => {
                          if (item.classificacao[0]) {
                            if (item.classificacao[0].colocacao) {
                              return (
                                <Grid item xs={12} key={key}>
                                  <AccordionPodium
                                    etapas={etapas}
                                    eventoProva={item}
                                  />
                                </Grid>
                              );
                            }
                          }
                          return <React.Fragment key={key}></React.Fragment>;
                        })
                      : null}
                    {!resultado && !etapas.loading && !etapas.error ? (
                      <React.Fragment>
                        {etapas.payload.map((item, key) => (
                          <Grid item xs={12} key={key}>
                            <AccordionEtapa
                              title={item.descricao}
                              etapa={item}
                            />
                          </Grid>
                        ))}
                      </React.Fragment>
                    ) : null}
                  </Grid>
                </AccordionCard>
              </div>
            </Container>
            <Container maxWidth="lg" className={classes.containerMD}>
              {resultado && evento.payload && evento.payload.id ? (
                <section>
                  <TabelaAtleta
                    classesProvas={classesProvas}
                    eventoProva={eventoProva}
                    idEvento={id}
                    evento={evento.payload}
                  />
                </section>
              ) : null}
            </Container>

            <Container maxWidth="md">
              <section>
                <EventoInfo
                  loading={evento.loading}
                  evento={evento.payload}
                  errorEvento={evento.error}
                  pageResultado={resultado}
                />
              </section>
            </Container>
            <Container maxWidth="md" className={classes.containerMD}>
              {resultado ? (
                <AccordionCard resultado={!resultado} title="Cronograma">
                  <Grid container spacing={2}>
                    {!etapas.loading && !etapas.erro ? (
                      <React.Fragment>
                        {etapas.payload.map((item, key) => (
                          <Grid item xs={12} key={key}>
                            <AccordionEtapa
                              title={item.descricao}
                              etapa={item}
                            />
                          </Grid>
                        ))}
                      </React.Fragment>
                    ) : null}
                  </Grid>
                </AccordionCard>
              ) : null}
            </Container>
            <Container maxWidth="md">
              {boletins.error ? null : (
                <section>
                  <SectionTitle color="infoLight">
                    Boletins do Evento:
                  </SectionTitle>
                  <CardBoletim
                    boletinsSection={false}
                    boletins={boletins.boletins}
                    loadingBoletins={boletins.loading}
                    errorBoletins={boletins.error}
                    handleChange={handleChangePageBoletim}
                    totalPages={totalPageBoletins}
                    page={pageBoletins}
                  />
                </section>
              )}
              {noticias.error ? null : (
                <section>
                  <SectionTitle color="infoLight">
                    Notícias Relacionadas:
                  </SectionTitle>

                  <NoticiasRecentes
                    fullSection
                    noticiasSection={false}
                    noticias={noticias.payload}
                    loadingNoticias={noticias.loading}
                    errorNoticias={noticias.error}
                  />
                </section>
              )}
              <Galeria idEvento={id} />
            </Container>
            <section className={classes.lastSection}>
              <InstagramSection noPaddintTop />
              <Container maxWidth="md">
                <SectionTitle color="infoLight">
                  Acompanhe os treinos da seleção:
                </SectionTitle>
                <AcompanheTreinos />
              </Container>
            </section>
          </div>
        </div>
      </div>
      <TransparenciaSection />
    </React.Fragment>
  );
}
