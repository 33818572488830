import { makeStyles, Tab, Tabs, withStyles } from "@material-ui/core";

export const AntTabs = withStyles({
    root: {
        minHeight: 'unset',
        borderBottom: '3px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: 'rgba(0,0,0,0.3)',
    },
})(Tabs);

export const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        padding: theme.spacing(0.8, 2.5),
        minHeight: 'unset',
        color: '#fff',
        borderRadius: 12,
        border: '2px solid transparent',
        transition: 'background 250ms ease-in-out',
        '& .MuiTab-wrapper': {
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 900,
        },
        '&:hover': {
            color: theme.palette.primary.darker,
            background: `linear-gradient(111deg, #FFFFFF 0%, #F5F8FB 52%, #D4EEFF 100%)`,
            opacity: 0.85,
            boxShadow: '6px 6px 12px #00101A',
            border: '2px solid #004A530D',
        },
        '&$selected': {
            background: `linear-gradient(111deg, #FFFFFF 0%, #F5F8FB 52%, #D4EEFF 100%)`,
            color: theme.palette.primary.darker,
            fontWeight: theme.typography.fontWeightMedium,
            boxShadow: '6px 6px 12px #00101A',
            border: '2px solid #004A530D',
        },
        '&:focus': {
            color: theme.palette.primary.darker,
        },
        [theme.breakpoints.up('sm')]: {
            marginRight: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: theme.spacing(4),
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const useStyles = makeStyles((theme) => ({
    container: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
        }
    },
    root: {
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(4),
        }
    },
    headerWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    heading: {
        color: theme.palette.warning.light,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 400,
        fontSize: '1.5rem',
        marginBottom: '.5em',
        [theme.breakpoints.up('lg')]: {
            fontSize: '3rem',
        }
    },
    subtitle: {
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 500,
        color: '#fff',
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 540
        }
    },
    //search
    search: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        marginLeft: 0,
        borderRadius: 30,
        border: '1px solid #000',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1.5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
        height: 40,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
    },
    inputInput: {
        padding: theme.spacing(0.8, 1, 0.8, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3.3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '20ch',
        },
    },
    //end serach
    cardWrapper: {
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.up('lg')]: {
            marginTop: 50,
            marginBottom: 50,
        }
    },
    filtrosWrapper: {
        margin: '20px 0',
    },
    filterTitle: {
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 500,
        color: theme.palette.warning.pale,
        fontSize: '1.15rem',
        marginBottom: '1rem',
        marginTop: 25,
    },
    checkboxLabel: {
        '& .MuiFormControlLabel-label': {
            backgroundColor: '#000F11',
            color: '#CCF9FF',
            borderRadius: 12,
            fontFamily: "'Poppins', sans-serif",
            fontWeight: 600,
            padding: theme.spacing(1, 2),
            marginBottom: 10,
            marginRight: '.5rem',
        },

        '& .Mui-checked ~ span': {
            color: '#000F11',
            background: 'linear-gradient(116deg, #FFF8CC 0%, #32E8FF 52%, #008BE7 100%)',
        },
        '& .MuiIconButton-root': {
            display: 'none'
        }
    },
    FormGroupUF: {
        justifyContent: 'flex-start',
        '& .MuiFormControlLabel-root': {
            margin: 0,
        }
    },
    btnSearchArrow: {
        transform: 'translateX(30px)',
        transition: 'all 250ms ease-in-out'
    },
    btnSearchArrowShow:{
        transform: 'translateX(0px)',
    }
}))