import moment from "moment";
import "moment/locale/pt-br";
export const convertDate = (dateTime) => {
  return moment(dateTime).format("DD/MM/YYYY");
};

export const convertDateDayMonth = (dateTime) => {
  var formatedDate = moment(dateTime).format("DD/MM");
  return formatedDate;
};

export const convertDateTime = (dateTime) => {
  return moment(dateTime).format("DD/MM/YYYY HH:mm");
};

export const convertDateMonthString = (dateTime) => {
  return moment(dateTime).format(`DD [de] MMMM [de] YYYY`);
};
export const convertDateYearString = (dateTime) => {
  let date1 = moment(dateTime);
  let date2 = moment();
  let diffDate = date2.diff(date1, "years");
  return diffDate + " Anos";
};
