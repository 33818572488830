import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    section: {
        minHeight: 1050,
        overflow: 'hidden',
        [theme.breakpoints.up('sm')]: {
            minHeight: 1150,
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 1500,
        }
    },
    root: {
        position: 'relative',
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.dark100,
        padding: '30px 12px',
        borderRadius: 20,
        [theme.breakpoints.up('sm')]: {
            padding: '30px 30px',
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 50,
            padding: '30px 50px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(8),
        }
    },
    rootAuto: {
        backgroundColor: theme.palette.secondary.dark100,
        padding: '30px 12px',
        borderRadius: 20,
        [theme.breakpoints.up('sm')]: {
            padding: '30px 30px',
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: 50,
            padding: '30px 50px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(8),
        }
    },
    rootTransparent: {
        padding: 0,
        backgroundColor: 'transparent',
        [theme.breakpoints.up('md')]: {
            backgroundColor: 'rgba(255,255,255,0.5)',
            border: '1px solid #fffff04d',
            padding: theme.spacing(4),
            borderRadius: 20,
            backdropFilter: 'blur(5px)',
            marginBottom: 100,
        }
    },
    container: {
        maxWidth: 'unset',
        padding: 0,
        [theme.breakpoints.up('sm')]: {
            maxWidth: 1280,
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(6),
            paddingRight: theme.spacing(6),
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: theme.spacing(12),
            paddingRight: theme.spacing(12),
        },
        [theme.breakpoints.up('lg2')]: {
            paddingLeft: theme.spacing(4.5),
            paddingRight: theme.spacing(4.5),
        }
    },
    containerAuto: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    },
    heading: {
        fontSize: '1.5rem',
        color: theme.palette.info.light,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 400,
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
        }
    },
    headingBGTransparent: {
        color: '#fff',
        padding: '6px 10px',
        margin: 0,
        fontWeight: 500,
        [theme.breakpoints.up('md')]: {
            color: theme.palette.info.dark,
            paddingLeft: 0
        }
    },
    cardWrapper: {
        marginTop: 40,
        display: 'flex',
        flexDirection: 'row'
    },
    cardWrapperBGTransparent: {
        marginTop: 0
    },
    CardProximoEvento: {
        background: `linear-gradient(180deg, #8677F1 0%, #32E8FF 61%, #FFED77 100%)`,
        borderRadius: '4px 4px 4px 20px',
        [theme.breakpoints.up('sm')]: {
            borderRadius: '10px 10px 10px 40px',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: 600,
            borderRadius: '4px 4px 4px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            minWidth: 800,
            maxWidth: '80%',
        },
        marginBottom: 30
    },
    CardProximoEventoBGTransparent: {
        marginBottom: 0,
    },
    CardActionArea: {
        width: 'auto',
        display: 'flex',
        marginLeft: 6,
        padding: theme.spacing(2, 2),
        backgroundColor: '#fff',
        borderRadius: '4px 4px 4px 20px',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(2, 1, 2, 4),
            borderRadius: '10px 10px 10px 40px',
        },
        [theme.breakpoints.up('md')]: {
            borderRadius: '4px 4px 4px 30px',
        },
    },
    CardMedia: {
        height: 80,
        minWidth: 80,
        backgroundColor: theme.palette.secondary.dark80,
        borderRadius: 5,
        '&.MuiSkeleton-root': {
            backgroundColor: 'rgba(0,0,0,0.1)'
        },
        [theme.breakpoints.up('sm')]: {
            height: 100,
            maxWidth: 100,
            margin: 'auto',
        },
        [theme.breakpoints.up('md')]: {
            height: 140,
            maxWidth: 140,
            margin: 'auto',
        }
    },
    CardContent: {
        padding: 0,
        paddingBottom: '0px !important',
        minWidth: 200,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    cardFooter: {
        marginTop: 'auto'
    },
    EventoTitulo: {
        color: theme.palette.secondary.dark80,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bolder',
        fontSize: '1rem',
        lineHeight: 1.1,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.25rem',
        }
    },
    chipProrrogado: {
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        backgroundColor: theme.palette.info.main,
        color: '#FFF',
        padding: theme.spacing(1, 0)
    },
    Escopo: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    ChipEscopo: {
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        backgroundColor: theme.palette.primary.light,
        color: '#FFF',
        boxShadow: `0px 3px 6px ${alpha(theme.palette.info.light, 0.4)}`,
        borderRadius: 16,
        padding: theme.spacing(1, 0.5),
        fontSize: '1rem',
        lineHeight: 1,
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(1.7, 1),
            fontSize: '1.2rem',
        }
    },
    limiteInscricao: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 600,
        color: theme.palette.error.main,
        textDecoration: 'underline',
        marginBottom: '.5rem',
        fontSize: '.9rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem'
        }
    },
    EventoDescricao: {
        margin: '0.5rem 0',
        color: theme.palette.secondary.dark80,
        lineHeight: 1,
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical',
        fontSize: '.9rem',
        fontWeight: 300,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        },
    },
    Endereco: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '0.5rem',
        '& svg': {
            fontSize: '1.5rem',
            marginRight: 8,
        },

        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
        },
    },
    Local: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: '0.5rem',
    },

    avatarEvento: {
        height: 20,
        width: 20,
        background: '#d7d7d7',
        color: '#d7d7d7',
        marginLeft: 3,
        marginRight: 10,
    },
    DataEvento: {
        textAlign: 'center',
        margin: '10px 0',
        color: theme.palette.secondary.dark80,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bolder',
        fontSize: '0.80rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '.9rem',
        }
    },
    StatusEvento: {
        textAlign: 'center',
        margin: '10px 0',
    },
    linkWrapper: {
        textAlign: 'center',
    },
    link: {
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        fontSize: '1.25rem',
        color: theme.palette.info.light,
        textDecoration: 'underline'
    },
    EntendaWrapper: {
        position: 'relative',
        height: 500,
        marginLeft: -12,
        marginRight: -12,
        [theme.breakpoints.up('sm')]: {

            marginLeft: -(theme.spacing(3.5)),
            marginRight: -(theme.spacing(3.5)),
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: -(theme.spacing(6)),
            marginRight: -(theme.spacing(6)),
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
            marginRight: 0,
            height: 900,
        },
        [theme.breakpoints.up('lg2')]: {
            position: 'unset',
        },
    },
    EntendaSection: {
        marginTop: 50,
        height: 600,
        background: `linear-gradient(134deg, #32E8FF 0%, #1EB1C4 50%, #008BE7 100%)`,
        borderRadius: 30,
        marginBottom: 40,
        [theme.breakpoints.up('sm')]: {
            height: 700,
        },
        [theme.breakpoints.up('md')]: {
            height: 900,
        },
        [theme.breakpoints.up('lg')]: {
            position: 'absolute',
            height: 960,
            width: 1100,
            left: 0,
        },
        [theme.breakpoints.up('lg2')]: {
            width: 'unset',
            left: theme.spacing(8),
            marginTop: 65,
        },

        '& img': {
            marginLeft: '5%',
            marginTop: '10%',
            height: 400,
            [theme.breakpoints.up('sm')]: {
                marginLeft: '15%',
                height: 500,
            },
            [theme.breakpoints.up('md')]: {
                marginLeft: '30%',
                height: 700,
            },
            [theme.breakpoints.up('lg')]: {
                height: 'auto',
                marginLeft: '35%',
            }
        }
    }
}));