import { Divider } from "@material-ui/core";
import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ReguaSVG } from "../../assets/icons/ReguaSVG";
import { portalApi } from "../../services/api";
// import Footer from "../../components/footer";
import ApoieSection from "./sections/Apoie";
// import DuvidasFrequentes from "./sections/DuvidasFrequentes";
import Entenda from "./sections/Entenda";
import HeroSection from "./sections/Hero";
import SocialSection from "./sections/Instagram";
import LiveEvento from "./sections/LiveEvento";
import NossosHerois from "./sections/NossosHerois";
import CardNovidades from "./sections/Novidades";
import Sobre from "./sections/Sobre";
import TransparenciaSection from "./sections/Transparencia";
import { useStyles } from './styles'
import _ from 'lodash';
import { saltoAovivo } from "../../assets/data/saltoAoVivo";

export default function Home() {
    const classes = useStyles();

    const [noticias, setNoticias] = useState([]);
    const [noticiaPrincipal, setNoticiaPrincipal] = useState([]);
    const [loadingNoticias, setLoadingNoticias] = useState(true);
    const [errorNoticias, setErrorNoticias] = useState(false);

    useEffect(() => {
        setLoadingNoticias(true)
        setErrorNoticias(false)
        async function getNoticias() {
            await portalApi.get('noticia', {
                params: {
                    flag_del: 0,
                    flag_publicado: 1,
                    id_estabelecimento: 1,
                    sort: '-created_at',
                    lessField: 'created_at',
                    lessValue: (new Date()).getTime().toString().substr(0, 10),
                    pageSize: 5
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorNoticias(true)
                } else {
                    setNoticias(_.slice(response.data.items, 1, 6))
                    setNoticiaPrincipal(response.data.items[0])
                    setLoadingNoticias(false)
                    setErrorNoticias(false)
                }
            }).catch((err) => {
                setErrorNoticias(true)
                console.log(err)
            });
        }
        getNoticias()
    }, [])

    return (
        <div className={classes.rootGradient}>
            <div className={classes.root}>
                <div className={classes.aguaBG}></div>
                <div className={classes.bgGradient1}></div>
                <div className={classes.bgGradient2}></div>
                <Container className={classes.container}>
                    <div className={classes.regua}>
                        <ReguaSVG />
                    </div>
                    <HeroSection />
                    <section>
                        {saltoAovivo[0].online === 0 ? '' :
                            <div className={classes.cardContainer}>
                                <LiveEvento />
                            </div>
                        }
                    </section>
                    <section>
                        <CardNovidades
                            noticiaPrincipal={noticiaPrincipal}
                            noticias={noticias}
                            loadingNoticias={loadingNoticias}
                            errorNoticias={errorNoticias}
                        />
                    </section>
                </Container>
                <Sobre />
                <Entenda />

                <NossosHerois />
                <SocialSection />
                <Divider className={classes.divider} />
                <ApoieSection />

                <TransparenciaSection />
            </div>
        </div>
    )
}
