import { Card, CardActionArea, Grid, Typography } from "@material-ui/core";
import { IGTVSVG, TikTokSVG, TwitchSVG } from "../../../assets/icons/RedesSociais";
import { useStyles } from "../styles/AcompanheTreinos";

export default function AcompanheTreinos() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container className={classes.container} justifyContent="space-around">
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <Grid container justifyContent="center">
                                <Grid item xs={2} sm={2} md={12}>
                                    <TwitchSVG />
                                </Grid>
                                <Grid item xs={10} sm={10} md={12}>
                                    <Typography className={classes.textLink}>
                                        @TWITCH
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Card className={classes.card}>
                        <CardActionArea href="https://www.instagram.com/saltosbrasil/channel/" target="_blank" rel="noreferrer">
                            <Grid container justifyContent="center">
                                <Grid item xs={2} sm={2} md={12}>
                                    <IGTVSVG />
                                </Grid>
                                <Grid item xs={10} sm={10} md={12}>
                                    <Typography className={classes.textLink}>
                                        @IGTV
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={3}>
                    <Card className={classes.card}>
                        <CardActionArea>
                            <Grid container justifyContent="center">
                                <Grid item xs={2} sm={2} md={12}>
                                    <TikTokSVG />
                                </Grid>
                                <Grid item xs={10} sm={10} md={12}>
                                    <Typography className={classes.textLink}>
                                        @TIKTOK
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </div>
    )
}