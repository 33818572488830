import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.dark80,
        paddingTop: 30,
        position: 'relative',
        zIndex: 5,
        paddingBottom: 25,
        borderRadius: '0 0 30px 30px',
        overflow: 'hidden',
    },
    Heading: {
        marginTop: 10,
        color: theme.palette.secondary.light,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 400,
        fontSize: '1.5rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.7rem',
        }
    },
    section: {
        margin: '50px 0'
    },
    ContainerNoticias: {
        // marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            // marginTop: 30,
            marginBottom: 30,
        },
        [theme.breakpoints.up('lg')]: {
            // marginTop: 50,
            marginBottom: 50,
        }
    },

    //tabs
    TabList: {
        marginTop: 30,
        '& .MuiTabs-indicator': {
            background: 'transparent'
        },
    },
    Tab: {
        padding: theme.spacing(1, 1.5),
        minWidth: 'auto',
        minHeight: 'auto',
        borderRadius: 12,
        marginRight: 20,
        textShadow: `0px 2px 6px ${alpha(theme.palette.secondary.dark, 0.8)}`,
        '&.Mui-selected': {
            background: `linear-gradient(124deg, ${theme.palette.info.light} 0%, ${theme.palette.secondary.light} 100%)`,
            color: theme.palette.warning.white
        },
        backgroundColor: theme.palette.secondary.dark100,
        color: theme.palette.secondary.pale
    },
    TabPanel: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important'
    }
}));