import { Box, Grid, Typography } from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { gestaoApi } from "../../../services/api";
import { useStyles } from "../styles/Pessoas";
import CardAtleta from "./components/CardAtleta";

function AtletasSection({ searchTerm }) {
  const classes = useStyles();
  const sectionTop = useRef(null);
  const [page, setPage] = useState(1);
  const [atletas, setAtletas] = useState({
    error: false,
    loading: true,
    payload: [],
    meta: {},
  });

  useEffect(() => {
    setAtletas({
      error: false,
      loading: true,
      payload: [],
      meta: {},
    });

    async function getAtletas() {
      // await CBSaltospi.get("atleta", {
      //   params: {
      //     expand: "atleta,classe,estabelecimento",
      //     forcejoin: "atleta.atletaHistoricoAtual.estabelecimento,atleta,classe",
      //     "atletaGestao.id": 1,
      //     "atletaGestao.flag_del": 0,
      //     sort: "nome_evento",
      //     arrayLike: `nome_evento:maria`,
      //   },
      // })
      await gestaoApi
        .get("atleta", {
          params: {
            flag_del: 0,
            sort: "nome_completo",
            arrayLike: `nome_completo:${searchTerm}`,
            page,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setAtletas({
              error: true,
              loading: false,
              payload: [],
              meta: {},
            });
          } else {
            setAtletas({
              error: false,
              loading: false,
              payload: response.data.items,
              meta: {
                pageCount: response.data._meta.pageCount,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setAtletas({
            error: true,
            loading: false,
            payload: [],
            meta: {},
          });
        });
    }
    getAtletas();
  }, [searchTerm, page]);

  useEffect(() => {
    if (atletas.error) {
      console.log("erro atleta");
    }
  }, [atletas.error]);

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 200,
    });

  const handleChange = (event, value) => {
    setPage(value);
    if (value === 1) {
      executeScroll();
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 3 }}>
      <div className={classes.cardHeaderLabel}>
        <Typography variant="h2" className={classes.CardTitle}>
          Atletas
        </Typography>
      </div>
      <Grid container spacing={2}>
        {atletas.payload.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item.id}>
            <CardAtleta atleta={item} />
          </Grid>
        ))}
      </Grid>
      {atletas.error && (
        <div className={classes.errorContainer}>
          <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
        </div>
      )}
      {atletas.meta && atletas.meta.pageCount > 1 && (
        <div className={classes.paginationContainer}>
          <Pagination page={page} count={atletas.meta.pageCount} onChange={handleChange} />
        </div>
      )}
    </Box>
  );
}

export default AtletasSection;
