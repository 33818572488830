import { alpha, makeStyles, Tab, Tabs, withStyles } from "@material-ui/core";
import posterEventosDesk from '../../../assets/images/poster_eventos_desk_2400.webp'
import posterEventosTablet from '../../../assets/images/poster_eventos_tablet_1200.webp'
import posterEventosMobile from '../../../assets/images/poster_eventos_mobile_1000.webp'
// import Maria_Lenk from '../../../assets/images/Maria_Lenk_Aquatic_Center.jpg'

export const EventoTabs = withStyles((theme) => ({
    root: {
        minHeight: 'unset',
        marginBottom: 30,
    },
    indicator: {
        backgroundColor: theme.palette.info.light,
        height: 4,
        width: '40px !important',
        borderRadius: 6,
    },
}))(Tabs);

export const EventoTab = withStyles((theme) => ({
    root: {
        padding: 0,
        minWidth: 'unset',
        minHeight: 'unset',
        marginRight: 20,
        fontSize: '1.2rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 900,
        color: theme.palette.primary.pale,
        opacity: 0.9,
        textTransform: 'unset',
        [theme.breakpoints.up('sm')]: {
            marginRight: 40,
        },
        '&$selected': {
            color: '#fff'
        },
        '&:focus': {
            color: '#fff'
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const useStyles = makeStyles((theme) => ({
    root: {
        // minHeight: '200vh',
        background: `url(${posterEventosMobile})`,
        backgroundColor: theme.palette.secondary.dark80,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        paddingTop: 30,
        position: 'relative',
        zIndex: 5,
        borderRadius: '0 0 40px 4px',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            background: `url(${posterEventosTablet})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: theme.palette.secondary.dark80,
            backgroundSize: '100%',
            backgroundAttachment: 'fixed',
            borderRadius: '0 0 80px 30px',
        },
        [theme.breakpoints.up('lg')]: {
            background: `url(${posterEventosDesk})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: theme.palette.secondary.dark80,
            backgroundSize: '100%',
            backgroundAttachment: 'fixed',
        },
    },
    secondaryTab: {
        '& .MuiTabs-fixed .MuiTabs-indicator': {
            backgroundColor: theme.palette.warning.main,
        }
    },
    liveContainer: {
        marginTop: -280,
        marginBottom: 30,
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            marginTop: 60,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 80,
        },
    },
    containerEventos: {
        position: 'relative',
        minHeight: '80vh',
        background: `linear-gradient(180deg, ${alpha(theme.palette.info.main, 0.7)} 0%,  ${theme.palette.primary.dark80} 100%)`,
        borderTop: `2px solid ${theme.palette.info.light}`,
        borderRadius: '0px 0px 80px 11px',
        backdropFilter: 'blur(5px)',
        marginTop: 300,
        paddingBottom: 50,
        [theme.breakpoints.up('md')]: {
            marginTop: 40,
            paddingTop: 60,
            paddingBottom: 80,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 100,
            paddingBottom: 130,
        }
    },
    containerEventosSecondary: {
        background: `linear-gradient(180deg, ${alpha(theme.palette.secondary.light, 0.8)} 0%,  ${theme.palette.secondary.dark80} 100%)`,
        borderTop: `2px solid ${theme.palette.secondary.light2}`,
    },
    PageTitle: {
        paddingTop: 20,
        fontSize: '2.25rem',
        color: theme.palette.warning.white,
        textTransform: 'uppercase',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 900,
        marginBottom: 10,
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            textAlign: 'left'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 20,
            paddingTop: 0,
        }
    },
    cardContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    }
    // containerArenas: {
    //     marginTop: -100,
    //     background: `url(${Maria_Lenk})`,
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: '120%',
    //     backgroundPosition: 'bottom left',
    //     display: 'flex',
    //     flexDirection: 'column',

    // },
    // arenasContent: {
    //     marginTop: '100vh',
    //     height: '100%',
    //     // minHeight: '100vh',
    //     background: `linear-gradient(180deg, transparent 0%, ${theme.palette.primary.darker} 30%,  ${theme.palette.primary.dark80} 100%)`,
    //     [theme.breakpoints.up('sm')]: {
    //         marginTop: '90vh',
    //     },
    //     [theme.breakpoints.up('md')]: {
    //         marginTop: '80vh',
    //     },
    //     [theme.breakpoints.up('lg')]: {
    //         marginTop: '50vh',
    //     },
    // },

}));