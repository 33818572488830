import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 10,
        marginBottom: 15,
        [theme.breakpoints.up('sm')]: {
            marginBottom: 20,
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: 25,
        }
    },
    Card: {
        background: `linear-gradient(163deg, #FFF8CC 0%, #1EB1C4 100%)`,
        borderRadius: '4px 4px 4px 12px',
        [theme.breakpoints.up('sm')]: {
            borderRadius: '10px 10px 10px 20px',
        }
    },
    CardSecondary: {
        background: `linear-gradient(180deg, #8677F1 0%, #32E8FF 61%, #FFED77 100%)`,
    },
    CardActionArea: {
        marginLeft: 8,
        borderRadius: '4px 4px 4px 12px',
        display: 'flex',
        flexDirection: 'row',
        // justifyContent: 'space-between',
        padding: theme.spacing(1.6, 2),
        backgroundColor: theme.palette.primary.white,
        [theme.breakpoints.up('sm')]: {
            marginLeft: 5,
            borderRadius: '10px 10px 10px 20px',
        }
    },
    CardMedia: {
        height: 80,
        width: 80,
        minWidth: 80,
        backgroundColor: theme.palette.secondary.dark80,
        borderRadius: 5,
        [theme.breakpoints.up('sm')]: {
            height: 100,
            width: 100,
            minWidth: 100,
            borderRadius: 10,
        },
        [theme.breakpoints.up('lg')]: {
            height: 116,
            width: 116,
            minWidth: 116
        },
        // '&.MuiSkeleton-root': {
        //     background: alpha(theme.palette.secondary.dark80,.80)
        // }
    },
    EventInfo: {
        paddingLeft: 10,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 20,
        },
        '& .MuiAvatar-root': {
            height: 18,
            width: 18,
            marginRight: 5,
            color: '#e2e2e2',
            background: '#e2e2e2',
            [theme.breakpoints.up('sm')]: {
                height: 25,
                width: 25,
            }
        },
        '& .MuiTypography-caption': {
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 700,
            fontSize: '.8rem',
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1,
            [theme.breakpoints.up('sm')]: {
                fontSize: '.9rem',
            },
            [theme.breakpoints.up('lg')]: {
                lineHeight: 1.2,
            }
        },
        '& .dataEvento': {
            fontSize: '.8rem',
            [theme.breakpoints.up('lg')]: {
                fontSize: '.9rem',
            }
        },
        '& .MuiChip-root': {
            marginLeft: 5,
            [theme.breakpoints.up('sm')]: {
                marginLeft: 0,
            }
        }
    },
    tituloEvento: {
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '0.95rem',
        fontWeight: 'bolder',
        margin: '8px 0',
        lineHeight: 1,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.25rem',
            lineHeight: 1.1
        }
    },
    localEvento: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    cardTag: {
        marginBottom: 'auto',
        // marginLeft: 'auto',
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            marginLeft: 10,
        }
    },
    dataLimite: {
        color: theme.palette.error.main,
        textDecoration: 'underline',
        fontWeight: "bold",
        fontSize: '0.75rem',
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: 'calc(100% - 120px)',
            minWidth: 180
        }
    }
}))