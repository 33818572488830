import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    [theme.breakpoints.up("sm")]: {
      marginTop: 520,
    },
    [theme.breakpoints.up("md")]: {
      marginTop: 480,
    },
  },
  heading: {
    color: theme.palette.info.dark,
    fontFamily: "'Quicksand', sans-serif",
    fontSize: "2.25rem",
    fontWeight: 400,
    textShadow: `0px 10px 10px ${alpha(theme.palette.info.main, 0.3)}`,
    marginBottom: 50,
    [theme.breakpoints.up("sm")]: {
      fontSize: "2.5rem",
    },
  },
  cardDiretoria: {
    borderRadius: 40,
    boxShadow: `0px 20px 30px ${alpha(theme.palette.info.main, 0.3)}`,
  },
  cardContent: {
    padding: theme.spacing(2, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 4),
    },
  },
  avatarDiretor: {
    height: 100,
    width: 100,
  },
  nameWrapper: {
    marginLeft: 35,
    "& .nome, & .sobrenome": {
      fontFamily: "'Quicksand', sans-serif",
      lineHeight: 1,
    },
    "& .nome": {
      fontFamily: "'Poppins', sans-serif",
      fontSize: "1rem",
      fontWeight: "bold",
    },
    "& .sobrenome": {
      fontSize: "2rem",
      fontWeight: 500,
    },
    "& .cargo": {
      fontSize: ".9rem",
      fontWeight: 400,
      fontStyle: "italic",
      color: theme.palette.secondary.darker,
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: 20,
    },
  },
  Divider: {
    background: theme.palette.info.main,
    margin: "20px auto 10px auto",
  },
  biografia: {
    padding: "0 6px",
    fontSize: ".9rem",
    lineHeight: 1,
  },
  btnSocial: {
    marginRight: 16,
    color: theme.palette.info.main,
    boxShadow: `0px 10px 10px ${alpha(theme.palette.info.main, 0.3)}`,
    "& svg": {
      fontSize: "2rem",
    },
  },
  redesSociais: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    "& :last-child": {
      marginRight: 0,
      marginLeft: "auto",
    },
  },
}));
