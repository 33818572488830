import { Card, CardContent, Typography } from "@material-ui/core";
import { useStyles } from "../style/QuemSomos";

export default function QuemSomos() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.cardTop}></div>
            <Card className={classes.cardQuemSomos} style={{textAlign: "justify"}}>
                <CardContent className={classes.cardContent}>
                    <Typography variant="h2" className={classes.cardTitle}>
                        Quem somos
                    </Typography>
                    <Typography variant="body1" component="p" className={classes.cardText}>
                        A Confederação Brasileira de Saltos Ornamentais – CBSO – é uma instituição sem fins lucrativos com a principal finalidade de atuar como a entidade nacional de administração dos Saltos Ornamentais e Saltos de Altura (High Diving).
                    </Typography><br />
                    <Typography variant="body1" component="p" className={classes.cardText}>
                        Foi criada no dia 18 de maio de 2017 por representantes da totalidade dos clubes brasileiros, incluindo, mas não se limitando a técnicos, árbitros e os principais atletas do país. É a única entidade nacional dedicada exclusivamente ao desenvolvimento dos saltos ornamentais e tem como principais metas se tornar uma referência na gestão esportiva do País e colocar o esporte brasileiro entre as principais potências mundiais da modalidade.
                    </Typography><br />
                    <Typography variant="body1" component="p" className={classes.cardText}>
                      É reconhecida pela Confederação Brasileira de Desportos Aquáticos desde 03 de julho de 2019 efiliada a Federação Internacional de Natação em 05 de novembro de 2020
                    </Typography>
                </CardContent>
            </Card>
            <div className={classes.cardBottom}></div>
        </div>
    )
}
