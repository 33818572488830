import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardContent: {
        color: '#fff',
        marginTop: 15,
        '& .MuiTypography-body1': {
            fontFamily: "'Quicksand', sans-serif",
        }
    },
    gridSaltos: {
        paddingBottom: 40,
        '& img': {
            maxWidth: '100%',
            width: 120,
            height: 120,
            minWidth: 120,
            borderRadius: '4px 4px 4px 0',
            border: '2px solid #fff',
            boxShadow: '-10px 10px 20px #000F1133',
            zIndex: 3,
            position: 'relative',
        }
    },
    imgWrapper: {
        width: 124,
        height: 124,
        zIndex: 2,
        position: 'relative'
    },
    saltoWrapper: {
        margin: '50px 30px 0 30px',
        display: 'flex',
        '& .MuiTypography-subtitle1': {
            color: '#fff',
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 600,
            paddingLeft: '1rem',
            // textTransform: 'uppercase',
        },
        '& .MuiDivider-root': {
            backgroundColor: theme.palette.info.light
        },
        '& .MuiTypography-body2': {
            paddingLeft: 15,
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 400,
            fontSize: '1.2rem',
            marginTop: 10,
            color: theme.palette.info.light
        }
    },
    roundImg: {
        '& .MuiTypography-root': {
            textAlign: 'center',

            color: '#fff',
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 600,
            paddingLeft: '1rem',
        },
        '& img': {
            borderRadius: 11,
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '20%',
            display: 'block',
            '& .MuiTypography-root': {
                paddingLeft: 0,
            }
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '30%',
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: '40%',
        }
    },
    borderBottom: {
        height: 18,
        width: 60,
        backgroundColor: '#fff',
        borderRadius: '0 0 30px 2px',
        marginTop: -14,
        position: 'relative',
        zIndex: 1,
    },
    saltoInfo: {
        [theme.breakpoints.up('md')]: {
            width: 250,
        },
        '& small': {
            fontFamily: "'Quicksand', sans-serif",
            paddingLeft: 15,
            color: theme.palette.info.pale
        }
    },
    saltoDescricao: {
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 300,
        color: '#fff',
        fontSize: '1rem',
        lineHeight: 1.2,
        paddingLeft: 15,
        paddingTop: 15,
        '& b': {
            fontWeight: 600,
        }
    },
    saltoEsquerda: {
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-end',
        }
    },
    posicaoInfo: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.up('md')]: {
            width: 250,
        },
    },
    posicaoInfoVertical: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up('sm')]: {
            marginTop: 20,
        }
    },
    grupoSaltosWrap: {
        marginTop: 20,
        marginBottom: 40,
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'noWrap',

    },
    btnGrupoSalto: {
        minWidth: 45,
        height: 55,
        fontSize: '1.1rem',
        borderRadius: 10,
        border: '2px solid #fff',
        margin: 3,
        color: '#fff',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 300,
        textTransform: 'unset',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.2rem',
        },
        [theme.breakpoints.up('md')]: {
            height: 80,
            width: 66,
            marginLeft: 10,
            marginRight: 10,
            fontSize: '2rem'
        },
        '&:before': {
            content: "''",
            height: 0,
            width: 0,
            borderLeft: '2px solid transparent',
            borderBottom: '2px solid transparent',
            position: 'absolute',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            bottom: 0,
            transform: 'rotate(-45deg)',
            transition: 'all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        }
    },
    btnGrupoSaltoActive: {
        backgroundColor: theme.palette.primary.darker,
        fontWeight: 600,
        '&:before': {
            content: "''",
            height: 12,
            width: 12,
            borderLeft: '2px solid #fff',
            borderBottom: '2px solid #fff',
            borderBottomLeftRadius: 4,
            bottom: -8,
            left: 0,
            right: 0,
            textAlign: 'center',
            backgroundColor: theme.palette.primary.darker,
            [theme.breakpoints.up('sm')]: {
                bottom: -8,
            },
            [theme.breakpoints.up('md')]: {
                height: 15,
                width: 15,
                bottom: -10,
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.darker,
            [theme.breakpoints.up('md')]: {
                backgroundColor: theme.palette.primary.dark,
            },
            '&:before': {
                backgroundColor: theme.palette.primary.darker,
                [theme.breakpoints.up('md')]: {
                    backgroundColor: theme.palette.primary.dark,
                }
            }
        }
    },
    menuSalto: {
        padding: theme.spacing(1.2),
        marginTop: theme.spacing(2),
        boxShadow: '0px 10px 20px #00000029',
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: 12,
        [theme.breakpoints.up('md')]: {
            minWidth: 200
        }
    },
    tituloMenu: {
        color: theme.palette.primary.darker,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        textTransform: 'uppercase',
        textAlign: 'center',
        fontSize: '.9rem'
    },
    Divider: {
        backgroundColor: `${theme.palette.primary.light} !important`,
        marginBottom: '.5rem'
    },
    menuContent: {
        padding: theme.spacing(0, 1.2),
        '& .MuiTypography-body1': {
            color: theme.palette.primary.dark80,
            textAlign: 'center',
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 'bold',
            fontSize: '1rem'
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 2),
            '& .MuiTypography-body1': {
                fontSize: '1.5rem'
            },
        }
    }, 
    SectionDivider: {
        marginBottom: 30,
    }
}))