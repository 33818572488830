import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.dark80,
        paddingTop: 30,
        position: 'relative',
        zIndex: 5,
        paddingBottom: 60,
        borderRadius: '0 0 30px 30px'
    },
    container: {
        margin: '30px 0'
    },
    btnWrapper: {
        display:'flex',
    },
    svgBtn: {
        width: '90%',
        margin:'auto',
        marginBottom: 20,
        '& svg': {
            fontSize: '9rem'
        },
        [theme.breakpoints.up('lg')]: {
            width: 'unset',
            marginBottom: 0,
        }
    },
    textWhite: {
        fontFamily: "'Quicksand', sans-serif",
        color: '#FFFDEE',
        fontSize: '1.2rem',
        marginBottom: '0.5em',
    },
    section: {
        margin: '30px 0',
        [theme.breakpoints.up('lg')]: {
            margin: '50px 0',
        }
    },
}));

