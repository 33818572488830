import React, { useEffect, useState } from "react";
import TransparenciaSection from "../home/sections/Transparencia";
import { Container, Paper, Typography } from "@material-ui/core";
import { useStyles } from "./style";
import QuemSomos from "./sections/QuemSomos";
import ConselhoAdministrativo from "./sections/ConselhoAdministrativo";
import ConselhoNotorio from "./sections/ConselhoNotorio";
import ApoieSection from "../home/sections/Apoie";
import NossosHeroisSobre from "./sections/NossosHerois";
import ClubesAssociados from "./sections/ClubesAssociados";
import { gestaoApi } from "../../services/api";
import { Helmet } from "react-helmet";
import Arenas from "../competicoes/sections/Arenas";

export default function PageSobre() {
    const classes = useStyles();
    const [estabelecimento, setEstabelecimento] = useState([]);
    const [loadingEstabelecimento, setLoadingEstabelecimento] = useState(true);


    useEffect(() => {
        setLoadingEstabelecimento(true)
        async function getClubes() {
            await gestaoApi.get('estabelecimento', {
                params: {
                    flag_del: 0,
                    flg_ativo: 1,
                    "estabelecimentoStatus.status": "A",
                    // "estabelecimentoStatus.data_validade": "2021-12-31",
                    id_estabelecimento_tipo: 3,
                    pageSize: 12
                },
            }).then((response) => {
                setEstabelecimento(response.data.items)
                // console.log(response.data.items)
                if (response.data._meta.totalCount > 0) {
                    setLoadingEstabelecimento(false)
                }

            }).catch((err) => {
                console.log(err)
            });
        }
        getClubes()
    }, [])

    const [atletas, setAtletas] = useState({
        error: false,
        loading: true,
        payload: [],
    });

    useEffect(() => {
        setAtletas({
            error: false,
            loading: true,
            payload: [],
        })
        async function getAtletas() {
            await gestaoApi.get('selecao', {
                params: {
                    flag_del: 0,
                    expand: 'atletas,atleta',
                    pageSize: 12
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setAtletas({
                        error: true,
                        loading: false,
                        payload: [],
                    })
                } else {
                    setAtletas({
                        error: false,
                        loading: false,
                        payload: response.data.items,
                    })
                }
            }).catch((err) => {
                console.log(err)
                setAtletas({
                    error: true,
                    loading: false,
                    payload: [],
                })
            });
        }
        getAtletas()
    }, [])

    return (
        <React.Fragment>
            <Helmet>
                <title>Sobre - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais</title>
                <meta name="description" content="Sobre - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais" />
            </Helmet>
            <div className={classes.root}>
                <Container>
                    <div className={classes.headingWrapper}>
                        <Typography variant="h1" className={classes.subHeading}>
                            Sobre a
                        </Typography>
                        <Typography variant="h1" className={classes.heading}>
                            Saltos Brasil
                        </Typography>
                    </div>
                </Container>
            </div>
            <Paper className={classes.darkSection}>
                <Paper className={classes.sectionConselho}>
                    <Container className={classes.conselhoContainer}>
                        <QuemSomos />
                    </Container>
                    <Container maxWidth="md" style={{textAlign: "justify"}}>
                        <ConselhoAdministrativo />
                    </Container>
                </Paper>
                <Container>
                    <ConselhoNotorio />
                </Container>
                <ApoieSection />
                <NossosHeroisSobre atletas={atletas} />
                <ClubesAssociados estabelecimento={estabelecimento} loading={loadingEstabelecimento} encontreClubeHidden={true} />
            </Paper>
            <Arenas />

            <TransparenciaSection />
        </React.Fragment>
    )
}
