import { Box, Card, Grid, Typography } from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { governancaApi } from "../../../services/api";
import CardItemBoletim from "../../noticias/sections/components/CardItemBoletim";
import { useStyles } from "../styles/Noticias";

export default function BoletinsSections(props) {
  const { searchTerm } = props;
  const classes = useStyles();
  const sectionTop = useRef(null);
  const [page, setPage] = useState(1);
  const [boletins, setBoletins] = useState({
    error: false,
    loading: true,
    payload: [],
    meta: {},
  });

  useEffect(() => {
    setBoletins({
      error: false,
      loading: true,
      payload: [],
      meta: {},
    });

    async function getBoletins() {
      await governancaApi
        .get("arquivo-documento", {
          params: {
            sort: "created_at",
            arrayLike: `descricao:${searchTerm}`,
            page,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setBoletins({
              error: true,
              loading: false,
              payload: [],
              meta: {},
            });
          } else {
            setBoletins({
              error: false,
              loading: false,
              payload: response.data.items,
              meta: {
                pageCount: response.data._meta.pageCount,
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setBoletins({
            error: true,
            loading: false,
            payload: [],
            meta: {},
          });
        });
    }

    getBoletins();
  }, [searchTerm, page]);

  const handleChange = (event, value) => {
    setPage(value);
    if (value === 1) {
      executeScroll();
    }
  };

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 200,
    });

  return (
    <Box>
      <div className={classes.cardHeaderLabel}>
        <Typography variant="h2" className={classes.CardTitle}>
          Boletins
        </Typography>
      </div>

      <Grid container spacing={2}>
        {boletins.payload.map((item) => (
          <Grid item xs={12} sm={6} md={4} key={item.id}>
            <Card className={classes.CardBoletim}>
              <CardItemBoletim boletim={item} nomeArquivo={item.descricao} />
            </Card>
          </Grid>
        ))}
      </Grid>
      {boletins.error && (
        <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
      )}
      {boletins.meta && boletins.meta.pageCount > 1 && (
        <div className={classes.paginationContainer}>
          <Pagination
            page={page}
            count={boletins.meta.pageCount}
            onChange={handleChange}
          />
        </div>
      )}
    </Box>
  );
}
