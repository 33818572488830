export const escopo = [
    {
        label: 'Nacional',
        value: 'Nacional'
    },
    {
        label: 'Internacional',
        value: 'Internacional'
    },
    {
        label: 'Estadual',
        value: 'Estadual'
    }
]