import _ from "lodash"

export const formataNotas = (notas) => {

    let notasA = notas[0].split(';')
    let _notasA = notas[0].split(';')

    let mapA = notasA.map((item) => {
        let a = item.replace(',', '.')
        return (parseFloat(a))
    })
    let totalA = mapA.reduce(
        (prevValue, currentValue) => prevValue + (currentValue), 0
    );

    let notasB = ''
    let totalB = 0
    let mapB = ''
    if (notas[1]) {
        notasB = notas[1].split(';')
        mapB = notasB.map((item) => {
            let a = item.replace(',', '.')
            return (parseFloat(a))
        })

        totalB = mapB.reduce(
            (prevValue, currentValue) => prevValue + (currentValue), 0
        );
    }

    let totalSalto = totalA - totalB;

    let testeA = []

    if (notasB) {
        let indexes = []
        notasB.forEach((element, i) => {
            if (i > 0) {
                _notasA[indexes[i - 1]] = null
            }
            indexes.push(_notasA.findIndex(val => val === element))
        })
        // console.log(notasB)
        // console.log(indexes)

        notasA.forEach((element, i) => {
            if (_.indexOf(indexes, i) !== -1) {
                testeA.push({ nota: element, stroke: true, index: i })
            } else {
                testeA.push({ nota: element, stroke: false, index: i })
            }
        })

    } else {
        notasA.forEach(element => {
            testeA.push({ nota: element, stroke: false, index: null })
        })
    }

    return ({
        notasA,
        notasB,
        totalSalto,
        notasSaltos: testeA
    })

}