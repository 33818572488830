import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.secondary.dark80,
        paddingTop: 30,
        position: 'relative',
        zIndex: 5,
        paddingBottom: 100,
        borderRadius: '0 0 30px 30px',
        overflow: 'hidden',
    },
    Heading: {
        marginTop: 10,
        color: theme.palette.secondary.light,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 400,
        fontSize: '1.5rem',
        marginBottom:30,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.7rem',
            marginBottom:50,
        }, 
    },
    section: {
        margin: '50px 0'
    },
    ContainerNoticias: {
        marginTop: 20,
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            marginTop: 30,
            marginBottom: 30,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 50,
            marginBottom: 50,
        }
    },
}));