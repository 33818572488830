export const siglasEstados = [
    { descricao: 'AC' },
    { descricao: 'AL' },
    { descricao: 'AP' },
    { descricao: 'AM' },
    { descricao: 'BA' },
    { descricao: 'CE' },
    { descricao: 'DF' },
    { descricao: 'ES' },
    { descricao: 'GO' },
    { descricao: 'MA' },
    { descricao: 'MT' },
    { descricao: 'MS' },
    { descricao: 'MG' },
    { descricao: 'PA' },
    { descricao: 'PB' },
    { descricao: 'PR' },
    { descricao: 'PE' },
    { descricao: 'PI' },
    { descricao: 'RJ' },
    { descricao: 'RN' },
    { descricao: 'RS' },
    { descricao: 'RO' },
    { descricao: 'RR' },
    { descricao: 'SC' },
    { descricao: 'SP' },
    { descricao: 'SE' },
    { descricao: 'TO' },
]