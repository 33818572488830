import {
  Avatar,
  Box,
  Card,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "../styles/Transparencia";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { getCardIcon } from "../../transparencia/CardIcons";
import { CreateMenusTransparencia } from "../../../assets/data/transparencia";
import { useEffect, useState } from "react";
import { governancaApi } from "../../../services/api";

export default function TransparenciaSection() {
  const classes = useStyles();
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const matchesLG = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [menusTransparencia, setMenusTransparencia] = useState([]);

  useEffect(() => {
    async function FetchMenus() {
      await governancaApi
        .get("menu-governanca", { params: { id_pai: 2, sort: "titulo" } })
        .then((response) => {
          if (response.data._meta.totalCount > 0) {
            const menus = CreateMenusTransparencia(response.data.items);
            setMenusTransparencia(menus);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    FetchMenus();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.blueBrackground}></div>
      <div className={classes.sectionTop}></div>
      <div className={classes.purpleGradient}></div>
      <div className={classes.purpleGradient2}></div>
      <div className={classes.blueGradient}></div>

      <Container className={classes.contentContainer}>
        <Typography className={classes.title}>Transparência</Typography>

        {matchesLG ? (
          <Box className={classes.FlexContainer}>
            {menusTransparencia.map((item, key) => (
              <Box key={key} sx={{ m: 2 }}>
                <Link
                  to={`/transparencia?q=${item.slug}`}
                  className={classes.linkTransparencia}
                >
                  <Card
                    className={clsx(
                      classes.cardItem,
                      classes.cardItemHorizontal
                    )}
                  >
                    <Avatar>{getCardIcon(item.icon)}</Avatar>
                    <Typography className={classes.cardTitle}>
                      {item.title}
                    </Typography>
                  </Card>
                </Link>
              </Box>
            ))}
          </Box>
        ) : (
          <Grid
            container
            spacing={matchesMD ? 2 : 1}
            justifyContent="flex-start"
          >
            {menusTransparencia.map((item, key) => (
              <Grid key={key} item xs={12} sm={12} md={12} lg={2}>
                <Link
                  to={`/transparencia?q=${item.slug}`}
                  className={classes.linkTransparencia}
                >
                  <Card
                    className={clsx(
                      classes.cardItem,
                      classes.cardItemHorizontal
                    )}
                  >
                    <Avatar>{getCardIcon(item.icon)}</Avatar>
                    <Typography className={classes.cardTitle}>
                      {item.title}
                    </Typography>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </div>
  );
}
