import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.warning.main,
        minHeight: '100vh',
        zIndex: 10,
        position: 'relative',
    },
    cardContainer: {
        paddingBottom: 100,
        paddingTop: 100,
        marginBottom: 100
    },

    darkSection: {
        maxWidth: '100%',
        overflow: 'hidden',
        marginTop: -20,
        paddingTop: 50,
        marginBottom: -70,
        paddingBottom: 100,
        background: `linear-gradient(180deg, ${theme.palette.info.dark100} 0%, ${theme.palette.primary.dark80} 2%, ${theme.palette.primary.dark80} 100%)`,
        borderRadius: '30px 30px 80px 11px',
        minHeight: '100vh',
        zIndex: 10,
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            marginTop: -100,
        }
    },
}))