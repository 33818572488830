import { Container, Grid, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SectionTitle from "../../components/SectionTitle";
import { gestaoApi, governancaApi, portalApi } from "../../services/api";
import TransparenciaSection from "../home/sections/Transparencia";
import AcompanheTreinos from "../noticias/sections/AcompanheTreinos";
import CardBoletim from "../noticias/sections/CardBoletim";
import EventoSection from "../noticias/sections/Eventos";
import InstagramSection from "../noticias/sections/Instagram";
import NoticiasRecentes from "../noticias/sections/NoticiasRecentes";
import ProximaCompeticao from "../noticias/sections/ProximaCompeticao";
import RedesSociais from "../noticias/sections/RedesSociais";
import NoticiaSection from "./sections/NoticiaSection";
import { useStyles } from "./style";

export default function NoticiaLeitura() {
    const classes = useStyles();
    const { id, slug } = useParams();
    const [noticia, setNoticia] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (id) {
            setError(false)
            setLoading(true)
            async function getNoticia() {
                await portalApi.get('noticia', {
                    params: {
                        id: id,
                        expand: 'user,idPessoa',
                    },
                }).then((response) => {
                    if (response.data._meta.totalCount === 0) {
                        setError(true)
                    } else {
                        setNoticia(response.data.items[0]);
                    }
                    setLoading(false)
                });
            }
            getNoticia();
        }
    }, [id])


    const [noticias, setNoticias] = useState([]);
    const [loadingNoticias, setLoadingNoticias] = useState(true);
    const [errorNoticias, setErrorNoticias] = useState(false);

    useEffect(() => {
        setLoadingNoticias(true)
        setErrorNoticias(false)
        async function getNoticias() {
            await portalApi.get('noticia', {
                params: {
                    flag_del: 0,
                    flag_publicado: 1,
                    id_estabelecimento: 1,
                    sort: '-created_at',
                    lessField: 'created_at',
                    lessValue: (new Date()).getTime().toString().substr(0, 10),
                    pageSize: 4
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorNoticias(true)
                } else {
                    setNoticias(response.data.items)
                    setLoadingNoticias(false)
                    setErrorNoticias(false)
                }
            }).catch((err) => {
                setErrorNoticias(true)
                console.log(err)
            });
        }
        getNoticias()
    }, [])

    const [eventos, setEventos] = useState([]);
    const [loadingEventos, setLoadingEventos] = useState(true);
    const [errorEventos, setErrorEventos] = useState(false);

    useEffect(() => {
        setLoadingEventos(true)
        setErrorEventos(false)
        async function getEventos() {
            await gestaoApi.get('evento', {
                params: {
                    flag_del: 0,
                    id_estabelecimento: 1,
                    sort: '-data_inicio',
                    pageSize: 12
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorEventos(true)
                } else {
                    setEventos(response.data.items)
                    setLoadingEventos(false)
                    setErrorEventos(false)
                }
            }).catch((err) => {
                setErrorEventos(true)
                console.log(err)
            });
        }
        getEventos()
    }, [])

     

    const [boletins, setBoletins] = useState([]); 
    const [loadingBoletins, setLoadingBoletins] = useState(true);
    const [errorBoletins, setErrorBoletins] = useState(false);

    useEffect(() => {
        setLoadingBoletins(true)
        setErrorBoletins(false)
        async function getEventos() {
            await governancaApi.get('arquivo-documento', {
                params: {
                    sort: 'created_at',
                    pageSize: 1,
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorBoletins(true)
                    setLoadingBoletins(true)
                } else {
                    setBoletins(response.data.items) 
                    setLoadingBoletins(false)
                    setErrorBoletins(false)
                }
            }).catch((err) => {
                setErrorBoletins(true)
                console.log(err)
            });
        }
        getEventos()
    }, [])


    return (
        <div>
            <div className={classes.root}>
                <Container>
                    <Typography variant="h1" className={classes.Heading}>Notícias</Typography>
                    <div className={classes.section}>
                        <NoticiaSection noticia={noticia} slug={slug} loading={loading} error={error} noticias={noticias} loadingNoticias={loadingNoticias} />
                    </div>
                    <RedesSociais />
                    <SectionTitle variant="body1" color="infoLight">Recentes:</SectionTitle>
                    <NoticiasRecentes fullSection={true} noticias={noticias} loadingNoticias={loadingNoticias} errorNoticias={errorNoticias} />
                    <div className={classes.section}>
                        <SectionTitle variant="body1" color="errorLight">Eventos:</SectionTitle>
                        <EventoSection eventos={eventos} loadingEventos={loadingEventos} errorEventos={errorEventos} />
                    </div>
                    <Grid container className={classes.section}>
                        <Grid item xs={12} sm={12} md={9} lg={9}>
                            <SectionTitle variant="body1" color="infoLight">Último Boletim:</SectionTitle>
                            <CardBoletim boletins={boletins} loadingBoletins={loadingBoletins} errorBoletins={errorBoletins} />
                        </Grid>
                    </Grid>
                </Container>
                <ProximaCompeticao onlyCompeticao={true} eventos={eventos} loadingEventos={loadingEventos} errorEventos={errorEventos} />
                <Container>
                    <div className={classes.section}>
                        <SectionTitle variant="body1" color="infoLight">Acompanhe os treinos da seleção:</SectionTitle>
                        <AcompanheTreinos />
                    </div>
                </Container>
                <InstagramSection />


            </div>
            <TransparenciaSection />
        </div>
    )
}