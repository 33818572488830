import { makeStyles } from "@material-ui/core";
import campeonato_bg from '../../../assets/images/bg_campeonato.webp'
export const useStyles = makeStyles((theme) => ({
    root: {

        background: `url(${campeonato_bg})`,
        backgroundColor: theme.palette.secondary.dark80,
        backgroundRepeat: 'no-repeat',
        paddingTop: 30,
        position: 'relative',
        zIndex: 5,
        borderRadius: '0 0 40px 4px',
        backgroundSize: '250%',
        backgroundPositionX: '-130px',
        [theme.breakpoints.up('md')]: {
            backgroundAttachment: 'fixed',
            backgroundSize: '100%',
            backgroundPositionX: 'unset',
            borderRadius: ' 0px 0px 30px 30px',
            overflow: 'hidden'
        },
        '& section': {
            margin: '40px 0',
            [theme.breakpoints.up('md')]: {
                margin: '60px 0',
            }
        }
    },
    // content: {
    //     marginTop: 80,
    // },
    blurContainer: {
        backdropFilter: 'blur(30px)',
        background: `linear-gradient(180deg, transparent 0%, ${theme.palette.primary.dark80} 20% ,${theme.palette.secondary.dark100} 100%)`,
    },
    firstCard: {
        position: 'relative',
        zIndex: 10,
    },
    lastSection: {
        paddingBottom: 60,
        marginBottom: '0px !important',
        [theme.breakpoints.up('md')]: {
            paddingBottom: 100,
        },
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 150,
        }
    },
    containerMD: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        }
    }
}))