import React, { useEffect, useState, useRef } from "react";
import { Alert, Pagination } from "@material-ui/lab";
import { gestaoApi } from "../../../services/api";

import { Box, Paper, Typography } from "@material-ui/core";
import EventoSection from "../../noticias/sections/Eventos";
import { useStyles } from "../styles/Eventos";
import clsx from "clsx";

export default function PesquisaEventos(props) {
  const { searchTerm } = props;
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [eventos, setEventos] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const sectionTop = useRef(null);

  useEffect(() => {
    setLoading(true);
    setError(false);
    if (searchTerm) {
      async function getEventos() {
        await gestaoApi
          .get("evento", {
            params: {
              flag_del: 0,
              sort: "-data_inicio",
              lessField: "id_tipo",
              lessValue: 4,
              descricao: searchTerm,
              pageSize: 12,
              page: page,
            },
          })
          .then((response) => {
            setEventos(response.data.items);
            setTotalPages(response.data._meta.pageCount);
            if (response.data._meta.totalCount === 0) {
              setError(true);
            }
            setLoading(false);
          })
          .catch((err) => {
            setEventos([]);
            setTotalPages(1);
            setError(true);
            setLoading(false);
            console.log(err);
          });
      }
      getEventos();
    }
  }, [searchTerm, page]);

  const handleChange = (event, value) => {
    setPage(value);
    if (value === 1) {
      executeScroll();
    }
  };

  useEffect(() => {
    if (page > 1) {
      executeScroll();
    }
  }, [page]);

  const executeScroll = () => window.scrollTo({ behavior: "smooth", top: sectionTop.current.offsetTop - 150 });

  return (
    <Box>
      <div ref={sectionTop}></div>
      <div className={classes.root}>
        <div className={classes.cardTop}></div>
        <Paper className={clsx(classes.noticiasWrapper, { [classes.noticiasWrapperError]: error })}>
          <div className={clsx(classes.cardHeaderLabel, { [classes.cardHeaderLabelError]: error })}>
            <Typography variant="h2" className={classes.CardTitle}>
              Eventos
            </Typography>
          </div>
          {error ? (
            <div className={classes.errorContainer}>
              <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
            </div>
          ) : (
            <EventoSection gridLayout={true} eventos={eventos} loadingEventos={loading} errorEventos={error} />
          )}
          {totalPages > 1 && (
            <div className={classes.paginationContainer}>
              <Pagination page={page} count={totalPages} onChange={handleChange} />
            </div>
          )}
        </Paper>
        <div className={classes.cardBottom}></div>
      </div>
    </Box>
  );
}
