import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";

// import _ from "lodash";
import React, { useEffect, useState } from "react";
import { formatDecimalNum } from "../../../util/formatString";
import { calculaNJ } from "../functions/calculaNJ";
import { calculaSalto } from "../functions/calculaSalto";
import { formataNotas } from "../functions/formataNotas";
import { useStyles } from "../styles/TabelaSaltos";

export default function TabelaSaltos(props) {
  const { eventoAtleta, isSincronizado } = props;
  const classes = useStyles();
  const [eventoAtletaNotas, setEventoAtletaNotas] = useState([]);

  useEffect(() => {
    if (eventoAtleta) {
      let saltosAtelta = eventoAtleta.boletimSaltos.map((item) => {
        if (item.notas_arbitros) {
          let notas;
          let Exec;
          let Sinc;

          let notasSaltos;
          let notasSaltosSinc;

          if (isSincronizado) {
            notas = item.notas_arbitros.split("/");
            Exec = notas[0].replace("Exec:", "").replace("EXEC:", "").split("|");
            if (notas[1]) {
              Sinc = notas[1].replace("Sinc:", "").replace("SINC:", "").split("|");
              notasSaltosSinc = [formataNotas(Exec), formataNotas(Sinc)];
            } else {
              notasSaltosSinc = [formataNotas(Exec)];
            }
          } else {
            notasSaltos = formataNotas(item.notas_arbitros.split("|"));
          }

          return {
            ...item,
            ...notasSaltos,
            notasSaltosSinc,
          };
        } else {
          return { ...item };
        }
      });
      setEventoAtletaNotas(saltosAtelta);
    }
  }, [eventoAtleta, isSincronizado]);

  const calculaNotaFinal = (index) => {
    let teste = eventoAtleta.boletimSaltos.map((item) =>
      item.nota_final ? item.nota_final : 0
    );
    let sliced = teste.slice(0, index);
    let total = sliced.reduce(
      (prevValue, currentValue) => prevValue + parseFloat(currentValue),
      0
    );

    return total.toFixed(2);
  };

  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const ProvaIndividual = () => {
    return eventoAtletaNotas.map((item, key) => (
      <React.Fragment key={key}>
        <TableRow className={classes.topRow}>
          <TableCell>
            <div className="first">{key + 1}</div>
          </TableCell>
          <TableCell>
            <div className="second">
              {item.tipoSalto ? item.tipoSalto.codigo : ""}
              {item.posicao ? item.posicao : ""}
            </div>
          </TableCell>

          <TableCell>
            <div className="middle">
              {formatDecimalNum(
                calculaSalto(item, isSincronizado).toString(),
                true
              )}
            </div>
          </TableCell>
          <TableCell>
            <div
              className="middle"
              style={{ color: "red", fontWeight: "bold", minHeight: "1.2rem" }}
            >
              {item.nota_penalidade === "0.00" ? "" : item.nota_penalidade}
            </div>
          </TableCell>
          <TableCell>
            <div className="middle">{item.num_gd}</div>
          </TableCell>
          <TableCell>
            <div className="last">{item.nota_final ? item.nota_final : 0}</div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={4}>
            <Table size="small" className={classes.tabelaPontuacaoJ}>
              <TableHead>
                <TableRow>
                  {(item.notasA ? item.notasA : Array.from(new Array(5))).map(
                    (item, key) => (
                      <TableCell key={key}>J{key + 1}</TableCell>
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {item.notasSaltos
                    ? item.notasSaltos.map((item, key) => (
                      <TableCell
                        key={key}
                        className={clsx(classes.saltoTD, {
                          [classes.saltoCancelado]: item.stroke,
                        })}
                      >
                        <Typography variant="body2">
                          {item ? formatDecimalNum(item.nota) : ""}
                        </Typography>
                      </TableCell>
                    ))
                    : ""}
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
          <TableCell>
            <Typography variant="subtitle1" className={classes.pontuacaoTotal}>
              {calculaNotaFinal(key + 1)}
            </Typography>
          </TableCell>
        </TableRow>
      </React.Fragment>
    ));
  };

  const ProvaSincronizada = () => {
    return eventoAtletaNotas.map((item, EANIndex) => (
      <React.Fragment key={EANIndex}>
        <TableRow className={classes.topRow}>
          <TableCell>
            <div className="first">{EANIndex + 1}</div>
          </TableCell>
          <TableCell>
            <div className="second">
              {item.tipoSalto ? item.tipoSalto.codigo : ""}
              {item.posicao ? item.posicao : ""}
            </div>
          </TableCell>

          <TableCell>
            <div className="middle">
              {formatDecimalNum(
                calculaSalto(item, isSincronizado).toString(),
                true
              )}
            </div>
          </TableCell>
          <TableCell>
            <div className="middle">{item.num_gd}</div>
          </TableCell>
          <TableCell>
            <div className="last">{item.nota_final ? item.nota_final : 0}</div>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell></TableCell>
          <TableCell colSpan={3}>
            <Table size="small" className={classes.tabelaPontuacaoJ}>
              <TableHead>
                <TableRow>
                  {item.notasSaltosSinc
                    ? Array.from(
                      new Array(calculaNJ(item.notasSaltosSinc))
                    ).map((item, index) => (
                      <TableCell key={index}>J{index + 1}</TableCell>
                    ))
                    : Array.from(new Array(5)).map((item, index) => (
                      <TableCell key={index}>J{index + 1}</TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {item.notasSaltosSinc
                    ? item.notasSaltosSinc.map((item, index) => (
                      <React.Fragment key={index}>
                        {item.notasSaltos.map((item, key) => (
                          <TableCell
                            key={key}
                            className={clsx(classes.saltoTD, {
                              [classes.saltoCancelado]: item.stroke,
                            })}
                          >
                            {key === 0 ? <span>E</span> : <span>S</span>}
                            <Typography variant="body2">
                              {/* {console.log('item.nota:',item.nota)} */}
                              {item ? formatDecimalNum(item.nota) : ""}
                            </Typography>
                          </TableCell>
                        ))}
                      </React.Fragment>
                    ))
                    : ""}
                </TableRow>
              </TableBody>
            </Table>
          </TableCell>
          <TableCell>
            <Typography variant="subtitle1" className={classes.pontuacaoTotal}>
              {calculaNotaFinal(EANIndex + 1)}
            </Typography>
          </TableCell>
        </TableRow>
      </React.Fragment>
    ));
  };

  return (
    <div className={classes.root}>
      <Table size="small" className={classes.table}>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell>SALTO</TableCell>
            <TableCell>{matchesMD ? "CÓDIGO" : "COD"}</TableCell>
            <TableCell>{matchesMD ? "PONTOS" : "PTS"}</TableCell>
            {!isSincronizado && (
              <TableCell>{matchesMD ? "PENALIDADE" : "PN"}</TableCell>
            )}
            <TableCell>{matchesMD ? "DIFICULDADE" : "GD"}</TableCell>
            <TableCell>{matchesMD ? "PONTOS TOTAIS" : "PTS Totais"}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {isSincronizado && eventoAtletaNotas[0]
            ? ProvaSincronizada()
            : ProvaIndividual()}
        </TableBody>
      </Table>
    </div>
  );
}
