import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        marginTop: -100,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0',
        boxShadow: 'none',
        backgroundColor: theme.palette.primary.dark80,
        backdropFilter: 'blur(30px)',
        textAlign: 'center',
        paddingTop: 50,
        paddingBottom: 50,
    },
    link: {
        color: '#fff',
        textTransform: 'uppercase',
        fontSize: '1rem',
        '& svg': {
            fontSize: '2.5rem',
        },
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            color: 'rgba(255, 255, 255, 0.8)',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
            '& svg': {
                fontSize: '3.5rem',
            },
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2rem',
            '& svg': {
                fontSize: '4rem',
            },
        },
    },
    heading: {
        color: '#fff',
        lineHeight: 1.2,
        fontSize: '1.25rem',
        fontFamily: 'Quicksand',
        textTransform: 'uppercase',
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        }
    },
    linkWrappers: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    }
}));