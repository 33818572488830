import { alpha, makeStyles, withStyles } from "@material-ui/core";
import { Accordion, AccordionSummary } from "@material-ui/core";

export const AccordionConta = withStyles({
  root: {
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
    "& .MuiAccordionDetails-root": {
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 500,
      "& p": {
        marginTop: ".3rem",
        marginBottom: ".3rem",
      },
    },
    "&.MuiAccordion-rounded": {
      borderRadius: "10px !important",
      boxShadow: "0px 10px 30px #006F7D59",
    },
  },
  expanded: {},
})(Accordion);

export const AccordionContaSummary = withStyles((theme) => ({
  root: {
    marginBottom: -1,
    minHeight: 56,
    "& .MuiAccordionSummary-expandIcon": {
      color: "#3E63FF",
    },
    "&$expanded": {
      minHeight: 56,
      "& .MuiAccordionSummary-expandIcon": {
        transform: "rotate(45deg)",
      },
    },
    "& .MuiTypography-body1": {
      fontWeight: "bold",
      textTransform: "uppercase",
      fontFamily: "'Quicksand', sans-serif",
      fontSize: "1.2rem",
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
}))(AccordionSummary);

export const useStyles = makeStyles((theme) => ({
  sectionTop: {
    height: 100,
  },
  root: {
    marginTop: -100,
    zIndex: 1,
    background: "linear-gradient(116deg, #E5FCFF 0%, #D4EEFF 100%)",
    minHeight: "60vh",
    display: "flex",
    flexDirection: "column",
    paddingBottom: 80,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 100,
    },
  },
  contentContainer: {
    zIndex: 3,
  },
  blueBrackground: {
    background: "#CCF9FF",
    position: "absolute",
    opacity: 0.25,
    top: 100,
    width: "100%",
    height: 568,
    zIndex: 0,
    [theme.breakpoints.up("md")]: {
      left: 0,
      top: 200,
      opacity: 0.2,
    },
    [theme.breakpoints.up("lg")]: {
      opacity: 0.25,
      left: "10%",
      top: 100,
    },
  },
  purpleGradient: {
    background:
      "radial-gradient(closest-side at 60% 50%, #8677F1 0%, #4938BF 79%, transparent  100%)",
    opacity: 0.45,
    position: "absolute",
    top: "70%",
    height: 663,
    width: 1805,
    right: "25%",
    [theme.breakpoints.up("sm")]: {
      top: "70%",
      left: -1235,
      right: 0,
    },
    [theme.breakpoints.up("lg")]: {
      opacity: 0.3,
      top: "73%",
      left: -1035,
      right: 0,
    },
  },
  purpleGradient2: {
    background:
      "radial-gradient(closest-side at 60% 50%, #8677F1 0%, #4938BF 79%, transparent  100%)",
    opacity: 0.1,
    position: "absolute",
    top: "73%",
    right: -1035,
    height: 663,
    width: 1805,
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  blueGradient: {
    background:
      "radial-gradient(closest-side at 50% 50%, #32E8FF 0%, #32E8FF 58%, #32E8FF00 100%)",
    opacity: 0.3,
    position: "absolute",
    top: "20%",
    left: -727,
    width: 1361,
    height: 568,
    [theme.breakpoints.up("sm")]: {
      top: "28%",
    },
    [theme.breakpoints.up("md")]: {
      left: -503,
      height: 672,
      width: 942,
      top: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      opacity: 0.25,
      height: 568,
      top: "28%",
    },
  },
  title: {
    marginTop: 30,
    color: theme.palette.secondary.dark80,
    fontFamily: "'Quicksand', sans-serif",
    fontSize: "2rem",
    marginBottom: 30,
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginBottom: 40,
      fontSize: "2.2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.3rem",
      marginBottom: 40,
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: 70,
      fontSize: "2.5rem",
    },
  },
  GridContainer: {
    width: "90%",
    margin: "auto",
    [theme.breakpoints.up("md")]: {
      width: "70%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90%",
    },
  },
  FlexContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  cardItem: {
    cursor: "pointer",
    width: "100%",
    height: "100%",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "#fff",
    padding: "0",
    borderRadius: "50px 20px 20px 50px",
    boxShadow: "0px 10px 30px #006F7D59",
    "& .MuiAvatar-root": {
      transition: "all 200ms ease-in-out",
      marginRight: 15,
      height: 60,
      width: 60,
      color: "#fff",
      backgroundColor: "#fff",
      boxShadow: "0px 0px 20px #1EB1C44F",
      marginBottom: "auto",
    },
    "& .MuiSvgIcon-root": {
      transition: "all 200ms ease-in-out",
      fontSize: "2.4em",
    },
    [theme.breakpoints.up("sm")]: {
      width: "75%",
      margin: "auto",
    },
    [theme.breakpoints.up("md")]: {
      width: "85%",
      margin: "auto",
      "& .MuiAvatar-root": {
        fontSize: "3em",
        height: 80,
        width: 80,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.4em",
      },
    },
  },
  cardItemHorizontal: {
    [theme.breakpoints.up("lg")]: {
      paddingBottom: 13,
      paddingTop: 13,
      justifyContent: "center",
      width: 120,
      height: 160,
      flexDirection: "column",
      borderRadius: "60px 60px 15px 15px",
      "& .MuiAvatar-root": {
        marginRight: 0,
        height: 100,
        width: 100,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.7em",
      },
    },
  },
  cardItemExpanded: {
    "& .MuiAvatar-root": {
      marginRight: 15,
      height: 80,
      width: 80,
    },
    "& .MuiSvgIcon-root": {
      fontSize: "3.4em",
    },
    [theme.breakpoints.up("md")]: {
      "& .MuiAvatar-root": {
        height: 100,
        width: 100,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.8em",
      },
    },
    [theme.breakpoints.up("lg")]: {
      "& .MuiAvatar-root": {
        height: 95,
        width: 95,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.7em",
      },
    },
  },
  cardItemDisabled: {
    cursor: "unset",
  },
  linkTransparencia: {
    textDecoration: "none",
  },
  cardTitle: {
    color: theme.palette.secondary.dark80,
    fontFamily: "'Quicksand', sans-serif",
    fontSize: "0.9rem",
    fontWeight: 900,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1.05rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "1.2rem",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "0 9px",
      fontSize: "0.85rem",
    },
  },
  cardTitleLG: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
    },
  },
  arquivoTitle: {
    width: "100%",
    textAlign: "left",
    fontFamily: "'Poppins', sans-serif",
    fontSize: "0.9rem",
    fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
      paddingLeft: 15,
      paddingRight: 15,
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.25rem",
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  Collapse: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      margin: "auto",
      maxWidth: "60%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "auto",
      maxWidth: "100%",
    },
  },
  CollapseContainer: {
    marginBottom: 15,
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  collapseContent: {
    marginTop: 10,
    marginBottom: 20,
    [theme.breakpoints.up("lg")]: {
      marginBottom: 40,
      paddingLeft: 20,
      paddingRight: 20,
      maxWidth: "85%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  Divider: {
    marginTop: 0,
    marginBottom: 16,
    backgroundColor: theme.palette.primary.main,
  },
  docTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },
  btnExpandAnexos: {
    color: "#3E63FF",
    transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    "& svg": {
      filter: "drop-shadow(-5px 5px 20px #004B7D63)",
    },
  },
  btnRotate: {
    transform: "rotateZ(45deg)",
  },
  AnexosCollapse: {
    "& .MuiCollapse-wrapperInner": {
      width: "100%",
      background: "#1F646E",
    },
  },

  anexosContainer: {
    borderRadius: 0,
    padding: theme.spacing(1.5, 3),
    boxShadow: "inset 0px 0px 12px -2px #1F646E",
    [theme.breakpoints.up("md")]: {
      boxShadow: "none",
    },
  },
  anexoWrapper: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 10,
    paddingTop: 10,
  },
  arquivoTitleInterno: {
    fontFamily: "'Poppins', sans-serif",
    fontSize: "0.95rem",
    marginLeft: 10,
    fontWeight: 500,
  },
  btnAnexo: {
    maxWidth: 100,
    borderColor: "#3E63FF",
    color: "#3E63FF",
    lineHeight: 1.2,
    fontFamily: "'Poppins', sans-serif",
    fontSize: "0.8rem",
    fontWeight: 600,
    "&:hover": {
      border: " 1px solid #3E63FF",
      backgroundColor: alpha("#3E63FF", 0.04),
    },
    "& svg": {
      color: "#3E63FF",
    },
  },
  errorContainer: {
    maxWidth: "90%",
    margin: "auto",
    marginTop: 10,
  },
  btnDetalhesPessoa: {
    color: "#3E63FF",
    border: "2px solid #3E63FF",
    width: 100,
    minWidth: 100,
    lineHeight: 1.1,
    textAlign: "left",
    fontFamily: "'Quicksand', sans-serif",
    fontSize: ".8rem",
    fontWeight: "bold",
    borderRadius: 3,
    padding: "5px",
    marginRight: 16,
    "& svg": {
      fontSize: 18,
      marginRight: 2,
    },
  },
  nomePessoa: {
    color: theme.palette.secondary.dark80,
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bold",
    lineHeight: 1.1,
  },
  pessoaRoot: {
    paddingTop: 30,
    [theme.breakpoints.up("md")]: {
      paddingTop: 50,
    },
  },
  TituloNomePessoa: {
    fontSize: "1.5rem",
    fontWeight: 800,
    fontFamily: "'Quicksand', sans-serif",
    color: theme.palette.secondary.dark80,
    marginBottom: 30,
    [theme.breakpoints.up("md")]: {
      fontSize: "1.8rem",
    },
  },
  pessoaInfoCard: {
    borderRadius: 10,
    boxShadow: "0px 10px 30px #006F7D59",
    padding: theme.spacing(2, 3),
    "& .MuiTypography-body1": {
      fontFamily: "'Quicksand', sans-serif",
      marginBottom: "1rem",
      fontSize: "1rem",
      fontWeight: 500,
      [theme.breakpoints.up("md")]: {
        fontSize: "1.25rem",
      },
    },
  },
  infoLabel: {
    fontFamily: "'Quicksand', sans-serif",
    color: "#3E63FF",
    fontSize: ".8rem",
    fontWeight: 700,
    marginBottom: "0px !important",
  },
  accordionsWrapper: {
    marginTop: 30,
  },
}));
