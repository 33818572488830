import { Avatar, Container, Grid, Paper, Typography } from "@material-ui/core";
import { useStyles } from "../style/ConselhoNotorio";
import ConselhoTopicos from "./ConselhoTopicos";

export default function ConselhoNotorio() {
    const classes = useStyles();
    const conselho = [
        {
            nome: 'Álvaro',
            sobrenome: 'Araújo Souza',
        },
        {
            nome: 'Lindberg',
            sobrenome: 'Aziz Cury Junior',
        },
        {
            nome: 'Ricardo',
            sobrenome: 'Leyser',
        },
    ]
    return (
        <Paper className={classes.root}>
            <Container maxWidth="md">
                <Typography variant="h2" className={classes.heading}>Conselho de Notórios</Typography>
                <Grid container>
                    {conselho.map((item, key) => (
                        <Grid key={key} item xs={12} sm={4}>
                            <div className={classes.conselhoItem}>
                                <Avatar className={classes.avatar} />
                                <div className={classes.nameWrapper}>
                                    <Typography className={classes.nome}>
                                        {item.nome}
                                    </Typography>
                                    <Typography className={classes.sobrenome}>
                                        {item.sobrenome}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <ConselhoTopicos />
            </Container>
        </Paper>
    )
}
