import React, { useEffect, useState } from 'react'
import { AppBar, Avatar, Toolbar } from "@material-ui/core";
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import CssBaseline from '@material-ui/core/CssBaseline';
import PropTypes from 'prop-types';
import SaltosBrasil from '../../../assets/icons/SaltosBrasil';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from '../styles/LiveNotify';

function ElevationScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 2 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
};


export default function HeaderLiveNotify(props) {
    const classes = useStyles();

    //scroll
    const [pageScrolled, setPageScrolled] = useState(false);

    const trigger = useScrollTrigger({
        disableHysteresis: true,
    });

    useEffect(() => {
        setPageScrolled(Boolean(trigger))
    }, [trigger])

    return (
        <React.Fragment>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar position="fixed" className={clsx(classes.Appbar, { [classes.AppbarScrolled]: pageScrolled })}>
                    <Toolbar className={classes.AppToolbar}>
                        <SaltosBrasil height={pageScrolled ? "20" : "35"} width={pageScrolled ? "45" : ""} />
                    </Toolbar>
                    <div className={clsx(classes.liveNotify, { [classes.liveNotifyScrolled]: pageScrolled })}>
                        <Avatar className={clsx(classes.liveImg, { [classes.liveImgScrolled]: pageScrolled })} />
                        <div className={classes.liveInfo}>
                            <Typography className={classes.liveTitle} variant="h6">Olimpíadas 2020: Semi Finais</Typography>
                            {pageScrolled ? '' :
                                <Typography className={classes.liveSubTitle} variant="subtitle1"><span style={{ fontWeight: 600 }}>EM 5 MIN:</span> Kawan Pereira nos 10m Individuais</Typography>
                            }
                        </div>
                    </div>
                </AppBar>
            </ElevationScroll>
            <Toolbar className={classes.Toolbar} />
        </React.Fragment >
    )
}