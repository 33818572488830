export const calculaNJ = (notasSaltosSinc) => {
    if (notasSaltosSinc[1]) {
        if (notasSaltosSinc[1].notasSaltos.length && notasSaltosSinc[0].notasSaltos.length) {
            return (notasSaltosSinc[1].notasSaltos.length + notasSaltosSinc[0].notasSaltos.length)
        } else {
            return (notasSaltosSinc[0].notasSaltos.length)
        }
    } else {
        return (notasSaltosSinc[0].notasSaltos.length)
    }
}