import { Box, Paper } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles/Pessoas";
import BoletinsSections from "./Boletins";
import DocOrgaosSection from "./DocOrgaos";

function DocumentosSection({ searchTerm }) {
  const classes = useStyles();

  return (
    <Box>
      <div className={classes.root}>
        <div className={classes.cardTop} />
        <Paper className={classes.noticiasWrapper}>
          <BoletinsSections searchTerm={searchTerm} />
          <DocOrgaosSection searchTerm={searchTerm} />
        </Paper>
        <div className={classes.cardBottom} />
      </div>
    </Box>
  );
}

export default DocumentosSection;
