import { SvgIcon } from "@material-ui/core";

export function SearchSVG() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
            <defs>
                <clipPath id="clip-ico---nav---busca">
                    <rect width="32" height="32" />
                </clipPath>
            </defs>
            <g id="ico---nav---busca" clipPath="url(#clip-ico---nav---busca)">
                <g id="ico---nav---busca-2" data-name="ico---nav---busca">
                    <g id="Ellipse_33" data-name="Ellipse 33" transform="translate(3 2.999)" fill="none" stroke="currentcolor" strokeWidth="2">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8" fill="none" />
                    </g>
                    <g id="Group_1311" data-name="Group 1311" transform="translate(-1 -1)">
                        <line id="Line_143" data-name="Line 143" x1="2" y1="2" transform="translate(20.5 20.5)" fill="none" stroke="currentcolor" strokeLinecap="round" strokeWidth="2" />
                        <line id="Line_144" data-name="Line 144" x1="5" y1="5" transform="translate(22.5 22.5)" fill="none" stroke="currentcolor" strokeLinecap="round" strokeWidth="4" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export function BarsSVG() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
            <defs>
                <clipPath id="clip-ico---nav---menu-open_1">
                    <rect width="32" height="32" />
                </clipPath>
            </defs>
            <g id="ico---nav---menu-open_1" data-name="ico---nav---menu-open – 1" clipPath="url(#clip-ico---nav---menu-open_1)">
                <g id="ico---nav---menu-open" transform="translate(4.001 4.001)">
                    <g id="burger" transform="translate(5 7.166)">
                        <rect id="Rectangle_49" data-name="Rectangle 49" width="14" height="2" rx="1" transform="translate(-0.001 -0.167)" fill="#001e32" />
                        <rect id="Rectangle_50" data-name="Rectangle 50" width="14" height="2" rx="1" transform="translate(-0.001 3.833)" fill="#001e32" />
                        <rect id="Rectangle_51" data-name="Rectangle 51" width="14" height="2" rx="1" transform="translate(-0.001 7.833)" fill="#001e32" />
                    </g>
                    <g id="Ellipse_33" data-name="Ellipse 33" transform="translate(-2.001 -2.001)" fill="none" stroke="#001e32" strokeWidth="2">
                        <circle cx="14" cy="14" r="14" stroke="none" />
                        <circle cx="14" cy="14" r="13" fill="none" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export function NoticiasSVG() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
            <defs>
                <clipPath id="clip-ico---nav---novidades_1">
                    <rect width="32" height="32" />
                </clipPath>
            </defs>
            <g id="ico---nav---novidades_1" data-name="ico---nav---novidades – 1" clipPath="url(#clip-ico---nav---novidades_1)">
                <g id="ico---nav---novidades" transform="translate(-2 -2)">
                    <g id="Rectangle_2636" data-name="Rectangle 2636" transform="translate(4 4)" fill="none" stroke="#001e32" strokeWidth="2">
                        <rect width="28" height="28" rx="6" stroke="none" />
                        <rect x="1" y="1" width="26" height="26" rx="5" fill="none" />
                    </g>
                    <g id="Rectangle_2641" data-name="Rectangle 2641" transform="translate(8 9)" fill="none" stroke="#001e32" strokeWidth="2">
                        <rect width="9" height="9" rx="3" stroke="none" />
                        <rect x="1" y="1" width="7" height="7" rx="2" fill="none" />
                    </g>
                    <rect id="Rectangle_49" data-name="Rectangle 49" width="9" height="2" rx="1" transform="translate(18 10)" fill="#001e32" />
                    <rect id="Rectangle_2642" data-name="Rectangle 2642" width="8" height="2" rx="1" transform="translate(18 14)" fill="#001e32" />
                    <rect id="Rectangle_2643" data-name="Rectangle 2643" width="18" height="2" rx="1" transform="translate(9 20)" fill="#001e32" />
                    <rect id="Rectangle_2644" data-name="Rectangle 2644" width="16" height="2" rx="1" transform="translate(9 24)" fill="#001e32" />
                </g>
            </g>
        </SvgIcon>
    )
}

export function TimesCircleSVG() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
            <g xmlns="http://www.w3.org/2000/svg" id="enlKYLJ2E8s2" clipPath="url(#enlKYLJ2E8s12)">
                <g id="enlKYLJ2E8s3">
                    <g id="enlKYLJ2E8s4" transform="matrix(1 0 0 1 4.00100000000000 4.00100000000000)">
                        <g id="enlKYLJ2E8s5" transform="matrix(1 0 0 1 5 7.16600000000000)">
                            <rect id="enlKYLJ2E8s6" width="14" height="2" rx="1" ry="1" transform="matrix(0.70710678118655 0.70710678118655 -0.70710678118655 0.70710678118655 2.75635931000000 -0.82385425000000)" fill="rgb(0,30,50)" stroke="none" strokeWidth="1" />
                            <rect id="enlKYLJ2E8s7" width="14" height="2" rx="1" ry="1" transform="matrix(0 0 0 0 6.99900000000000 4.12589322000000)" fill="rgb(0,30,50)" stroke="none" strokeWidth="1" opacity="0" />
                            <rect id="enlKYLJ2E8s8" width="14" height="2" rx="1" ry="1" transform="matrix(0.70710678118655 -0.70710678118655 0.70710678118655 0.70710678118655 1.34214575500005 9.07564069000002)" fill="rgb(0,30,50)" stroke="none" strokeWidth="1" /></g>
                        <g id="enlKYLJ2E8s9" transform="matrix(1 0 0 1 -2.00100000000000 -2.00100000000000)">
                            <circle id="enlKYLJ2E8s10" r="14" transform="matrix(1 0 0 1 14 14)" fill="none" stroke="none" strokeWidth="2" />
                            <circle id="enlKYLJ2E8s11" r="13" transform="matrix(1 0 0 1 14 14)" fill="none" stroke="rgb(0,30,50)" strokeWidth="2" />
                        </g></g>
                </g>
                <clipPath id="enlKYLJ2E8s12">
                    <rect id="enlKYLJ2E8s13" width="32" height="32" rx="0" ry="0" fill="rgb(0,0,0)" stroke="none" strokeWidth="1" />
                </clipPath>
            </g>
        </SvgIcon>
    )
}

export function LifeRingSVG() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
            <defs>
                <clipPath id="clip-ico---nav---apoie_1">
                    <rect width="32" height="32" />
                </clipPath>
            </defs>
            <g id="ico---nav---apoie_1" data-name="ico---nav---apoie – 1" clipPath="url(#clip-ico---nav---apoie_1)">
                <g id="ico---nav---apoie" transform="translate(-1.999 -2)">
                    <g id="Ellipse_33" data-name="Ellipse 33" transform="translate(3.999 4)" fill="none" stroke="#001e32" strokeWidth="2">
                        <circle cx="14" cy="14" r="14" stroke="none" />
                        <circle cx="14" cy="14" r="13" fill="none" />
                    </g>
                    <g id="Ellipse_34" data-name="Ellipse 34" transform="translate(11.999 12)" fill="none" stroke="#001e32" strokeWidth="2">
                        <circle cx="6" cy="6" r="6" stroke="none" />
                        <circle cx="6" cy="6" r="5" fill="none" />
                    </g>
                    <g id="cross-lines" transform="translate(8.083 8.084)">
                        <line id="Line_138" data-name="Line 138" x2="7" y2="7" fill="none" stroke="#001e32" strokeLinecap="round" strokeWidth="2" />
                        <line id="Line_141" data-name="Line 141" x2="7" y2="7" transform="translate(12.832 12.832)" fill="none" stroke="#001e32" strokeLinecap="round" strokeWidth="2" />
                        <line id="Line_139" data-name="Line 139" x1="7" y2="7" transform="translate(0 12.832)" fill="none" stroke="#001e32" strokeLinecap="round" strokeWidth="2" />
                        <line id="Line_140" data-name="Line 140" x1="7" y2="7" transform="translate(12.832)" fill="none" stroke="#001e32" strokeLinecap="round" strokeWidth="2" />
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}

export function CalendarSVG() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
            <defs>
                <clipPath id="clip-ico---nav---compete_1">
                    <rect width="32" height="32" />
                </clipPath>
            </defs>
            <g id="ico---nav---compete_1" data-name="ico---nav---compete – 1" clipPath="url(#clip-ico---nav---compete_1)">
                <g id="Icon_feather-calendar" data-name="Icon feather-calendar" transform="translate(4 4)">
                    <g id="Rectangle_2636" data-name="Rectangle 2636" transform="translate(-1 2)" fill="none" stroke="currentColor" strokeWidth="2">
                        <rect width="26" height="24" rx="4" stroke="none" />
                        <rect x="1" y="1" width="24" height="22" rx="3" fill="none" />
                    </g>
                    <rect id="Rectangle_2637" data-name="Rectangle 2637" width="24" height="2" transform="translate(0 9)" fill="currentColor" />
                    <rect id="Rectangle_2638" data-name="Rectangle 2638" width="4" height="8" rx="2" transform="translate(3 -1)" fill="currentColor" />
                    <rect id="Rectangle_2639" data-name="Rectangle 2639" width="4" height="8" rx="2" transform="translate(17 -1)" fill="currentColor" />
                </g>
            </g>
        </SvgIcon>
    )
}

export function HomeSVG() {
    return (
        <SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none">
            <defs>
                <clipPath id="clip-ico---nav---home_3">
                    <rect width="32" height="32" />
                </clipPath>
            </defs>
            <g id="ico---nav---home_3" data-name="ico---nav---home – 3" clipPath="url(#clip-ico---nav---home_3)">
                <g id="ico---nav---home">
                    <path id="Path_15167" data-name="Path 15167" d="M22.772,5.376v3.34L19.8,6.228a5.049,5.049,0,0,0-6.6,0L6.6,11.753a3.205,3.205,0,0,0-1.1,2.456V20.86C5.5,24.251,7.962,27,11,27H22c3.038,0,5.5-2.749,5.5-6.14V14.209a3.205,3.205,0,0,0-1.1-2.456l-.827-.826" transform="translate(-0.5)" fill="none" stroke="#001e32" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                    <circle id="Ellipse_35" data-name="Ellipse 35" cx="4" cy="4" r="4" transform="translate(12 13)" fill="#001e32" />
                </g>
            </g>
        </SvgIcon>
    )
}

export function HomeLogoSVG() {
    return (
        <SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none">
            <defs>
                <clipPath id="clip-ico---nav---home">
                    <rect width="40" height="40" />
                </clipPath>
            </defs>
            <g id="ico---nav---home" clipPath="url(#clip-ico---nav---home)">
                <g id="ico---nav---home-2" data-name="ico---nav---home" transform="translate(0 4)">
                    <path id="Path_15456" data-name="Path 15456" d="M258.6,77.166l.59-2.273-1.53-6.577.863-2.622-1.5-3.169-1.377.535h-.109l-6.282-.142-4.643-1.7-1.311.7-.809-.754-1.6-1.508,2.491-.459.754.994,3.671-.339,2.841.7,4.764-1.508.787-2.524-.153-5.015,4.512-1.9,4.512,1.9-.142,5.015.787,2.524,4.764,1.508,2.841-.7,3.671.339.754-.994,2.491.459-1.6,1.508-.809.754-1.311-.7-4.643,1.7-6.282.142h-.109l-1.377-.535-1.5,3.169.852,2.622-1.519,6.577.59,2.273-1.978,3.911Z" transform="translate(-240.572 -49.425)" fill="#161138" />
                    <path id="Path_15457" data-name="Path 15457" d="M257.895,48.339l4.36,1.836-.142,4.939.819,2.611.066.033,4.818,1.519,2.852-.71,3.737.339.743-.972,2.1.382-1.366,1.3-.732.677-1.278-.677-4.687,1.715-6.25.153h-.077l-1.475-.568-1.6,3.354.863,2.633-1.53,6.567.579,2.262-1.8,3.584h0l-1.8-3.584.579-2.262-1.53-6.567L256,64.269l-1.6-3.354-1.475.568h-.076l-6.261-.142-4.687-1.715-1.278.677-.732-.677-1.366-1.3,2.1-.382.743.972,3.737-.339,2.852.71,4.818-1.519.066-.033.819-2.611-.142-4.939Zm0-.339-.12.055-4.349,1.836-.2.087v.208l.142,4.884-.754,2.426-4.644,1.464-2.764-.688h-.1l-3.562.328-.645-.841-.12-.153-.186.033-2.1.382-.6.109.437.415,1.366,1.3.732.688.164.153.2-.109,1.158-.612,4.556,1.661.044.022h.055l6.261.142h.142l.055-.022,1.213-.47,1.42,2.983-.83,2.524-.022.087.022.087,1.5,6.479-.568,2.185-.033.109.055.109,1.8,3.584.273.546.273-.546,1.814-3.584.055-.109L260,75.643l-.568-2.185,1.508-6.49.022-.087-.022-.087-.83-2.513,1.409-2.983,1.213.47.055.022h.142l6.261-.142h.055l.044-.022,4.556-1.661,1.158.612.2.109.164-.153.732-.677,1.366-1.311.437-.415-.6-.109-2.1-.382-.186-.033-.12.153-.645.841-3.562-.328h-.1l-2.775.688-4.644-1.464-.754-2.426.142-4.884v-.219l-.2-.087-4.349-1.825Z" transform="translate(-237.9 -48)" fill="#161138" />
                    <path id="Path_15458" data-name="Path 15458" d="M568.379,155.078l.732-.678H567.1Z" transform="translate(-531.133 -142.775)" fill="#fd0" />
                    <path id="Path_15459" data-name="Path 15459" d="M568.379,155.078l.732-.678H567.1Z" transform="translate(-531.133 -142.775)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15460" data-name="Path 15460" d="M420.9,204.525v0Z" transform="translate(-400.905 -186.956)" fill="#fd0" />
                    <path id="Path_15461" data-name="Path 15461" d="M420.9,204.525v0Z" transform="translate(-400.905 -186.956)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15462" data-name="Path 15462" d="M420.9,209.117v0Z" transform="translate(-400.905 -191.231)" fill="#fd0" />
                    <path id="Path_15463" data-name="Path 15463" d="M420.9,209.117v0Z" transform="translate(-400.905 -191.231)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15464" data-name="Path 15464" d="M420.9,184.048v0Z" transform="translate(-400.905 -167.003)" fill="#fd0" />
                    <path id="Path_15465" data-name="Path 15465" d="M420.9,184.048v0Z" transform="translate(-400.905 -167.003)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15466" data-name="Path 15466" d="M425.927,90.6l-.809-2.623-.962-3.082-3.257.4,5.016,5.289Z" transform="translate(-400.906 -80.867)" fill="#fd0" />
                    <path id="Path_15467" data-name="Path 15467" d="M425.927,90.6l-.809-2.623-.962-3.082-3.257.4,5.016,5.289Z" transform="translate(-400.906 -80.867)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15468" data-name="Path 15468" d="M453.259,131.154l-.066-.022-.022-.011L451.5,130.4l2.262,1.552,4.317,1.29Z" transform="translate(-428.163 -121.397)" fill="#fd0" />
                    <path id="Path_15469" data-name="Path 15469" d="M453.259,131.154l-.066-.022-.022-.011L451.5,130.4l2.262,1.552,4.317,1.29Z" transform="translate(-428.163 -121.397)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15470" data-name="Path 15470" d="M425.916,93.889,420.9,88.6l3.344,4.568Z" transform="translate(-400.906 -84.163)" fill="#fd0" />
                    <path id="Path_15471" data-name="Path 15471" d="M425.916,93.889,420.9,88.6l3.344,4.568Z" transform="translate(-400.906 -84.163)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15472" data-name="Path 15472" d="M466.977,137.122l-.077-.022h.011Z" transform="translate(-441.879 -127.365)" fill="#fd0" />
                    <path id="Path_15473" data-name="Path 15473" d="M466.977,137.122l-.077-.022h.011Z" transform="translate(-441.879 -127.365)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15474" data-name="Path 15474" d="M420.9,88.6l2.186,6.12,1.158-1.552Z" transform="translate(-400.906 -84.163)" fill="#fd0" />
                    <path id="Path_15475" data-name="Path 15475" d="M420.9,88.6l2.186,6.12,1.158-1.552Z" transform="translate(-400.906 -84.163)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15476" data-name="Path 15476" d="M466.822,137.011h-.011L466.8,137Z" transform="translate(-441.79 -127.276)" fill="#fd0" />
                    <path id="Path_15477" data-name="Path 15477" d="M466.822,137.011h-.011L466.8,137Z" transform="translate(-441.79 -127.276)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15478" data-name="Path 15478" d="M420.9,51.1v4.1l3.257-.4Z" transform="translate(-400.906 -50.761)" fill="#fd0" />
                    <path id="Path_15479" data-name="Path 15479" d="M420.9,51.1v4.1l3.257-.4Z" transform="translate(-400.906 -50.761)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15480" data-name="Path 15480" d="M451.662,72.84l.142-4.94-1.1,1.858Z" transform="translate(-427.45 -65.725)" fill="#00707e" />
                    <path id="Path_15481" data-name="Path 15481" d="M451.662,72.84l.142-4.94-1.1,1.858Z" transform="translate(-427.45 -65.725)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15482" data-name="Path 15482" d="M425.261,52.936,420.9,51.1l3.257,3.694Z" transform="translate(-400.906 -50.761)" fill="#00707e" />
                    <path id="Path_15483" data-name="Path 15483" d="M425.261,52.936,420.9,51.1l3.257,3.694Z" transform="translate(-400.906 -50.761)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15484" data-name="Path 15484" d="M440.9,131.952h3.421l-2.262-1.552Z" transform="translate(-418.721 -121.397)" fill="#fd0" />
                    <path id="Path_15485" data-name="Path 15485" d="M440.9,131.952h3.421l-2.262-1.552Z" transform="translate(-418.721 -121.397)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15486" data-name="Path 15486" d="M567.1,140.683h2.011L568.379,139Z" transform="translate(-531.133 -129.057)" fill="#fd0" />
                    <path id="Path_15487" data-name="Path 15487" d="M567.1,140.683h2.011L568.379,139Z" transform="translate(-531.133 -129.057)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15488" data-name="Path 15488" d="M579.532,140.683l1.366-1.3L578.8,139Z" transform="translate(-541.554 -129.057)" fill="#fd0" />
                    <path id="Path_15489" data-name="Path 15489" d="M579.532,140.683l1.366-1.3L578.8,139Z" transform="translate(-541.554 -129.057)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15490" data-name="Path 15490" d="M425.938,97.649l.568-2.929h-3.421L420.9,88.6v3.923l4.951,5.126Z" transform="translate(-400.906 -84.163)" fill="#fd0" />
                    <path id="Path_15491" data-name="Path 15491" d="M425.938,97.649l.568-2.929h-3.421L420.9,88.6v3.923l4.951,5.126Z" transform="translate(-400.906 -84.163)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15492" data-name="Path 15492" d="M541,145.849l.536-.71L537.8,144.8Z" transform="translate(-505.035 -134.224)" fill="#fd0" />
                    <path id="Path_15493" data-name="Path 15493" d="M541,145.849l.536-.71L537.8,144.8Z" transform="translate(-505.035 -134.224)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15494" data-name="Path 15494" d="M471.885,138.83l-4.82-1.53,4.82,2.087L467,141.027l6.251-.142-1.366-1.5Z" transform="translate(-441.97 -127.543)" fill="#fd0" />
                    <path id="Path_15495" data-name="Path 15495" d="M471.885,138.83l-4.82-1.53,4.82,2.087L467,141.027l6.251-.142-1.366-1.5Z" transform="translate(-441.97 -127.543)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15496" data-name="Path 15496" d="M467.568,144.6,467,147.529l4.885-1.639Z" transform="translate(-441.969 -134.046)" fill="#fd0" />
                    <path id="Path_15497" data-name="Path 15497" d="M467.568,144.6,467,147.529l4.885-1.639Z" transform="translate(-441.969 -134.046)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15498" data-name="Path 15498" d="M524.2,156.116l4.688-1.716-2.033.076Z" transform="translate(-492.921 -142.775)" fill="#fd0" />
                    <path id="Path_15499" data-name="Path 15499" d="M524.2,156.116l4.688-1.716-2.033.076Z" transform="translate(-492.921 -142.775)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15500" data-name="Path 15500" d="M511.7,155.242l1.366,1.5,2.656-1.639Z" transform="translate(-481.785 -143.398)" fill="#fd0" />
                    <path id="Path_15501" data-name="Path 15501" d="M511.7,155.242l1.366,1.5,2.656-1.639Z" transform="translate(-481.785 -143.398)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15502" data-name="Path 15502" d="M420.9,234.738l1.224-1.825L420.9,227Z" transform="translate(-400.906 -207.446)" fill="#00707e" />
                    <path id="Path_15503" data-name="Path 15503" d="M420.9,234.738l1.224-1.825L420.9,227Z" transform="translate(-400.906 -207.446)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15504" data-name="Path 15504" d="M443.7,162.6Z" transform="translate(-421.214 -150.079)" fill="#fd0" />
                    <path id="Path_15505" data-name="Path 15505" d="M443.7,162.6Z" transform="translate(-421.214 -150.079)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15506" data-name="Path 15506" d="M420.9,153.8v2.535l2.492-1.574Z" transform="translate(-400.906 -142.241)" fill="#0072bc" />
                    <path id="Path_15507" data-name="Path 15507" d="M420.9,153.8v2.535l2.492-1.574Z" transform="translate(-400.906 -142.241)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15508" data-name="Path 15508" d="M423.392,162.6h0l-2.492,1.574,1.891,2.142Z" transform="translate(-400.906 -150.079)" fill="#0072bc" />
                    <path id="Path_15509" data-name="Path 15509" d="M423.392,162.6h0l-2.492,1.574,1.891,2.142Z" transform="translate(-400.906 -150.079)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15510" data-name="Path 15510" d="M422.124,206.1l.667-9.2-1.891,3.29Z" transform="translate(-400.906 -180.633)" fill="#00707e" />
                    <path id="Path_15511" data-name="Path 15511" d="M422.124,206.1l.667-9.2-1.891,3.29Z" transform="translate(-400.906 -180.633)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15512" data-name="Path 15512" d="M433.63,199.534l-.863-2.634-.667,9.2Z" transform="translate(-410.882 -180.633)" fill="#00707e" />
                    <path id="Path_15513" data-name="Path 15513" d="M433.63,199.534l-.863-2.634-.667,9.2Z" transform="translate(-410.882 -180.633)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15514" data-name="Path 15514" d="M420.9,301.811l1.8-3.574-1.8-.437Z" transform="translate(-400.906 -270.508)" fill="#00707e" />
                    <path id="Path_15515" data-name="Path 15515" d="M420.9,301.811l1.8-3.574-1.8-.437Z" transform="translate(-400.906 -270.508)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15516" data-name="Path 15516" d="M420.9,197.933l1.891-1.333-1.891.809Z" transform="translate(-400.906 -180.364)" fill="#0072bc" />
                    <path id="Path_15517" data-name="Path 15517" d="M420.9,197.933l1.891-1.333-1.891.809Z" transform="translate(-400.906 -180.364)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15518" data-name="Path 15518" d="M422.7,283.362l-.579-2.262-1.224,1.825Z" transform="translate(-400.906 -255.633)" fill="#00707e" />
                    <path id="Path_15519" data-name="Path 15519" d="M422.7,283.362l-.579-2.262-1.224,1.825Z" transform="translate(-400.906 -255.633)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15520" data-name="Path 15520" d="M422.791,166.316l-1.891,1.65v1.672l1.891-3.29,1.585-3.366-.984-.383Z" transform="translate(-400.906 -150.08)" fill="#00707e" />
                    <path id="Path_15521" data-name="Path 15521" d="M422.791,166.316l-1.891,1.65v1.672l1.891-3.29,1.585-3.366-.984-.383Z" transform="translate(-400.906 -150.08)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15522" data-name="Path 15522" d="M420.9,179.579v.372l1.891-.809L420.9,177v2.579Z" transform="translate(-400.906 -162.906)" fill="#0072bc" />
                    <path id="Path_15523" data-name="Path 15523" d="M420.9,179.579v.372l1.891-.809L420.9,177v2.579Z" transform="translate(-400.906 -162.906)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15524" data-name="Path 15524" d="M423.392,128.664h0l.984.382,1.475.579L420.9,124.5v3.2Z" transform="translate(-400.906 -116.142)" fill="#fd0" />
                    <path id="Path_15525" data-name="Path 15525" d="M423.392,128.664h0l.984.382,1.475.579L420.9,124.5v3.2Z" transform="translate(-400.906 -116.142)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15526" data-name="Path 15526" d="M517.755,145.849l-3.2-1.049-2.853,1.268Z" transform="translate(-481.787 -134.224)" fill="#fd0" />
                    <path id="Path_15527" data-name="Path 15527" d="M517.755,145.849l-3.2-1.049-2.853,1.268Z" transform="translate(-481.787 -134.224)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15528" data-name="Path 15528" d="M420.9,197.933v.317l1.891-1.65Z" transform="translate(-400.906 -180.364)" fill="#0072bc" />
                    <path id="Path_15529" data-name="Path 15529" d="M420.9,197.933v.317l1.891-1.65Z" transform="translate(-400.906 -180.364)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15530" data-name="Path 15530" d="M514.552,144.8l-2.853.71v.557Z" transform="translate(-481.785 -134.224)" fill="#fd0" />
                    <path id="Path_15531" data-name="Path 15531" d="M514.552,144.8l-2.853.71v.557Z" transform="translate(-481.785 -134.224)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15532" data-name="Path 15532" d="M420.9,183.873v0Z" transform="translate(-400.905 -167.003)" fill="#fd0" />
                    <path id="Path_15533" data-name="Path 15533" d="M420.9,183.873v0Z" transform="translate(-400.905 -167.003)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15534" data-name="Path 15534" d="M420.9,204v0Z" transform="translate(-400.905 -186.956)" fill="#fd0" />
                    <path id="Path_15535" data-name="Path 15535" d="M420.9,204v0Z" transform="translate(-400.905 -186.956)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15536" data-name="Path 15536" d="M420.9,208.844v0Z" transform="translate(-400.905 -191.231)" fill="#fd0" />
                    <path id="Path_15537" data-name="Path 15537" d="M420.9,208.844v0Z" transform="translate(-400.905 -191.231)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15538" data-name="Path 15538" d="M257.132,155.078l-.732-.678h2.011Z" transform="translate(-254.378 -142.775)" fill="#fd0" />
                    <path id="Path_15539" data-name="Path 15539" d="M257.132,155.078l-.732-.678h2.011Z" transform="translate(-254.378 -142.775)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15540" data-name="Path 15540" d="M420.9,204.525v0Z" transform="translate(-400.905 -186.956)" fill="#fd0" />
                    <path id="Path_15541" data-name="Path 15541" d="M420.9,204.525v0Z" transform="translate(-400.905 -186.956)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15542" data-name="Path 15542" d="M420.9,209.117v0Z" transform="translate(-400.905 -191.231)" fill="#fd0" />
                    <path id="Path_15543" data-name="Path 15543" d="M420.9,209.117v0Z" transform="translate(-400.905 -191.231)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15544" data-name="Path 15544" d="M420.9,184.048v0Z" transform="translate(-400.905 -167.003)" fill="#fd0" />
                    <path id="Path_15545" data-name="Path 15545" d="M420.9,184.048v0Z" transform="translate(-400.905 -167.003)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15546" data-name="Path 15546" d="M375,90.6l.809-2.623.962-3.082,3.246.4L375,90.594Z" transform="translate(-360.021 -80.867)" fill="#fd0" />
                    <path id="Path_15547" data-name="Path 15547" d="M375,90.6l.809-2.623.962-3.082,3.246.4L375,90.594Z" transform="translate(-360.021 -80.867)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15548" data-name="Path 15548" d="M335.02,131.154l.066-.022.011-.011,1.683-.721-2.262,1.552-4.317,1.29Z" transform="translate(-320.116 -121.397)" fill="#fd0" />
                    <path id="Path_15549" data-name="Path 15549" d="M335.02,131.154l.066-.022.011-.011,1.683-.721-2.262,1.552-4.317,1.29Z" transform="translate(-320.116 -121.397)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15550" data-name="Path 15550" d="M375,93.889l5.016-5.289-3.333,4.568Z" transform="translate(-360.021 -84.163)" fill="#fd0" />
                    <path id="Path_15551" data-name="Path 15551" d="M375,93.889l5.016-5.289-3.333,4.568Z" transform="translate(-360.021 -84.163)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15552" data-name="Path 15552" d="M374.3,137.122l.077-.022h-.011Z" transform="translate(-359.397 -127.365)" fill="#fd0" />
                    <path id="Path_15553" data-name="Path 15553" d="M374.3,137.122l.077-.022h-.011Z" transform="translate(-359.397 -127.365)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15554" data-name="Path 15554" d="M393.733,88.6l-2.175,6.12L390.4,93.168Z" transform="translate(-373.738 -84.163)" fill="#fd0" />
                    <path id="Path_15555" data-name="Path 15555" d="M393.733,88.6l-2.175,6.12L390.4,93.168Z" transform="translate(-373.738 -84.163)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15556" data-name="Path 15556" d="M374.9,137.011h.011V137Z" transform="translate(-359.931 -127.276)" fill="#fd0" />
                    <path id="Path_15557" data-name="Path 15557" d="M374.9,137.011h.011V137Z" transform="translate(-359.931 -127.276)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15558" data-name="Path 15558" d="M394.446,51.1v4.1l-3.246-.4Z" transform="translate(-374.451 -50.761)" fill="#fd0" />
                    <path id="Path_15559" data-name="Path 15559" d="M394.446,51.1v4.1l-3.246-.4Z" transform="translate(-374.451 -50.761)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15560" data-name="Path 15560" d="M381.242,72.84,381.1,67.9l1.1,1.858Z" transform="translate(-365.454 -65.725)" fill="#00707e" />
                    <path id="Path_15561" data-name="Path 15561" d="M381.242,72.84,381.1,67.9l1.1,1.858Z" transform="translate(-365.454 -65.725)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15562" data-name="Path 15562" d="M381.1,52.936l4.35-1.836L382.2,54.794Z" transform="translate(-365.454 -50.761)" fill="#00707e" />
                    <path id="Path_15563" data-name="Path 15563" d="M381.1,52.936l4.35-1.836L382.2,54.794Z" transform="translate(-365.454 -50.761)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15564" data-name="Path 15564" d="M373.121,131.952H369.7l2.262-1.552Z" transform="translate(-355.3 -121.397)" fill="#fd0" />
                    <path id="Path_15565" data-name="Path 15565" d="M373.121,131.952H369.7l2.262-1.552Z" transform="translate(-355.3 -121.397)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15566" data-name="Path 15566" d="M258.411,140.683H256.4l.732-1.683Z" transform="translate(-254.378 -129.057)" fill="#fd0" />
                    <path id="Path_15567" data-name="Path 15567" d="M258.411,140.683H256.4l.732-1.683Z" transform="translate(-254.378 -129.057)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15568" data-name="Path 15568" d="M245.266,140.683l-1.366-1.3L246,139Z" transform="translate(-243.244 -129.057)" fill="#fd0" />
                    <path id="Path_15569" data-name="Path 15569" d="M245.266,140.683l-1.366-1.3L246,139Z" transform="translate(-243.244 -129.057)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15570" data-name="Path 15570" d="M370.345,97.649l4.951-5.126V88.6l-2.175,6.12H369.7l.568,2.929Z" transform="translate(-355.3 -84.163)" fill="#fd0" />
                    <path id="Path_15571" data-name="Path 15571" d="M370.345,97.649l4.951-5.126V88.6l-2.175,6.12H369.7l.568,2.929Z" transform="translate(-355.3 -84.163)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15572" data-name="Path 15572" d="M270.435,145.849l-.536-.71,3.738-.339Z" transform="translate(-266.403 -134.224)" fill="#fd0" />
                    <path id="Path_15573" data-name="Path 15573" d="M270.435,145.849l-.536-.71,3.738-.339Z" transform="translate(-266.403 -134.224)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15574" data-name="Path 15574" d="M319.066,138.83l4.819-1.53-4.819,2.087,4.885,1.639-6.251-.142,1.366-1.5Z" transform="translate(-308.982 -127.543)" fill="#fd0" />
                    <path id="Path_15575" data-name="Path 15575" d="M319.066,138.83l4.819-1.53-4.819,2.087,4.885,1.639-6.251-.142,1.366-1.5Z" transform="translate(-308.982 -127.543)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15576" data-name="Path 15576" d="M334.517,144.6l.568,2.929L330.2,145.89Z" transform="translate(-320.116 -134.046)" fill="#fd0" />
                    <path id="Path_15577" data-name="Path 15577" d="M334.517,144.6l.568,2.929L330.2,145.89Z" transform="translate(-320.116 -134.046)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15578" data-name="Path 15578" d="M279.488,156.116,274.8,154.4l2.033.076Z" transform="translate(-270.767 -142.775)" fill="#fd0" />
                    <path id="Path_15579" data-name="Path 15579" d="M279.488,156.116,274.8,154.4l2.033.076Z" transform="translate(-270.767 -142.775)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15580" data-name="Path 15580" d="M297.422,155.242l-1.366,1.5L293.4,155.1Z" transform="translate(-287.336 -143.398)" fill="#fd0" />
                    <path id="Path_15581" data-name="Path 15581" d="M297.422,155.242l-1.366,1.5L293.4,155.1Z" transform="translate(-287.336 -143.398)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15582" data-name="Path 15582" d="M411.013,234.738l-1.213-1.825L411.013,227Z" transform="translate(-391.018 -207.446)" fill="#00707e" />
                    <path id="Path_15583" data-name="Path 15583" d="M411.013,234.738l-1.213-1.825L411.013,227Z" transform="translate(-391.018 -207.446)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15584" data-name="Path 15584" d="M398.2,162.6Z" transform="translate(-380.686 -150.079)" fill="#fd0" />
                    <path id="Path_15585" data-name="Path 15585" d="M398.2,162.6Z" transform="translate(-380.686 -150.079)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15586" data-name="Path 15586" d="M400.681,153.8v2.535l-2.481-1.574Z" transform="translate(-380.686 -142.241)" fill="#0072bc" />
                    <path id="Path_15587" data-name="Path 15587" d="M400.681,153.8v2.535l-2.481-1.574Z" transform="translate(-380.686 -142.241)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15588" data-name="Path 15588" d="M398.2,162.6h0l2.481,1.574-1.88,2.142Z" transform="translate(-380.686 -150.079)" fill="#0072bc" />
                    <path id="Path_15589" data-name="Path 15589" d="M398.2,162.6h0l2.481,1.574-1.88,2.142Z" transform="translate(-380.686 -150.079)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15590" data-name="Path 15590" d="M404.367,206.1l-.667-9.2,1.88,3.29Z" transform="translate(-385.585 -180.633)" fill="#00707e" />
                    <path id="Path_15591" data-name="Path 15591" d="M404.367,206.1l-.667-9.2,1.88,3.29Z" transform="translate(-385.585 -180.633)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15592" data-name="Path 15592" d="M395.8,199.534l.863-2.634.667,9.2Z" transform="translate(-378.548 -180.633)" fill="#00707e" />
                    <path id="Path_15593" data-name="Path 15593" d="M395.8,199.534l.863-2.634.667,9.2Z" transform="translate(-378.548 -180.633)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15594" data-name="Path 15594" d="M406.2,301.811l-1.8-3.574,1.8-.437Z" transform="translate(-386.208 -270.508)" fill="#00707e" />
                    <path id="Path_15595" data-name="Path 15595" d="M406.2,301.811l-1.8-3.574,1.8-.437Z" transform="translate(-386.208 -270.508)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15596" data-name="Path 15596" d="M405.58,197.933,403.7,196.6l1.88.809Z" transform="translate(-385.585 -180.364)" fill="#0072bc" />
                    <path id="Path_15597" data-name="Path 15597" d="M405.58,197.933,403.7,196.6l1.88.809Z" transform="translate(-385.585 -180.364)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15598" data-name="Path 15598" d="M404.4,283.362l.59-2.262,1.213,1.825Z" transform="translate(-386.208 -255.633)" fill="#00707e" />
                    <path id="Path_15599" data-name="Path 15599" d="M404.4,283.362l.59-2.262,1.213,1.825Z" transform="translate(-386.208 -255.633)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15600" data-name="Path 15600" d="M390.785,166.316l1.88,1.65v1.672l-1.88-3.29-1.585-3.366.984-.383Z" transform="translate(-372.669 -150.08)" fill="#00707e" />
                    <path id="Path_15601" data-name="Path 15601" d="M390.785,166.316l1.88,1.65v1.672l-1.88-3.29-1.585-3.366.984-.383Z" transform="translate(-372.669 -150.08)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15602" data-name="Path 15602" d="M405.58,179.579v.372l-1.88-.809L405.58,177v2.579Z" transform="translate(-385.585 -162.906)" fill="#0072bc" />
                    <path id="Path_15603" data-name="Path 15603" d="M405.58,179.579v.372l-1.88-.809L405.58,177v2.579Z" transform="translate(-385.585 -162.906)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15604" data-name="Path 15604" d="M378.07,128.664h0l-.984.382-1.486.579,4.951-5.126v3.2Z" transform="translate(-360.555 -116.142)" fill="#fd0" />
                    <path id="Path_15605" data-name="Path 15605" d="M378.07,128.664h0l-.984.382-1.486.579,4.951-5.126v3.2Z" transform="translate(-360.555 -116.142)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15606" data-name="Path 15606" d="M274.8,145.849,278,144.8l2.852,1.268Z" transform="translate(-270.767 -134.224)" fill="#fd0" />
                    <path id="Path_15607" data-name="Path 15607" d="M274.8,145.849,278,144.8l2.852,1.268Z" transform="translate(-270.767 -134.224)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15608" data-name="Path 15608" d="M405.58,197.933v.317l-1.88-1.65Z" transform="translate(-385.585 -180.364)" fill="#0072bc" />
                    <path id="Path_15609" data-name="Path 15609" d="M405.58,197.933v.317l-1.88-1.65Z" transform="translate(-385.585 -180.364)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15610" data-name="Path 15610" d="M304.1,144.8l2.852.71v.557Z" transform="translate(-296.867 -134.224)" fill="#fd0" />
                    <path id="Path_15611" data-name="Path 15611" d="M304.1,144.8l2.852.71v.557Z" transform="translate(-296.867 -134.224)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15612" data-name="Path 15612" d="M420.9,183.873v0Z" transform="translate(-400.905 -167.003)" fill="#fd0" />
                    <path id="Path_15613" data-name="Path 15613" d="M420.9,183.873v0Z" transform="translate(-400.905 -167.003)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15614" data-name="Path 15614" d="M420.9,204v0Z" transform="translate(-400.905 -186.956)" fill="#fd0" />
                    <path id="Path_15615" data-name="Path 15615" d="M420.9,204v0Z" transform="translate(-400.905 -186.956)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                    <path id="Path_15616" data-name="Path 15616" d="M420.9,208.844v0Z" transform="translate(-400.905 -191.231)" fill="#fd0" />
                    <path id="Path_15617" data-name="Path 15617" d="M420.9,208.844v0Z" transform="translate(-400.905 -191.231)" fill="none" stroke="#161138" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.2" />
                </g>
            </g>
        </SvgIcon>
    )
}