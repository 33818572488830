import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: alpha(theme.palette.info.darker, 0.4),
        borderRadius: 10,
        [theme.breakpoints.up('md')]: {
            padding: '0 60px'
        }
    },
    table: {
        '& td, & th': {
            padding: '0px !important',
            border: 'none',
            textAlign: 'center'
        }
    },


    topRow: {
        backgroundColor: alpha(theme.palette.info.darker, 0.2),
        borderRadius: 20,
        '& .second': {
            padding: '4px 6px',
            backgroundColor: alpha(theme.palette.info.darker, 0.3),
            borderRadius: '20px 0 0 20px',
        },
        '& td': {
            color: theme.palette.info.light,
            [theme.breakpoints.up('md')]: {
                fontSize: '1.05rem'
            }
        },
        '& td:first-child': {
            borderRadius: '20px 0 0 20px',
            color: "#fff",
        },

        '& .middle': {
            padding: '4px 6px',
            backgroundColor: alpha(theme.palette.info.darker, 0.3),
        },
        '& td:last-child': {
            backgroundColor: alpha(theme.palette.info.darker, 0.2),
            borderRadius: '0 20px 20px 0',
        },
        '& .last': {
            backgroundColor: alpha(theme.palette.info.darker, 0.6),
            borderRadius: 20,
            color: "#fff",
            padding: '4px 6px',
        },
    },
    pontuacaoTotal: {
        color: theme.palette.warning.pale,
        fontSize: theme.typography.pxToRem(20),
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 300,
        [theme.breakpoints.up('md')]: {
            fontSize: theme.typography.pxToRem(24),
        }
    },
    tabelaPontuacaoJ: {
        '& thead th': {
            color: theme.palette.info.dark
        },
        '& tbody td': {
            color: theme.palette.info.light
        }
    },
    saltoTD: {
        '& .MuiTypography-body2': {
            display: 'inline !important'
        },
        '& span': {
            fontSize: '.6rem',
            verticalAlign: 'super',
            marginRight: 1.5,
        },
    },
    saltoCancelado: { 
        '& p': {
            textDecoration: 'line-through',
        },
        color: `${theme.palette.error.light} !important`,
        opacity: 0.8

    }

}))