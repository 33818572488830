import { Container, Grid, Typography } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import SectionTitle from "../../components/SectionTitle";
import { gestaoApi, governancaApi, portalApi } from "../../services/api";
import TransparenciaSection from "../home/sections/Transparencia";
import AcompanheTreinos from "./sections/AcompanheTreinos";
import CardBoletim from "./sections/CardBoletim";
import CardNoticiaPrincipal from "./sections/CardNoticaPrincipal";
import EventoSection from "./sections/Eventos";
import InstagramSection from "./sections/Instagram";
import NoticiasRecentes from "./sections/NoticiasRecentes";
import ProximaCompeticao from "./sections/ProximaCompeticao";
import RedesSociais from "./sections/RedesSociais";
import TodosArtigos from "./sections/TodosArtigos";
import { useStyles } from "./styles";

import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import MateriasTab from "./sections/MateriasTab";
import CompeticoesTab from "./sections/CompeticoesTab";

export default function PageNoticias() {
    const classes = useStyles();

    const [noticias, setNoticias] = useState([]);
    // const [outrasNoticias, setOutrasNoticias] = useState([]);
    const [noticiaPrincipal, setNoticiaPrincipal] = useState([]);
    const [loadingNoticias, setLoadingNoticias] = useState(true);
    const [errorNoticias, setErrorNoticias] = useState(false);

    useEffect(() => {
        setLoadingNoticias(true)
        setErrorNoticias(false)
        async function getNoticias() {
            await portalApi.get('noticia', {
                params: {
                    flag_del: 0,
                    flag_publicado: 1,
                    id_estabelecimento: 1,
                    sort: '-created_at',
                    lessField: 'created_at',
                    colunas:
                        'id,titulo,created_at,resumo,id_coluna,foto_capa,id_estabelecimento,flag_destaque,id_esporte',
                    lessValue: (new Date()).getTime().toString().substr(0, 10),
                    pageSize: 12
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorNoticias(true)
                } else {
                    setNoticias(_.slice(response.data.items, 1, 4))
                    setNoticiaPrincipal(response.data.items[0])
                    setLoadingNoticias(false)
                    setErrorNoticias(false)
                }
            }).catch((err) => {
                setErrorNoticias(true)
                console.log(err)
            });
        }
        getNoticias()
    }, [])

    const [eventos, setEventos] = useState([]);
    const [loadingEventos, setLoadingEventos] = useState(true);
    const [errorEventos, setErrorEventos] = useState(false);

    useEffect(() => {
        setLoadingEventos(true)
        setErrorEventos(false)
        async function getEventos() {
            await gestaoApi.get('evento', {
                params: {
                    flag_del: 0,
                    id_estabelecimento: 1,
                    sort: '-data_inicio',
                    pageSize: 12
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorEventos(true)
                    setLoadingEventos(true)
                } else {
                    setEventos(response.data.items)
                    setLoadingEventos(false)
                    setErrorEventos(false)
                }
            }).catch((err) => {
                setErrorNoticias(true)
                console.log(err)
            });
        }
        getEventos()
    }, [])

    const [boletins, setBoletins] = useState([]);
    const [pageBoletins, setPageBoletins] = useState(1);
    const [totalPageBoletins, setTotalPageBoletins] = useState(1);
    const [loadingBoletins, setLoadingBoletins] = useState(true);
    const [errorBoletins, setErrorBoletins] = useState(false);

    useEffect(() => {
        setLoadingBoletins(true)
        setErrorBoletins(false)
        async function getEventos() {
            await governancaApi.get('arquivo-documento', {
                params: { 
                    documentos_flag_del: 0,
                    pageSize: 8,
                    sort: 'created_at',
                    page: pageBoletins
                },
            }).then((response) => {
                if (response.data._meta.totalCount === 0) {
                    setErrorBoletins(true)
                    setLoadingBoletins(true)
                } else {
                    setBoletins(response.data.items)
                    setTotalPageBoletins(response.data._meta.pageCount)
                    setLoadingBoletins(false)
                    setErrorBoletins(false)
                }
            }).catch((err) => {
                setErrorBoletins(true)
                console.log(err)
            });
        }
        getEventos()
    }, [pageBoletins])

    const [tabIndex, setTabIndex] = useState('1');

    const handleChangeTab = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleChangePageBoletim = (event, value) => {
        setPageBoletins(value);
    };

    return (
        <div>
            <Helmet>
                <title>Notícias - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais</title>
                <meta name="description" content="Notícias - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais" />
            </Helmet>
            <div className={classes.root}>
                <Container>
                    <Typography variant="h1" className={classes.Heading}>Notícias</Typography>

                    <TabContext value={tabIndex}>

                        <TabList onChange={handleChangeTab} className={classes.TabList}>
                            <Tab className={classes.Tab} label="Todas" value="1" />
                            <Tab className={classes.Tab} label="Matérias" value="2" />
                            <Tab className={classes.Tab} label="Competições" value="3" />
                            <Tab className={classes.Tab} label="Boletins" value="4" />
                        </TabList>

                        <TabPanel className={classes.TabPanel} value="1">
                            <div>
                                <Grid container spacing={2} className={classes.ContainerNoticias}>
                                    <Grid item xs={12} sm={12} md={12} lg={7}>
                                        <CardNoticiaPrincipal noticiaPrincipal={noticiaPrincipal} loadingNoticias={loadingNoticias} errorNoticias={errorNoticias} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={5}>
                                        <SectionTitle variant="body1" color="infoLight">Recentes:</SectionTitle>
                                        <NoticiasRecentes noticias={noticias} loadingNoticias={loadingNoticias} errorNoticias={errorNoticias} />
                                    </Grid>
                                </Grid>
                                <TodosArtigos />
                                <Grid container className={classes.section}>
                                    <Grid item xs={12} sm={12} md={9} lg={9}>
                                        <SectionTitle variant="body1" color="infoLight">Último Boletim:</SectionTitle>
                                        <CardBoletim boletins={boletins} loadingBoletins={loadingBoletins} errorBoletins={errorBoletins} />
                                    </Grid>
                                </Grid>
                            </div>
                        </TabPanel>
                        <TabPanel className={classes.TabPanel} value="2">
                            <MateriasTab />
                        </TabPanel>
                        <TabPanel className={classes.TabPanel} value="3">
                            <CompeticoesTab />
                        </TabPanel>
                        <TabPanel className={classes.TabPanel} value="4">
                            <CardBoletim boletinsSection={true} boletins={boletins} loadingBoletins={loadingBoletins} errorBoletins={errorBoletins}
                                handleChange={handleChangePageBoletim} totalPages={totalPageBoletins} page={pageBoletins} />
                        </TabPanel>
                    </TabContext>
 
                    <div className={classes.section}>
                        <SectionTitle variant="body1" color="errorLight">Eventos:</SectionTitle>
                        <EventoSection eventos={eventos} loadingEventos={loadingEventos} errorEventos={errorEventos} />
                    </div>
                    <RedesSociais />
                </Container>

                <ProximaCompeticao eventos={eventos} loadingEventos={loadingEventos} errorEventos={errorEventos} />

                <Container>
                    <div className={classes.section}>
                        <SectionTitle variant="body1" color="infoLight">Acompanhe os treinos da seleção:</SectionTitle>
                        <AcompanheTreinos />
                    </div>
                </Container>
                <InstagramSection />
            </div>
            <TransparenciaSection />
        </div>
    )
}