import { filter, orderBy } from "lodash";
import { CBSaltospi } from "../../../services/api";

const paramsFases = {
  f: {
    flag_fase_f: 1,
    lessField: "flag_del",
    lessValue: 3,
  },
  s: {
    flag_fase_s: 1,
    lessField: "flag_del",
    lessValue: 2,
  },
  e: {
    flag_fase_e: 1,
    lessField: "flag_del",
    lessValue: 1,
  },
};

export async function fetchEventoAtleta(props) {
  const { idEvento, idProva, faseAtual, tabIndexFormato } = props;
  const paramsFase = paramsFases[faseAtual];

  const res = await CBSaltospi.get("evento-atleta", {
    params: {
      id_evento: idEvento,
      expand:
        `boletimSaltosE,boletimSaltosF,boletimSaltosS,atleta,tipoSalto,notaTotalProva${faseAtual.toUpperCase()},atleta.atleta.estabelecimento`,
      id_evento_prova: idProva,
      ...paramsFase,
      pageSize: 200,
    },
  });
  const data = res.data.items;
  return handleEventoPartida({ faseAtual, data, tabIndexFormato });
}

function handleEventoPartida({ faseAtual, data, tabIndexFormato }) {
  let calculados = [];
  if (faseAtual === "e") {
    calculados = data.map((item, key) => ({
      ...item,
      boletimSaltos: item.boletimSaltosE,
      notaTotalProva: item.notaTotalProvaE,
    }));
  }
  if (faseAtual === "f") {
    calculados = data.map((item, key) => ({
      ...item,
      boletimSaltos: item.boletimSaltosF,
      notaTotalProva: item.notaTotalProvaF,
    }));
  }
  if (faseAtual === "s") {
    calculados = data.map((item, key) => ({
      ...item,
      boletimSaltos: item.boletimSaltosS,
      notaTotalProva: item.notaTotalProvaS,
    }));
  }

  let order = orderBy(calculados, [`colocacao_${faseAtual}`], ["asc"]);

  // orderna atletas de forma diferente quando é sincronizado

  if (tabIndexFormato === 1) {
    const atletas = [];

    order.forEach((element) => {
      if (element.id_atleta_par && element.flag_titular === 1) {
        let atletaPar = filter(order, function (o) {
          return o.id_atleta === element.id_atleta_par;
        });

        if (atletaPar[0]) {
          atletas.push({
            ...element,
            atleta: [element.atleta, atletaPar[0].atleta],
          });
        } else {
          atletas.push({
            ...element,
            atleta: [element.atleta],
          });
        }
      }
    });

    return atletas;
  }

  return order;
}
