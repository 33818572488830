import { Divider } from "@material-ui/core";
import { Instagram } from "@material-ui/icons";
import clsx from "clsx";
import TemplateLink from "../../../components/Links";
import { useStyles } from "../styles/Instagram";

export default function InstagramSection(props) {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, { [classes.rootPadding]: props.noPaddintTop })}>
            <Divider className={clsx(classes.divider, { [classes.dividerPadding]: props.noPaddintTop })} />
            <div className={classes.linkWrapper}>
                <div className={classes.linkInstagram}>
                    <TemplateLink color="white" href="https://www.instagram.com/saltosbrasil/" target="_blank" rel="noreferrer">
                        <Instagram /><br />
                        SIGA A CBSO NO INSTAGRAM
                    </TemplateLink>
                </div>
            </div>
        </div>
    )
}