import { Container, Grid, IconButton, Typography } from "@material-ui/core";
import { useStyles } from "./styles";
import DuvidasFrequentes from "../../pages/home/sections/DuvidasFrequentes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import texto_saltos_branco from "../../assets/images/texto_saltos_branco.png";
// import logoGov from "../../assets/images/logoGov.png";
import jogo_limpo from "../../assets/images/jogo_limpo.png";
import clinor from "../../assets/images/clinor.png";
import { LogoDesenhoSalto } from "../../assets/icons/LogoDesenhoSalto";
import LogoBigmidia from "../../assets/images/bigmidia_sm.webp";

export default function Footer() {
  const classes = useStyles();
  return (
    <div>
      <DuvidasFrequentes />
      <div className={classes.root}>
        <div className={classes.sectionTop}></div>
        <Container className={classes.contentContainer}>
          <Grid container justifyContent="center" spacing={4} style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <div className={classes.footerSaltosLogo}>
                <LogoDesenhoSalto />
                <img src={texto_saltos_branco} alt="saltos brasil" height="56" />
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <img src={jogo_limpo} alt="#jogolimpo" className={classes.jogoLimpo} />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <img src={clinor} alt="clinor" className={classes.clinor} />
            </Grid>
            {/* <Grid item xs={12}>
                    <div className={classes.govLogo}>
                        <img src={logoGov} alt="governo federal" style={{ maxWidth: '100%', maxHeight: 80 }} />
                    </div>
                </Grid> */}
          </Grid>

          <div className={classes.textWrapper}>
            <div className={classes.textContainer}>
              <Typography className={classes.textFooter}>
                SHCN CL 305, Bloco C, nº 34, 1º andar, Parte 1, Asa Norte, Brasília, DF. CEP: 70737-530 <br />
                Horário de atendimento: 08h às 18h Segunda a Sexta, Sábado das 08h às 12h.
                <br />
                Telefone: (61) 99956-7181
                <br />
                <a href="mailto:saltosbrasil@cbso.org.br" target="_blank" rel="noreferrer">
                  saltosbrasil@cbso.org.br
                </a>
                <br />
                <br />
                © 2017-2020 CBSO <br />
                CONFEDERAÇÃO BRASILEIRA DE SALTOS ORNAMENTAIS.
              </Typography>
            </div>
            <div className={classes.iconContainer}>
              <IconButton
                color="primary"
                href="https://www.instagram.com/saltosbrasil/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className={classes.footerIcon} icon={faInstagram} />
              </IconButton>
              <IconButton color="primary" href="https://www.facebook.com/saltosbrasil" target="_blank" rel="noreferrer">
                <FontAwesomeIcon className={classes.footerIcon} icon={faFacebook} />
              </IconButton>
            </div>

            <div className={classes.linkBigmidia}>
              <Typography>Sistema e Tecnologia</Typography>
              <a href="http://bigmidia.com/" target="_blank" rel="noreferrer" className={classes.sitedevLogo}>
                <img src={LogoBigmidia} alt="bigmidia" />
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
