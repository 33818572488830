import { Box, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "../styles/Beneficios";
import CardSocios from "./PagePrincipal/CardApoie";
// import imgPlaceholder from '../../../assets/images/img_notica.webp';

export default function ApoieSaltosSection() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.cardTop}></div>
      <Paper className={classes.root}>
        <div className={clsx(classes.container, classes.containerTop)}>
          <Typography className={classes.heading}>
            APOIE OS SALTOS ORNAMENTAIS
          </Typography>
          <Box sx={{ pb: 4 }}>
            <Typography variant="subtitle1">
              A Saltos Brasil é a mais nova Confederação Olímpica e é a única
              que não recebe nenhum recurso proveniente da Lei 13.756/2018, que
              destina um percentual arrecadado com as Loterias no país ao
              esporte olímpico brasileiro. Por isso, os saltos ornamentais
              precisam do seu apoio para continuar como um dos esportes que mais
              cresce no país em resultados e em praticantes.
            </Typography>
          </Box>
          <CardSocios />
        </div>
        {/* <img src={imgPlaceholder} alt="" className={classes.cardImg}/> */}
      </Paper>
      <div className={classes.cardBottom}></div>
    </div>
  );
}
