import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    search: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        overflow: 'hidden',
        position: 'relative',
        backgroundColor: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.common.white,
        },
        marginLeft: 0,
        borderRadius: 30,
        border: '1px solid #000',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(2),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 1.5),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
    },
    inputInput: {
        padding: theme.spacing(0.8, 1, 0.8, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(3.3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '20ch',
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        padding: 10,
        paddingBottom: 0,
        paddingTop: 0,
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'flex-end'
        },
    },
    menuSearch: {
        '& ::placeholder': {
            fontWeight: 500,
            opacity: 0.8,
        }
    },
    btnSearch: {
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        '&:hover': {
            backgroundColor: theme.palette.info.dark,
        }
    },
    textField: {
        '& .MuiInput-underline:after': {
            borderBottom: `2px solid ${theme.palette.info.main}`
        }
    },
    btnSearchArrow: {
        transform: 'translateX(30px)',
        transition: 'all 250ms ease-in-out'
    },
    btnSearchArrowShow:{
        transform: 'translateX(0px)',
    }
}));