import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  Root: {
    borderRadius: "10px",
    boxShadow: "5px 5px 15px rgb(0 111 125 / 20%)",
    "&:hover": {
      boxShadow: "2px 2px 6px rgb(0 111 125 / 20%)",
    },
  },
  CardActionRoot: {
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: theme.spacing(1.5),
  },
  Avatar: {
    marginRight: theme.spacing(1),
  },
  Nome: {
    color: theme.palette.primary.main,
    fontSize: ".9rem",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bolder",
    lineHeight: 1.2,
  },
}));
