import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        // minHeight: '200vh',
        marginTop: 20,
    },
    CardRoot: {
        backgroundColor: theme.palette.primary.darker,
        paddingTop: 5,
        borderRadius: 40,
    },
    CardRootSecondary: {
        paddingTop: 25,
        backgroundColor: theme.palette.secondary.darker,
    },
    cardTop: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(2, 3)
    },
    CardContent: {
        backgroundColor: theme.palette.primary.dark80,
        borderRadius: 40,
        minHeight: 200,
        padding: theme.spacing(3, 2.5),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(3, 3.5),
        }
    },
    CardContentSecondary: {
        backgroundColor: theme.palette.secondary.dark80,
    },
    btnDropdown: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 12,
        padding: theme.spacing(.2, 0.5, .2, 2),
        color: '#fff',
        fontSize: '1rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 400,
        textTransform: 'unset',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        },
        '& svg': {

            fill: theme.palette.info.light
        }
    },
    titulo: {
        color: theme.palette.info.light,
        fontSize: '1rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 900,
        marginBottom: 20,
        marginTop: 10,
    },
    tituloSecondary: {
        color: theme.palette.warning.light,
    },
    Alert: {
        '& .MuiSvgIcon-root': {
            fontSize: '3.5rem',
        },
        '& .MuiAlertTitle-root': {
            fontSize: '1.25rem',
        },
        '& .MuiAlert-message': {
            fontSize: '.9rem',
        }
    },
    customMenu: {
        '& .MuiPopover-paper, .MuiPopover-paper': {
            maxHeight: 'inherit !important'
        }
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
        '& .MuiPaginationItem-root': {
            fontSize: '1rem',
            fontWeight: 'bolder',
            fontFamily: "'Quicksand', sans-serif",
            color: theme.palette.primary.main
        },
        '& .MuiPaginationItem-page:hover': {
            backgroundColor: alpha(theme.palette.primary.light, 0.1),

        },
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: alpha(theme.palette.primary.light, 0.3),
            color: theme.palette.primary.main
        },

    },
}));