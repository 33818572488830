import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    card: {
        backdropFilter: 'blur(15px)',
        webkitBackdropFilter: ' blur(15px)',
        borderRadius: '48px 20px 20px 20px',
        [theme.breakpoints.up('md')]: {
            borderRadius: '65px 60px 30px 30px'
        }
    },
    cardTop: {
        position: 'relative',
        backgroundColor: alpha(theme.palette.primary.dark80, 0.8),
        color: theme.palette.warning.light,
        minHeight: 200,
        backdropFilter: 'blur(0px)',
        borderRadius: '48px 20px 20px 20px',
        [theme.breakpoints.up('md')]: {
            borderRadius: '65px 60px 30px 30px'
        }
    },
    cardTopCompeticao: {
        position: 'relative',
        backgroundColor: alpha(theme.palette.primary.dark80, 0.8),
        color: theme.palette.warning.light,
        minHeight: 200,
        backdropFilter: 'blur(0px)',
        borderRadius: 20,
        [theme.breakpoints.up('md')]: {
            borderRadius: 30
        }
    },
    cardContent: {
        background: theme.palette.primary.dark80,
        color: '#fff',
        minHeight: 250,
        borderRadius: 20,
        height: '100%',
        [theme.breakpoints.up('md')]: {
            borderRadius: 30,
        }
    },
    gridContainer: {
        height: '100%'
    },
    cardContentBottom: {
        background: theme.palette.secondary.dark100,
        color: '#fff',
        minHeight: '100%',
        borderRadius: 20,
        [theme.breakpoints.up('md')]: {
            borderRadius: 30,
        }
    },
    cardBottom: {
        marginTop: '-45px',
        borderRadius: 20,
        paddingTop: 60,
        paddingBottom: 10,
        background: alpha(theme.palette.secondary.pale, 0.75),
        textAlign: 'center',
        [theme.breakpoints.up('md')]: {
            borderRadius: 30,
        }
    },
    LinkEvento: {
        color: theme.palette.secondary.dark,
        fontFamily: "'Quicksand', sans-serif",
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.2rem',
        }
    },
    cardBody: {
        padding: '20px 15px',
        [theme.breakpoints.up('sm')]: {
            padding: '20px 35px',
        },
        [theme.breakpoints.up('md')]: {
            padding: '20px 40px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '20px 80px',
        }
    },
    cardHeading: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 15,
        padding: 4,
    },
    imgEventoBorder: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        background: `linear-gradient(to right, ${theme.palette.info.light}, ${theme.palette.primary.light}, ${theme.palette.primary.main})`,
        borderRadius: '50%',
        height: 86,
        width: 86,
        marginRight: 10,
        marginBottom: 'auto',
        [theme.breakpoints.up('md')]: {
            marginRight: 25,
            height: 128,
            width: 128,
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 50,
        }
    },
    imgContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

        marginBottom: 'auto'
    },
    btnLive: {
        zIndex: 10,
        color: '#000f11',
        borderRadius: 6,
        background: `radial-gradient(circle at 100% 248%, ${theme.palette.warning.light} 0%, ${theme.palette.info.light} 100%)`,
        border: `1px solid ${theme.palette.warning.pale}`,
        fontSize: '0.8rem',
        lineHeight: '1.5',
        padding: theme.spacing(0, 1.5),
        marginRight: 10,
        fontWeight: 'bold',
        fontFamily: 'Roboto,"sans-serif"',
        marginTop: '-10px',
        [theme.breakpoints.up('md')]: {
            marginRight: 25,
            fontSize: '0.9rem',
        },
        [theme.breakpoints.up('lg')]: {
            marginRight: 50,
            fontSize: '1rem',
        }
    },
    imgEvento: {
        height: 80,
        width: 80,
        color: '#fff',
        backgroundColor: '#fff',
        [theme.breakpoints.up('md')]: {
            height: 120,
            width: 120,
        },
    },
    cardHeadingTitle: {
        fontSize: '1.1rem',
        fontWeight: 'bold',
        marginBottom: '.3rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.4rem',
        }
    },
    cardHeadingLabel: {
        color: '#fff',
        fontSize: '.85rem',
        marginBottom: '1rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem',
        }
    },
    cardTitle: {
        color: theme.palette.info.light,
    },
    infoAtleta: {
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        [theme.breakpoints.up('md')]: {
            padding: '15px 10px',
        }
    },
    cardSubtitle: {
        color: theme.palette.warning.pale,
        marginBottom: 15
    },
    tabelaSalto: {
        borderTop: ` 2px solid ${theme.palette.info.light}`,
        '& tbody tr td': {
            padding: '6px 10px 0px 0px',
            color: theme.palette.info.pale,
            borderBottom: 'none',
        },
        '& .pts': {
            color: '#fff',
            fontWeight: 500,
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem'
            }
        },
        '& .ptsFinal': {
            color: theme.palette.info.light,
            fontWeight: 500,
            [theme.breakpoints.up('md')]: {
                fontSize: '1rem'
            }
        }
    },
    fotoAtleta: {
        height: 45,
        width: 45,
        border: `1px solid ${theme.palette.info.dark}`,
        background: '#fff',
        color: '#fff',
        [theme.breakpoints.up('md')]: {
            height: 60,
            width: 60,
        }
    },
    nomeWrapper: {
        marginLeft: 10,
        [theme.breakpoints.up('md')]: {
            marginLeft: 20,
        }
    },
    nomeAtleta: {
        fontWeight: 'bold',
        lineHeight: 1.2,
        fontSize: '0.9rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1rem',
        }
    },
    sobrenomeAtleta: {
        lineHeight: 1.2,
        fontWeight: 'bold',
        fontSize: '1.25rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '1.6rem',
        }
    },
    tabelaPodio: {
        '& tbody tr td:first-child': {
            color: theme.palette.warning.main
        },
        '& tbody tr td': {
            padding: '5px 10px 0px 0px',
            color: '#fff',
            borderBottom: 'none',
            paddingBottom: 10
        },
    },
    atletaPodio: {
        display: 'flex',
        alignItems: 'center',
        '& .nomeAtleta': {
            lineHeight: 1.2,
            fontSize: '0.75rem',
        },
        '& .sobrenomeAtleta': {
            lineHeight: 1.2,
            fontSize: '0.9rem',
        },
    },
    fotoAtletaPodio: {
        marginRight: 10,
        border: `1px solid ${theme.palette.info.dark}`,
        background: '#fff',
        color: '#fff',
    },
    ptsPodio: {
        color: '#010B1A',
        background: theme.palette.secondary.light,
        fontWeight: 'bolder',
        borderRadius: 17,
        padding: '0 12px'
    },
    cardFlex: {
        display: 'flex',
        flexDirection: 'column'
    },
    cardVideo: {
        padding: theme.spacing(1.5),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(1.5),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(2.5),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(4),
        }
    },
    liveMedia: {
        height: 200,
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: 10,
        margin: '10px auto 15px',
        [theme.breakpoints.up('sm')]: {
            height: 300,
        },
        [theme.breakpoints.up('md')]: {
            height: 360,
        },
        [theme.breakpoints.up('lg')]: {
            height: 185,
        }
    },
    liveTagWrapper: {
        position: 'absolute',
        left: 0,
        top: 5
    },
    CardMedia: {
        position: 'relative',
    },
    liveBtn: {
        backgroundColor: '#fff',
        color: theme.palette.secondary.dark,
        fontSize: '1.14rem',
        padding: theme.spacing(1.7),
        borderRadius: 30,

        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: '#fff'
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
        }
    },
    btnWrapper: {
        textAlign: 'center',
        marginTop: 'auto',
        display: 'none',
        [theme.breakpoints.up('lg')]: {
            display: 'block',

        }
    },
}));