import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2, 0),
        minHeight: '40vh',
        position: 'relative',
        background: `linear-gradient(180deg, #E5FCFF 0%, ${theme.palette.primary.white} 100%)`,
        borderRadius: 20,
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(4, 0),
        }
    },
    cardBottom: {
        marginTop: -30,
        height: 45,
        width: '70%',
        marginRight: 'auto',
        background: `linear-gradient(93deg, ${theme.palette.info.light} 0%, ${theme.palette.secondary.light} 100%)`,
        borderRadius: '4px 4px 50px 20px',
        [theme.breakpoints.up('md')]: {
            marginTop: -25,
            width: '80%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '90%',
            marginLeft: '2rem',
        }
    },
    TableHeading: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    titulo: {
        fontSize: '1rem',
        color: theme.palette.primary.darker,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        [theme.breakpoints.up('md')]: {
            fontSize: '1.5rem',
        },
    },
    Divider: {
        backgroundColor: theme.palette.primary.dark80,
        height: 2,
        margin: '10px 0',
    },
    btnPosicao: {
        padding: theme.spacing(0.5, 2.5),
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 'bold',
        background: alpha(theme.palette.info.light, 0.5),
        borderRadius: 12,
        fontSize: '.7rem',
        '&:hover': {
            background: alpha(theme.palette.info.light, 0.8),
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '.8rem',
        }
    },
    Table: {
        '& td': {
            color: '#161138',
            [theme.breakpoints.down('sm')]: {
                padding: 8,
            }
        },
        '& .MuiTableCell-root': {
            borderBottom: `1px solid #d4eeffa6`,
        }

    },
    TableRow: {
        cursor: 'pointer',
        position: 'relative'
    },
    AvatarWapper: {
        display: 'flex',
        alignItems: 'center'
    },
    CellAvatarSelected: {
        verticalAlign: 'baseline',
        borderRadius: '20px 0px 0px 20px',
        paddingLeft: '15px !important',
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 0,
            verticalAlign: 'middle'
        }
    },
    posicaoAtleta: {
        marginRight: 10,
        fontSize: '1.2rem',
        fontWeight: 500,
        color: '#000F11',
    },
    posicaoAtletaHidden: {
        display: 'none'
    },
    AvatarBorder: {
        marginRight: 9,
    },
    AvatarBorderSelected: {
        backgroundColor: theme.palette.info.light,
        paddingLeft: 16,
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        marginRight: 10,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 25,
            marginRight: 15,
        }
    },
    Avatar: {
        height: 36,
        width: 36,
        border: `1px solid ${theme.palette.primary.pale}`
    },
    AvatarSelected: {
        height: 66,
        width: 66,
        border: 'none'
    },
    AvatarClube: {
        height: 16,
        width: 16,
        marginRight: 9,
        color: '#fff',
        backgroundColor: '#fff',
        border: `1px solid ${theme.palette.info.pale}`
    },
    Nome: {
        lineHeight: 1.2,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        fontSize: '.75rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '.85rem',
        }
    },
    NomeSelected: {
        color: '#fff'
    },
    Sobrenome: {
        lineHeight: 1.2,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        fontSize: '0.85rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.25rem',
        }
    },
    SobrenomeSelected: {
        color: '#fff'
    },
    tableChip: {
        width: '80%',
        margin: 'auto',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 700,
        color: theme.palette.info.darker,
        backgroundColor: alpha(theme.palette.info.light, 0.2)
    },
    TableRowSelected: {
        background: `linear-gradient(180deg, #010B1A 0%, #001E32 100%) !important`,
        borderRadius: 20,
        '& td': {
            color: theme.palette.info.pale,
        },
        '& .MuiChip-root': {
            fontSize: '1.2rem',
            backgroundColor: theme.palette.info.light
        },
        [theme.breakpoints.up('sm')]: {
            borderRadius: 50,
        }
    },
    CheckboxCell: {
        display: 'none'
    },
    linkPerfil: {
        marginTop: 7,
        color: theme.palette.info.light,
        fontSize: '.75rem',
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
            marginTop: 15,
        }
    },
    titleInfoAtleta: {
        color: theme.palette.info.main,
        fontSize: '0.875rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        }
    },
    infoAtletaWrapper: {
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        }
    },
    infoAtleta: {
        color: theme.palette.info.pale,
        fontSize: '0.875rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        }
    },
    categoria: {
        color: theme.palette.warning.main
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
        '& .MuiPaginationItem-root': {
            fontSize: '1rem',
            fontWeight: 'bolder',
            fontFamily: "'Quicksand', sans-serif",
            color: theme.palette.secondary.main
        },
        '& .MuiPaginationItem-page:hover': {
            backgroundColor: alpha(theme.palette.secondary.light, 0.1),

        },
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: alpha(theme.palette.secondary.light, 0.3),
            color: theme.palette.secondary.main
        },
    },
    tableCellClassificacao: {
        minWidth: 100,
        borderRadius: '0px  20px 20px 0px',
        [theme.breakpoints.up('sm')]: {
            width: 160,
        }
    }
}))