import React from "react";
import moment from "moment";
export const formatDatePeriod = (start, end, isNumeric, short) => {
  let startString = moment(start).format("MM-DD-YYYY");
  let endString = moment(end).format("MM-DD-YYYY");

  let startMonth = moment(start).format("MM-YYYY");
  let endMonth = moment(end).format("MM-YYYY");

  let startYear = moment(start).format("YYYY");
  let endYear = moment(end).format("YYYY");

  if (startString === endString) {
    return <React.Fragment>{moment(start).format("DD/MM/YYYY")}</React.Fragment>;
  }

  if (startMonth === endMonth) {
    if (isNumeric && short) {
      return (
        <React.Fragment>
          {moment(start).format("DD")}&nbsp;a&nbsp;
          {moment(end).format("DD/MM")}
        </React.Fragment>
      );
    }
    if (isNumeric) {
      return (
        <React.Fragment>
          {moment(start).format("DD")}&nbsp;a&nbsp;
          {moment(end).format("DD/MM/YYYY")}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {moment(start).format("DD")}&nbsp;a&nbsp;
        {moment(end).format("DD [de] MMM [de] YYYY")}
      </React.Fragment>
    );
  }

  if (startYear === endYear) {
    if (isNumeric && short) {
      return (
        <React.Fragment>
          {moment(start).format("DD/MM")}
          &nbsp;a&nbsp;
          {moment(end).format("DD/MM")}
        </React.Fragment>
      );
    }
    if (isNumeric) {
      return (
        <React.Fragment>
          {moment(start).format("DD/MM")}
          &nbsp;a&nbsp;
          {moment(end).format("DD/MM/YYYY")}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {moment(start).format("DD [de] MMM")}
        &nbsp;a&nbsp;
        {moment(end).format("DD [de] MMM [de] YYYY")}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {moment(start).format("DD [de] MMM [de] YYYY")}
      &nbsp;a&nbsp;
      {moment(end).format("DD [de] MMM [de] YYYY")}
    </React.Fragment>
  );
};
