import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#E3E5EB',
        backdropFilter: 'blur(15px)',
        borderRadius: '20px 20px 20px 0px',
        position: 'relative',
        paddingTop: 30,
        paddingBottom: 30,
    },
    content: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        }
    },
    cardTop: {
        height: 40,
        marginBottom: -30,
        background: `linear-gradient(95deg, ${theme.palette.warning.pale} 0%, ${theme.palette.secondary.light} 100%)`,
        boxShadow: `0px 3px 6px ${theme.palette.primary.dark100}`,
        borderRadius: 23,
        width: '90%',
        margin: 'auto',
        [theme.breakpoints.up('lg')]: {
            height: 45,
            width: '95%',
        }
    },
    cardBottom: {
        height: 40,
        marginTop: -26,
        background: `linear-gradient(95deg, ${theme.palette.warning.pale} 0%, ${theme.palette.secondary.light} 100%)`,
        boxShadow: `0px 3px 6px ${theme.palette.primary.dark100}`,
        borderRadius: '4px 4px 60px 20px',
        width: '80%',
        [theme.breakpoints.up('lg')]: {
            height: 46,
            width: '75%',
        }

    },
    heading: {
        color: theme.palette.error.main,
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '1.5rem',
        fontWeight: 400,
        marginTop: 15,
        paddingLeft: theme.spacing(1.5),
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
            paddingLeft: 0,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 30,
            fontSize: '2.5rem',
        }
    },
    noticiasWrapper: {
        marginTop: 30
    },
    CardNoticiaRecente: {
        background: 'transparent',
        boxShadow: 'none',

    },
    cardSpacing: {
        padding: '10px',
        marginBottom: 5,
        [theme.breakpoints.up('sm')]: {
            padding: '10px',
            marginBottom: 0,
        },
        [theme.breakpoints.up('lg')]: {
            padding: '15px'
        }
    },
    CardMedia: {
        height: 100,
        position: 'relative',
        zIndex: 10,
        borderRadius: 10,
        boxShadow: '5px 5px 20px #006F7D40',
        [theme.breakpoints.up('sm')]: {
            height: 165,
        },

    },
    CardContent: {
        paddingTop: 0,
        paddingBottom: '0px !important',
        [theme.breakpoints.up('lg')]: {
            paddingTop: theme.spacing(2.5),
            paddingLeft: 0,
            paddingRight: 0,
        }
    },
    titulo: {
        fontWeight: 'bolder',
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.secondary.main,
        marginBottom: '0.3em',
        maxHeight: '3rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        lineHeight: 1.1,
        [theme.breakpoints.up('sm')]: {
            maxHeight: '3.5rem',
            fontSize: '1.2rem',
            lineHeight: 1.2,
        }
    },
    resumo: {
        fontWeight: 'bolder',
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '.85rem',
        maxHeight: '3.8rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        marginBottom: '0.3em',
        lineHeight: 1.1,
        [theme.breakpoints.up('sm')]: {
            maxHeight: '5rem',
            fontSize: '1rem',
            lineHeight: 1.2,
        }
    },
    data: {
        fontWeight: 'bolder',
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.info.main,
        fontSize: '.85rem'
    },
    paginationContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 30,
        '& .MuiPaginationItem-root': {
            fontSize: '1rem',
            fontWeight: 'bolder',
            fontFamily: "'Quicksand', sans-serif",
            color: theme.palette.error.main
        },
        '& .MuiPaginationItem-page:hover': {
            backgroundColor: alpha(theme.palette.error.light, 0.1),

        },
        '& .MuiPaginationItem-page.Mui-selected': {
            backgroundColor: alpha(theme.palette.error.light, 0.3),
            color: theme.palette.error.main
        },

    }
}));