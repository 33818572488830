import { Button, useMediaQuery } from "@material-ui/core";
import { Container, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../styles/Apoie";
import apoie_1600 from "../../../assets/images/apoie_1600.webp";
import apoie_2400 from "../../../assets/images/apoie_2400.webp";
import React from "react";
import clsx from "clsx";
import { Mail, WhatsApp } from "@material-ui/icons";
// import logoGov from '../../../assets/images/logoGov.png';
import jogo_limpo from "../../../assets/images/jogo_limpo.png";
import clinor_branco from "../../../assets/images/clinor_branco.png";

export default function ApoieSection(props) {
  const classes = useStyles();
  const { pageApoie } = props;

  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <div className={clsx(classes.root, { [classes.rootStright]: pageApoie })}>
      {!pageApoie && (
        <div className={classes.marcasWrapper}>
          <Container maxWidth="md">
            {/* <Grid container justifyContent="center" className={classes.topSection}>
                    <Grid item xs={12}>
                        <img src="/static/media/logoGov.009e20f2.png" alt="" />
                    </Grid>
                </Grid> */}
          </Container>
        </div>
      )}
      <div className={classes.contentWrapper}>
        <div className={classes.contentContainer}>
          <Grid container justifyContent="flex-end">
            <Grid
              item
              className={classes.textContainer}
              xs={12}
              sm={12}
              md={12}
              lg={pageApoie ? 6 : 5}
              xl={pageApoie ? 5 : 4}
            >
              <div className={clsx(classes.wrapper, { [classes.wrapperAbsolute]: pageApoie })}>
                {pageApoie ? (
                  <React.Fragment>
                    {/* <Typography variant="h2" className={clsx(classes.title, { [classes.titleWarning]: true })}>
                      patrocinadores
                    </Typography> */}
                    {/* <div className={clsx(classes.marcas, { [classes.logoPatrocinadores]: true })}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <div className={classes.imgWrapper}>
                                        <img src="/static/media/logoGov.009e20f2.png" alt="Secretaria Especial do Esporte" />
                                    </div>
                                </Grid> 
                            </Grid>
                        </div> */}
                    <Typography variant="h2" className={clsx(classes.title, { [classes.titleInfo]: true })}>
                      apoiadores
                    </Typography>
                    <div className={classes.marcas}>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <div className={classes.imgWrapper}>
                            <img src={jogo_limpo} alt="#jogolimpo" />
                          </div>
                        </Grid>
                        <Grid item>
                          <div className={classes.imgWrapper}>
                            <img src={clinor_branco} alt="Clinor" />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Typography variant="h2" className={classes.sectionTitle}>
                      Título para área de apoio
                    </Typography>
                    <Typography variant="body1" className={classes.sectionInfo}>
                      Intro aos formatos de patrocínio, diferenciais e quais benefícios / contrapartidas oferecidas
                      (geral).
                    </Typography>
                  </React.Fragment>
                )}
                <Grid container justifyContent="center">
                  <Grid item xs={12} sm={12} md={pageApoie ? 4 : 12} lg={12} className={classes.btnWrapper}>
                    <Button
                      className={clsx(classes.btnApoiePF, { [classes.btnMargin0]: pageApoie })}
                      variant="outlined"
                    >
                      APOIE COMO PESSOA FÍSICA
                    </Button>
                    <a href="mailto:saltosbrasil@cbso.org.br" className={classes.linkApoieEquipe}>
                      PATROCINE NOSSA EQUIPE
                    </a>
                  </Grid>
                  {pageApoie && (
                    <Grid item xs={12} sm={12} md={4} lg={12}>
                      <div>
                        <Typography className={classes.footerTitle}>Contato para apoios:</Typography>
                        <div className={classes.contatoWrapper}>
                          <Grid container justifyContent="center">
                            <Grid item>
                              <div className={classes.contatoItem}>
                                <WhatsApp /> <Typography>(61) 99956-7181</Typography>
                              </div>
                            </Grid>
                            <Grid item>
                              <div className={classes.contatoItem}>
                                <Mail />
                                <Typography>
                                  <a href="mailto:saltosbrasil@cbso.org.br">saltosbrasil@cbso.org.br</a>
                                </Typography>
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
            <Grid item className={classes.imgContainer} xs={12} sm={12} md={12} lg={6}>
              <div className={classes.imgApoie}>
                {matchesMD ? <img src={apoie_2400} alt="" /> : <img src={apoie_1600} alt="" />}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
