import { Grid } from "@material-ui/core";
import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import { useGaleria } from "../hooks/useGaleria";
import { Skeleton } from "@material-ui/lab";
import SectionTitle from "../../../components/SectionTitle";
import GaleriaFotosItem from "./GaleriaFotosItem";

const options = {
  settings: {
    autoplaySpeed: 4000,
    boxShadow: "none",
  },
};

export function LoadingGaleria() {
  return (
    <>
      <Grid container spacing={1}>
        {Array.from(new Array(8)).map((item, key) => (
          <Grid item xs={6} md={3} key={key}>
            <Skeleton
              height={180}
              width="100%"
              variant="rect"
              style={{ backgroundColor: "#D6D6D6" }}
            />
          </Grid>
        ))}
      </Grid>
      {/* <Box sx={{ mt: 7.75, display: "flex", justifyContent: "center" }}>
        <Skeleton
          variant="rect"
          height={32}
          width={"50%"}
          style={{ backgroundColor: "#D6D6D6" }}
        />
      </Box> */}
    </>
  );
}

function Galeria({ idEvento }) {
  const { galeria } = useGaleria({ idEvento });

  // if (galeria && galeria.loading) {
  //   return (
  //     <section>
  //       <LoadingGaleria />
  //     </section>
  //   );
  // }

  if (galeria && galeria.payload) {
    return (
      <section>
        {galeria.payload.map((galeriaItem, key) => (
          <React.Fragment key={key}>
            <SectionTitle color="infoLight">
              Galeria - {galeriaItem.descricao}:
            </SectionTitle>
            <SimpleReactLightbox>
              <SRLWrapper options={options}>
                <GaleriaFotosItem galeria={galeriaItem} />
              </SRLWrapper>
            </SimpleReactLightbox>
          </React.Fragment>
        ))}
      </section>
    );
  }

  return <div></div>;
}

export default Galeria;
