import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 40,
        margingTop: 50,
    },
    header: {
        backgroundColor: '#F3F2F6',
        paddingTop: 5,
        paddingBottom: 5,
        color: theme.palette.secondary.dark,
        fontWeight: 700,
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '.85rem',
        '& .MuiGrid-item': {
            paddingLeft: 10,
            paddingRight: 10
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: '1rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.25rem',
            '& .MuiGrid-item': {
                paddingLeft: 14,
                paddingRight: 14
            },
        }
    },
    infoEvento: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
        marginTop: 10,
        marginBottom: 10,
        '& .MuiAvatar-root': {
            backgroundColor: '#fff',
            color: '#fff',
            height: 36,
            width: 36,
            [theme.breakpoints.up('md')]: {
                height: 50,
                width: 50,
            }
        },
        '& .MuiTypography-subtitle1, & a': {
            marginLeft: 10,
            fontWeight: 'bold',
            fontSize: '.9rem',
            lineHeight: 1.3,
            [theme.breakpoints.up('sm')]: {
                fontSize: '1.05rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '1.25rem',
            }
        }, '& a': {
            color: theme.palette.secondary.dark80,
            textDecoration: 'none',
            '&:hover': {
                color: theme.palette.secondary.dark,
            }
        }
    },
    competicao: {
        display: 'flex',
        alignItems: 'center',
        padding: '0px',
        backgroundColor: theme.palette.secondary.pale,
        borderRadius: 17,
        marginLeft: 40,
        marginBottom: 5,
        FontFamily: "'Roboto', sans-serif",
        [theme.breakpoints.up('sm')]: {
            marginLeft: 60,
        },
        [theme.breakpoints.up('md')]: {
            marginLeft: 70,
        }
    },
    pts: {
        padding: '2px 10px',
        backgroundColor: '#fff',
        borderRadius: 17,
        color: '#21166B',
        position: 'relative',
        fontWeight: 700,
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            padding: '2px 16px',
            minWidth: 40,
        },

    },
    pos: {
        padding: '2px 10px 2px 10px',
        backgroundColor: theme.palette.secondary.dark80,
        marginLeft: -20,
        borderRadius: 17,
        color: '#fff',
        minWidth: 60,
        textAlign: 'right',
        [theme.breakpoints.up('sm')]: {
            minWidth: 40,
            padding: '2px 12px 2px 10px',
        },
        [theme.breakpoints.up('md')]: {
            minWidth: 50,
            padding: '2px 16px 2px 10px',
        }
    },
    nomeProva: {
        paddingLeft: 16,
        fontSize: '.8rem',
        FontFamily: "'Roboto', sans-serif",
        fontWeight: 500,
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: {
            fontSize: '1rem'
        }
    },
    resultadosEventoItem: {
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            marginTop: 20,
            marginBottom: 25
        }
    },
    medal: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.3em',
            marginRight: 5,
            [theme.breakpoints.up('sm')]: {
                marginRight: 10,
            }
        },
        '& .tipo-1': {
            color: theme.palette.gold.main
        },
        '& .tipo-2': {
            color: theme.palette.silver.main
        },
        '& .tipo-3': {
            color: theme.palette.bronze.main
        }
    },

}));