import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';


const styledBy = (property, mapping) => (props) => mapping[props[property]];

export default function SectionTitle(props) {
    const theme = useTheme();

    const styles = {
        root: {
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '1.25rem',
            fontWeight: 400,
            marginBottom: '0.5em',
            color: styledBy('color', {
                white: '#fff',
                primary: theme.palette.primary.main,
                primaryLight: theme.palette.primary.light,
                secondary: theme.palette.secondary.main,
                secondaryLight: theme.palette.secondary.light,
                info: theme.palette.info.main,
                infoLight: theme.palette.info.light,
                warning: theme.palette.warning.main,
                warningLight: theme.palette.warning.light,
                error: theme.palette.error.main,
                errorLight: theme.palette.error.light,
            }),
        }
    }

    const Title = withStyles(styles)(({ classes, color, ...other }) => (
        <Typography className={classes.root} {...other} />
    ));

    return (
        <Title {...props}>
            {props.children}
        </Title>
    )
}