import _ from "lodash";
import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CBSaltospi, gestaoApi } from "../../services/api";
import PerfilPessoa from "./sections/PerfilPessoa";

export default function PageAtleta() {
  const [atleta, setAtleta] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  const { id } = useParams();

  useEffect(() => {
    setAtleta({
      loading: true,
      error: false,
      payload: [],
    });

    async function getAtleta() {
      await CBSaltospi.get("atleta", {
        params: {
          id_atleta: id,
          expand: "atleta,classe,estabelecimento,tecnico",
          "atletaGestao.id": 1,
        },
      }).then((response) => {
        if (response.data._meta.totalCount === 0) {
          setAtleta({
            loading: false,
            error: true,
            payload: [],
          });
        } else {
          let nome = response.data.items[0].atleta.nome_completo.split(" ");
          let sobrenome = response.data.items[0].atleta.nome_completo
            .split(" ")
            .slice(1)
            .join(" ");

          setAtleta({
            loading: false,
            error: false,
            payload: {
              ...response.data.items[0],
              firstName: nome[0],
              lastName: sobrenome,
            },
          });
        }
      });
    }
    getAtleta();
  }, [id]);

  const [eventoAtleta, setEventoAtleta] = useState({
    error: false,
    loading: true,
    eventoAtletas: [],
    enventoIDs: "",
  });

  useEffect(() => {
    setEventoAtleta({
      error: false,
      loading: true,
      eventoAtletas: [],
      enventoIDs: "",
    });
    if (id) {
      async function getEventos() {
        await CBSaltospi.get("evento-atleta", {
          params: {
            id_atleta: id,
            expand:
              "eventoProva,eventoProva.altura,eventoProva.aparelho,eventoProva.classe,notaTotalProva",
            pageSize: 200,
            flag_del: 0,
            flag_fase_f: 1,
          },
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setEventoAtleta({
                error: true,
                loading: true,
                eventoAtletas: [],
                enventoIDs: "",
              });
            } else {
              let groupedEvento = _.groupBy(response.data.items, "id_evento");
              let eventoIDs = _.keys(groupedEvento);
              setEventoAtleta({
                error: false,
                loading: false,
                eventoAtletas: _.toArray(groupedEvento),
                enventoIDs: eventoIDs,
              });
            }
          })
          .catch((err) => {
            setEventoAtleta({
              error: true,
              loading: true,
              eventoAtletas: [],
              enventoIDs: "",
            });
            console.log(err);
          });
      }
      getEventos();
    } else {
      setEventoAtleta({
        error: true,
        loading: false,
        eventoAtletas: [],
        enventoIDs: "",
      });
    }
  }, [id]);

  const [eventos, setEventos] = useState({
    error: false,
    loading: true,
    eventos: [],
  });

  useEffect(() => {
    if (eventoAtleta.enventoIDs) {
      setEventos({
        error: false,
        loading: true,
        eventos: [],
      });
      async function getEventos() {
        await gestaoApi
          .get("evento", {
            params: {
              flag_del: 0,
              // id_estabelecimento: 1,
              arrayIn: `id:${eventoAtleta.enventoIDs.join("|")}`,
              sort: "-data_inicio",
            },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setEventos({
                error: true,
                loading: false,
                eventos: [],
              });
            } else {
              setEventos({
                error: false,
                loading: false,
                eventos: response.data.items,
              });
            }
          })
          .catch((err) => {
            setEventos({
              error: true,
              loading: false,
              eventos: [],
            });
            console.log(err);
          });
      }
      getEventos();
    }
  }, [eventoAtleta]);

  return (
    <PerfilPessoa
      pessoa={atleta}
      isAtleta={true}
      eventos={eventos}
      eventoAtleta={eventoAtleta}
    />
  );
}
