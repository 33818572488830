import { Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "../styles/gruposSaltos";

import trampolim from "../../../assets/images/entenda/aparelhos/trampolim.png";
import plataforma from "../../../assets/images/entenda/aparelhos/plataforma.png";


export default function AparelhosSection() {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.gridSaltos}>
                <Grid container justifyContent="center">
                    <Grid item xs={12} sm={4}>
                        <div className={clsx(classes.saltoWrapper, classes.roundImg)}>
                            <div className={classes.imgWrapper}>
                                <img src={trampolim} alt="entenda1" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={clsx(classes.posicaoInfo, classes.posicaoInfoVertical)}>
                                <Typography variant="body1">TRAMPOLIM</Typography>
                                <Typography variant="body1">1m</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={clsx(classes.saltoWrapper, classes.roundImg)}>
                            <div className={classes.imgWrapper}>
                                <img src={trampolim} alt="entenda1" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={clsx(classes.posicaoInfo, classes.posicaoInfoVertical)}>
                                <Typography variant="body1">TRAMPOLIM</Typography>
                                <Typography variant="body1">3m</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <div className={clsx(classes.saltoWrapper, classes.roundImg)}>
                            <div className={classes.imgWrapper}>
                                <img src={plataforma} alt="entenda1" />
                                <div className={classes.borderBottom}></div>
                            </div>
                            <div className={clsx(classes.posicaoInfo, classes.posicaoInfoVertical)}>
                                <Typography variant="body1">PLATAFORMA</Typography>
                                <Typography variant="body1">5m, 7,5m, 10m</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}
