import { Avatar, Container, Divider, Grid, List, ListItem, Paper, Typography } from "@material-ui/core";
// import TemplateLink from "../../../components/Links";
import NossosHerois from "../../home/sections/NossosHerois";
import { useStyles } from "../style/NossosHerois";
import EntendaSection from "../../noticias/sections/Entenda";
import React from "react";
import clsx from "clsx";
import { Link } from 'react-router-dom'
import { createSlug } from "../../../util/createSlug";
export default function NossosHeroisSobre(props) {
    const classes = useStyles();
    const { atletas, cardOnly, textOnly } = props;

    const nossosHerois = (atletas) => {
        return (
            <React.Fragment>
                <Typography className={classes.heading}>Nossos Heróis</Typography>
                <div className={clsx(classes.herosWrapper, { [classes.wrapperShadow]: !cardOnly })}>
                    <NossosHerois onlyCardAtletas={true} />
                    {atletas.loading ?
                        ''
                        :
                        <Paper className={classes.PaperNossosAtletas}>
                            {atletas.payload.map((item, key) => {
                                if (item.atletas?.[0]) {
                                    return (
                                        <div key={key}>
                                            <Typography variant="subtitle1" className={classes.contentTitle}>{item.descricao}</Typography>
                                            <Grid container justifyContent="center" alignContent="center" spacing={2}>
                                                {item.atletas.map((item, key) => (
                                                    <Grid key={key} item xs={3} className={classes.avatarWrapper}>
                                                        <div className={classes.AvatarBorder}>
                                                            <Avatar className={classes.Avatar} src={!item ? '' : `https://saltosbrasil.bigmidia.com/_uploads/fotoAtleta/${item.atleta.foto}`} />
                                                        </div>
                                                        <Link to={item ? `/atleta/${item.atleta.id}/${createSlug(item.atleta.nome_completo)}` : ''} className={classes.nomeAtleta}>
                                                            {!item ? '' : item.atleta.nome_completo}
                                                        </Link>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </div>
                                    )
                                } else {
                                    return (<div key={key}></div>)
                                }
                            })}

                        </Paper>
                    }

                </div>
            </React.Fragment>
        )
    }

    const textSection = () => {
        return (
            <div className={classes.textSection}>
                <div className={classes.section}>
                    <Typography className={clsx(classes.sectionTitle, { [classes.contrastColor]: textOnly })} variant="h3">
                        Como entrar no time de heróis
                    </Typography>
                    <Divider className={clsx(classes.titleDevider, { [classes.contrastBackground]: textOnly })} />
                    <Typography variant="body1" className={clsx(classes.sectionContent, { [classes.contrastColor]: textOnly })}>
                        Para entrar para a Seleção Brasileira de saltos ornamentais é preciso atingir, dentro da categoria do atleta, a pontuação necessária da seletiva.
                    </Typography>
                    <Typography variant="body1" className={clsx(classes.sectionContent, { [classes.contrastColor]: textOnly })}>
                        A pontuação necessária varia de competição para competição, sendo que algumas exigem estar entre as duas melhores pontuações nacionais da categoria e outras basta atingir uma pontuação mínima.
                    </Typography>
                </div>
                <div className={classes.section}>
                    <Typography className={clsx(classes.sectionTitle, { [classes.contrastColor]: textOnly })} variant="h3">
                        Curiosidades sobre nossos atletas olímpicos:
                    </Typography>
                    <Divider className={clsx(classes.titleDevider, { [classes.contrastBackground]: textOnly })} />
                    <List>
                        <ListItem className={clsx(classes.ListItem, { [classes.contrastColor]: textOnly })}>
                            <span className={clsx(classes.listItemIcon, { [classes.contrastBackground]: textOnly })}></span> 12 horas de treino diário
                        </ListItem>
                        <ListItem className={clsx(classes.ListItem, { [classes.contrastColor]: textOnly })}>
                            <span className={clsx(classes.listItemIcon, { [classes.contrastBackground]: textOnly })}></span> Mínimo de 5 anos competindo
                        </ListItem>
                        <ListItem className={clsx(classes.ListItem, { [classes.contrastColor]: textOnly })}>
                            <span className={clsx(classes.listItemIcon, { [classes.contrastBackground]: textOnly })}></span> Média de 385pts em Competições Oficiais
                        </ListItem>
                    </List>
                </div>
            </div >
        )
    }

    if (cardOnly) {
        return nossosHerois(atletas)
    }

    if (textOnly) {
        return (textSection())
    }

    return (
        <div className={classes.root}>
            <Container className={classes.Container}>
                <Paper className={classes.paper}>
                    {nossosHerois(atletas)}
                    {textSection()} 
                    <div className={classes.entendaSection}>
                        <EntendaSection />
                    </div> 
                </Paper>
            </Container>
        </div>
    )
}