import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: 10,
        position: 'relative',
        background: 'linear-gradient(112deg, #094146 0%, #004A53 100%)',
        borderRadius: 20,
        marginTop: -30,
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 20,
        [theme.breakpoints.up('md')]: {
            borderRadius: 60,
            marginTop: -50,
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: 30,
            marginTop: -25,
        }
    },
    container: {
        marginTop: 30,
        marginBottom: 30,
        [theme.breakpoints.up('md')]: {
            marginTop: 60,
            marginBottom: 60,
        },
    },
    title: {
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '2rem',
        fontWeight: 300,
        color: theme.palette.warning.light,
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: '2.1rem',
            marginLeft: '5%',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.25rem',
            marginLeft: '10%',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
        }
    },
    accordionContainer: {
        maxWidth: '100%',
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '90%',
        },
        [theme.breakpoints.up('md')]: {
            maxWidth: '80%',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 0,
        }
    },
    keyword: {
        color: theme.palette.warning.light,
    },
    numDuvida: {
        fontFamily: "'Cormorant Infant', serif",
        marginLeft: 'auto',
        paddingLeft: 10,
        color: theme.palette.primary.pale,
    }
}));