import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-end',
        overflow: 'hidden',
        '& a': {
            display: 'flex',
        },
        '& img': {
            maxWidth: '85%',
            objectFit: 'contain',
            marginLeft: 'auto'
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'flex-end',
            '& img': {
                maxWidth: '100%',
                maxHeight: '100vh',
            },
        },

        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center',
            '& img': {
                maxWidth: 'unset',
                maxHeight: 'unset',
            },
        },

    },

    sectionAction: {
        position: 'absolute',
        left: 0,
        top: 0,
    },
    entendaBorder: {
        borderLeft: `3px solid ${theme.palette.warning.light}`,
        borderTop: `3px solid ${theme.palette.warning.light}`,
        borderBottom: `3px solid ${theme.palette.warning.light}`,
        padding: '40px 35px 0 20px',
        [theme.breakpoints.up('sm')]: {
            padding: '40px 0 17px 40px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '44px 0 27px 91px',

        }
    },
    entendaCard: {
        background: `linear-gradient(145deg, ${alpha(theme.palette.info.light, 0.4)} 0%, ${alpha(theme.palette.primary.darker, 0.4)} 100%)`,
        border: `1px solid ${theme.palette.info.light}`,
        borderRadius: '12px',
        backdropFilter: 'blur(50px)',
        WebkitBackdropFilter: 'blur(50px)',
        minHeight: 430,
        marginBottom: '-30px',
        display: 'flex',
        flexDirection: 'column',

        [theme.breakpoints.up('sm')]: {
            marginBottom: '-45px',
            width: 400,
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 0,
            width: 440,

        },
        [theme.breakpoints.up('lg')]: {
            border: `2px solid ${theme.palette.info.light}`,
            minHeight: 630,
            width: 480,
        }
    },
    cardWrapper: {
        padding: '30px 0 0 30px',
        [theme.breakpoints.up('lg')]: {
            padding: '50px 0 0 60px',
        }
    },
    cardAction: {
        '& .MuiButtonBase-root': {
            borderTop: `1px solid ${theme.palette.info.light}`,
            borderLeft: `1px solid ${theme.palette.info.light}`,
            borderRight: `1px solid ${theme.palette.info.light}`,
            width: 'calc(100% + 2px)',
            color: '#fff',
            fontSize: '1rem',
            lineHeight: '1',
            padding: theme.spacing(2),
            borderRadius: 12,
            margin: -1,
            [theme.breakpoints.up('lg')]: {
                margin: -2,
                width: 'calc(100% + 4px)',
                fontSize: '1.5rem',
                borderTop: `2px solid ${theme.palette.info.light}`,
                borderLeft: `2px solid ${theme.palette.info.light}`,
                borderRight: `2px solid ${theme.palette.info.light}`,
            }
        },
    },
    title: {
        fontSize: '2.1rem',
        fontFamily: "'Quicksand', sans-serif",
        textTransform: 'uppercase',
        color: theme.palette.warning.dark,
        fontWeight: 900,
        letterSpacing: '.1em',
        textShadow: `-20px 21px 7px #004B7D63`,
        [theme.breakpoints.up('md')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '4rem',
        },
    },
    cardInfo: {
        marginTop: 'auto',
        color: theme.palette.info.light,
        padding: '20px 30px',
        marginBottom: 0,
        '& .MuiTypography-body1': {
            fontFamily: "'Quicksand', sans-serif",
            fontSize: '1rem',
            fontWeight: 500,
            [theme.breakpoints.up('md')]: {
                lineHeight: '1.7',
                fontSize: '1.3rem',
            },
        },
        [theme.breakpoints.up('md')]: {
            marginBottom: 50,
        },
    }
}));