import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    sectionTop: {
        height: 100,
    },
    root: {
        marginTop: -100,
        zIndex: 1,
        // background: 'linear-gradient(137deg, #E5FCFF 0%, #D4EEFF 100%)',
        background: `linear-gradient(180deg, ${theme.palette.primary.dark80} 0%, ${theme.palette.secondary.dark100} 100%)`,
        minHeight: '60vh',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: 80,
        position: 'relative',
        overflow: 'hidden',
        [theme.breakpoints.up('lg')]: {
            paddingBottom: 100,
        }
    },
    contentContainer: {
        paddingTop: 30,
        zIndex: 3,
        minHeight: 500,
    },
    textWrapper: {
        paddingTop: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    textContainer: {
        marginTop: 'auto',
    },
    textFooter: {
        color: theme.palette.primary.white,
        textAlign: 'center',
        fontSize: '0.8rem',
        '& a': {
            textDecoration: 'none',
            color: theme.palette.info.light,
            '&:hover': {
                color: theme.palette.info.main,
            }
        }
    },
    iconContainer: {
        marginTop: 20,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        '& .MuiButtonBase-root': {
            margin: '0 30px',
        }
    },
    footerIcon: {
        color: '#fff',
        fontSize: '2.5rem',
    },
    footerSaltosLogo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 20,
        [theme.breakpoints.up('lg')]: {
            marginBottom: 50,
        }
    },
    govLogo: {
        marginBottom: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    jogoLimpo: {
        height: 160,
        maxHeight: 75,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 100,
        }
    },
    clinor: {
        height: 160,
        maxHeight: 60,
        [theme.breakpoints.up('lg')]: {
            maxHeight: 80,
        }
    },
    linkBigmidia: {
        marginTop: 30,
        marginBottom: 40,
        color: '#fff',
        textAlign: 'center',
        '& .MuiTypography-body1': {
            marginBottom: 10,
            color: theme.palette.primary.white,
            textAlign: 'center',
            fontSize: '0.9rem',
        }
    }
}));