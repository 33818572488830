import { Box, Paper, Typography } from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { portalApi } from "../../../services/api";
import CardsNoticas from "../../home/sections/CardsNoticias";
import { useStyles } from "../styles/Noticias";

export default function PesquisaNoticias(props) {
  const { searchTerm } = props;
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [noticias, setNoticias] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const sectionTop = useRef(null);

  useEffect(() => {
    if (searchTerm) {
      setError(false);
      setLoading(true);

      async function getNoticia() {
        await portalApi
          .get("noticia", {
            params: {
              flag_del: 0,
              flag_publicado: 1,
              id_estabelecimento: 1,
              arrayLike: `titulo:${searchTerm},corpo:${searchTerm}`,
              sort: "-created_at",
              pageSize: 12,
              page: page,
              lessField: "created_at",
              lessValue: new Date().getTime().toString().substr(0, 10),
            },
          })
          .then((response) => {
            if (page > 1) {
              executeScroll();
            }
            setTimeout(() => {
              setNoticias(response.data.items);
              setTotalPages(response.data._meta.pageCount);
              if (response.data._meta.totalCount === 0) {
                setError(true);
              }
              // console.log(response.data.items)
              setLoading(false);
            }, 600);
          });
      }
      getNoticia();
    }
  }, [searchTerm, page]);

  const handleChange = (event, value) => {
    setPage(value);
    if (value === 1) {
      executeScroll();
    }
  };

  const executeScroll = () =>
    window.scrollTo({
      behavior: "smooth",
      top: sectionTop.current.offsetTop - 200,
    });

  return (
    <Box>
      <div ref={sectionTop}></div>
      <div className={classes.root}>
        <div className={classes.cardTop}></div>
        <Paper className={classes.noticiasWrapper}>
          <div className={classes.cardHeaderLabel}>
            <Typography variant="h2" className={classes.CardTitle}>
              Novidades
            </Typography>
          </div>
          {error ? (
            <div className={classes.errorContainer}>
              <Alert severity="warning">A pesquisa não retornou resultados.</Alert>
            </div>
          ) : (
            <CardsNoticas noContainer={true} noticias={noticias} loadingNoticias={loading} />
          )}
          {totalPages > 1 && (
            <div className={classes.paginationContainer}>
              <Pagination page={page} count={totalPages} onChange={handleChange} />
            </div>
          )}
        </Paper>
        <div className={classes.cardBottom}></div>
      </div>
    </Box>
  );
}
