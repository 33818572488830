import { Card, Container, List, ListItem, Typography, useMediaQuery } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { useStyles } from "./styles";
import entendaImg from '../../assets/images/entenda.webp'
import entendaImg_sm from '../../assets/images/entenda_sm.webp';
import TransparenciaSection from "../home/sections/Transparencia";
import Arenas from "../competicoes/sections/Arenas";
import GrupoSaltos from "./sections/gruposSaltos";
import PosicaoCorpo from "./sections/posicaoCorpo";
import CodigoSaltos from "./sections/codigoSaltos";
import AparelhosSection from "./sections/aparelhos";
import clsx from "clsx";
import CalculadoraSaltos from "./sections/calculadoraSaltos";
import CalculadoraInfo from "./sections/calculadoraInfo";

export default function EntendaPage() {
    const classes = useStyles();

    const matchesSm = useMediaQuery(theme => theme.breakpoints.up('sm'));

    const regras = [
      {
        conteudo: 'FINA Diving Rules - Valid from 12.09.2017',
        url: "https://resources.fina.org/fina/document/2021/01/12/916f78f6-2a42-46d6-bea8-e49130211edf/2017-2021_diving_16032018.pdf"
      },
      {
        conteudo: 'FINA Diving Figures B-W',
        url: "https://resources.fina.org/fina/document/2021/01/19/8f4ec421-9425-4e11-8844-b0a285e535fe/2017-2021_diving_figures_bw_0.pdf"
      },
      {
        conteudo: 'FINA Diving Figures Colour',
        url: "https://resources.fina.org/fina/document/2021/01/19/da20e4d9-77bd-4e99-9af0-58298a66ef6c/2017-2021_diving_figures_colour_0.pdf"
      }
    ]

    return (
        <React.Fragment>
            <Helmet>
                <title>Entenda - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais</title>
                <meta name="description" content="Entenda - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais" />
            </Helmet>
            <div className={classes.root}>
                <div className={classes.bgWater}></div>
                <div className={classes.content}>
                    {matchesSm ?
                        <img src={entendaImg} alt="entenda" className={classes.imgEntenda} />
                        : <img src={entendaImg_sm} alt="entenda" className={classes.imgEntendaSM} />
                    }
                    <div className={classes.bgContent}>
                        <Container>
                            <Typography variant="h1" className={classes.heading}>
                                O Salto Ornamental
                            </Typography>
                            <Typography variant="body1" className={classes.text}>
                                Salto Ornamental é um esporte individual que consiste em saltar de uma plataforma elevada, fixa ou não (trampolin), em direção a uma piscina, realizando uma série de movimentos acrobáticos e estéticos.
                            </Typography>
                            <Typography variant="h6" className={classes.headingInfo}>Regras Oficiais FINA</Typography>
                            <List className={classes.listRegras}>
                              {regras.map((item, key) => (
                                <ListItem key={key}>
                                 <a href={item.url} target="_blank" rel="noreferrer">{item.conteudo}</a>
                                </ListItem>
                                ))}
                            </List>

                            <section>
                                <Card className={classes.card}>
                                    <div className={classes.cardTitle}>
                                        Grupos de Saltos
                                    </div>
                                    <GrupoSaltos />
                                </Card>
                                <div className={classes.cardBottom}></div>
                            </section>
                            <section>
                                <Card className={classes.card}>
                                    <div className={classes.cardTitle}>
                                        Posição do Corpo
                                    </div>
                                    <PosicaoCorpo />
                                </Card>
                                <div className={classes.cardBottom}></div>
                            </section>
                            <section>
                                <Card className={classes.card}>
                                    <div className={classes.cardTitle}>
                                        Aparelhos
                                    </div>
                                    <AparelhosSection />
                                </Card>
                                <div className={classes.cardBottom}></div>
                            </section>
                        </Container>
                        <Container className={classes.containerFullSM}>
                            <section>
                                <Card className={classes.card}>
                                    <div className={clsx(classes.cardTitle, classes.cardTitleFull)}>
                                        Entenda o Código do Salto
                                    </div>
                                    <CodigoSaltos />
                                </Card>

                            </section>
                        </Container>
                        <Container maxWidth="md" className={classes.containerFullSM}>
                            <section>
                                <Card className={clsx(classes.card, classes.cardRoundTop)}>
                                    <div className={clsx(classes.cardTitle, classes.cardTitleRoundCard)}>
                                        Calculadora dos Saltos
                                    </div>
                                    <CalculadoraSaltos />
                                </Card>
                                <div className={classes.cardBottom}></div>
                            </section>
                            <section>
                                <Card className={clsx(classes.card, classes.cardRoundBottom)}>
                                    <div className={clsx(classes.cardTitle, classes.cardTitleRoundCard)}>
                                        Como funciona o Cálculo de Pontos
                                    </div>
                                    <CalculadoraInfo />
                                </Card>

                            </section>
                        </Container>
                    </div>
                </div>
            </div>
            <Arenas />
            <TransparenciaSection />
        </React.Fragment >
    )
}
