import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    Appbar: {
        backgroundColor: theme.palette.warning.main,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20,
        minHeight: 120,
        transition: 'all 250ms ease-in-out',
        zIndex: 119,
    },
    AppToolbar: {
        justifyContent: 'center',
    },
    Toolbar: {
        minHeight: 100,
    },
    liveNotify: {
        display: 'flex',
        margin: '0px 20px 10px'
    },
    liveImg: {
        marginRight: 10,
        height: 55,
        width: 55,
        color: '#fff',
        backgroundColor:'#fff',
    },
    liveInfo: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
    },
    liveTitle: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
        fontFamily: "'Poppins', sans-serif",
    },
    liveSubTitle: {
        fontSize: '0.7rem',
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
    },
    //scroll
    AppbarScrolled: {
        flexDirection: 'row',
        minHeight: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    liveImgScrolled: { 
        height: 35,
        width: 35,
    },
    liveNotifyScrolled: {
        display: 'flex',
        alignItems: 'center',
        margin: 0,
    }
}));