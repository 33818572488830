import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    CardNoticiaPrincipal: {
        borderRadius: 20,
        background: 'transparent'
    },
    CardMedia: {
        height: 270,
        position: 'relative',
        zIndex: 10,
        borderRadius: '0 0 20px 20px',
        boxShadow: `0px 3px 6px ${alpha(theme.palette.secondary.dark, 0.4)}`,
        '&.MuiSkeleton-root': {
            background: '#e3e3e3'
        },
        [theme.breakpoints.up('sm')]: {
            height: 320,
        },
        [theme.breakpoints.up('lg')]: {
            height: 370,
        },
    },
    CardContent: {
        backgroundColor: '#fff',
        padding: theme.spacing(5, 2, 2, 2),
        borderRadius: '0 0 20px 20px',
        marginTop: -20,
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(4, 3),
        }
    },
    CardNoticiaTitle: {
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '1.25rem',
        fontWeight: 'bolder',
        color: theme.palette.info.darker,
        marginBottom: '.25em',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.35rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.5rem',
        },
    },
    CardNoticiaResumo: {
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.secondary.dark80,
        fontWeight: 'bolder',
        marginBottom: '.3em',
        maxHeight: '5.8rem',
        overflow: 'hidden',
        display: '-webkit-box',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical',
        fontSize: '0.95rem',
        lineHeight:1.2,
        [theme.breakpoints.up('sm')]: {
            maxHeight: '6rem',
            WebkitLineClamp: '4',
            lineHeight:1.3,
        },
        [theme.breakpoints.up('lg')]: {
            lineHeight:1.4,
            fontSize: '1.1rem',
        }
    },
    CardNoticiaData: {
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.info.dark,
        fontWeight: 'bolder',
    },
    cardBottom: {
        height: 30,
        marginTop: -20,
        width: '50%',
        borderRadius: '0 0 20px 20px',
        background: `linear-gradient(106deg, ${theme.palette.info.light} 0%, ${theme.palette.secondary.light} 100%)`
    },
    shareBtn: {
        position: 'absolute',
        right: 20,
        top: -10,
        zIndex: 100,
        '& svg': {
            fontSize: '1.2rem'
        },
        [theme.breakpoints.up('lg')]: {
            top: -5,
            right: 35,
            '& svg': {
                fontSize: '1em'
            }
        }
    }
}));