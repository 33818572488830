import {
  Typography,
  InputBase,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Container,
  Grid,
  IconButton,
} from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { siglasEstados } from "../../../assets/data/estadosBR";
import { SearchSVG } from "../../../assets/icons/NavIcons";
import { CBSaltospi, gestaoApi } from "../../../services/api";
import { useStyles, AntTab, AntTabs } from "../styles/federados";
import TabelaContainer from "./TabelaContainer";
import { categoriasAtletas } from "../../../assets/data/categoriasAtletas";
import { cargoPessoa } from "../../../assets/data/cargoPessoa";
import { categoriasPessoas } from "../../../assets/data/categoriasPessoas";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function FederadosSection(props) {
  const classes = useStyles();
  const { searchTerm } = props;

  const [categoria, setCategoria] = useState("");
  const handleChangeCategoria = (event) => {
    setCategoria(event.target.name);
  };

  const [categoriaPessoa, setCategoriaPessoa] = useState("");
  const handleChangeCategoriaPessoa = (event) => {
    setCategoriaPessoa(event.target.name);
  };

  const [cargo, setCargo] = useState("");
  const handleChangeCargo = (event) => {
    setCargo(event.target.name);
  };

  const [selectedUF, setSelectedUF] = useState("");

  const handleChangeUF = (event) => {
    if (event.target.name === "todos") {
      setSelectedUF("todos");
    } else {
      setSelectedUF(event.target.name);
    }
  };

  const generos = ["Feminino", "Masculino"];

  const [selectTipo, setSelectTipo] = useState(0);
  const [selectGenero, setSelectGenero] = useState(2);

  const handleChangeTipo = (event, newValue) => {
    setSelectTipo(newValue);
    setPage(1);
  };
  const handleChangeGenero = (event, newValue) => {
    setSelectGenero(newValue);
    setPage(1);
  };

  const [searchQuery, setSearchQuery] = useState("");

  const [error, setError] = useState(false);
  let history = useHistory();
  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      executeSearch();
      e.preventDefault();
    }
  };

  const executeSearch = () => {
    if (searchQuery !== "") {
      setError(false);
      history.push(`?q=${searchQuery}`);
    } else {
      setError(true);
      history.push("/equipe");
    }
  };

  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const [atletas, setAtletas] = useState({
    error: false,
    loading: true,
    payload: [],
  });
  useEffect(() => {
    if (selectTipo === 0) {
      let arrSexo = ["F", "M", ""];
      setAtletas({
        error: false,
        loading: true,
        payload: [],
      });

      let forceWhere = [];

      if (selectedUF) {
        forceWhere.push(`estabelecimento.uf:${selectedUF}`);
      }
      if (selectGenero !== 2) {
        forceWhere.push(`sexo:${arrSexo[selectGenero]}`);
      }
      if (categoria) {
        forceWhere.push(`classe.id:${categoria}`);
      }

      forceWhere.push("atletaGestao.flag_del:0");

      let params;

      if (forceWhere.length === 0) {
        params = {
          expand: "atleta,classe,estabelecimento",
          forcejoin: "atleta.atletaHistoricoAtual.estabelecimento",
          "atletaGestao.id": 1,
          sort: "nome_completo",
          page: page,
        };
      } else {
        params = {
          expand: "atleta,classe,estabelecimento",
          forcejoin: "atleta.atletaHistoricoAtual.estabelecimento,atleta,classe",
          "atletaGestao.id": 1,
          forcewhere: forceWhere.join(","),
          sort: "nome_completo",
          page: page,
        };
      }

      if (searchTerm) {
        params = {
          ...params,
          likeField: "atletaGestao.nome_completo",
          likeValue: searchTerm,
        };
      }

      async function getAtletas() {
        await CBSaltospi.get("atleta", {
          params: params,
        })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setAtletas({
                error: true,
                loading: false,
                payload: [],
              });
            } else {
              setAtletas({
                error: false,
                loading: false,
                payload: response.data.items,
              });
            }
            setTotalPages(response.data._meta.pageCount);
          })
          .catch((err) => {
            console.log(err);
            setAtletas({
              error: true,
              loading: false,
              payload: [],
            });
          });
      }
      getAtletas();
    }
  }, [selectGenero, searchTerm, selectTipo, selectedUF, categoria, page]);

  const [tecnicos, setTecnicos] = useState({
    error: false,
    loading: true,
    payload: [],
  });
  useEffect(() => {
    if (selectTipo === 1) {
      let arrSexo = ["F", "M", ""];
      setTecnicos({
        error: false,
        loading: true,
        payload: [],
      });

      let params;

      if (selectedUF) {
        params = {
          "pessoa.flag_del": 0,
          id_departamento: 1,
          sexo: arrSexo[selectGenero],
          nome_completo: searchTerm,
          expand: "departamento,cargo,estabelecimento",
          id_tipo: cargo,
          sort: "nome_completo",
          "estabelecimento.uf": selectedUF,
          page: page,
        };
      } else {
        params = {
          flag_del: 0,
          id_departamento: 1,
          sexo: arrSexo[selectGenero],
          nome_completo: searchTerm,
          expand: "departamento,cargo,estabelecimento",
          id_tipo: cargo,
          sort: "nome_completo",
          page: page,
        };
      }

      async function getTecnicos() {
        await gestaoApi
          .get("pessoa", {
            params: params,
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setTecnicos({
                error: true,
                loading: false,
                payload: [],
              });
            } else {
              setTecnicos({
                error: false,
                loading: false,
                payload: response.data.items,
              });
            }
            setTotalPages(response.data._meta.pageCount);
          });
      }
      getTecnicos();
    }
  }, [selectGenero, searchTerm, selectTipo, page, cargo, selectedUF]);

  //ARBITRO
  const [arbitros, setArbitros] = useState({
    error: false,
    loading: true,
    payload: [],
  });
  useEffect(() => {
    if (selectTipo === 2) {
      let arrSexo = ["F", "M", ""];
      setArbitros({
        error: false,
        loading: true,
        payload: [],
      });

      let params = {
        expand: "pessoa,estabelecimento,categoria,estabelecimento",
        forcejoin: "categoria,pessoa",
        sort: "nome_completo",
        page: page,
      };

      if (arrSexo[selectGenero]) {
        params = {
          ...params,
          "pessoa.sexo": arrSexo[selectGenero],
        };
      }

      if (categoriaPessoa) {
        params = {
          ...params,
          forcewhere: `arbitro_categoria.id:${categoriaPessoa}`,
        };
      }

      if (selectedUF) {
        params = {
          ...params,
          "pessoa.flag_del": 0,
          forcejoin: "categoria,pessoa,pessoa.estabelecimento",
          forcewhere: `estabelecimento.uf:${selectedUF}${
            categoriaPessoa ? `,arbitro_categoria.id:${categoriaPessoa}` : ""
          }`,
        };
      } else {
        params = {
          ...params,
          flag_del: 0,
        };
      }

      if (searchTerm) {
        params = {
          ...params,
          likeField: "pessoa.nome_completo",
          likeValue: searchTerm,
        };
      }

      async function getTecnicos() {
        await CBSaltospi.get("arbitro", {
          params: params,
        }).then((response) => {
          if (response.data._meta.totalCount === 0) {
            setArbitros({
              error: true,
              loading: false,
              payload: [],
            });
          } else {
            setArbitros({
              error: false,
              loading: false,
              payload: response.data.items,
            });
          }
          setTotalPages(response.data._meta.pageCount);
        });
      }
      getTecnicos();
    }
  }, [selectGenero, searchTerm, selectTipo, page, categoriaPessoa, selectedUF]);

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.headerWrapper}>
          <Grid container>
            <Grid item xs={12} sm={6} md={8}>
              <Typography variant="h1" className={classes.heading}>
                Federados
              </Typography>
              <Typography variant="subtitle1" className={classes.subtitle}>
                Encontre atletas, técnicos e árbitros federados na Saltos Brasil. Use filtros para diferentes categorias
                e ordene na sequência que preferir.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchSVG />
                </div>
                <InputBase
                  placeholder="Buscar Federado"
                  value={searchQuery}
                  error={error}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => handleSearchKeyDown(e)}
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
                <div
                  className={clsx(classes.btnSearchArrow, {
                    [classes.btnSearchArrowShow]: searchQuery.length > 0,
                  })}
                >
                  <IconButton size="small" onClick={executeSearch}>
                    <ArrowForward />
                  </IconButton>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Container maxWidth="md">
        <div className={classes.filtrosWrapper}>
          <Typography className={classes.filterTitle}> Formato</Typography>
          <AntTabs value={selectTipo} onChange={handleChangeTipo} centered>
            <AntTab label="Atletas" />
            <AntTab label="Técnicos" />
            <AntTab label="Árbitros" />
          </AntTabs>

          {selectTipo === 0 ? (
            <React.Fragment>
              <Typography className={classes.filterTitle}> Categoria</Typography>
              <FormGroup row>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={<Checkbox checked={categoria === ""} onChange={handleChangeCategoria} name="" />}
                  label="TODAS"
                />
                {categoriasAtletas.map((item, key) => (
                  <FormControlLabel
                    key={key}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        checked={categoria === item.id.toString()}
                        onChange={handleChangeCategoria}
                        name={item.id}
                      />
                    }
                    label={item.sigla}
                  />
                ))}
              </FormGroup>
            </React.Fragment>
          ) : (
            ""
          )}

          {selectTipo === 1 ? (
            <React.Fragment>
              <Typography className={classes.filterTitle}>Tipo</Typography>
              <FormGroup row>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={<Checkbox checked={cargo === ""} onChange={handleChangeCargo} name="" />}
                  label="TODOS"
                />
                {cargoPessoa.map((item, key) => (
                  <FormControlLabel
                    key={key}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox checked={cargo === item.id.toString()} onChange={handleChangeCargo} name={item.id} />
                    }
                    label={item.descricao}
                  />
                ))}
              </FormGroup>
            </React.Fragment>
          ) : (
            ""
          )}

          {selectTipo === 2 ? (
            <React.Fragment>
              <Typography className={classes.filterTitle}>Categoria</Typography>
              <FormGroup row>
                <FormControlLabel
                  className={classes.checkboxLabel}
                  control={<Checkbox checked={categoriaPessoa === ""} onChange={handleChangeCategoriaPessoa} name="" />}
                  label="TODOS"
                />
                {categoriasPessoas.map((item, key) => (
                  <FormControlLabel
                    key={key}
                    className={classes.checkboxLabel}
                    control={
                      <Checkbox
                        checked={categoriaPessoa === item.id.toString()}
                        onChange={handleChangeCategoriaPessoa}
                        name={item.id}
                      />
                    }
                    label={item.descricao}
                  />
                ))}
              </FormGroup>
            </React.Fragment>
          ) : (
            ""
          )}

          <Typography className={classes.filterTitle}> Gênero</Typography>
          <AntTabs value={selectGenero} onChange={handleChangeGenero} centered>
            <AntTab label="Feminino" />
            <AntTab label="Masculino" />
          </AntTabs>
          <Typography className={classes.filterTitle}> UF</Typography>
          <FormGroup row className={classes.FormGroupUF}>
            <FormControlLabel
              className={classes.checkboxLabel}
              control={<Checkbox checked={selectedUF === ""} onChange={handleChangeUF} name="" />}
              label="TODOS"
            />
            {siglasEstados.map((item, key) => (
              <FormControlLabel
                key={key}
                className={classes.checkboxLabel}
                control={
                  <Checkbox checked={selectedUF === item.descricao} onChange={handleChangeUF} name={item.descricao} />
                }
                label={item.descricao}
              />
            ))}
          </FormGroup>
        </div>
      </Container>
      <Container className={classes.container}>
        <div className={classes.cardWrapper}>
          <TabelaContainer
            searchTerm={searchTerm}
            genero={generos[selectGenero]}
            tipo={selectTipo}
            atletas={atletas}
            tecnicos={tecnicos}
            arbitros={arbitros}
            handleChangePage={handleChangePage}
            totalPages={totalPages}
            page={page}
          />
        </div>
      </Container>
    </div>
  );
}
