import { useEffect, useState } from "react";
import { gestaoApi } from "../../../services/api";

export const useEvento = ({ idEvento }) => {
  const [evento, setEvento] = useState({
    loading: true,
    error: false,
    payload: {},
  });

  useEffect(() => {
    setEvento({ loading: true, error: false, payload: {} });
    async function getEventos() {
      await gestaoApi
        .get("evento", {
          params: {
            id: idEvento,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setEvento({ loading: false, error: true, payload: {} });
          } else {
            setEvento({
              loading: false,
              error: false,
              payload: response.data.items[0],
            });
          }
        })
        .catch((err) => {
          setEvento({ loading: false, error: true, payload: {} });
          console.log(err);
        });
    }
    getEventos();
  }, [idEvento]);

  return { evento };
};
