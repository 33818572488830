import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardEvento: {
        position: 'relative',
        marginTop: '20%',
        marginBottom: 50,
        [theme.breakpoints.up('md')]: {
            borderRadius: 0,
            marginTop: 260,
            marginBottom: 60,
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 380,
            marginBottom: 80,
        }
    },
    cardEventoResultado: {
        borderRadius: '4px 4px 4px 20px',
        background: `linear-gradient(180deg, #8677F1 0%, #32E8FF 61%, #FFED77 100%)`,
        marginBottom: 20,
        [theme.breakpoints.up('md')]: {
            borderRadius: 20,
            marginBottom: 30,
        },
        [theme.breakpoints.up('lg')]: { 
            marginBottom: 50,
        }
    },
    Card: {
        position: 'relative',
        marginLeft: 4,
        backgroundColor: theme.palette.primary.white,
        borderRadius: '4px 4px 4px 20px',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.up('md')]: {
            borderRadius: '20px 20px 20px 30px',
        },
    },
    CardResultado: {
        borderRadius: '4px 4px 4px 20px',
        [theme.breakpoints.up('md')]: {
            borderRadius: 20,
        }
    },
    CardContent: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        '& .MuiAvatar-root': {
            height: 18,
            width: 18,
            marginRight: 5,
            color: '#e2e2e2',
            background: '#e2e2e2',
            [theme.breakpoints.up('sm')]: {
                height: 25,
                width: 25,
            }
        },

        '& .MuiTypography-caption': {
            marginTop: 5,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: '0.8rem',
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 900,
        }
    },
    CardMedia: {
        height: 80,
        width: 80,
        minWidth: 80,
        backgroundColor: theme.palette.secondary.dark80,
        borderRadius: 5,
        [theme.breakpoints.up('sm')]: {
            height: 100,
            width: 100,
            minWidth: 100,
            borderRadius: 10,
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: 20,
            height: 134,
            width: 134,
            minWidth: 134,
        },
    },
    cardBackdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 160,
        height: 'calc(100% + 25px) ',
        background: 'linear-gradient(153deg, #FFF8CC 0%, #1EB1C4 100%)',
        borderRadius: '4px 0px 30px 20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        fontSize: '0.8rem',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 900,
        paddingBottom: 5,
        [theme.breakpoints.up('sm')]: {
            height: 'calc(100% + 30px) ',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '1rem',
            borderRadius: '20px 0px 20px 30px',
            width: 166,
            paddingBottom: 10,
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    cardInfo: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 'auto',
        marginLeft: 10,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 15,
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 20,
        },
    },
    cardTitle: {
        fontFamily: "'Quicksand', sans-serif",
        fontSize: '0.95rem',
        fontWeight: 'bolder',
        margin: '8px 0',
        lineHeight: 1,
        color: theme.palette.secondary.dark80,
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.1rem',
        },
        [theme.breakpoints.up('lg')]: {
            marginTop: 8,
            fontSize: '1.25rem',
            lineHeight: 1.1
        }
    },
    cardText: {
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 700,
        fontSize: '.8rem',
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1,
        marginBottom: 'auto',
        [theme.breakpoints.up('sm')]: {
            fontSize: '.9rem',
        },
        [theme.breakpoints.up('lg')]: {
            lineHeight: 1.2,
        }
    },
    tagEvento: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        textAlign: 'right',
    },
    eventoLocal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    dataLimite: {
        color: theme.palette.error.main,
        textDecoration: 'underline',
        fontWeight: "bold",
        fontSize: '0.75rem',
        marginTop: 0,
        [theme.breakpoints.up('sm')]: {
            marginTop: 'auto',
            minWidth: 180
        }
    },
    dataEvento: {
        textAlign: 'center',
        marginTop: 8,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 700,
        fontSize: '.8rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '.9rem',
        },
    },

}))