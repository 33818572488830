import React, { useEffect } from "react";
import { Button, CardContent, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../styles/calculadoraSaltos";
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
// import RemoveIcon from '@material-ui/icons/Remove';
import { useState } from "react";
import {
  ChevronDOWNSVG,
  ChevronUPSVG,
  MinusSVG,
} from "../../../assets/icons/LogosTemplate";
import { CBSaltospi } from "../../../services/api";
import { Skeleton } from "@material-ui/lab";

export default function CalculadoraSaltos() {
  const classes = useStyles();

  const [codSalto, setCodSalto] = useState({
    seis: -1,
    cinco: -1,
    s: 1,
    v: -1,
    g: 0,
    p: 3,
    x: 2,
  });

  const xOptions = ["A", "B", "C", "D"];
  // const [codString, setCodString] = useState('')
  const [codigoSalto, setCodigoSalto] = useState("103");
  const [posicaoSalto, setPosicaoSalto] = useState("C");

  useEffect(() => {
    let xopts = ["A", "B", "C", "D"];
    let arrOBJ = Object.entries(codSalto);
    let codArr = [];
    arrOBJ.forEach(function (value, key) {
      if (key === 6) {
        codArr.push(xopts[value[1]]);
      } else {
        codArr.push(value[1]);
      }
    });

    let codString = codArr.map((item, key) => {
      if (item === -1) {
        return "";
      } else {
        return item;
      }
    });

    setCodigoSalto(codString.slice(0, 6).join(""));
    setPosicaoSalto(codString[6]);
  }, [codSalto]);

  const [gdSaltos, setGDSaltos] = useState({
    error: false,
    loading: true,
    payload: [],
  });
  useEffect(() => {
    setGDSaltos({
      error: false,
      loading: true,
      payload: [],
    });
    if (codigoSalto && posicaoSalto) {
      async function getGDSaltos() {
        await CBSaltospi.get("salto-gd", {
          params: {
            codigo: codigoSalto,
            posicao: posicaoSalto,
            sort: "altura",
          },
        }).then((response) => {
          if (response.data._meta.totalCount === 0) {
            setGDSaltos({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setGDSaltos({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        });
      }
      getGDSaltos();
    }
  }, [codigoSalto, posicaoSalto]);

  const [tipoSalto, setTipoSalto] = useState({
    error: false,
    loading: true,
    payload: {},
  });
  useEffect(() => {
    setTipoSalto({
      error: false,
      loading: true,
      payload: {},
    });
    if (codigoSalto) {
      async function getTipoSalto() {
        await CBSaltospi.get("tipo-salto", {
          params: {
            codigo: codigoSalto,
          },
        }).then((response) => {
          if (response.data._meta.totalCount === 0) {
            setTipoSalto({
              loading: false,
              error: true,
              payload: {},
            });
          } else {
            setTipoSalto({
              loading: false,
              error: false,
              payload: response.data.items[0],
            });
          }
        });
      }
      getTipoSalto();
    }
  }, [codigoSalto]);

  return (
    <div>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8}>
            <Typography>
              Altere os números acima para inserir o salto desejado
            </Typography>
            <div className={classes.calculadoraWrapper}>
              <div className="calcItem">
                <div className="calcHeader">6</div>
                <div className="calcSelector">
                  <Button
                    disabled={codSalto.seis > 5}
                    className={codSalto.seis > 5 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, seis: codSalto.seis + 7 })
                    }
                  >
                    {codSalto.seis > 5 ? <MinusSVG /> : <ChevronUPSVG />}
                  </Button>
                  <span>{codSalto.seis < 0 ? "ー" : codSalto.seis}</span>

                  <Button
                    disabled={codSalto.seis < 0}
                    className={codSalto.seis < 0 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, seis: codSalto.seis - 7 })
                    }
                  >
                    {codSalto.seis < 0 ? <MinusSVG /> : <ChevronDOWNSVG />}
                  </Button>
                </div>
              </div>
              <div className="calcItem">
                <div className="calcHeader">5</div>
                <div className="calcSelector">
                  <Button
                    disabled={codSalto.cinco > 4}
                    className={codSalto.cinco > 4 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, cinco: codSalto.cinco + 6 })
                    }
                  >
                    {codSalto.cinco > 4 ? <MinusSVG /> : <ChevronUPSVG />}
                  </Button>
                  <span>{codSalto.cinco < 0 ? "ー" : codSalto.cinco}</span>
                  <Button
                    disabled={codSalto.cinco < 0}
                    className={codSalto.cinco < 0 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, cinco: codSalto.cinco - 6 })
                    }
                  >
                    {codSalto.cinco < 0 ? <MinusSVG /> : <ChevronDOWNSVG />}
                  </Button>
                </div>
              </div>
              <div className="calcItem">
                <div className="calcHeader">
                  #<span>s</span>
                </div>
                <div className="calcSelector">
                  <Button
                    disabled={codSalto.s > 8}
                    className={codSalto.s > 8 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, s: codSalto.s + 1 })
                    }
                  >
                    {codSalto.s > 8 ? <MinusSVG /> : <ChevronUPSVG />}
                  </Button>
                  <span>{codSalto.s < 0 ? "ー" : codSalto.s}</span>
                  <Button
                    disabled={codSalto.s < 0}
                    className={codSalto.s < 0 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, s: codSalto.s - 1 })
                    }
                  >
                    {codSalto.s < 0 ? <MinusSVG /> : <ChevronDOWNSVG />}
                  </Button>
                </div>
              </div>
              <div className="calcItem">
                <div className="calcHeader">
                  #<span>v</span>
                </div>
                <div className="calcSelector">
                  <Button
                    disabled={codSalto.v > 8}
                    className={codSalto.v > 8 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, v: codSalto.v + 1 })
                    }
                  >
                    {codSalto.v > 8 ? <MinusSVG /> : <ChevronUPSVG />}
                  </Button>
                  <span>{codSalto.v < 0 ? "ー" : codSalto.v}</span>
                  <Button
                    disabled={codSalto.v < 0}
                    className={codSalto.v < 0 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, v: codSalto.v - 1 })
                    }
                  >
                    {codSalto.v < 0 ? <MinusSVG /> : <ChevronDOWNSVG />}
                  </Button>
                </div>
              </div>
              <div className="calcItem">
                <div className="calcHeader">
                  #<span>g</span>
                </div>
                <div className="calcSelector">
                  <Button
                    disabled={codSalto.g > 8}
                    className={codSalto.g > 8 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, g: codSalto.g + 1 })
                    }
                  >
                    {codSalto.g > 8 ? <MinusSVG /> : <ChevronUPSVG />}
                  </Button>
                  <span>{codSalto.g < 0 ? "ー" : codSalto.g}</span>
                  <Button
                    disabled={codSalto.g < 0}
                    className={codSalto.g < 0 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, g: codSalto.g - 1 })
                    }
                  >
                    {codSalto.g < 0 ? <MinusSVG /> : <ChevronDOWNSVG />}
                  </Button>
                </div>
              </div>
              <div className="calcItem">
                <div className="calcHeader">
                  #<span>p</span>
                </div>
                <div className="calcSelector">
                  <Button
                    disabled={codSalto.p > 8}
                    className={codSalto.p > 8 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, p: codSalto.p + 1 })
                    }
                  >
                    {codSalto.p > 8 ? <MinusSVG /> : <ChevronUPSVG />}
                  </Button>
                  <span>{codSalto.p < 0 ? "ー" : codSalto.p}</span>
                  <Button
                    disabled={codSalto.p < 0}
                    className={codSalto.p < 0 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, p: codSalto.p - 1 })
                    }
                  >
                    {codSalto.p < 0 ? <MinusSVG /> : <ChevronDOWNSVG />}
                  </Button>
                </div>
              </div>
              <div className="calcItem">
                <div className="calcHeader">X</div>
                <div className="calcSelector">
                  <Button
                    disabled={codSalto.x > 2}
                    className={codSalto.x > 2 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, x: codSalto.x + 1 })
                    }
                  >
                    {codSalto.x > 2 ? <MinusSVG /> : <ChevronUPSVG />}
                  </Button>
                  <span>{codSalto.x < 0 ? "ー" : xOptions[codSalto.x]}</span>
                  <Button
                    disabled={codSalto.x < 1}
                    className={codSalto.x < 1 ? "disabled" : ""}
                    size="small"
                    onClick={() =>
                      setCodSalto({ ...codSalto, x: codSalto.x - 1 })
                    }
                  >
                    {codSalto.x < 1 ? <MinusSVG /> : <ChevronDOWNSVG />}
                  </Button>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Typography className={classes.sectionTitle}>
              Descrição do salto
            </Typography>
            <Typography className={classes.textBoldLG}>
              {codigoSalto}
              {posicaoSalto}
            </Typography>
            <Typography className={classes.descSalto}>
              {tipoSalto.loading ? (
                <Skeleton variant="text" />
              ) : (
                <>
                  {tipoSalto.error
                    ? "Nenhum salto Encontrado"
                    : tipoSalto.payload.nome_pt}
                </>
              )}
            </Typography>
            <Typography className={classes.sectionTitle}>
              Nível de dificuldade
            </Typography>
            <Typography className={classes.textBoldLG}>
              {gdSaltos.loading ? (
                <Skeleton variant="text" />
              ) : (
                <>
                  {gdSaltos.error
                    ? ""
                    : gdSaltos.payload.map((item, key) => (
                        <spam
                          key={key}
                          style={{
                            fontSize: "1rem",
                            display: "inline-block",
                            marginRight: 5,
                          }}
                        >
                          {item.altura}M
                          {item.aparelho !== "-" ? ` ${item.aparelho}` : ""}:{" "}
                          {item.gd},
                        </spam>
                      ))}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </div>
  );
}
