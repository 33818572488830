import { Box, Paper } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles/Pessoas";
import ArbitrosSection from "./Arbitros";
import AtletasSection from "./Atletas";
import TecnicosSection from "./Tecnicos";

function PessoasSection({ searchTerm }) {
  const classes = useStyles();

  return (
    <Box>
      <div className={classes.root}>
        <div className={classes.cardTop} />
        <Paper className={classes.noticiasWrapper}>
          <AtletasSection searchTerm={searchTerm} />
          <ArbitrosSection searchTerm={searchTerm} />
          <TecnicosSection searchTerm={searchTerm} />
        </Paper>
        <div className={classes.cardBottom} />
      </div>
    </Box>
  );
}

export default PessoasSection;
