import { Chip } from '@material-ui/core';
import { alpha, withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';


const styledBy = (property, mapping) => (props) => mapping[props[property]];

export default function TagEscopo(props) {
    const theme = useTheme();

    const styles = {
        root: {
            fontFamily: "'Quicksand', sans-serif",
            fontWeight: 600,
            fontSize: styledBy('size', {
                small: '0.8rem',
                large: '1rem',
            }),
            borderRadius: 16,
            lineHeight: 1,
            color: "#fff",
            padding: theme.spacing(1, 0.5),
            backgroundColor: styledBy('escopo', {
                internacional: theme.palette.primary.light,
                nacional: theme.palette.secondary.light,
                estadual: theme.palette.error.light,
            }),
            boxShadow: styledBy('escopo', {
                internacional: `0px 3px 6px ${alpha(theme.palette.info.light, 0.4)}`,
                nacional: `0px 3px 6px ${alpha(theme.palette.secondary.light, 0.4)}`,
                estadual: `0px 3px 6px ${alpha(theme.palette.warning.light, 0.4)}`,
            }),
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(1.7, 1),
                fontSize: styledBy('size', {
                    small: '0.85rem',
                    large: '1.2rem',
                }),
            },
            
        },
    }

    const Tag = withStyles(styles)(({ classes, color, ...other }) => (
        <Chip className={classes.root} {...other} />
    ));

    return (
        <Tag {...props}>
            {props.children}
        </Tag>
    )
}