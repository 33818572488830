import { Divider, Grid, List, ListItem, ListItemText, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { useStyles } from "../styles/calculadoraInfo";
// import clsx from 'clsx';

export default function CalculadoraInfo() {
    const classes = useStyles();

    let notas7 = [
        {
            nota: '8.0',
            strike: true
        }, {
            nota: '8.5',
            strike: false
        }, {
            nota: '8.0',
            strike: false
        }, {
            nota: '8.5',
            strike: false
        }, {
            nota: '9.0',
            strike: true
        }, {
            nota: '8.0',
            strike: false
        }, {
            nota: '8.0',
            strike: false
        },
    ]

    let notas11a = [
        {
            nota: '7.6',
            strike: true
        }, {
            nota: '8.5',
            strike: false
        }, {
            nota: '8.0',
            strike: false
        }, {
            nota: '8.5',
            strike: false
        }, {
            nota: '9.0',
            strike: true
        }, {
            nota: '8.0',
            strike: false
        },
    ]
    let notas11b = [
        {
            nota: '7.8',
            strike: true
        }, {
            nota: '8.5',
            strike: false
        }, {
            nota: '8.0',
            strike: false
        }, {
            nota: '8.5',
            strike: false
        }, {
            nota: '9.2',
            strike: true
        },
    ]


    return (
        <div>
            <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                    <List className={classes.List}>
                        <ListItem>
                            <ListItemText>
                                <Typography>De <b>7</b> a <b>11 Juízes</b> avaliam e pontuam cada salto.
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <Typography>As notas <b>mais baixas</b> e <b>mais altas</b> são descartadas.
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemText>
                                <Typography>O <b>restante</b> das notas é <b>somado</b>, e a soma é <b>multiplicada</b> pelo <b>Nível de Dificuldade do Salto</b>.</Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} sm={12} md={8} className={classes.TabelaGridContainer}>

                    <Grid container alignItems="center" className={classes.tabelaGrid}>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.Table}>
                                <TableHead>
                                    <TableRow>
                                        {Array.from(new Array(7)).map((item, key) => (
                                            <TableCell key={key}>
                                                J{key + 1}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {notas7.map((item, key) => (
                                            <TableCell key={key} className={item.strike ? classes.strikeTD : ''}>
                                                {item.nota}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                        <Divider orientation="vertical" flexItem className={classes.Divider} />
                        <div>
                            <Typography className={classes.notaParcial}>
                                32.50
                            </Typography>
                            <Typography className={classes.numGD}>
                                x3.1
                            </Typography>
                        </div>
                        <Divider orientation="vertical" flexItem className={classes.Divider} />
                        <Typography className={classes.notaFinal}>
                            100.75
                        </Typography>
                        <Divider orientation="horizontal" className={classes.DividerHorizontal} />
                    </Grid>
                    
                    
                    <Grid container alignItems="center" className={classes.tabelaGrid}>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.Table}>
                                <TableHead>
                                    <TableRow>
                                        {Array.from(new Array(6)).map((item, key) => (
                                            <TableCell key={key}>
                                                J{key + 1}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {notas11a.map((item, key) => (
                                            <TableCell key={key} className={item.strike ? classes.strikeTD : ''}>
                                                {item.nota}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <Table className={classes.Table}>
                                <TableHead>
                                    <TableRow>
                                        {Array.from(new Array(5)).map((item, key) => (
                                            <TableCell key={key}>
                                                J{key + 7}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {notas11b.map((item, key) => (
                                            <TableCell key={key} className={item.strike ? classes.strikeTD : ''}>
                                                {item.nota}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                        <Divider orientation="vertical" flexItem className={classes.Divider} />
                        <div>
                            <Typography className={classes.notaParcial}>
                                58.00
                            </Typography>
                            <Typography className={classes.numGD}>
                                x4.0
                            </Typography>
                        </div>
                        <Divider orientation="vertical" flexItem className={classes.Divider} />
                        <Typography className={classes.notaFinal}>
                            232.00
                        </Typography>
                        <Divider orientation="horizontal" className={classes.DividerHorizontal} />
                    </Grid>
                    
                
                </Grid>
            </Grid>
        </div>
    )
}