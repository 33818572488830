import { List, ListItem, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles/CardPorqueApoiar";
import clsx from "clsx";

const listItems = [
  "É um dos esportes olímpicos que mais cresce no país, tendo aumentado o número de praticantes em mais de 300% nos últimos anos",
  "As saltadoras e saltadores brasileiros vêm alcançando resultados expressivos e inéditos nos últimos anos",
  "Indicadores estatísticos mostram que os saltadores brasileiros têm grandes chances de alcançar uma medalha inédita nos próximos Jogos Olímpicos",
  "É um dos esportes olímpicos com maior audiência em todo o mundo (saiba mais)",
  "Atletas brasileiros com expressiva participação e milhares de seguidores nas redes sociais",
  "Competições nacionais e internacionais com transmissão ao vivo em streaming e TV",
  "Esporte com ampla participação feminina, onde há tantas mulheres praticantes quanto homens",
  "Esporte com ampla participação de atletas que apoiam a família e o movimento LGBTQI+",
  "O crescimento e o desenvolvimento do esporte é fruto de um planejamento e trabalho estruturado da Confederação",
  "A Saltos Brasil desenvolve projetos sociais e de massificação nas cinco regiões do país que possibilitam que mais de mil crianças carentes tenham acesso gratuito à prática dos saltos ornamentais, promovendo a democratização do esporte",
  "A Saltos Brasil é uma das confederações olímpicas com modelo de gestão mais moderno e maior transparência, tendo suas contas auditadas anualmente por auditores externos independentes",
  "Tenha total transparência e saiba exatamente para onde está indo o dinheiro que você está apoiando.",
  "Excelência, Precisão, Perfeição, Qualidade, Confiança, Coragem, Ousadia, Superação, Foco, Resiliência, Determinação, são algumas palavras que descrevem os atributos indispensáveis de um bom atleta de saltos ornamentais",
];

function CardPorqueApoiar() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.cardTop}></div>
      <Paper className={classes.root}>
        <div className={clsx(classes.container, classes.containerTop)}>
          <Typography className={classes.heading}>
            Por que apoiar os Saltos ornamentais?
          </Typography>
          <List className={classes.List}>
            {listItems.map((item, key) => (
              <ListItem className={classes.ListItem} key={key}>
                <span className={classes.listIcon}></span>
                {item}
              </ListItem>
            ))}
          </List>
        </div>
      </Paper>
      <div className={classes.cardBottom}></div>
    </div>
  );
}

export default CardPorqueApoiar;
