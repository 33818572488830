import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "#fff",
        borderRadius: '20px 20px 40px 10px',
        paddingBottom: 40,
    },
    heading: {
        padding: theme.spacing(3, 0),
    },
    container: {
        padding: theme.spacing(0, 2.5),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(0, 4),
        },
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 6),
        },
        [theme.breakpoints.up('lg')]: {
            padding: theme.spacing(0, 8),
        },


    },
    headingTitle: {
        color: theme.palette.info.darker,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        fontSize: '1.25rem',
        marginBottom: '0.2em',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.7rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.25rem',
        },

    },
    headingSubtitle: {
        color: theme.palette.secondary.dark80,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        fontSize: '0.85rem',
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.1rem',
        }
    },
    autor: {
        display: 'inline-block',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        color: theme.palette.error.main,
        fontSize: '0.9rem',
        minWidth: 100
    },
    dataNoticia: {
        display: 'inline-block',
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 'bold',
        color: theme.palette.info.dark,
        fontSize: '0.9rem',
        marginLeft: 'auto',
        minWidth: 100

    },
    Divider: {
        backgroundColor: theme.palette.error.main,
        maxWidth: '95%',
        height: 2,
        margin: '10px auto',
        [theme.breakpoints.up('sm')]: {
            maxWidth: '93%',
        }
    },
    Card: {
        borderRadius: 20,
        boxShadow: `0px 3px 6px ${alpha(theme.palette.secondary.dark, 0.4)}`,
    },
    CardMedia: {
        width: '100%',
        height: 240,
        [theme.breakpoints.up('md')]: {
            height: 420,
        },
        [theme.breakpoints.up('lg')]: {
            height: 460,
        },
    },
    corpo: {
        fontFamily: "'Quicksand', sans-serif",
        marginTop: 25,
        fontSize: '1rem',
        fontWeight: 500,
        letterSpacing: '-.02rem',
        overflowWrap: 'break-word',
        lineHeight: 1.2,
        [theme.breakpoints.up('md')]: {
            letterSpacing: '-.03rem',
            marginTop: 40,
            fontSize: '1.125rem',
            lineHeight: 1.5,
        },
        [theme.breakpoints.up('lg')]: {
            lineHeight: 1.7,
            fontSize: '1.15rem',
        },
        '& img': {
            maxWidth: '100%'
        },
    },
    sectionTitulo: {
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 600,
        color: theme.palette.error.main,
        fontSize: '1.05rem',
        marginBottom: 20,
        [theme.breakpoints.up('lg')]: {
            fontSize: '1.20rem', 
        },
    }
}));