import { Button, Checkbox, Container, FormControlLabel, Grid, makeStyles, MenuItem, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import DropdownSelect from '../../components/Form/Dropdown';
import TextInput from '../../components/Form/TextInput';
import ReCAPTCHA from "react-google-recaptcha";
import geraProtocolo from '../../util/geraProtocolo';
import ouvidoriaFields from '../../assets/data/ouvidoriaFields';
import { governancaApi } from '../../services/api';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 30,
        paddingBottom: 100,
        backgroundColor: theme.palette.secondary.dark80,
    },
    title: {
        color: theme.palette.secondary.light,
        fontSize: '1.7rem',
        marginTop: 10,
        paddingBottom: 25,
        fontFamily: "'Quicksand', sans-serif",
        fontWeight: 400,
        [theme.breakpoints.up('md')]: {
            fontSize: '2rem',
        }
    },
    form: {
        marginTop: 25,
    },
    formInfoAlert: {
        [theme.breakpoints.up('md')]: {
            fontSize: '1.1rem',
        },
        '& svg': {
            fontSize: '2rem'
        },
    },
    formControl: {
        [theme.breakpoints.up('md')]: {
            marginBottom: '1rem',
        },
    },
    recaptcha: {
        '& iframe': {
            height: 100
        }
    },
    ControlCheckbox: {
        color: theme.palette.info.light,
        '& span': {
            color: theme.palette.info.light,
        }
    },
}));

const motivos = [
    {
        label: "Dúvidas",
        value: "Dúvidas",
    },
    {
        label: "Sugestão",
        value: "Sugestão",
    },
    {
        label: "Informação Geral",
        value: "Informação Geral",
    },
    {
        label: "Departamento Administrativo",
        value: "Departamento Administrativo",
    },
    {
        label: "Departamento Técnico",
        value: "Departamento Técnico",
    },
    {
        label: "Departamento de Eventos",
        value: "Departamento de Eventos",
    },
    {
        label: "Departamento Jurídico",
        value: "Departamento Jurídico",
    },
    {
        label: "Ouvidoria",
        value: "Ouvidoria",
    },
    {
        label: "Outros",
        value: "Outros",
    }
]

function ContatoPage() {

    const classes = useStyles();

    const [formValues, setFormValues] = useState(ouvidoriaFields)
    const [notRobot, setNotRobot] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');
    const [storedCaptchaToken, setStoredCaptchaToken] = useState('');
    const [selectMotivo, setSelectMotivo] = useState('')
    const [mensagemAnonima, setMensagemAnonima] = useState(false);

    const onChangeCaptcha = (value) => {
        setNotRobot(true);
        setCaptchaToken(value)
    };

    const handleChangeValues = (event) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    };
    const [snackState, setSnackState] = useState({
        severity: '',
        text: '',
        open: false
    })
    const handleCloseSnack = () => {
        setSnackState({
            severity: '',
            text: '',
            open: false
        })
    }

    const handleChangeMotivo = (event) => {
        setSelectMotivo(event.target.value)
    };

    const [protocolo, setProtocolo] = useState("")

    function validateForm(event) {
        event.preventDefault();
        if (notRobot && captchaToken) {
            if (captchaToken === storedCaptchaToken) {
                alert('Robô detectado')
            } else {
                var protocolo = geraProtocolo();
                var datenow = Math.round(new Date() / 1000);
                if (mensagemAnonima) {
                    setFormValues({ ...formValues, nome_solicitante: "anônimo", telefone: "", protocolo: protocolo, updated_at: datenow, created_at: datenow });
                } else {
                    setFormValues({ ...formValues, protocolo: protocolo, updated_at: datenow, created_at: datenow });
                }
                setProtocolo(protocolo)
                setStoredCaptchaToken(captchaToken)
            }
        } else {
            setSnackState({
                severity: "error",
                text: "Valide o captcha para enviar a mensagem!",
                open: true
            })
        }
    }

    useEffect(() => {
        if (formValues.created_at !== '') {

            async function postFornecedor() {
                await governancaApi.post('solicitacao-ouvidoria', formValues
                ).then((response) => {
                    setFormValues({
                        ...formValues,
                        nome_solicitante: "",
                        telefone: "",
                        email: "",
                        motivo: "",
                        mensagem: "",
                        created_at: "",
                        updated_at: "",
                        created_by: "",
                        updated_by: ""
                    })
                    setSnackState({
                        severity: 'success',
                        text: "Mensagem enviada com sucesso!",
                        open: true
                    })
                }).catch((err) => {
                    console.error(err)
                    setFormValues({
                        ...formValues,
                        created_at: "",
                        updated_at: "",
                    })
                    setSnackState({
                        severity: "error",
                        text: "Erro ao enviar mensagem. Verifique o preenchimento dos campos e tente novamente.",
                        open: true
                    })
                    setProtocolo('')
                });
            }
            postFornecedor()
        }
    }, [formValues.created_at, formValues, protocolo])


    return (
        <React.Fragment>
            <Helmet>
                <title>Contato - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais</title>
                <meta name="description" content="Contato - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais" />
            </Helmet>
            <div className={classes.root}>
                <Container maxWidth="md" className={classes.containerMD}>
                    <Typography className={classes.title}>
                        Contato
                    </Typography>

                    {/* <Alert severity="info" className={classes.formInfoAlert}>
                        Este canal é para você se aproximar da CBTKD, enviando seus comentários, sugestões e dúvidas.<br /> Nós teremos o maior prazer em lhe responder.
                    </Alert> */}


                    <form onSubmit={validateForm} className={classes.form}>
                        <Alert severity="success" style={protocolo !== "" ? { marginBottom: '1rem' } : { display: 'none' }}>Seu protocolo é: {protocolo}</Alert>
                        <Grid container spacing={2}>
                            {mensagemAnonima ? '' :
                                <Grid item xs={12}>
                                    <div className={classes.formControl}>
                                        <TextInput fullWidth label="Nome" value={formValues.nome_solicitante} required type="name" name="nome_solicitante" onChange={handleChangeValues} />
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <div className={classes.formControl}>
                                    <TextInput fullWidth label="Email" value={formValues.email} required type="email" name="email" onChange={handleChangeValues} />
                                </div>
                            </Grid>
                            {mensagemAnonima ? '' :
                                <Grid item xs={12}>
                                    <div className={classes.formControl}>
                                        <TextInput fullWidth label="Telefone" value={formValues.telefone} required type="phone" name="telefone" onChange={handleChangeValues} />
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <div className={classes.formControl}>
                                    <DropdownSelect label="Motivo" required selectedValue={selectMotivo} handleChange={handleChangeMotivo}>
                                        {motivos.map((item, key) => (
                                            <MenuItem value={item.value} key={key}>{item.label}</MenuItem>
                                        ))}
                                    </DropdownSelect>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.formControl}>
                                    <TextInput fullWidth multiline rows={8} label="Sua mensagem" value={formValues.mensagem} required type="text" name="mensagem" onChange={handleChangeValues} />
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    className={classes.ControlCheckbox}
                                    control={<Checkbox checked={mensagemAnonima} onChange={() => setMensagemAnonima(!mensagemAnonima)} />}
                                    label="Deseja enviar de forma anônima?"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ReCAPTCHA
                                    className={classes.recaptcha}
                                    sitekey={'6LdTJ2ceAAAAAEwopX-AL4nE1U1GXape9ZKrysSZ'}
                                    onChange={onChangeCaptcha}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5} md={3}>
                                <Button color="secondary" variant='contained' type="submit" border={30}>Enviar</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
                <Snackbar
                    open={snackState}
                    onClose={handleCloseSnack}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    autoHideDuration={6000}
                >
                    <Alert severity={snackState.severity} sx={{ width: '100%' }}>
                        {snackState.text}
                    </Alert>
                </Snackbar>
            </div>

        </React.Fragment>
    )

}

export default ContatoPage;
