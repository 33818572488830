import { Box, Container } from "@material-ui/core";
import ApoieSection from "../home/sections/Apoie";
import { useStyles } from "./styles";
import TransparenciaSection from "../home/sections/Transparencia";
import React from "react";
import { Helmet } from "react-helmet";
import CardPorqueApoiar from "./sections/PagePrincipal/CardPorqueApoiar";
import ApoieSaltosSection from "./sections/Beneficios";

export default function PageApoie() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Apoie - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais
        </title>
        <meta
          name="description"
          content="Apoie - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais"
        />
      </Helmet>
      <div className={classes.pageWrapper}>
        <div className={classes.root}>
          <ApoieSection pageApoie={true} />
          <Container maxWidth="md" className={classes.beneficiosContainer}>
            <ApoieSaltosSection />
            <Box sx={{ py: 10 }}>
              <CardPorqueApoiar />
            </Box>
          </Container>
          {/* <Container>
            <NiveisPatrocinio />
          </Container> */}
        </div>
        <TransparenciaSection />
      </div>
    </React.Fragment>
  );
}
