import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    cardRoot: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.primary.dark80,
        paddingTop: 50,
        paddingBottom: 150,
        borderRadius: '20px 20px 0px 0px',
        [theme.breakpoints.up('md')]: {
            paddingTop: 60,
            paddingBottom: 160, 
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 80,
            paddingBottom: 180, 
        },
        '& *': {
            border: 'none !important',
        }, 
        [theme.breakpoints.up('lg')]: { 
            '& img': {
                maxHeight: '400px !important', 
            },
        }
    },
    
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '20px 20px 0 0',
        background: `linear-gradient(180deg, ${alpha(theme.palette.primary.dark80, 0.2)} 0%, ${theme.palette.primary.dark80} 8%)`,
        backdropFilter: 'blur(30px)',
        paddingTop: 50,
        paddingBottom: 150,
        [theme.breakpoints.up('md')]: {
            paddingTop: 60,
            paddingBottom: 160,
            borderRadius: '60px 60px 0 0',
            backgroundRepeat: 'repeat-x',
            backgroundSize: '72%',
            // background: `radial-gradient(farthest-corner at 42% 50%, ${alpha(theme.palette.info.light, 0.5)} -50%, ${alpha(theme.palette.primary.dark80, 0.2)} 50%)`,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 80,
            paddingBottom: 180,
            borderRadius: '30px 30px 0 0',
            backgroundSize: '60%',
        },
    },
    bgWrapper: {
        background: `linear-gradient(180deg, ${alpha(theme.palette.info.light, 0.2)} 0%, ${theme.palette.primary.dark80} 8%)`,
        borderRadius: '20px 20px 0 0',
        [theme.breakpoints.up('md')]: {
            borderRadius: '60px 60px 0 0',
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: '30px 30px 0 0',
        },
    },
    title: {
        fontSize: '1.6rem',
        fontFamily: "'Quicksand', sans-serif",
        color: theme.palette.warning.light,
        fontWeight: 900,
        marginBottom: 20,
        [theme.breakpoints.up('sm')]: {
            fontSize: '2rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.5rem',
            marginBottom: 30,
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '3rem',
        }
    },
    cardAtletas: {
        position: 'relative',
        border: `4px solid ${theme.palette.warning.main}`,
        borderRadius: '6px 6px 0px 0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'row-reverse',
        '& img': {
            paddingTop: 5,
            maxHeight: 240,
            '&:nth-child(0)': {
                marginRight: -40
            },
            '&:nth-child(4)': {
                marginRight: -60
            },
            '&:nth-child(3)': {
                marginRight: 0
            },
            '&:nth-child(2)': {
                marginRight: -40,
                display: 'none'
            },
            '&:nth-child(1)': {
                marginLeft: -20,
                display: 'none'
            }
        },
        [theme.breakpoints.up('sm')]: {
            '& img': {
                paddingTop: 10,
                maxHeight: 360,
            },
        },
        [theme.breakpoints.up('md')]: {
            minHeight: '40vh',
            '& img': {
                paddingTop: 20,
                maxHeight: 380,
                '&:nth-child(2)': {
                    display: 'block !important'
                },
            },
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: 400,
            '& img': {
                maxHeight: 360,
                '&:nth-child(1)': {
                    display: 'block !important'
                },
            },
        }
    },
    cardAtletasBottom: {
        padding: 25,
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: theme.palette.warning.main,
        borderRadius: '0 0 30px 30px',
        position: 'relative',
    },
    link: {
        color: theme.palette.info.dark100,
        fontSize: '1rem',
        fontWeight: 'bolder',
        cursor: 'pointer',
        fontFamily: "'Quicksand', sans-serif",
        [theme.breakpoints.up('sm')]: {
            fontSize: '1.3rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '1.7rem',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '2.1rem',
        }
    },
    greenGradient1: {
        background: 'radial-gradient(closest-side at 50% 50%, #32E8FF 0%, #004A53 79%, transparent 100%)',
        opacity: 0.25,
        position: 'absolute',
        width: 300,
        height: 300,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            top: '20%',
            left: 0,
        },
        [theme.breakpoints.up('md')]: {
            width: 400,
            height: 400,
        },
        [theme.breakpoints.up('lg')]: {
            left: '10%',
            width: 655,
            height: 661,
            top: '10%',

        }
    },
    greenGradient2: {
        background: 'radial-gradient(closest-side at 50% 50%, #32E8FF 0%, #004A53 79%, transparent 100%)',
        opacity: 0.25,
        position: 'absolute',
        width: 300,
        height: 300,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
            top: '20%',
            right: 0,
        },
        [theme.breakpoints.up('md')]: {
            width: 400,
            height: 400,
        },
        [theme.breakpoints.up('lg')]: {
            right: '8%',
            width: 655,
            height: 661,
            top: '10%',
        }
    },
}));