import { useMediaQuery } from "@material-ui/core";
import { useStyles } from "../styles/Entenda";
import entenda_2400 from '../../../assets/images/entenda_2400.webp';
import entenda_1000 from '../../../assets/images/entenda_1000.webp';

export default function Entenda() {
    const classes = useStyles();
    const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <div className={classes.root}>
            <a href="entenda">
                {matchesLG ?
                    <img src={entenda_2400} alt="" />
                    :
                    <img src={entenda_1000} alt="" />
                }
            </a>
        </div>
        // <div className={classes.entendaBorder}>
        //     <div className={classes.entendaCard}>
        //         <div className={classes.cardWrapper}>
        //             <Typography variant="h2" className={classes.title}>
        //                 Entenda<br /> o Esporte
        //             </Typography>
        //         </div>
        //         <div className={classes.cardInfo}>
        //             <Typography variant="body1">Altura: 10M</Typography>
        //             <Typography variant="body1">Salto: 107B</Typography>
        //             <Typography variant="body1">Dificuldade: 3.0x</Typography>
        //             <Typography variant="body1">Categoria: A</Typography> 
        //             <Typography variant="body1">Formato: Individual</Typography> 
        //         </div>
        //         <div className={classes.cardAction}>
        //             <Button>VER REGRAS E CRITÉRIOS</Button>
        //         </div>
        //     </div>
        // </div>
    )
}