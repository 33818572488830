import { Button, CardContent, ClickAwayListener, Container, Divider, Grow, Paper, Popper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useStyles } from "../styles/gruposSaltos";
import ExemplosSaltos from "./exemplosSaltos";
import TabelaCodigoSaltos from "./tabelaCodigoSaltos";

export default function CodigoSaltos() {
    const classes = useStyles();
    const [activeMenu, setActiveMenu] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);

    const handleClick = (target, index) => {
        setAnchorEl(target);
        setActiveMenu(index);
        setOpenMenu({ [index]: !openMenu[index] })
        if (activeMenu === index) {
            setActiveMenu(0);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(false)
        setActiveMenu(0);
    };

    const tagContent = [
        {
            titulo: "Grupo do Salto",
            content: "1, 2, 3, 4 ou 6",
        }, {
            titulo: "Grupo do Salto",
            content: "1, 2, 3, 4 ou 6",
        }, {
            titulo: "Grupo do Salto",
            content: "1, 2, 3, 4 ou 6",
        }, {
            titulo: "Com ou sem Vôo",
            content: "1, 2, 3, 4 ou 6",
        }, {
            titulo: "Número de meio mortal",
            content: "1, 2, 3, 4 ou 6",
        }, {
            titulo: "número de meio parafuso",
            content: "1, 2, 3, 4 ou 6",
        }, {
            titulo: "posição do corpo",
            content: "A, B, C ou D",
        },
    ]

    return (
        <React.Fragment>
            <CardContent className={classes.cardContent}>
                <Typography>
                    Introdução ao conceito dos diferentes tipos de saltos ornamentais e suas diferenças fundamentais - tipos de saída, movimentos de saída, etc...
                </Typography>
            </CardContent>
            <Divider />
            <ClickAwayListener onClickAway={handleClose}>
                <div className={classes.grupoSaltosWrap}>
                    <Button
                        className={clsx(classes.btnGrupoSalto, { [classes.btnGrupoSaltoActive]: activeMenu === 1 })}
                        onClick={e => handleClick(e.currentTarget, 1)}>6</Button>
                    <Button
                        className={clsx(classes.btnGrupoSalto, { [classes.btnGrupoSaltoActive]: activeMenu === 2 })}
                        onClick={e => handleClick(e.currentTarget, 2)}>5</Button>
                    <Button
                        className={clsx(classes.btnGrupoSalto, { [classes.btnGrupoSaltoActive]: activeMenu === 3 })}
                        onClick={e => handleClick(e.currentTarget, 3)}>#s</Button>
                    <Button
                        className={clsx(classes.btnGrupoSalto, { [classes.btnGrupoSaltoActive]: activeMenu === 4 })}
                        onClick={e => handleClick(e.currentTarget, 4)}>#v</Button>
                    <Button
                        className={clsx(classes.btnGrupoSalto, { [classes.btnGrupoSaltoActive]: activeMenu === 5 })}
                        onClick={e => handleClick(e.currentTarget, 5)}>#g</Button>
                    <Button
                        className={clsx(classes.btnGrupoSalto, { [classes.btnGrupoSaltoActive]: activeMenu === 6 })}
                        onClick={e => handleClick(e.currentTarget, 6)}>#p</Button>
                    <Button
                        className={clsx(classes.btnGrupoSalto, { [classes.btnGrupoSaltoActive]: activeMenu === 7 })}
                        onClick={e => handleClick(e.currentTarget, 7)}>X</Button>
                    <Popper
                        anchorEl={anchorEl}
                        open={openMenu[activeMenu]}
                        onClose={handleClose}
                        role={undefined} transition disablePortal>
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                                <Paper className={classes.menuSalto}>
                                    <div>
                                        <Typography variant="subtitle1" className={classes.tituloMenu}>
                                            {activeMenu ? tagContent[activeMenu - 1].titulo : tagContent[0].titulo}
                                        </Typography>
                                        <Divider className={classes.Divider} />
                                        <div className={classes.menuContent}>
                                            <Typography variant="body1">
                                                {activeMenu ? tagContent[activeMenu - 1].content : tagContent[0].content}
                                            </Typography>
                                        </div>
                                    </div>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </ClickAwayListener>
            <TabelaCodigoSaltos />
            <Container maxWidth="md">
                <Divider className={classes.SectionDivider} />
                <ExemplosSaltos />
            </Container>

            <br />
            <br />
            <br />
            <br />
        </React.Fragment>
    )
}