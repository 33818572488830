import { Box, Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useGaleriaFotos } from "../hooks/useGaleriaFotos";
import { useStyles } from "../styles/Galeria";
import { LoadingGaleria } from "./Galeria";
import { Alert, Pagination } from "@material-ui/lab";

function GaleriaFotosItem({ galeria }) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const { galeriaFotos } = useGaleriaFotos({
    idGaleria: galeria.id,
    page: page,
  });

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  if (galeriaFotos.error) {
    return <Alert severity="warning">Nenhuma foto cadastrada.</Alert>;
  }

  if (galeriaFotos && galeriaFotos.payload) {
    return (
      <Box>
        {galeriaFotos.loading ? (
          <LoadingGaleria />
        ) : (
          <Grid container spacing={1}>
            {galeriaFotos.payload.map((item, key) => (
              <Grid item xs={6} md={3} key={key}>
                <Box className={classes.galeriaItem}>
                  <img
                    src={`https://saltosbrasil.bigmidia.com/_uploads/galeria/fotos/${item.url}`}
                    loading="lazy"
                    alt={item.legenda || item.nome_arquivo_original}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        )}

        {galeria.creditos ? (
          <Box className={classes.creditosGaleria}>
            <Typography color="primary" align="center">
              Créditos: {galeria.creditos}
            </Typography>
          </Box>
        ) : null}

        <Box sx={{ mt: 3 }}>
          {galeriaFotos.meta.pageCount > 1 ? (
            <div className={classes.paginationContainer}>
              <Pagination
                color="primary"
                count={galeriaFotos.meta.pageCount}
                page={page}
                onChange={handleChangePage}
              />
            </div>
          ) : null}
        </Box>
      </Box>
    );
  }

  return <Alert severity="warning">Nenhuma foto cadastrada.</Alert>;
}

export default GaleriaFotosItem;
