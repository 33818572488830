import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "60vh",
    background:
      "transparent linear-gradient(180deg, #231D47 0%, #0E0B1E 90%, #06031D 100%)",
    color: "#fff",
    padding: theme.spacing(6, 0),
    paddingBottom: 100,
    "& .MuiTypography-h1": {
      fontSize: "2.5rem",
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 400,
      marginBottom: "0.5em",
      color: theme.palette.info.light,
    },
    "& .MuiTypography-subtitle1": {
      fontSize: "1.05rem",
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 400,
      marginBottom: "0.5em",
      color: "rgba(255,255,255,0.85)",
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(10, 0),
    },
  },
  cardRoot: {
    padding: theme.spacing(5, 2),
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bold",
    color: theme.palette.primary.darker,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 4, 0, 4),
      fontSize: "1.15rem",
    },
  },
  banner: {
    height: 250,
    width: "100%",
    margin: theme.spacing(6, 0),
    backgroundColor: "rgba(255,255,255,0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiTypography-body1": {
      fontSize: "30px",
    },
  },
  heading: {
    color: theme.palette.primary.light,
    fontFamily: "'Quicksand', sans-serif",
    fontSize: "2.25rem",
    fontWeight: 400,
    textShadow: `0px 10px 10px ${alpha(theme.palette.primary.main, 0.3)}`,
    marginBottom: 50,
  },
  cardWhite: {
    borderRadius: 30,
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: "bold",
    "& .MuiCardContent-root": {
      padding: theme.spacing(5, 4),
    },
    "& .MuiListItemIcon-root": {
      minWidth: 40,
      "& svg": {
        fontSize: 15,
      },
    },
  },
  cardContent: {
    padding: theme.spacing(5, 4),
  },
  darkSection: {
    marginTop: -20,
    paddingTop: 50,
    marginBottom: -70,
    paddingBottom: 100,
    background: "#161138",
    borderRadius: 30,
    zIndex: 10,
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      marginTop: -100,
    },
  },
}));
