import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { useStyles } from "../styles/ProximaCompeticao";
import imgEvento from '../../../assets/images/poster_eventos_mobile_1000.webp';
import RoomIcon from '@material-ui/icons/Room';
import { convertDate } from "../../../util/convertDate";
import { formatDatePeriod } from "../../../util/formatDatePeriod";


import TemplateLink from "../../../components/Links";
import React from "react";
import TagEscopo from "../../../components/Tags/TagEscopo";
import EntendaSection from "./Entenda";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";

export default function ProximaCompeticao(props) {
    const classes = useStyles();
    const { eventos, loadingEventos, errorEventos, onlyCompeticao, bgTransparent } = props;

    const matchesSm = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const matchesMD = useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <section className={onlyCompeticao ? '' : classes.section}>
            <Container className={onlyCompeticao ? classes.containerAuto : classes.container}>
                <div className={onlyCompeticao ? clsx(classes.rootAuto, { [classes.rootTransparent]: bgTransparent }) : classes.root}>
                    {!matchesMD && bgTransparent ? ''
                        :
                        <Typography variant="h2" className={clsx(classes.heading, { [classes.headingBGTransparent]: bgTransparent })}>
                            Próximo Evento:
                        </Typography>
                    }
                    {errorEventos ?
                        <Alert severity="warning">
                            Ainda não há um próximo evento.
                        </Alert>
                        :
                        <div className={clsx(classes.cardWrapper, { [classes.cardWrapperBGTransparent]: bgTransparent })}>
                            <Card className={clsx(classes.CardProximoEvento, { [classes.CardProximoEventoBGTransparent]: bgTransparent })}>
                                <div>
                                    {matchesMD || !bgTransparent ? ''
                                        :
                                        <Typography variant="h2" className={clsx(classes.heading, classes.headingBGTransparent)}>
                                            Próximo Evento:
                                        </Typography>
                                    }
                                </div>
                                <CardActionArea component={Link} to={eventos[0] ? `/competicao/${eventos[0].id}/${createSlug(eventos[0].descricao)}` : ''} className={classes.CardActionArea}>

                                    <Grid container spacing={2}>
                                        <Grid item xs={4} sm={3} md={3} lg={2}>
                                            {loadingEventos ?
                                                <Skeleton animation="wave" variant="rect" className={classes.CardMedia} />
                                                :
                                                <CardMedia
                                                    className={classes.CardMedia}
                                                    component="img"
                                                    src={eventos[0].arte_evento ? `https://saltosbrasil.bigmidia.com/_uploads/relatorioEvento/${eventos[0].arte_evento}` : imgEvento}
                                                    title={eventos[0].descricao}
                                                />
                                            }
                                            <Typography className={classes.DataEvento}>
                                                {eventos[0] ?
                                                    formatDatePeriod(eventos[0].data_inicio, eventos[0].data_fim, true)
                                                    :
                                                    <Skeleton animation="wave" variant="text" />
                                                }
                                            </Typography>
                                            {/* <div className={classes.StatusEvento}>
                                            <Chip size="small" label="PRORROGADO" className={classes.chipProrrogado} />
                                        </div> */}
                                        </Grid>
                                        <Grid item xs={8} sm={9} md={9} lg={10}>
                                            <CardContent className={classes.CardContent}>
                                                <Typography variant="h5" className={classes.EventoTitulo}>
                                                    {eventos[0] ? eventos[0].descricao : <Skeleton animation="wave" variant="text" />}
                                                </Typography>
                                                <Typography className={classes.EventoDescricao}>
                                                    {eventos[0] ? 'Evento anual realizado com clubes cariocas com pontuação válida para o circuito nacional e mais informações interessantes.'
                                                        :
                                                        <Skeleton animation="wave" height={20} variant="text" width="100%" />
                                                    }
                                                </Typography>
                                                {/* <div className={classes.Local}>
                                                    <Avatar className={classes.avatarEvento} />
                                                    {eventos[0] ? <Typography>{eventos[0].local}</Typography> : <Skeleton animation="wave" height={20} variant="text" width="100%" />}
                                                </div> */}
                                                <Typography className={classes.Endereco}>
                                                    <RoomIcon />
                                                    {eventos[0] ? eventos[0].local : <Skeleton animation="wave" variant="text" height={20} width="100%" />}
                                                </Typography>

                                                <Grid container className={classes.cardFooter}>
                                                    <Grid item xs={8} sm={8} md={8} lg={8}>
                                                        {eventos[0] ?
                                                            <Typography className={classes.limiteInscricao}>
                                                                INSCREVA-SE ATÉ {convertDate(eventos[0].data_limit_inscricao)}
                                                            </Typography>
                                                            :
                                                            ''
                                                        }
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4} lg={4}>
                                                        <div className={classes.Escopo}>
                                                            {eventos[0] ?
                                                                <TagEscopo escopo={eventos[0].escopo ? (eventos[0].escopo).toLowerCase() : ''} label={matchesSm ? eventos[0].escopo : `${eventos[0].escopo.slice(0, 3)}.`} size="small" />
                                                                :
                                                                ''
                                                            }
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Grid>
                                    </Grid>
                                </CardActionArea>
                            </Card>
                        </div>
                    }

                    {!bgTransparent ?
                        <div className={classes.linkWrapper}>
                            <TemplateLink color="infoLight" component={Link} to="/competicoes">VER CALENDÁRIO E RESULTADOS</TemplateLink>
                        </div>
                        : ''
                    }

                    {onlyCompeticao ?
                        <React.Fragment></React.Fragment>
                        :
                        <EntendaSection />
                    }
                </div>
            </Container>
        </section>
    )
}