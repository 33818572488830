import { useStyles } from "../styles/Eventos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { convertDateMonthString } from "../../../util/convertDate";
import imgEvento from '../../../assets/images/poster_eventos_mobile_1000.webp';
import React from "react";
import clsx from "clsx";
import { Link } from 'react-router-dom'
import { createSlug } from "../../../util/createSlug";

export default function EventoSection(props) {
    const classes = useStyles();
    const { eventos, loadingEventos, gridLayout } = props;

    const settings = {
        dots: false,
        arrows: false,
        infinite: false,
        autoplay: true,
        swipeToSlide: true,
        autoplaySpeed: 8000,
        speed: 800,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 2,
        lazyLoad: true,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ],

    };

    const testifEventEnd = (evento) => {
        if (evento.data_inicio > (new Date()).toISOString().substr(0, 10)) {
            return (true)
        } else {
            return (false)
        }
    }


    const cardsEventos = (item, loadingEventos) => {
        return (
            <React.Fragment>
                <Card className={clsx(classes.cardEvento, { [classes.GridItem]: gridLayout })}>
                    <CardActionArea disabled={!item} component={Link} to={item ? `/${testifEventEnd(item) ? 'resultado' : 'competicao'}/${item.id}/${item.descricao ? createSlug(item.descricao) : ''}` : ''}>
                        {loadingEventos ?
                            <Skeleton animation="wave" variant="rect" className={classes.cardMedia} />
                            :
                            <CardMedia
                                className={classes.cardMedia}
                                component="img"
                                src={item.arte_evento ? `https://saltosbrasil.bigmidia.com/_uploads/relatorioEvento/${item.arte_evento}` : imgEvento}
                                title={item.descricao}
                            />
                        }

                        <CardContent className={classes.cardContent}>
                            <Typography className={classes.eventoDescricao}>
                                {loadingEventos ?
                                    <React.Fragment>
                                        <Skeleton animation="wave" variant="text" height={30} />
                                        <Skeleton animation="wave" variant="text" height={25} width="80%" />
                                    </React.Fragment>
                                    :
                                    item.descricao}
                            </Typography>
                            <Typography className={classes.eventoData}>
                                {loadingEventos ? <Skeleton animation="wave" variant="text" height={16} width="70%" /> : convertDateMonthString(item.data_inicio)}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
                <div className={classes.cardBottom}></div>
            </React.Fragment>
        )
    }

    if (gridLayout) {
        return (
            <div className={classes.root}>
                <Grid container spacing={2}>
                    {(loadingEventos ? Array.from(new Array(12)) : eventos).map((item, key) => (
                        <Grid xs={12} sm={12} md={3} item key={key} className={clsx(classes.slideItem, classes.cardMargin)}>
                            {cardsEventos(item, loadingEventos)}
                        </Grid>
                    ))}
                </Grid>

            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Slider className={classes.slider} {...settings}>
                {(loadingEventos ? Array.from(new Array(12)) : eventos).map((item, key) => (
                    <div key={key} className={classes.slideItem}>
                        {cardsEventos(item, loadingEventos)}
                    </div>
                ))}
                {cardsEventos(eventos, loadingEventos)}
            </Slider>
        </div>
    )
}