import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 80,
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 30,
    "& .MuiPaginationItem-root": {
      fontSize: "1rem",
      fontWeight: "bolder",
      fontFamily: "'Quicksand', sans-serif",
      color: theme.palette.primary.main,
    },
    "& .MuiPaginationItem-page:hover": {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: alpha(theme.palette.primary.light, 0.3),
      color: theme.palette.primary.main,
    },
  },
  cardTop: {
    height: 10,
    margin: "0px 4%",
    borderRadius: "10px 10px 0 0",
    background: `linear-gradient(to right, ${theme.palette.info.light}, ${theme.palette.primary.light})`,
    [theme.breakpoints.up("sm")]: {
      borderRadius: "15px 15px 0 0",
      height: 15,
      margin: "0px 6%",
    },
    [theme.breakpoints.up("md")]: {
      marginRight: "15%",
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: "4%",
    },
  },
  cardBottom: {
    marginTop: "-25px",
    height: 40,
    width: "80%",
    borderRadius: "4px 4px 60px 20px",
    background: `linear-gradient(to right, ${theme.palette.info.light}, ${theme.palette.primary.light})`,
  },
  noticiasWrapper: {
    position: "relative",
    borderRadius: 6,
    boxShadow: "none",
    minHeight: 100,
    padding: theme.spacing(3, 1.5),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(4, 2.5),
    },
    [theme.breakpoints.up("md")]: {
      borderRadius: 20,
      maxWidth: "85%",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100%",
    },
  },
  cardHeaderLabel: {
    padding: theme.spacing(0, 1.5, 3),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 2.5, 4),
    },
  },
  CardTitle: {
    fontSize: "1.5rem",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 400,
  },
  CardBoletim: {
    background: `linear-gradient(115deg, ${theme.palette.secondary.light2} 20%, ${theme.palette.secondary.light} 100%)`,
    borderRadius: 10,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    "& .MuiTypography-root": {
      color: theme.palette.secondary.darker,
      fontWeight: "bold",
    },
  },
}));
