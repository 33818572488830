import {
  Avatar,
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import SectionTitle from "../../../components/SectionTitle";
import { useStyles } from "../styles/EventoInfo";
import React from "react";
import RoomIcon from "@material-ui/icons/Room";
import { CalendarSVG } from "../../../assets/icons/NavIcons";
import { formatDatePeriod } from "../../../util/formatDatePeriod";
import TagEscopo from "../../../components/Tags/TagEscopo";
import { convertDate } from "../../../util/convertDate";
import { fetchRelatorioEvento } from "../hooks/fetchRelatorios";
import { useQuery } from "@tanstack/react-query";

export default function EventoInfo(props) {
  const { evento, loading, pageResultado } = props;
  const classes = useStyles();
  const matchesMD = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const { data: relatoriosData, isSuccess } = useQuery({
    queryKey: ["relatoriosEvento", evento.id],
    queryFn: () => fetchRelatorioEvento({ id_evento: evento.id }),
  });

  return (
    <div>
      <SectionTitle color="infoLight">Informações da competição:</SectionTitle>
      <Typography variant="h6" className={classes.eventoDescricao}>
        {loading ? (
          <Skeleton variant="text" width={"100%"} height={32} />
        ) : (
          evento.descricao
        )}
      </Typography>
      <Typography variant="body2" className={classes.cardText}>
        {loading ? (
          <Skeleton variant="text" width={"100%"} height={16} />
        ) : (
          <React.Fragment>
            {evento.texto ? <div dangerouslySetInnerHTML={{ __html: evento.texto || '' }}/> : ""}
          </React.Fragment>
        )}
      </Typography>
      <div className={classes.eventoLocal}>
        <Avatar />
        <Typography variant="body1">--</Typography>
      </div>
      <div className={classes.eventInfo}>
        <div className={classes.eventInfoLeft}>
          <div className={classes.eventLocal}>
            <RoomIcon />
            <Typography variant="body1">
              {evento.local ? evento.local : ""}
            </Typography>
            {!matchesMD ? (
              <div style={{ marginLeft: "auto" }}>
                <TagEscopo
                  escopo={loading ? "" : evento.escopo.toLowerCase()}
                  label={loading ? "" : evento.escopo}
                  size="small"
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <Typography className={classes.eventData}>
            <CalendarSVG />
            {formatDatePeriod(evento.data_inicio, evento.data_fim, true)}
          </Typography>
          {matchesMD ? (
            <TagEscopo
              escopo={loading ? "" : evento.escopo.toLowerCase()}
              label={loading ? "" : evento.escopo}
              size="small"
            />
          ) : (
            ""
          )}
        </div>
        {!pageResultado ? (
          <Typography className={classes.dataInscricao}>
            Inscreva-se até:{" "}
            {evento.data_limit_inscricao
              ? convertDate(evento.data_limit_inscricao)
              : ""}
          </Typography>
        ) : null}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      >
        <React.Fragment>
          {isSuccess && relatoriosData.length
            ? relatoriosData.map((item, key) => (
                <Box sx={{ m: "0 5px" }}>
                  <Button
                    target="_blank"
                    rel="noreferrer"
                    href={`https://saltosbrasil.bigmidia.com/_uploads/relatorioEvento/${item.arquivo}`}
                    className={classes.btnCertificado}
                  >
                    {item.descricao}
                  </Button>
                </Box>
              ))
            : null}
          {/* <div style={{ margin: "0 5px" }}>
              <Button
                target="_blank"
                rel="noreferrer"
                href={
                  "https://saltosbrasil.bigmidia.com/site/resultado-saltos-evento?fase=e&id=" +
                  evento.id
                }
                className={classes.btnCertificado}
              >
                Resultado - Elim
              </Button>
            </div>
            <div style={{ margin: "0 5px" }}>
              <Button
                target="_blank"
                rel="noreferrer"
                href={
                  "https://saltosbrasil.bigmidia.com/site/resultado-saltos-evento?fase=s&id=" +
                  evento.id
                }
                className={classes.btnCertificado}
              >
                Resultado - Semi
              </Button>
            </div>
            <div style={{ margin: "0 5px" }}>
              <Button
                target="_blank"
                rel="noreferrer"
                href={
                  "https://saltosbrasil.bigmidia.com/site/resultado-saltos-evento?fase=f&id=" +
                  evento.id
                }
                className={classes.btnCertificado}
              >
                Resultado - Final
              </Button>
            </div> */}
        </React.Fragment>
      </div>
    </div>
  );
}
