import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./styles";
import clsx from "clsx";

const tiposApoio = [
  {
    titulo: "Sócio Torcedor:",
    texto:
      "Contribua com seu clube e seu atleta predileto com um valor a partir de R$ 10 reais que será debitado mensalmente do seu cartão de crédito e tenha acesso a milhares de benefícios.",
    buttons: [
      {
        url: "https://saltosbrasil.bigmidia.com/amigodoesporte/cadastro",
        text: "Seja um Sócio Torcedor",
        style: "contained",
      },
    ],
  },
  {
    titulo: "Padrinho:",
    texto:
      " Ceda a sua imagem e engaje para que a Saltos Brasil tenha mais Sócios Torcedores e mais recursos para investir nos saltos ornamentais do Brasil",
    buttons: [
      {
        url: "/apoie-os-saltos-ornamentais/padrinho",
        text: "Seja um Padrinho",
        style: "contained",
      },
    ],
  },
  {
    titulo: "Doador:",
    texto:
      "Faça uma doação de qualquer valor sempre que quiser. O valor será integralmente aplicado no desenvolvimento dos saltos ornamentais do Brasil e poderá ser integralmente deduzido do valor a ser pago do imposto de renda seu ou da sua empresa.",
    buttons: [
      {
        url: "/apoie-os-saltos-ornamentais/doador",
        text: "Seja um Doador",
        style: "contained",
      },
    ],
  },
  {
    titulo: "Patrocine:",
    texto:
      "Invista nos saltos ornamentais e atrele a imagem da sua empresa a um dos esportes olímpicos de maior beleza e maior audiência no mundo.",
    buttons: [
      {
        url: "/apoie-os-saltos-ornamentais/patrocine",
        text: "Seja um Patrocinador",
        style: "contained",
      },
    ],
  },
  {
    titulo: "Benefícios do Sócio Torcedor Saltos Brasil:",
    texto:
      "Faça parte do Clube de Vantagens Saltos Brasil e tenha benefícios em milhares de estabelecimentos em todo o país (Saiba mais)",
    buttons: [
      {
        url: "/apoie-os-saltos-ornamentais/beneficios",
        text: "Clube de vantagens",
        style: "contained",
      },
    ],
  },
  {
    titulo: "Transparência:",
    texto:
      "Saiba quanto está sendo arrecadado e como está sendo investido o seu dinheiro",
    buttons: [
      {
        url: "/apoie-os-saltos-ornamentais/transparencia",
        text: "Transparência",
        style: "outlined",
      },
    ],
  },
];

function CardApoie() {
  const classes = useStyles();

  return (
    <Box sx={{ mb: 5 }}>
      {tiposApoio.map((item, key) => (
        <Box className={clsx(classes.section, `section-${key + 1}`)} key={key}>
          <div className="sectionTop">{item.titulo}</div>
          <Typography className="sectionText">{item.texto}</Typography>
          <div className={classes.sectionAction}>
            {item.buttons.map((item, index) => (
              <Button key={index} href={item.url} variant={item.style}>
                {item.text}
              </Button>
            ))}
          </div>
        </Box>
      ))}
    </Box>
  );
}

export default CardApoie;
