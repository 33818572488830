import { Button, InputLabel, OutlinedInput, withStyles } from "@material-ui/core";

export const Input = withStyles((theme) => ({
    root: {
        minHeight: '2.7rem',
        borderRadius: 30,
        color: '#fff',
        '& fieldset': {
            padding: '0 15px',
            border: `2px solid ${theme.palette.info.main} !important`,
        },
        '& input': {
            padding: '3px 30px',
            '&::placeholder': {
                color: theme.palette.info.main,
                opacity: 1
            },
            '&:-webkit-autofill':{
                padding: '11px 30px',
            }
        }

    }
}))(OutlinedInput);

export const Label = withStyles((theme) => ({
    root: {
        top: '-5px',
        left: '8px',
        color: theme.palette.info.main,
        '&.Mui-focused': {
            color: theme.palette.info.main,
        }
    }
}))(InputLabel);

export const SearchInputButton = withStyles((theme) => ({
    root: {
        color: theme.palette.info.main,
        padding: '8px 20px',
        borderRadius: 30,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        marginRight: -12,
    }
}))(Button);
