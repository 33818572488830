import { useEffect, useState } from "react";
import { portalApi } from "../../../services/api";

export const useGaleriaFotos = ({ idGaleria, page = 1 }) => {
  const [galeriaFotos, setGaleriaFotos] = useState({
    error: false,
    loading: true,
    payload: [],
    meta: {},
  });

  useEffect(() => {
    if (idGaleria) {
      setGaleriaFotos({
        error: false,
        loading: true,
        payload: [],
        meta: {},
      });

      async function getGaleriaFotos() {
        await portalApi
          .get("galeria-fotos", {
            params: { id_galeria: idGaleria, pageSize: 8, page: page },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setGaleriaFotos({
                error: true,
                loading: false,
                payload: [],
                meta: {},
              });
            } else {
              setGaleriaFotos({
                error: false,
                loading: false,
                payload: response.data.items,
                meta: response.data._meta,
              });
            }
          })
          .catch((err) => {
            setGaleriaFotos({
              error: true,
              loading: false,
              payload: [],
              meta: {},
            });
            console.log(err);
          });
      }

      getGaleriaFotos();
    }
  }, [idGaleria, page]);

  return { galeriaFotos };
};
