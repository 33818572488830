import {
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import TagEscopo from "../../../components/Tags/TagEscopo";
import RoomIcon from "@material-ui/icons/Room";
import { useStyles } from "../styles/CardEvento";
import { convertDate } from "../../../util/convertDate";
import { formatDatePeriod } from "../../../util/formatDatePeriod";

export default function CardEventoCompeticao(props) {
  const { evento, loading } = props;
  const classes = useStyles();
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <div className={clsx(classes.cardEvento, classes.cardEventoResultado)}>
      <Card className={clsx(classes.Card, classes.CardResultado)} elevation={0}>
        <CardContent className={classes.CardContent}>
          <div>
            <CardMedia className={classes.CardMedia} src={evento.arte_evento} />
            <Typography className={classes.dataEvento}>
              {loading
                ? ""
                : formatDatePeriod(
                  evento.data_inicio,
                  evento.data_fim,
                  true,
                  true
                )}
            </Typography>
          </div>
          <div className={classes.cardInfo}>
            <Typography variant="h6" className={classes.cardTitle}>
              {loading ? (
                <Skeleton variant="text" width={"100%"} height={32} />
              ) : (
                evento.descricao
              )}
            </Typography>
            <Typography className={classes.cardText}>
              {loading ? (
                <Skeleton variant="text" width={"100%"} height={16} />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: evento.texto.replace("<p>&nbsp;</p>", "") || '' }} />
              )}
            </Typography>

            <Typography variant="caption">
              <Avatar />
              {loading ? (
                <Skeleton variant="text" width={150} height={16} />
              ) : (
                "--"
              )}
            </Typography>

            <Typography variant="caption">
              <RoomIcon />
              {loading ? (
                <Skeleton variant="text" width={150} height={16} />
              ) : (
                evento.local
              )}
            </Typography>
            {matchesSm ? (
              ""
            ) : (
              <div className={classes.eventoLocal}>
                <Typography variant="subtitle2" className={classes.dataLimite}>
                  INSCREVA-SE ATÉ {convertDate(evento.data_inicio)}
                </Typography>
                <TagEscopo
                  escopo={loading ? "" : evento.escopo.toLowerCase()}
                  label={loading ? "" : evento.escopo.slice(0, 3)}
                  size="small"
                />
              </div>
            )}
          </div>
          {matchesSm ? (
            <div className={classes.tagEvento}>
              <TagEscopo
                escopo={loading ? "" : evento.escopo.toLowerCase()}
                label={loading ? "" : evento.escopo}
                size="small"
              />
              <Typography variant="subtitle2" className={classes.dataLimite}>
                INSCREVA-SE ATÉ {convertDate(evento.data_inicio)}
              </Typography>
            </div>
          ) : (
            ""
          )}
        </CardContent>
      </Card>
    </div>
  );
}
