import { useEffect, useState } from "react";
import { portalApi } from "../../../services/api";

export const useGaleria = ({ idEvento }) => {
  const [galeria, setGaleria] = useState({
    error: false,
    loading: true,
    payload: [],
  });

  useEffect(() => {
    setGaleria({
      error: false,
      loading: true,
      payload: [],
    });

    async function getEventoAtelta() {
      await portalApi
        .get("galeria", {
          params: { id_evento: idEvento },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setGaleria({
              error: true,
              loading: false,
              payload: [],
            });
          } else {
            setGaleria({
              error: false,
              loading: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          setGaleria({
            error: true,
            loading: false,
            payload: [],
          });
          console.log(err);
        });
    }

    getEventoAtelta();
  }, [idEvento]);

  return { galeria };
};
