import {
  Avatar,
  Button,
  ButtonBase,
  Card,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { createSlug } from "../../util/createSlug";
import { useStyles } from "../home/styles/Transparencia";
import clsx from "clsx";
import { PlusIconSVG } from "../../assets/icons/Transparencia";
import { Alert } from "@material-ui/lab";
import _ from "lodash";
import { gestaoApi } from "../../services/api";
import { getCardIcon } from "./CardIcons";

function TransparenciaRecursosHumanos(props) {
  const { item, expanded, index, expandedAnexos, handleClickAnexos } = props;
  const classes = useStyles();

  const [pessoas, setPessoas] = useState({
    loading: true,
    error: true,
    payload: [],
  });

  useEffect(() => {
    setPessoas({
      loading: true,
      error: false,
      payload: [],
    });
    async function getPessoas() {
      await gestaoApi
        .get("pessoa", {
          params: {
            flag_del: 0,
            id_estabelecimento: 1,
            sort: "nome_completo",
            // 'contas.id_servico': 19,
            expand: "departamento,cargo",
            pageSize: 100,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setPessoas({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            let _departamento = _.filter(response.data.items, function (o) {
              return o.id_departamento !== null;
            });
            // let _grouped = _.groupBy(_departamento, "id_departamento");
            let _grouped2 = _.groupBy(_departamento, "departamento.descricao");
            let group = Object.entries(_grouped2);
            let PessoasDepartamento = group.map((item, key) => ({
              descricao: item[0],
              pessoas: _.orderBy(item[1], ["nome_completo"], ["asc"]),
            }));

            setPessoas({
              loading: false,
              error: false,
              payload: _.orderBy(PessoasDepartamento, ["descricao"], ["asc"]),
            });
          }
        })
        .catch((err) => {
          setPessoas({
            loading: false,
            error: true,
            payload: [],
          });
          console.log(err);
        });
    }
    getPessoas();
  }, []);

  return (
    <React.Fragment key={index}>
      <div className={classes.GridContainer}>
        <Container className={classes.contentContainer}>
          <Grid item xs={12} sm={12} md={12} style={{ zIndex: 4 }}>
            <Link
              to={
                expanded[item.id_menu]
                  ? "/transparencia"
                  : `/transparencia?q=${item.slug}`
              }
              className={classes.linkTransparencia}
            >
              <Card
                className={clsx(classes.cardItem, {
                  [classes.cardItemExpanded]: expanded[item.id_menu],
                })}
              >
                <Avatar>{getCardIcon(item.icon)}</Avatar>
                <Typography
                  className={clsx(classes.cardTitle, classes.cardTitleLG)}
                >
                  {item.title}
                </Typography>
              </Card>
            </Link>
          </Grid>
        </Container>
      </div>
      <Container className={classes.CollapseContainer}>
        <Collapse in={expanded[item.id_menu]} className={classes.Collapse}>
          <div className={classes.collapseContent}>
            {pessoas.error ? (
              <div className={classes.errorContainer}>
                <Alert severity="error">
                  Não foi encontrado registros para este item.
                </Alert>
              </div>
            ) : pessoas.loading ? (
              ""
            ) : (
              <React.Fragment>
                {pessoas.payload.map((item, key) => (
                  <div key={key}>
                    <ButtonBase
                      disableRipple
                      className={classes.docTitleWrapper}
                      onClick={(e) => handleClickAnexos(e.currentTarget, key)}
                    >
                      <Typography className={classes.arquivoTitle}>
                        {item.descricao ? item.descricao.toUpperCase() : ""}
                      </Typography>
                      <IconButton
                        className={clsx(classes.btnExpandAnexos, {
                          [classes.btnRotate]: expandedAnexos[key],
                        })}
                        onClick={(e) => handleClickAnexos(e.currentTarget, key)}
                      >
                        <PlusIconSVG />
                      </IconButton>
                    </ButtonBase>
                    <Collapse
                      in={expandedAnexos[key]}
                      className={classes.AnexosCollapse}
                    >
                      {item ? (
                        <Paper className={classes.anexosContainer}>
                          <List>
                            {item.pessoas.map((pessoa, key) => (
                              <ListItem key={key}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  component={Link}
                                  to={`/transparencia/pessoa/${
                                    pessoa.id
                                  }/${createSlug(pessoa.nome_completo)}`}
                                  className={classes.btnDetalhesPessoa}
                                >
                                  <PlusIconSVG />
                                  Mais detalhes
                                </Button>
                                <Typography className={classes.nomePessoa}>
                                  {pessoa.nome_completo}
                                </Typography>
                              </ListItem>
                            ))}
                          </List>
                        </Paper>
                      ) : (
                        ""
                      )}
                    </Collapse>
                    <Divider
                      className={classes.Divider}
                      style={expandedAnexos[key] ? { marginTop: 0 } : {}}
                    />
                  </div>
                ))}
              </React.Fragment>
            )}
          </div>
        </Collapse>
      </Container>
    </React.Fragment>
  );
}

export default TransparenciaRecursosHumanos;
