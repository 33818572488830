import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import React, { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DetalhesSalto from "./DetalhesSalto";
import TabelaSaltos from "./TabelaSaltos";
import { Alert } from "@material-ui/lab";
import { useStyles } from "../styles/TabelaAtleta";
import { Link } from "react-router-dom";
import { createSlug } from "../../../util/createSlug";
import { formataNotas } from "../functions/formataNotas";
import { fetchEventoAtleta } from "../hooks/fetchEventoAtleta";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import moment from "moment";

function TableAtletaResultado({ provaParams }) {
  const classes = useStyles();
  const [atletaAtual, setAtletaAtual] = useState(null);
  const [boletimSalto, setBoletimSalto] = useState({ salto_id: "" });
  const [saltoAtual, setSaltoAtual] = useState(null);
  const idEvento = provaParams.evento.id;

  // caso o evento esteja entre a data atual o staletime não mantem cache
  const isBetweenDate = moment().isBetween(
    provaParams.evento.data_inicio,
    provaParams.evento.data_fim
  );

  useEffect(() => {
    return () => {
      setAtletaAtual(null);
      setBoletimSalto({ salto_id: "" });
      setSaltoAtual(null);
    };
  }, [provaParams.idProva]);

  const { data, isSuccess, isLoading, isFetching } = useQuery({
    queryKey: ["eventoAtleta", provaParams.idProva, provaParams.faseAtual],
    queryFn: () =>
      fetchEventoAtleta({
        idEvento: idEvento,
        idProva: provaParams.idProva,
        faseAtual: provaParams.faseAtual,
        tabIndexFormato: provaParams.tabIndexFormato,
      }),
    keepPreviousData: true,
    staleTime: isBetweenDate ? 0 : Infinity,
  });

  const expandSaltosAtleta = (e, index) => {
    setAtletaAtual(null);
    setBoletimSalto({ salto_id: "" });
    if (index === saltoAtual) {
      setSaltoAtual(null);
    } else {
      setSaltoAtual(index);
    }
  };

  const expandProva = (e, salto, index) => {
    setAtletaAtual(index);
    if (salto.notas_arbitros) {
      let notas;
      let Exec;
      let Sinc;

      let notasSaltos;
      let notasSaltosSinc;
      
      if (provaParams.tabIndexFormato === 1) {
        notas = salto.notas_arbitros.split("/");
        Exec = notas[0].replace("Exec:", "").replace("EXEC:", "").split("|");
        if (notas[1]) {
          Sinc = notas[1].replace("Sinc:", "").replace("SINC:", "").split("|");
          notasSaltosSinc = [formataNotas(Exec), formataNotas(Sinc)];
        } else {
          notasSaltosSinc = [formataNotas(Exec)];
        }
      } else {
        notasSaltos = formataNotas(salto.notas_arbitros.split("|"));
      }

      let _saltos = {
        ...salto,
        salto_id: `${index}-${salto.num_ordem}`,
        ...notasSaltos,
        notasSaltosSinc,
      };

      if (boletimSalto.salto_id === `${index}-${salto.num_ordem}`) {
        setAtletaAtual(null);
        setBoletimSalto({ salto_id: "" });
      } else {
        setBoletimSalto(_saltos);
      }
    }
    setSaltoAtual(null);
  };

  const atletaIndividual = (item) => {
    if (item.atleta) {
      if (item.atleta.atleta) {
        return (
          <TableCell>
            <div className={classes.cellAtleta}>
              <Avatar
                src={`https://saltosbrasil.bigmidia.com/_uploads/fotoAtleta/${item.atleta.atleta.foto}`}
                alt={item.atleta.atleta.foto ? item.atleta.nome_evento : ""}
              />
              <Link
                to={`/atleta/${item.atleta.atleta.id}/${createSlug(
                  item.atleta.nome_evento
                )}`}
              >
                <div className={classes.nomeCompletoAtleta}>
                  <Typography variant="caption">
                    {item.atleta.nome_evento.split(" ")[0]}
                  </Typography>
                  <Typography>
                    {item.atleta.nome_evento.split(" ").slice(1).join(" ")}
                  </Typography>
                </div>
              </Link>
            </div>
          </TableCell>
        );
      } else {
        return <TableCell></TableCell>;
      }
    } else {
      return <TableCell></TableCell>;
    }
  };

  const atletasDuplas = (item) => {
    if (item.atleta) {
      if (item.atleta[0]) {
        return item.atleta.map((atleta, key) => (
          <TableCell key={key}>
            <div className={clsx(classes.cellAtleta, classes.cellAtletaDupla)}>
              <div className="atletaWrapper">
                <Avatar
                  src={`https://saltosbrasil.bigmidia.com/_uploads/fotoAtleta/${atleta.atleta.foto}`}
                  alt={atleta.atleta.foto ? atleta.nome_evento : ""}
                />
                <Link
                  to={`/atleta/${atleta.atleta.id}/${createSlug(
                    atleta.nome_evento
                  )}`}
                >
                  <div className={classes.nomeCompletoAtleta}>
                    <Typography variant="caption">
                      {atleta.nome_evento.split(" ")[0]}
                    </Typography>
                    <Typography>
                      {atleta.nome_evento.split(" ").slice(1).join(" ")}
                    </Typography>
                  </div>
                </Link>
              </div>
            </div>
          </TableCell>
        ));
      } else {
        return <TableCell colSpan={2}>-</TableCell>;
      }
    } else {
      return <TableCell colSpan={2}>-</TableCell>;
    }
  };


  const handleColocacao = (eventoAtletaItem) => {
    if (provaParams.faseAtual === 'f') return eventoAtletaItem.colocacao_f
    if (provaParams.faseAtual === 'e') return eventoAtletaItem.colocacao_e
    if (provaParams.faseAtual === 's') return eventoAtletaItem.colocacao_s
  }

  return (
    <TableContainer>
      <Table size="small" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell colSpan={provaParams.tabIndexFormato === 0 ? 1 : 2}>
              Atletas
            </TableCell>
            {!isLoading || !isFetching ? (
              <React.Fragment>
                {isSuccess && data.length ? (
                  <React.Fragment>
                    {Array.from(new Array(data[0].boletimSaltos.length)).map(
                      (item, key) => (
                        <TableCell
                          key={key}
                          align="center"
                          className={clsx(classes.SNthead, {
                            [classes.SNtheadLast]:
                              key === data[0].boletimSaltos.length - 1,
                          })}
                        >
                          S{key + 1}
                        </TableCell>
                      )
                    )}
                  </React.Fragment>
                ) : (
                  ""
                )}
              </React.Fragment>
            ) : (
              ""
            )}
            <TableCell>TOTAL</TableCell>
          </TableRow>
        </TableHead>

        {isLoading || isFetching ? (
          <TableBody>
            <TableRow>
              <TableCell colSpan={8}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: 100,
                  }}
                >
                  <CircularProgress />
                </div>
              </TableCell>
            </TableRow>
          </TableBody>
        ) : (
          <TableBody className={classes.tableBody}>
            {isSuccess && data.length ? (
              <React.Fragment>
                {data.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>
                        <Typography className="posicao">
                          {handleColocacao(item)}
                        </Typography>
                      </TableCell>
                      {provaParams.tabIndexFormato === 0
                        ? atletaIndividual(item)
                        : atletasDuplas(item)}
                      {!item.boletimSaltos[0] ? (
                        <TableCell colSpan={data[0].boletimSaltos.length}>
                          -
                        </TableCell>
                      ) : (
                        <React.Fragment>
                          {saltoAtual === index ? (
                            <TableCell
                              align="center"
                              colSpan={data[0].boletimSaltos.length}
                            >
                              <div className={classes.clubeAtleta}>
                                {provaParams.tabIndexFormato === 0 ? (
                                  <React.Fragment>
                                    {item.atleta.atleta.estabelecimento
                                      .descricao ? (
                                      <React.Fragment>
                                        {
                                          item.atleta.atleta.estabelecimento
                                            .descricao
                                        }
                                        <Avatar
                                          src={`https://saltosbrasil.bigmidia.com/_uploads/estabelecimento/logo/${item.atleta.atleta.estabelecimento.url_logo}`}
                                        />
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </React.Fragment>
                                ) : (
                                  ""
                                )}
                              </div>
                            </TableCell>
                          ) : (
                            item.boletimSaltos.map((item, key) => (
                              <TableCell align="center" key={key}>
                                <div className={classes.btnNotaWrapper}>
                                  {/* {console.log('item', item)} */}
                                  <Button
                                    size="small"
                                    disabled={!item.nota_final}
                                    className={clsx(classes.btnPontos, {
                                      [classes.btnPontosActive]:
                                        `${index}-${item.num_ordem}` === boletimSalto.salto_id,
                                    })}
                                    onClick={(e) => expandProva(e, item, index)}
                                  >
                                    {item.nota_final ? item.nota_final : "0.00"}
                                  </Button>
                                  {`${index}-${item.num_ordem}` ===
                                    boletimSalto.salto_id ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </TableCell>
                            ))
                          )}
                        </React.Fragment>
                      )}
                      <TableCell>
                        <Button
                          disabled={!item.boletimSaltos[0]}
                          className={clsx(classes.chipPontuacao, {
                            [classes.chipPontuacaoPrimeiro]: index === 0,
                            [classes.chipPontuacaoExtra]:
                              item.flag_extra !== 0 && (() => {
                                return (
                                  provaParams.faseAtual === 'e' ?
                                    item.flag_extra & 1
                                    : provaParams.faseAtual === 's' ?
                                      item.flag_extra & 2
                                      : //provaParams.faseAtual === 'f' ?
                                      item.flag_extra & 4
                                )
                              })()
                          })}
                          onClick={(e) => expandSaltosAtleta(e, index)}
                        >
                          {item.notaTotalProva
                            ? Number(item.notaTotalProva).toFixed(2)
                            : "0.00"}
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={
                          provaParams.tabIndexFormato === 0
                            ? item.boletimSaltos.length + 3
                            : item.boletimSaltos.length + 4
                        }
                        className={classes.saltoDetailsTD}
                      >
                        <Collapse in={atletaAtual === index}>
                          <DetalhesSalto
                            isSincronizado={
                              provaParams.tabIndexFormato === 0 ? false : true
                            }
                            boletimSalto={boletimSalto}
                          />
                        </Collapse>
                        <Collapse in={saltoAtual === index}>
                          <TabelaSaltos
                            isSincronizado={
                              provaParams.tabIndexFormato === 0 ? false : true
                            }
                            eventoAtleta={item}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              <TableRow>
                <TableCell colSpan={provaParams.tabIndexFormato === 1 ? 4 : 3}>
                  <Alert severity="error">
                    Nenhum atleta encontrado nesta etapa
                  </Alert>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
}

export default TableAtletaResultado;
