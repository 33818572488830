import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        background: '#161138',
        maxWidth: '100%',
        overflow: 'hidden',
    },
    root: {
        background: '#161138',
        borderRadius: '0px 0px 30px 30px',
        zIndex: 10,
        position: 'relative',
        paddingBottom: 100,
    },
    beneficiosContainer: {
        [theme.breakpoints.up('lg')]: {
            marginTop: '-8%',
            position: 'relative',
            zIndex: 100
        }
    },
    darkSection: {
        marginTop: -20,
        paddingTop: 50,
        marginBottom: -70,
        paddingBottom: 100,
        background: '#161138',
        borderRadius: 30,
        zIndex: 10,
        position: 'relative',
        [theme.breakpoints.up('lg')]: {
            marginTop: -100,
        }
    },
}))