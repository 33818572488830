import { Card, CardMedia, Divider, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { convertDateMonthString } from "../../../util/convertDate";
import { useStyles } from '../style/NoticiaSection';
import { Skeleton } from "@material-ui/lab";
import CardsNoticas from "../../home/sections/CardsNoticias";
import placeholder_img from '../../../assets/images/placeholder_img.webp'
export default function NoticiaSection(props) {
    const classes = useStyles();
    const { noticia, loading, noticias, loadingNoticias } = props;
    return (
        <React.Fragment>
            <Helmet>
                <title>{noticia.titulo ? noticia.titulo : ''} - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais</title>
                <meta name="description" content={`${noticia.titulo ? noticia.titulo : ''}  - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais`} />
            </Helmet>
            <Paper className={classes.root}>
                <div className={classes.heading}>
                    <div className={classes.container}>
                        <Typography variant="h1" className={classes.headingTitle}>
                            {noticia.titulo ? noticia.titulo : <Skeleton animation="wave" variant="text" height="2.5rem" />}
                        </Typography>
                        <Typography variant="subtitle1" className={classes.headingSubtitle}>
                            {noticia.resumo ? noticia.resumo : <Skeleton animation="wave" variant="text" width="80%" />}
                        </Typography>
                    </div>
                    <Divider className={classes.Divider} />
                    <div className={classes.container}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Typography variant="caption" className={classes.autor}>
                                    {noticia.user ? noticia.user.idPessoa.nome_completo : <Skeleton animation="wave" variant="text" />}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="caption" className={classes.dataNoticia}>
                                    {noticia.created_at ? convertDateMonthString(noticia.created_at * 1000) : <Skeleton animation="wave" variant="text" />}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Card className={classes.Card}>
                    {loading ?
                        <Skeleton animation="wave" variant="rect" className={classes.CardMedia} />
                        :
                        <CardMedia className={classes.CardMedia}
                            component="img"
                            onError={e => { e.target.src = `${placeholder_img}` }}
                            src={noticia.urlFotoCapa}
                            title={noticia.titulo ? noticia.titulo : ''}
                        />
                    }
                </Card>
                <div className={classes.container}>
                    <div className={classes.corpo} dangerouslySetInnerHTML={{ __html: noticia.corpo || '' }}>

                    </div>
                </div>
                <Divider className={classes.Divider} />
                <div className={classes.container}>
                    <Typography className={classes.sectionTitulo}>
                        Artigos relacionados
                    </Typography>
                    <CardsNoticas noContainer={true} noticias={noticias} loadingNoticias={loadingNoticias} />
                </div>

            </Paper>
        </React.Fragment>
    )
}