
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent:'center',
        height: 620,
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            height: 650,
        },
        [theme.breakpoints.up('md')]: {
            height: 700,
        },
        [theme.breakpoints.up('lg')]: {
            height: 570,
            justifyContent:'right'
        },
    }, 
    Heading: {
        color: '#FFFDEE',
        fontSize: '2.6rem',
        marginTop: 'auto',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontWeight: 600,
        letterSpacing: '0.04em',
        fontFamily: "'Quicksand', sans-serif",
        textShadow: '-18px 8px 10px rgba(0,0,0,0.4)',
        [theme.breakpoints.up('sm')]: {
            fontSize: '4rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '5rem',
        },
        [theme.breakpoints.up('lg')]: {
            textAlign: 'left',
            fontSize: '5.5rem',
            textShadow: '1px 4px 10px rgba(0,0,0,0.4)',
            marginLeft: 'auto',
            width: 500
        }
    },
}));

