import { useStyles } from "../style/CardListEventos";
import { Button, ListItemText, Menu, MenuItem, Paper, Typography } from "@material-ui/core";
import CardEvento from "./CardEvento";
import clsx from "clsx";
import React, { useState } from "react";
import meses from "../../../assets/data/meses";
import { Alert, AlertTitle, Pagination, Skeleton } from "@material-ui/lab";
import { temporadas } from "../../../assets/data/temporadas";
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { escopo } from "../../../assets/data/escopo";

export default function CardListEventos(props) {
    const classes = useStyles();
    const {
        tipoCard,
        eventos,
        loadingEventos,
        errorEventos,
        selectedTemporada,
        setSelectedTemporada,
        selectedEscopo,
        setSelectedEscopo,
        handleChangePage,
        totalPages,
        page,
        setPage,
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEscopoEl, setAnchorEscopoEl] = useState(null);

    const handleClickBtnTemporada = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClickBtnEscopo = (event) => {
        setAnchorEscopoEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEscopoEl(null);
    };

    const handleChangeTemporada = (newValue) => {
        setSelectedTemporada(newValue)
        setAnchorEl(null)
        setPage(1)
    }

    const handleChangeEscopo = (newValue) => {
        setSelectedEscopo(newValue)
        setAnchorEscopoEl(null)
        setPage(1)
    }

    return (
        <div className={classes.root}>

            <Paper className={clsx(classes.CardRoot, { [classes.CardRootSecondary]: tipoCard === 1 })}>
                {tipoCard === 1 ?
                    ''
                    :
                    <div className={classes.cardTop}>
                        <Button className={classes.btnDropdown}
                            onClick={handleClickBtnTemporada}>
                            Ano: {selectedTemporada}
                            <UnfoldMoreIcon />
                        </Button>

                        <Menu
                            elevation={1}
                            anchorEl={anchorEl}
                            keepMounted
                            disableScrollLock
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {temporadas.map((item, key) => (
                                <MenuItem onClick={() => handleChangeTemporada(item.value)} key={key}>
                                    <ListItemText primary={item.label} />
                                </MenuItem>
                            ))}
                        </Menu>

                        <Button className={classes.btnDropdown}
                            onClick={handleClickBtnEscopo}>
                            Categoria: {selectedEscopo}
                            <UnfoldMoreIcon />
                        </Button>
                        <Menu
                            elevation={1}
                            anchorEl={anchorEscopoEl}
                            keepMounted
                            disableScrollLock
                            open={Boolean(anchorEscopoEl)}
                            onClose={handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            {escopo.map((item, key) => (
                                <MenuItem onClick={() => handleChangeEscopo(item.value)} key={key}>
                                    <ListItemText primary={item.label} />
                                </MenuItem>
                            ))}
                        </Menu>

                    </div>
                }
                <div className={clsx(classes.CardContent, { [classes.CardContentSecondary]: tipoCard === 1 })}>
                    {errorEventos ?
                        <Alert severity="error" className={classes.Alert}>
                            <AlertTitle>Ops!</AlertTitle>
                            Nenhum evento encontrado.
                        </Alert>
                        :
                        (loadingEventos ? Array.from(new Array(2)) : eventos).map((item, key) => (
                            <React.Fragment key={key}>
                                {loadingEventos ?
                                    <Skeleton variant="text" style={{ backgroundColor: 'rgb(255 255 255 / 65%)', height: 40, width: 180 }} />
                                    :
                                    <Typography className={clsx(classes.titulo, { [classes.tituloSecondary]: tipoCard === 1 })}>
                                        {`${meses[item[0].month]} ${(item[0].data_inicio).substr(0, 4)}`}
                                    </Typography>
                                }

                                {(loadingEventos ? Array.from(new Array(2)) : item).map((evento, index) => (
                                    <CardEvento key={index}
                                        evento={evento}
                                        tipoCard={tipoCard}
                                        loadingEventos={loadingEventos} />
                                ))}
                            </React.Fragment>
                        ))}

                    {totalPages > 1 ?
                        <div className={classes.paginationContainer}>
                            <Pagination count={totalPages} page={page} onChange={handleChangePage} />
                        </div>
                        :
                        <></>
                    }
                </div>
            </Paper>

        </div>
    )
}