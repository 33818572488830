import { AccordionDetails, Avatar, Card, Container, Grid, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { PlusIconSVG, RecursosSVG } from "../../assets/icons/Transparencia";
import { gestaoApi } from "../../services/api";
import { AccordionConta, AccordionContaSummary, useStyles } from "../home/styles/Transparencia";
import { formataCPF } from "../../util/formatString";
import { convertDate } from "../../util/convertDate";
import { Skeleton } from "@material-ui/lab";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function PageTransparenciaPessoa(params) {
  const classes = useStyles();
  const { id } = useParams();

  const [pessoa, setPessoa] = useState({
    loading: true,
    error: true,
    payload: [],
  });

  useEffect(() => {
    setPessoa({
      loading: true,
      error: false,
      payload: [],
    });
    async function getPessoas() {
      await gestaoApi
        .get("pessoa", {
          params: {
            id: id,
            expand: "departamento,cargo,cpf",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setPessoa({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setPessoa({
              loading: false,
              error: false,
              payload: response.data.items[0],
            });
          }
        });
    }
    getPessoas();
  }, [id]);

  const [conta, setConta] = useState({
    loading: true,
    error: false,
    payload: [],
  });

  useEffect(() => {
    setConta({
      loading: true,
      error: false,
      payload: [],
    });
    async function getPessoas() {
      await gestaoApi
        .get("financeiro-contas", {
          params: {
            id_vinculo: id,
            id_servico: 19,
            tipo_vinculo: "PESSOA",
            flag_del: 0,
            sort: "-vencimento",
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setConta({
              loading: false,
              error: true,
              payload: [],
            });
          } else {
            setConta({
              loading: false,
              error: false,
              payload: response.data.items,
            });
          }
        });
    }
    getPessoas();
  }, [id]);

  const formatReferenciaContas = (competencia) => {
    let data = "";
    if (competencia.data_pagamento) {
      data = new Date(competencia.data_pagamento).toLocaleDateString("pt-br", {
        month: "long",
        year: "numeric",
      });
    } else {
      if (competencia.vencimento) {
        data = new Date(competencia.vencimento).toLocaleDateString("pt-br", {
          month: "long",
          year: "numeric",
        });
      }
    }

    return data;
  };

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Transparência - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais</title>
        <meta
          name="description"
          content="Transparência - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais"
        />
      </Helmet>
      <div className={classes.blueBrackground}></div>
      <div className={classes.sectionTop}></div>
      {/* <div className={classes.purpleGradient}></div> */}
      <div className={classes.purpleGradient2}></div>
      <div className={classes.blueGradient}></div>

      <Container className={classes.contentContainer}>
        <Typography className={classes.title}>Transparência CBSO</Typography>
      </Container>
      <div style={{ zIndex: 10 }}>
        <div className={classes.GridContainer}>
          <Container maxWidth="md" className={classes.contentContainer}>
            <Grid item xs={12} sm={12} md={12} style={{ zIndex: 4 }}>
              <Card className={clsx(classes.cardItem, classes.cardItemExpanded, classes.cardItemDisabled)}>
                <Avatar>
                  <RecursosSVG />
                </Avatar>
                <Typography className={clsx(classes.cardTitle, classes.cardTitleLG)}>Recursos Humanos</Typography>
              </Card>
            </Grid>
            {pessoa.loading ? (
              <Container maxWidth="sm">
                <Skeleton variant="text" height={30} style={{ marginBottom: 20, marginTop: 20 }} />
                <Skeleton variant="rect" height={400} />
              </Container>
            ) : (
              <div className={classes.pessoaRoot}>
                <Container maxWidth="sm">
                  <Typography variant="h1" className={classes.TituloNomePessoa}>
                    {pessoa.payload.nome_completo}
                  </Typography>

                  <Paper className={classes.pessoaInfoCard}>
                    <Typography variant="caption" className={classes.infoLabel}>
                      CPF
                    </Typography>
                    <Typography variant="body1">
                      {pessoa.payload.cpf ? formataCPF(pessoa.payload.cpf?.numero) : "xxx.xxx.xxx-xx"}
                    </Typography>

                    <Typography variant="caption" className={classes.infoLabel}>
                      ÁREA VINCULADA
                    </Typography>
                    <Typography variant="body1">{pessoa.payload?.cargo?.descricao}</Typography>

                    <Typography variant="caption" className={classes.infoLabel}>
                      FUNÇÃO DESEMPENHADA
                    </Typography>
                    <Typography variant="body1">{pessoa.payload?.departamento?.descricao}</Typography>

                    <Typography variant="caption" className={classes.infoLabel}>
                      ADMISSÃO
                    </Typography>
                    <Typography variant="body1">
                      {pessoa.payload.data_admissao ? convertDate(pessoa.payload?.data_admissao) : "xxx"}
                    </Typography>

                    <Typography variant="caption" className={classes.infoLabel}>
                      DESLIGAMENTO
                    </Typography>
                    <Typography variant="body1">
                      {pessoa.payload.data_desligamento ? convertDate(pessoa.payload?.data_desligamento) : "xxx"}
                    </Typography>
                  </Paper>
                  {!conta.error && (
                    <>
                      {conta.loading ? (
                        <div>
                          <Skeleton height={70} />
                          <Skeleton height={70} />
                          <Skeleton height={70} />
                        </div>
                      ) : (
                        <React.Fragment>
                          {conta.payload.map((item, key) => (
                            <div key={key} className={classes.accordionsWrapper}>
                              <AccordionConta classes={classes.AccordioConta}>
                                <AccordionContaSummary expandIcon={<PlusIconSVG />}>
                                  <Typography>REFERÊNCIA {formatReferenciaContas(item)}</Typography>
                                </AccordionContaSummary>
                                <AccordionDetails>
                                  <div dangerouslySetInnerHTML={{ __html: item.observacoes || '' }} />
                                </AccordionDetails>
                              </AccordionConta>
                            </div>
                          ))}
                        </React.Fragment>
                      )}{" "}
                    </>
                  )}
                </Container>
              </div>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}
