import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import { SelectInput } from './styles'
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';

const useStyles = makeStyles((theme) => ({
    formControl: {
        '& .MuiInputBase-root, & .MuiSelect-root': {
            borderRadius: 30,
        },
        '& fieldset': {
            border: `2px solid ${theme.palette.info.main} !important`,
        },
        '& svg': {
            color: theme.palette.info.main,
        },
        '& label': {
            margin: '-5px 0',
            fontWeight: 500,
            color: theme.palette.info.main,
            '&.Mui-focused':{
                color: theme.palette.info.main,
            }
        },
        '& .MuiInputBase-root': {
            height: 45,
            padding: '3px 14px', 
            color:'#fff',

        },
        '& .MuiOutlinedInput-input': {
            padding: '11.5px 14px',
        },
        '& .MuiSelect-select:focus': {
            height: 0,
            backgroundColor: 'rgba(44, 85, 249, 0.05)'
        }
    },
}));

export default function DropdownSelect(props) {
    const classes = useStyles();
    const { label, selectedValue, handleChange, selectOptions } = props;

    return (
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <InputLabel id="select-outlined-label">{label}</InputLabel>
            {props.children ?
                <SelectInput
                    labelId="select-outlined-label"
                    id="select-outlined"
                    value={selectedValue}
                    onChange={handleChange}
                    label={label}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    }}
                >{props.children}</SelectInput>
                :
                <SelectInput
                    labelId="select-outlined-label"
                    id="select-outlined"
                    value={selectedValue}
                    onChange={handleChange}
                    label={label}
                    IconComponent={KeyboardArrowDownRoundedIcon}
                    MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        }
                    }}
                >
                    <MenuItem value="">
                        <em>Todos</em>
                    </MenuItem>
                    {selectOptions.map((item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                </SelectInput>
            }
        </FormControl>
    );
}
