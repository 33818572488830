import { Button, Typography } from "@material-ui/core";
import { Container } from "@material-ui/core";
import { useStyles } from "../styles/Sobre";
import { Link } from "react-router-dom";

export default function Sobre() {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{textAlign: "justify"}}>

            <Container maxWidth="sm" className="section-1">
                <div className="sectionTop"></div>
                <div className={classes.section}>
                    <Typography variant="body1" className={classes.sectionText}>
                      A Saltos Brasil oferece estrutura e conhecimento necessários para que atletas, técnicos e árbitros possam focar no que importa, com missão de difundir os
                      Saltos Ornamentais como esporte nacional.
                    </Typography>
                    <div className="btnWrapper">
                        <Button component={Link} className={classes.btn1} variant="contained" to={'equipe'}>VER FEDERADOS</Button>
                    </div>
                </div>
            </Container>

            <Container maxWidth="sm" className="section-2">
                <div className="sectionTop"></div>
                <div className={classes.section}>
                    <Typography variant="body1" className={classes.sectionText}>
                      Somos responsáveis pela organização dos eventos nacionais de Saltos Ornamentais e levamos a seleção brasileira para as mais
                      importantes competições mundiais.
                    </Typography>
                    <div className="btnWrapper">
                        <Button className={classes.btn2} component={Link} to={'competicoes'} variant="contained">VER COMPETIÇÕES</Button>
                    </div>
                </div>
            </Container>

            <Container maxWidth="sm" className="section-3">
                <div className="sectionTop"></div>
                <div className={classes.section}>
                    <Typography variant="body1" className={classes.sectionText}>
                      Com ética e paixão, a Saltos Brasil é administrada com objetivo de ser a confederação mais moderna e transparente do Brasil
                    </Typography>
                    <div className="btnWrapper">
                        <Button className={classes.btn3} component={Link} to={'transparencia'} variant="contained">VER TRANSPARÊNCIA</Button>
                    </div>
                </div>
            </Container>
        </div>
    )
}
