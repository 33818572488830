import React, { useEffect, useState } from "react";
import { useStyles } from "../home/styles/Transparencia";
import { governancaApi } from "../../services/api";
import {
  Avatar,
  Button,
  ButtonBase,
  Card,
  Collapse,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Alert, Skeleton } from "@material-ui/lab";
import { PlusIconSVG } from "../../assets/icons/Transparencia";
import { DownloadSVG } from "../../assets/icons/LogosTemplate";
import _ from "lodash";
import { getCardIcon } from "./CardIcons";

function TransparenciaItem(props) {
  const {
    item,
    index,
    expanded,
    activeIdMenu,
    idMenu,
    expandedAnexos,
    handleClickAnexos,
  } = props;
  const classes = useStyles();
  const [orgao, setOrgao] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    setOrgao([]);
    if (activeIdMenu === idMenu) {
      setError(false);
      setLoading(true);
      async function getOrgao() {
        await governancaApi
          .get("orgao", {
            params: {
              id_menu: idMenu,
              flag_del: 0,
              sort: "-data",
              expand: "orgaoAnexos",
              pageSize: 100,
            },
          })
          .then((response) => {
            if (response.data._meta.totalCount === 0) {
              setError(true);
              setLoading(false);
              setOrgao([]);
            } else {
              setOrgao(response.data.items);
              setLoading(false);
            }
          });
      }
      getOrgao();
    } else {
      setOrgao([]);
      setError(true);
    }
  }, [idMenu, activeIdMenu, expanded]);

  return (
    <React.Fragment key={index}>
      <div className={classes.GridContainer}>
        <Container className={classes.contentContainer}>
          <Grid item xs={12} sm={12} md={12} style={{ zIndex: 4 }}>
            <Link
              to={
                expanded[item.id_menu]
                  ? "/transparencia"
                  : `/transparencia?q=${item.slug}`
              }
              className={classes.linkTransparencia}
            >
              <Card
                className={clsx(classes.cardItem, {
                  [classes.cardItemExpanded]: expanded[item.id_menu],
                })}
              >
                <Avatar>{getCardIcon(item.icon)}</Avatar>
                <Typography
                  className={clsx(classes.cardTitle, classes.cardTitleLG)}
                >
                  {item.title}
                </Typography>
              </Card>
            </Link>
          </Grid>
        </Container>
      </div>
      <Container className={classes.CollapseContainer}>
        <Collapse in={expanded[item.id_menu]} className={classes.Collapse}>
          <div className={classes.collapseContent}>
            {error ? (
              <div className={classes.errorContainer}>
                <Alert severity="error">
                  Não há documentos cadastrados para este item.
                </Alert>
              </div>
            ) : loading ? (
              ""
            ) : (
              <React.Fragment>
                {_.orderBy(orgao, ["data"], ["desc"]).map((item, key) => (
                  <div key={key}>
                    <ButtonBase
                      disableRipple
                      className={classes.docTitleWrapper}
                      onClick={(e) => handleClickAnexos(e.currentTarget, key)}
                    >
                      <Typography className={classes.arquivoTitle}>
                        {item ? (
                          item.titulo
                        ) : (
                          <Skeleton
                            animation="wave"
                            variant="text"
                            style={{ width: "100%" }}
                          />
                        )}
                      </Typography>
                      <IconButton
                        className={clsx(classes.btnExpandAnexos, {
                          [classes.btnRotate]: expandedAnexos[key],
                        })}
                        onClick={(e) => handleClickAnexos(e.currentTarget, key)}
                      >
                        <PlusIconSVG />
                      </IconButton>
                    </ButtonBase>
                    <Collapse
                      in={expandedAnexos[key]}
                      className={classes.AnexosCollapse}
                    >
                      {item ? (
                        <Paper className={classes.anexosContainer}>
                          {_.orderBy(
                            item.orgaoAnexos,
                            ["created_at"],
                            ["desc"]
                          ).map((anexo, key) => (
                            <div key={key} className={classes.anexoWrapper}>
                              <Button
                                href={`https://saltosbrasil.bigmidia.com/_uploads/orgaoAnexo/${anexo.nome_arquivo}`}
                                target="_blank"
                                variant="outlined"
                                color="primary"
                                size="small"
                                className={classes.btnAnexo}
                              >
                                <DownloadSVG /> Baixar arquivo
                              </Button>
                              <Typography
                                className={classes.arquivoTitleInterno}
                              >
                                <br />
                                {anexo.titulo}
                              </Typography>
                            </div>
                          ))}
                        </Paper>
                      ) : (
                        ""
                      )}
                    </Collapse>
                    <Divider
                      className={classes.Divider}
                      style={expandedAnexos[key] ? { marginTop: 0 } : {}}
                    />
                  </div>
                ))}
              </React.Fragment>
            )}
          </div>
        </Collapse>
      </Container>
    </React.Fragment>
  );
}

export default TransparenciaItem;
