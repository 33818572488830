import {
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { formatDecimalNum } from "../../../util/formatString";
import { calculaNJ } from "../functions/calculaNJ";
import { calculaSalto } from "../functions/calculaSalto";
import { useStyles } from "../styles/DetalhesSalto";

export default function DetalhesSalto(props) {
  const { boletimSalto, isSincronizado } = props;
  const classes = useStyles();
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const ProvaSincronizada = () => {
    if (boletimSalto.notasSaltosSinc) {
      return (
        <TableRow>
          {boletimSalto.notasSaltosSinc.map((item, index) =>
            item.notasSaltos.map((item, key) => (
              <TableCell
                key={key}
                className={clsx(classes.saltoTD, {
                  [classes.saltoCancelado]: item.stroke,
                })}
              >
                {index === 0 ? <span>E</span> : <span>S</span>}
                <Typography variant="body2">
                  {" "}
                  {item ? formatDecimalNum(item.nota) : ""}
                </Typography>
              </TableCell>
            ))
          )}
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell></TableCell>
        </TableRow>
      );
    }
  };

  const ProvaIndividual = () => {
    if (boletimSalto.notasSaltos) {

      return (
        <TableRow>
          {boletimSalto.notasSaltos.map((item, key) => (

            <TableCell
              key={key}
              className={clsx(classes.saltoTD, {
                [classes.saltoCancelado]: item.stroke,
              })}
            >
              <Typography variant="body2">
                {item ? formatDecimalNum(item.nota) : ""}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          <TableCell></TableCell>
        </TableRow>
      );
    }
  };

  return (
    <div className={classes.saltoDetails}>
      <div>
        <div>
          <Typography variant="h6" className={classes.tituloDetalhamento}>
            Salto #{boletimSalto.num_ordem ? boletimSalto.num_ordem : ""}{" "}
            <span>
              {boletimSalto.tipoSalto ? boletimSalto.tipoSalto.codigo : ""}
              {boletimSalto.posicao ? boletimSalto.posicao : ""}
            </span>
          </Typography>

          {matchesSm ? (
            <React.Fragment>
              <div className={classes.textInfo}>
                {boletimSalto.tipoSalto ? boletimSalto.tipoSalto.nome_pt : ""}
              </div>

              <div className={classes.textInfo}>
                Dificuldade x{boletimSalto.num_gd ? boletimSalto.num_gd : ""}
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        {matchesSm ? <Divider className={classes.divider} /> : ""}

        <Grid container alignItems="center" className={classes.tabelaGrid}>
          <Table size="small" className={classes.tabelaPontuacaoJ}>
            <TableHead>
              {isSincronizado ? (
                <TableRow>
                  {Array.from(
                    new Array(
                      boletimSalto.notasSaltosSinc
                        ? calculaNJ(boletimSalto.notasSaltosSinc)
                        : 5
                    )
                  ).map((item, key) => (
                    <TableCell key={key}>J{key + 1}</TableCell>
                  ))}
                </TableRow>
              ) : (
                <TableRow>
                  {(boletimSalto.notasSaltos
                    ? boletimSalto.notasSaltos
                    : Array.from(new Array(5))
                  ).map((item, key) => (
                    <TableCell key={key}>J{key + 1}</TableCell>
                  ))}
                </TableRow>
              )}
            </TableHead>
            <TableBody>
              {isSincronizado ? ProvaSincronizada() : ProvaIndividual()}
            </TableBody>
          </Table>
          <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
          />

          <Typography className={classes.dificuldade} style={{color:"red", fontWeight:"bold"}}>
            PEN.<br />{boletimSalto.nota_penalidade === '0.00' ? '-' : boletimSalto.nota_penalidade}
          </Typography>
          <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
          />
          <div>
            <Typography className={classes.pontosTotalTabela}>
              {formatDecimalNum(
                calculaSalto(boletimSalto, isSincronizado).toString(),
                true
              )}
            </Typography>
            <Typography className={classes.dificuldade}>
              x{boletimSalto.num_gd}
            </Typography>
          </div>
          <Divider
            orientation="vertical"
            flexItem
            className={classes.divider}
          />
          <Typography className={classes.pontosTotal}>
            {boletimSalto.nota_final}
          </Typography>
        </Grid>
        {!matchesSm ? (
          <React.Fragment>
            <Divider className={classes.divider} />
            <div className={classes.textInfo}>
              {boletimSalto.tipoSalto ? boletimSalto.tipoSalto.nome_pt : ""}
              <span>
                Dificuldade x{boletimSalto.num_gd ? boletimSalto.num_gd : ""}
              </span>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
