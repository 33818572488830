import { Typography } from "@material-ui/core";
import React from "react";
import { DownloadSVG } from "../../../../assets/icons/LogosTemplate";
import { SecondaryIconBtn } from "../../../../components/Buttons/Buttons";
import { useStyles } from "../../styles/CardBoletim";

function CardItemBoletim({ boletim, nomeArquivo }) {
  const classes = useStyles();
  return (
    <div className={!nomeArquivo && classes.downdload}>
      <SecondaryIconBtn
        size="small"
        target="_blank"
        href={boletim && `https://saltosbrasil.bigmidia.com/_uploads/arquivoDocumento/${boletim.nome_arquivo}`}
        className={classes.downloadBtn}
      >
        <DownloadSVG />
      </SecondaryIconBtn>
      <a
        target="_blank"
        rel="noreferrer"
        href={boletim && `https://saltosbrasil.bigmidia.com/_uploads/arquivoDocumento/${boletim.nome_arquivo}`}
      >
        <Typography className={classes.textBaixar}>{nomeArquivo || "Baixar Boletim"}</Typography>
      </a>
    </div>
  );
}

export default CardItemBoletim;
