import { alpha, lighten, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: -35,
    marginLeft: -16,
    marginRight: -16,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginLeft: 0,
      marginRight: 0,
      position: "absolute",
      left: 0,
      top: -120,
      width: 500,
      padding: 16,
    },
    [theme.breakpoints.up("md")]: {
      top: -120,
      width: 650,
    },
    [theme.breakpoints.up("lg")]: {
      width: 670,
    },
  },
  cardQuemSomos: {
    backgroundColor: "transparent",
    boxShadow: "none",
    borderRadius: 0,
    position: "relative",
    zIndex: 3,
  },
  cardTop: {
    height: 40,
    marginBottom: -25,
    width: "97%",
    marginLeft: "auto",
    marginRight: "auto",
    background: `linear-gradient(126deg, ${theme.palette.primary.light} 0%, ${theme.palette.info.light} 38%, ${theme.palette.warning.light} 100%)`,
    boxShadow: `0px 3px 6px #010B1A`,
    borderRadius: "40px 40px 0px 0px",
  },
  cardBottom: {
    height: 40,
    marginTop: -20,
    width: "90%",
    background: `linear-gradient(100deg, ${theme.palette.primary.light} 0%, ${theme.palette.info.light} 38%, ${theme.palette.warning.light} 100%)`,
    boxShadow: `0px 10px 20px #1EB1C480`,
    borderRadius: "0px 0px 40px 20px",
  },
  cardContent: {
    padding: theme.spacing(4, 3),
    background: lighten(theme.palette.secondary.pale, 0.4),
    borderRadius: 30,
    opacity: 1,
    backdropFilter: "blur(15px)",
    [theme.breakpoints.up("md")]: {
      background: alpha(theme.palette.secondary.pale, 0.8),
      padding: theme.spacing(3, 4),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(4, 6),
    },
  },
  cardTitle: {
    fontFamily: "'Quicksand', sans-serif",
    fontSize: "2rem",
    fontWeight: "bold",
    color: theme.palette.secondary.dark80,
    marginBottom: "2rem",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "3rem",
    },
  },
  cardText: {
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 600,
    color: theme.palette.primary.darker,
  },
}));
