import { makeStyles } from "@material-ui/core";
export const useStyles = makeStyles((theme) => ({
  root: {},
  eventoDescricao: {
    color: theme.palette.warning.light,
    fontSize: theme.typography.pxToRem(24),
    fontFamily: "'Roboto', sans-serif",
    fontWeight: 300,
    lineHeight: 1.2,
    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(36),
    },
  },
  cardText: {
    color: theme.palette.primary.white,
    fontSize: ".9rem",
    fontFamily: "'Roboto', sans-serif",
    lineHeight: 1.2,
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  eventoLocal: {
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    "& .MuiAvatar-root": {
      marginRight: 10,
      color: "#fff",
      backgroundColor: "#fff",
      border: `1px solid ${theme.palette.info.dark}`,
    },
    "& .MuiTypography-body1": {
      color: "#E5FCFF",
      fontSize: "1rem",
      fontFamily: "'Quicksand', sans-serif",
    },
  },
  eventInfo: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
    },
  },
  eventInfoLeft: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      width: "unset",
      alignItems: "center",
      flexDirection: "row",
    },
  },
  eventLocal: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginRight: 20,
    alignSelf: "flex-start",
    marginBottom: 10,
    color: theme.palette.error.light,
    "& .MuiTypography-body1": {
      fontSize: "0.9rem",
      fontFamily: "'Quicksand', sans-serif",
      fontWeight: 900,
    },
    [theme.breakpoints.up("md")]: {
      width: "unset",
      alignSelf: "center",
      marginBottom: 0,
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: 25,
    },
    "& svg": {
      height: "1.2rem",
      marginRight: ".2rem",
    },
  },
  eventData: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.9rem",
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 900,
    color: theme.palette.info.light,
    marginRight: 20,
    alignSelf: "flex-start",
    marginBottom: 16,
    [theme.breakpoints.up("md")]: {
      alignSelf: "center",
      marginBottom: 0,
    },
    [theme.breakpoints.up("lg")]: {
      marginRight: 25,
    },
    "& svg": {
      height: "1.2rem",
      marginRight: ".2rem",
      fill: theme.palette.info.light,
    },
  },
  dataInscricao: {
    backgroundColor: theme.palette.error.main,
    padding: theme.spacing(1, 2),
    color: "#fff",
    borderRadius: 12,
    fontSize: "0.9rem",
    marginTop: 16,
    [theme.breakpoints.up("md")]: {
      marginTop: 0,
    },
  },
  btnCertificado: {
    backgroundColor: theme.palette.info.main,
    color: "#fff",
    padding: theme.spacing(0.6, 1.6),
    borderRadius: 12,
    margin: theme.spacing(0.5, 0),
    "&:hover": {
      backgroundColor: theme.palette.info.dark,
    },
  },
}));
