import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { ButtonBase, Container, IconButton, useMediaQuery } from '@material-ui/core';
import { Link } from "react-router-dom";
// import logoSaltos from '../../../assets/images/logoSaltos.png';
import MenuIcon from '@material-ui/icons/Menu';
import SearchInput from '../../searchInput';
import { SearchMenu, useStyles } from '../styles/Header';
import { LoginBtnSM } from '../components/LoginBtn';
import { SearchSVG } from '../../../assets/icons/NavIcons';
import HeaderCarousel from './HeaderCarousel';
import SaltosBrasil from '../../../assets/icons/SaltosBrasil';
import { menu } from '../../../assets/data/menu';

export default function HeaderComponent(props) {
    const classes = useStyles();
    const { openMenu, setOpenMenu } = props;
    const matchesMD = useMediaQuery(theme => theme.breakpoints.up('md'));
    const matchesLG = useMediaQuery(theme => theme.breakpoints.up('lg'));

    const [anchorSearchEl, setAnchorSearchEl] = useState(null);

    const handleSearchClick = (event) => {
        setAnchorSearchEl(event.currentTarget);
    };

    const handleSearchClose = () => {
        setAnchorSearchEl(null);
    };

    const handleClickOpenMenu = () => {
        setOpenMenu(!openMenu);
    };

    const newDesign = true;

    if (openMenu) {
        return ('')
    }

    return (
        <div className={classes.root}>
            <AppBar className={classes.AppBar} elevation={0} position="fixed" >
                <div style={{ position: 'relative' }}>
                    {/* <div className={classes.headerBrand}></div> */}
                    <Container>
                        <Toolbar className={classes.Toolbar}>
                            <div className={classes.brandWrapper}>
                                <ButtonBase component={Link} to="/" className={classes.btnLogo}>
                                    <SaltosBrasil width="113px" height="47px" />
                                    {/* <img className={classes.logo} src={logoSaltos} alt="logo saltos" /> */}
                                </ButtonBase>
                            </div>
                            {matchesMD ?
                                <div className={classes.navbar}>
                                    {menu.map((item, key) => (
                                        <Button key={key} className={classes.NavLink} component={Link} to={item.url}>{item.titulo}</Button>
                                    ))}
                                   
                                    {matchesLG ?
                                        <React.Fragment>
                                            <SearchInput />
                                            <LoginBtnSM href="https://saltosbrasil.bigmidia.com/site/login" target="_blank" rel="noreferrer" color="primary" style={{ marginLeft: 15 }}>
                                                login
                                            </LoginBtnSM>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            {newDesign ?
                                                ''
                                                :
                                                <div style={{ marginLeft: 14 }}>
                                                    <IconButton aria-label="search" color="primary"
                                                        aria-controls="search-menu"
                                                        aria-haspopup="true"
                                                        onClick={handleSearchClick}
                                                        className={classes.searchButton}>
                                                        <SearchSVG />
                                                    </IconButton>
                                                    <SearchMenu
                                                        id="search-menu"
                                                        anchorEl={anchorSearchEl}
                                                        open={Boolean(anchorSearchEl)}
                                                        onClose={handleSearchClose}
                                                        style={{ margin: 10 }}
                                                    >
                                                        <SearchInput mobile={false} collapsed={true} setAnchorSearchEl={setAnchorSearchEl} />
                                                    </SearchMenu>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }
                                </div>
                                :
                                <IconButton onClick={handleClickOpenMenu} edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
                                    <MenuIcon />
                                </IconButton>
                            }
                        </Toolbar>
                    </Container>
                    {matchesMD ? <HeaderCarousel /> : ''}
                </div>
            </AppBar>
            {/* <HeaderCarousel/> */}
            <Toolbar className={classes.ToolbarHeight} />
        </div>
    );
}
