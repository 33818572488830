import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(4, 0),
    color: "#fff",
    background: "rgba(255, 255, 255, 0.2)",
    border: `1px solid rgba(255, 255, 255, 0.3)`,
    borderRadius: 16,
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(5px)",
  },
  cardContent: {
    padding: theme.spacing(5, 4),
  },
  section: {
    paddingBottom: theme.spacing(3),
    // border: `1px solid #000`,
    "& .sectionText": {
      fontSize: "1.1rem",
      color: "inherit",
      fontFamily: "'Quicksand', sans-serif",
    },
    "& .sectionTop": {
      marginBottom: ".5em",
      fontSize: "1.5rem",
      fontWeight: 600,
    },

    "&.section-1 ": {
      "& .MuiButtonBase-root": {
        background: `transparent linear-gradient(283deg, ${theme.palette.error.main} 0%, ${theme.palette.error.light} 100%) 0% 0% no-repeat padding-box`,
        color: "#fff",
      },
      "& .sectionTop": {
        color: theme.palette.error.main,
      },
    },
    "&.section-2": {
      "& .MuiButtonBase-root": {
        background: `transparent linear-gradient(283deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%) 0% 0% no-repeat padding-box`,
        color: "#fff",
      },
      "& .sectionTop": {
        color: theme.palette.primary.main,
      },
    },
    "&.section-3": {
      "& .MuiButtonBase-root": {
        background: `transparent linear-gradient(283deg, ${theme.palette.info.main} 0%, ${theme.palette.info.light} 100%) 0% 0% no-repeat padding-box`,
        color: "#fff",
      },
      "& .sectionTop": {
        color: theme.palette.info.main,
      },
    },
    "&.section-4": {
      "& .MuiButtonBase-root": {
        background: `transparent linear-gradient(283deg, ${theme.palette.secondary.main} 0%, ${theme.palette.secondary.light} 100%) 0% 0% no-repeat padding-box`,
        color: "#fff",
      },
      "& .sectionTop": {
        color: theme.palette.secondary.main,
      },
    },
    "&.section-5": {
      "& .MuiButtonBase-root": {
        background: `transparent linear-gradient(283deg, ${theme.palette.success.main} 0%, ${theme.palette.success.light} 100%) 0% 0% no-repeat padding-box`,
        color: "#fff",
      },
      "& .sectionTop": {
        color: theme.palette.success.main,
      },
    },
  },
  sectionAction: {
    marginTop: 30,
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "left",
    },
    "& .MuiButtonBase-root": {
      marginBottom: 30,
      padding: theme.spacing(2, 3.5),
      borderRadius: "12px",
      fontSize: ".95rem",
      fontWeight: "bold",
      fontFamily: "'Poppins', sans-serif",
      boxShadow: "none",
      "&:hover": {
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
      },
      //box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
      [theme.breakpoints.up("sm")]: {
        fontSize: "1rem",
        marginBottom: 0,
        minWidth: 280,
      },
    },
  },
}));
