import {
  ArquivosSVG,
  BalancetesSVG,
  ConselhoSVG,
  ConveniosSVG,
  LicitacoesSVG,
  RecursosSVG,
  RelatoriosSVG,
  TrabalheSVG,
} from "../../assets/icons/Transparencia";

export const getCardIcon = (iconString) => {
  if (iconString === "ArquivosSVG") {
    return <ArquivosSVG />;
  }
  if (iconString === "BalancetesSVG") {
    return <BalancetesSVG />;
  }
  if (iconString === "ConveniosSVG") {
    return <ConveniosSVG />;
  }
  if (iconString === "RelatoriosSVG") {
    return <RelatoriosSVG />;
  }
  if (iconString === "ConselhoSVG") {
    return <ConselhoSVG />;
  }
  if (iconString === "LicitacoesSVG") {
    return <LicitacoesSVG />;
  }
  if (iconString === "TrabalheSVG") {
    return <TrabalheSVG />;
  }
  if (iconString === "RecursosSVG") {
    return <RecursosSVG />;
  }

  return <ArquivosSVG />;
};
