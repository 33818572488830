import { Box, Container, Paper, Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "../styles";

function ClubeDevantagensPage() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.cardWhite}>
          <div className={classes.cardContent}>
            <Typography variant="h1" align="center">
              CLUBE DE VANTAGENS SALTOS BRASIL
            </Typography>
            <Typography variant="subtitle1"></Typography>
            <Typography>
              Seja Sócio Torcedor e faça parte do Clube de Vantagens Saltos
              Brasil, acessando milhares de benefícios em estabelecimentos de
              todo o país:
              {/* (listar todos os benefícios) */}
            </Typography>
          </div>
        </Paper>
      </Container>
    </Box>
  );
}

export default ClubeDevantagensPage;
