import { useStyles } from "./styles";
import TransparenciaSection from "../home/sections/Transparencia";
import NossosHeroisSobre from "../sobre/sections/NossosHerois";
import React, { useEffect, useState } from "react";
import { gestaoApi } from "../../services/api";
import { Container } from "@material-ui/core";
import FederadosSection from "./sections/federados";
import { Helmet } from "react-helmet";
import ClubesAssociados from "../sobre/sections/ClubesAssociados";

export default function PageEquipe(params) {
  const classes = useStyles();
  const { searchTerm } = params;

  const [atletas, setAtletas] = useState({
    error: false,
    loading: true,
    payload: [],
  });

  useEffect(() => {
    setAtletas({
      error: false,
      loading: true,
      payload: [],
    });
    async function getAtletas() {
      await gestaoApi
        .get("selecao", {
          params: {
            flag_del: 0,
            expand: "atletas,atleta",
            pageSize: 12,
          },
        })
        .then((response) => {
          if (response.data._meta.totalCount === 0) {
            setAtletas({
              error: true,
              loading: false,
              payload: [],
            });
          } else {
            setAtletas({
              error: false,
              loading: false,
              payload: response.data.items,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setAtletas({
            error: true,
            loading: false,
            payload: [],
          });
        });
    }
    getAtletas();
  }, []);

  const [estabelecimento, setEstabelecimento] = useState([]);
  const [loadingEstabelecimento, setLoadingEstabelecimento] = useState(true);

  useEffect(() => {
    setLoadingEstabelecimento(true);
    async function getClubes() {
      await gestaoApi
        .get("estabelecimento", {
          params: {
            flag_del: 0,
            flg_ativo: 1,
            // "estabelecimentoStatus.status": "A",
            // "estabelecimentoStatus.data_validade": "2021-12-31",
            id_estabelecimento_tipo: 3,
            pageSize: 12,
          },
        })
        .then((response) => {
          setEstabelecimento(response.data.items);
          if (response.data._meta.totalCount > 0) {
            setLoadingEstabelecimento(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    getClubes();
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Equipe - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais
        </title>
        <meta
          name="description"
          content="Equipe - Saltos Brasil – Confederação Brasileira de Saltos Ornamentais"
        />
      </Helmet>
      <div className={classes.root}>
        <Container className={classes.cardContainer}>
          <NossosHeroisSobre cardOnly={true} atletas={atletas} />
        </Container>
        <div className={classes.darkSection}>
          <FederadosSection searchTerm={searchTerm} />
          <Container>
            <NossosHeroisSobre textOnly={true} />
          </Container>
          <ClubesAssociados
            estabelecimento={estabelecimento}
            loading={loadingEstabelecimento}
            encontreClubeHidden={true}
          />
        </div>
        <TransparenciaSection />
      </div>
    </React.Fragment>
  );
}
