import { makeStyles, Tab, Tabs, withStyles } from "@material-ui/core";

export const NavTabs = withStyles((theme) => ({
    root: {
        minHeight: 60,
        paddingBottom:10,
    },
    scroller: {
        position: 'unset'
    },
    indicator: {
        top: -10,
        bottom: 'unset',
        height: 75,
        borderRadius: '50%',
        backgroundColor: theme.palette.warning.main,
        '& > span': {
            maxWidth: 40,
            width: '100%',
            backgroundColor: theme.palette.warning.main,
        },
    },
}))(Tabs);

export const NavTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        zIndex: 100,
        opacity: 1,
        '& .MuiSvgIcon-root': {
            height: '1.35em',
            width: '1.35em',
        },
        '&$selected': {
            // color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            // color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

export const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 'auto',
        bottom: -3,
        backgroundColor: theme.palette.warning.main,
        filter: 'drop-shadow(0px 1px 10px rgba(0,0,0,0.20))',
    },
    logoBtn: {
        margin: '0 auto',
        backgroundColor: theme.palette.warning.main,
        height: 60,
        maxWidth: 60
    },
    logoWrapper: {
        position: 'relative',
        marginBottom: 20,
    },
    logo: {
        position: 'absolute',
        height: 40,
    },
    home: {
        '& .MuiSvgIcon-root': {
            fontSize: '1.7rem',
        }
    },
    homeLogoSVG: {
        '& .MuiSvgIcon-root': {
            width: 'auto',
            height: '1.7em',
        }
    }
}));

