import { Select, withStyles } from "@material-ui/core";

export const SelectInput = withStyles((theme) => ({
    root: {
        // '& fieldset': {
        //     border: `2px solid ${theme.palette.info.main} !important`,
        // },
     
        // '& input': {
        //     padding: '3px 30px',
        //     borderRight: `2px solid ${theme.palette.info.main}`,
        //     '&::placeholder': {
        //         color: theme.palette.info.main,
        //         opacity: 1
        //     }
        // }

    }
}))(Select);

