import React, { useState } from 'react';
import { AppBar, useMediaQuery } from '@material-ui/core';
// import LogoIcon from '../../assets/images/logoIcon.png';
// import NavDialog from './sections/NavDialog';
import { useStyles, NavTabs, NavTab } from './styles';
import { BarsSVG, CalendarSVG, HomeLogoSVG, LifeRingSVG, NoticiasSVG, TimesCircleSVG } from '../../assets/icons/NavIcons';
import {Link} from 'react-router-dom'

export default function BottomAppBar(props) {
    const classes = useStyles();
    const [value, setValue] = useState(2);
    const { openMenu, setOpenMenu } = props;

    const matchesSm = useMediaQuery(theme => theme.breakpoints.up('sm'));

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpenMenu = () => {
        setOpenMenu(!openMenu);
        if (openMenu) {
            setValue(2)
        }
    };

    const handleClickCloseMenu = () => {
        setOpenMenu(false);
    };

    if (matchesSm) {
        return (<React.Fragment></React.Fragment>)
    }
    return (
        <React.Fragment>
            <AppBar position="fixed" color="primary" className={classes.appBar} elevation={0}>
                <NavTabs value={value} onChange={handleChange} variant="fullWidth" aria-label="navbar">
                    {/* <NavTab icon={<HomeSVG />} component={Link} to="/"  onClick={handleClickCloseMenu} /> */}
                    <NavTab icon={<NoticiasSVG />} component={Link} to="/noticias"  onClick={handleClickCloseMenu} />
                    <NavTab icon={<CalendarSVG />} component={Link} to="/competicoes" onClick={handleClickCloseMenu} />
                    <NavTab className={classes.homeLogoSVG} icon={<HomeLogoSVG />} component={Link} to="/"  onClick={handleClickCloseMenu} />
                    <NavTab icon={<LifeRingSVG />} component={Link} to="/apoio" onClick={handleClickCloseMenu} />
                    <NavTab icon={openMenu ? <TimesCircleSVG /> : <BarsSVG />} onClick={handleClickOpenMenu} />
                </NavTabs>
            </AppBar>
        </React.Fragment>
    );
}
