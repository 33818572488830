import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingBottom: 40,
        paddingTop: 40,
        // background: 'linear-gradient(168deg, #004A5300 0%, #004A5300 15%, #004A53 35%, #004B7D 100%)',
        // minHeight: '100vh',
        background: 'linear-gradient(175deg, #004A5300 0%, #004A5300 10%, #004A53 30%, #004B7D 100%)',
        minHeight: '30vh',
        marginBottom: -30,
        [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
            minHeight: '30vh',
            marginBottom: 0,
            paddingBottom: 20,
            background: 'transparent'
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: 80,
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: 100,
            paddingBottom: 60,
        },
    },
    rootPadding: {
        paddingTop: 0,
        minHeight: 'unset',
        background: 'none'
    },
    divider: {
        backgroundColor: theme.palette.warning.main,
        height: 0,
        [theme.breakpoints.up('md')]: {
            height: 6,
            borderRadius: 3,
            width: '70%',
            margin: 'auto',
        },
        [theme.breakpoints.up('lg')]: {
            borderRadius: 0,
            width: '100%',
        }
    },
    dividerPadding: {
        paddingTop: 0,
    },
    linkInstagram: {
        textAlign: 'center',
        '& svg': {
            marginTop: '.3em',
            fontSize: '2.5rem',
            [theme.breakpoints.up('sm')]: {
                fontSize: '3rem',
            },
            [theme.breakpoints.up('md')]: {
                fontSize: '4rem',
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '5rem',
            },
        }
    }
}));
